import { LoadingPage } from 'app/pages/LoadingPage';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Navigate, RouteProps } from 'react-router-dom';
import { getUser } from 'services/authServices';

const AuthorizedRoute = ({ children, module }: AuthorizedRouteProps) => {
  const [modules, setModules] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // const storedModules = localStorage.getItem('modules');
    // console.log(storedModules, 'storedModules');
    // if (storedModules) {
    //   setModules(storedModules.split(','));
    //   setIsLoading(false);
    // } else {
    getUser()
      .then(response => {
        if (response.status === 200) {
          localStorage.setItem('role', response.data.role);
          const token = localStorage.getItem('caravela_access_token');
          const url = `${process.env.REACT_APP_BASE_URL}/admin-role`;
          response.data.role &&
            axios
              .get(url, {
                params: { role: response.data.role },
                headers: { Authorization: `Bearer ${token}` },
              })
              .then(response => {
                if (response.status === 200) {
                  const filteredModules = response.data.filter(
                    value => value.split(';')[1] === 'View',
                  );
                  setModules(filteredModules);
                  localStorage.setItem('modules', filteredModules);
                  setIsLoading(false);
                }
              })
              .catch(error => {
                console.log(error);
                setIsLoading(false);
              });
        }
      })
      .catch(error => {
        console.log(error);
        setIsLoading(false);
      });
    // }
  }, []);

  if (isLoading) {
    // Render a loading state while waiting for modules to load
    return <LoadingPage />;
  }

  return modules.length > 0 && modules.includes(module) ? (
    children
  ) : (
    <Navigate to="/403" replace />
  );
};

interface AuthorizedRouteProps extends RouteProps {
  children?: any;
  module: string;
}

export default AuthorizedRoute;
