import styled from 'styled-components/macro';
import caravela from '../../../app/assets/SVG/caravela.svg';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';

const Button = styled.input`
  border: none;
  background: #5c36f3;
  color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  margin-bottom: 25px;
  width: 213.02px;
  height: 49.59px;
  margin: 0 10px;
`;
const Bg = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(15px);
`;

const Login = styled.div`
  width: 518px;
  height: 468px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: #ffffff;
  border-radius: 30px;
  z-index: 1000;
`;

const InputText = styled.div`
  font-style: normal;
  font-weight: 350;
  font-size: 20px;
  line-height: 24px;
  color: #545454;
  margin: 10px 0;
  padding: 0 50px;
`;

interface InfoProps {
  closeModal: () => void;
  openModalSign: boolean;
}

export function InfoModal({ closeModal, openModalSign }: InfoProps) {
  const { t } = useTranslation();

  const closeModalF = () => {
    closeModal();
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          closeModal();
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <Bg>
      <Login ref={wrapperRef}>
        <img
          src={caravela}
          alt="caravela logo"
          style={{ marginTop: '51px', marginBottom: '11px' }}
        />

        <div className="d-flex flex-column">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <InputText
              style={{
                margin: '0',
                padding: '0 30px',
                marginBottom: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                // marginTop: '30px',
              }}
            >
              {t(translations.general.oops)}
            </InputText>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Button
              style={{
                marginTop: '35px',
                background: 'blue',
              }}
              type="button"
              onClick={closeModalF}
              value={t(translations.general.close)}
            />
          </div>
        </div>
      </Login>
    </Bg>
  );
}
