import React from 'react';
import styled from 'styled-components/macro';
import { PopperUnstyled } from '@mui/base';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import tooltip_tail from 'app/assets/SVG/tooltip_tail.svg';

const StyledTooltipDiv = styled('div')`
  padding: 1em 2em;
  background-color: ${props => props.theme.Yellow};
  color: ${props => props.theme.Black};
  border-radius: 4px;
  font-weight: 325;
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.25);
  transform: translate(-5%, -50%);
  max-width: 25em;
  img {
    position: absolute;
    bottom: 0px;
    transform: translateX(-200%);
  }
`;

const Tooltip = ({
  children,
  label,
  visible,
  handleClickAway,
  withTail = true,
}: {
  children: any;
  label: any;
  visible: boolean;
  handleClickAway?: Function;
  withTail?: boolean;
}) => {
  const tooltipRef = React.useRef(null);
  return (
    <>
      <div ref={tooltipRef}>{children}</div>
      <PopperUnstyled
        open={visible}
        anchorEl={tooltipRef.current}
        placement="right-start"
      >
        <ClickAwayListener
          onClickAway={() => handleClickAway && handleClickAway()}
        >
          <StyledTooltipDiv>
            {withTail && <img src={tooltip_tail} alt="" />}
            {label}
          </StyledTooltipDiv>
        </ClickAwayListener>
      </PopperUnstyled>
    </>
  );
};
export default Tooltip;
