import styled, { useTheme } from 'styled-components/macro';
import caravela from '../../../app/assets/SVG/logo-caravela-2-SIN-LINEAS 2.png';
import { Link } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { ChangePassPayload } from 'models/authModels';
import { changePass } from 'services/authServices';

interface ChangePassProps {
  openCloseModal: () => void;
}

const Button = styled.input`
  border: none;
  background: #5c36f3;
  color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  margin-bottom: 25px;
  width: 213.02px;
  height: 49.59px;
  margin: 0 10px;
`;
const Bg = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(15px);
`;

const Login = styled.div`
  width: 518px;
  height: 708px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: #ffffff;
  border-radius: 30px;
  z-index: 1000;
`;

const InputPass = styled.input`
  width: 426.84px;
  height: 48.71px;
  left: 519.78px;
  top: 391.99px;
  background: #f9f9f9;
  border-radius: 30px;
  border: 0;
  padding: 0 20px;
`;

const ForgotPass = styled.div`
  font-style: normal;
  font-weight: 350;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  cursor: pointer;
  color: #f33636;
`;

const InputText = styled.div`
  font-style: normal;
  font-weight: 350;
  font-size: 20px;
  line-height: 24px;
  color: #545454;
  margin: 10px 0;
`;

export function ChangePassModal({ openCloseModal }: ChangePassProps) {
  const { t } = useTranslation();
  const theme: any = useTheme();

  const closeModal = () => {
    openCloseModal();
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          closeModal();
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const [error, setError] = useState('');
  const [changed, setChanged] = useState(false);
  const [current_password, setCurrent] = useState('');
  const [new_password, setNewP] = useState('');
  const [repeat, setRepeat] = useState('');

  const handleSubmit = event => {
    event.preventDefault();
    const payload: ChangePassPayload = { current_password, new_password };

    new_password !== repeat
      ? setError(t(translations.general.pass_no_match))
      : changePass(payload)
          .then(response => {
            if (response.status === 200) {
              setChanged(!changed);
            } else if (response.status === 400) {
              console.log('error');
            }
          })
          .catch(error => {
            setError(error);
          });
  };

  const resetModal = () => {
    setChanged(!changed);
    openCloseModal();
  };

  return (
    <Bg>
      <Login ref={wrapperRef}>
        <img
          src={caravela}
          alt="caravela logo"
          style={{ marginTop: '70px', marginBottom: '70px' }}
        />
        {changed ? (
          <>
            <div
              style={{
                color: 'green',
                display: 'flex',
                justifyContent: 'center',
                marginTop: '60px',
              }}
            >
              {t(translations.general.pass_changed)}
            </div>
            <Button
              type="button"
              style={{
                marginTop: '250px',
                background: theme.palette.primary.main,
              }}
              onClick={resetModal}
              value={t(translations.general.close)}
            />
          </>
        ) : (
          <form className="d-flex flex-column" onSubmit={handleSubmit}>
            <InputText>{t(translations.pages.newPass.current)}</InputText>
            <InputPass
              style={{ marginBottom: '15px' }}
              onChange={e => setCurrent(e.target.value)}
            />
            <InputText>{t(translations.pages.newPass.new)}</InputText>
            <InputPass
              style={{ marginBottom: '15px' }}
              onChange={e => setNewP(e.target.value)}
            />
            <InputText>{t(translations.pages.newPass.repeat)}</InputText>
            <InputPass
              style={{ marginBottom: '15px' }}
              onChange={e => setRepeat(e.target.value)}
            />
            {error !== '' ? (
              <div
                style={{
                  color: 'red',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {error}
              </div>
            ) : null}
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Link to="/reset-pass" style={{ textDecoration: 'none' }}>
                <ForgotPass>
                  {t(translations.pages.login.forgot_password)}
                </ForgotPass>
              </Link>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Button
                style={{
                  marginTop: '30px',
                  background: theme.palette.primary.main,
                }}
                type="submit"
                value={t(translations.general.confirm)}
              />
            </div>
          </form>
        )}
      </Login>
    </Bg>
  );
}
