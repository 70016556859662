import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import { QuantityTable } from 'app/components/QuantityTable';
import { useEffect, useState } from 'react';
import { useMediaQuery } from '@mui/material';
import { QuantityTableMobile } from 'app/components/QuantityTableMobile';

const Warehouse = styled.div<{ active: boolean }>`
  ${props =>
    props.active === true
      ? `
  background: #4F4E24; 
  color: white; 
  padding: 4px;
`
      : `
background: #D98458; 
color: white; 
`}
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  width: 214px;
  height: 39px;
  margin: 0 10px;
  cursor: pointer;
  overflow: hidden;
  text-align: center;
`;

const Total = styled.div<{ color: string }>`
  margin-top: 30px;
  border-radius: 35px;
  height: 59px;
  background: ${props => props.color};
  flex-grow: 1;
  margin: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 767px) {
    border-radius: 0px;
    margin: 0 0 1px 0;
  }
`;

const Text = styled.div`
  color: ${props => (props.color ? props.color : 'white')};
  size: 25px;
  line-height: 30px;
  margin: 0 30px;
`;

interface Step2Props {
  userData: any;
  storedData: any;
  setStoredData: (value: any) => void;
  deletedContracts: any;
  setDeletedContracts: (value: any) => void;
  dataToBeSent: any;
  setDataToBeSent: (value: any) => void;
}

export function SecondStep({
  userData,
  storedData,
  setStoredData,
  deletedContracts,
  setDeletedContracts,
  dataToBeSent,
  setDataToBeSent,
}: Step2Props) {
  const theme: any = useTheme();
  const [data, setData] = useState<any[]>([]);
  const [active, setActive] = useState('');
  const [total, setTotal] = useState({ boxes: [], bags: [], total: [] });
  const [totalShow, setTotalShow] = useState(0);
  const [bag, setBag] = useState(0);
  const [boxes, setBoxes] = useState(0);

  const isMobile = useMediaQuery('(max-width:767px)');

  useEffect(() => {
    let total = dataToBeSent
      .map(value => parseInt(value.quantity))
      .reduce((a, b) => a + b, 0);

    setTotalShow(total || 0);
    let bags = dataToBeSent
      .map(value => (value.type === 'Bags' ? parseInt(value.quantity) : 0))
      .reduce((a, b) => a + b, 0);

    setBag(bags || 0);
    let boxes = dataToBeSent
      .map(value => (value.type === 'Boxes' ? parseInt(value.quantity) : 0))
      .reduce((a, b) => a + b, 0);

    setBoxes(boxes || 0);
  }, [dataToBeSent]);

  const formatCoffe = coffe => {
    let groupingViaCommonProperty = Object.values(
      coffe.reduce((acc, current) => {
        acc[current.warehouse] = acc[current.warehouse] ?? [];
        acc[current.warehouse].push(current);
        return acc;
      }, {}),
    );
    let finalArray;
    let coffesFinal: any = [];
    for (let i = 0; i < groupingViaCommonProperty.length; i++) {
      let arr: any = groupingViaCommonProperty[i];
      finalArray = Object.values(
        arr.reduce((acc, current) => {
          acc[current.origin] = acc[current.origin] ?? [];
          acc[current.origin].push(current);
          return acc;
        }, {}),
      );
      coffesFinal.push(finalArray);
    }

    setActive(coffesFinal[0][0][0].warehouse);
    setData(coffesFinal);
  };

  const chageActive = warehouse => {
    setActive(warehouse);
  };

  useEffect(() => {
    console.log(userData);
    userData !== undefined &&
      userData[0].items.length > 0 &&
      formatCoffe(userData[0].items);
  }, [userData]);

  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>Purchase Order</title>
        <meta name="description" content="My Roasterhub order" />
      </Helmet>
      {data.length === 0 ? (
        <>
          <h3
            style={{
              color: 'gray',
              display: 'flex',
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            {t(`${translations.errors_400.no_data_available}`)}
          </h3>
        </>
      ) : (
        <>
          <div className="d-flex flex-column">
            <div className="d-flex">
              {data.map(item => (
                <>
                  {active === item[0][0].warehouse ? (
                    <Warehouse active={true}>{item[0][0].warehouse}</Warehouse>
                  ) : (
                    <Warehouse
                      active={false}
                      onClick={() => chageActive(item[0][0].warehouse)}
                    >
                      {item[0][0].warehouse}
                    </Warehouse>
                  )}
                </>
              ))}
            </div>
            {!isMobile ? (
              <QuantityTable
                total={total}
                setTotal={setTotal}
                setTotalShow={setTotalShow}
                setBag={setBag}
                setBoxes={setBoxes}
                fullData={data}
                data={data.filter(item => item[0][0].warehouse === active)}
                storedData={storedData}
                setStoredData={setStoredData}
                deletedContracts={deletedContracts}
                setDeletedContracts={setDeletedContracts}
                dataToBeSent={dataToBeSent}
                setDataToBeSent={setDataToBeSent}
              />
            ) : (
              <QuantityTableMobile
                total={total}
                setTotal={setTotal}
                setTotalShow={setTotalShow}
                setBag={setBag}
                setBoxes={setBoxes}
                fullData={data}
                data={data.filter(item => item[0][0].warehouse === active)}
                storedData={storedData}
                setStoredData={setStoredData}
                deletedContracts={deletedContracts}
                setDeletedContracts={setDeletedContracts}
                dataToBeSent={dataToBeSent}
                setDataToBeSent={setDataToBeSent}
              />
            )}
          </div>
          {!isMobile ? (
            <div className="d-flex aling-items-center flex-wrap mt-5">
              <h1>{t(translations.general.total_req)}</h1>
              <Total color={theme.palette.primary.main}>
                <Text>
                  <Text>
                    {t(translations.table.boxes)} {boxes}
                  </Text>
                </Text>
              </Total>
              <Total color={theme.palette.primary.main}>
                <Text>
                  {t(translations.table.bags)} {bag}
                </Text>
              </Total>
              <Total color={theme.palette.secondary.main}>
                <Text color={theme.palette.secondary.contrastText}>
                  {t(translations.table.total_units)} {totalShow}
                </Text>
              </Total>
            </div>
          ) : (
            <div className="d-flex aling-items-center flex-column mt-5">
              <h1>{t(translations.general.total_req)}</h1>
              <Total color={theme.palette.primary.main}>
                <Text>
                  <Text>
                    {t(translations.table.boxes)} {boxes}
                  </Text>
                </Text>
              </Total>
              <Total color={theme.palette.primary.main}>
                <Text>
                  {t(translations.table.bags)} {bag}
                </Text>
              </Total>
              <Total color={theme.palette.secondary.main}>
                <Text color={theme.palette.secondary.contrastText}>
                  {t(translations.table.total_units)} {totalShow}
                </Text>
              </Total>
            </div>
          )}
        </>
      )}
    </>
  );
}
