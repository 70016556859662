import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Dropdown from 'app/components/Dropdown';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { CustomButton } from '../Button';
import styledComponent from 'styled-components/macro';
import styled, { useTheme } from 'styled-components/macro';
import { ThemeProvider, useMediaQuery, Select, MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton } from '@mui/material';
import { OfferingData } from '../OfferingData';
import { sendOffering } from 'services/sampleServices';
import drop from '../../assets/SVG/dropdown.svg';
import { getImpersonateData } from 'services/authServices';
import CircularProgress from '@mui/material/CircularProgress';
import { LoadingPage } from 'app/pages/LoadingPage';
import FilterModalOffering from '../FilterModalOffering';
import { originToColor, GENERIC } from '../../constants';
import ModalComponent from '../ModalComponent';
import MaterialTooltip from '@mui/material/Tooltip';
import {
  getDeliveryAddress,
  generateNewAddress,
} from 'services/purchaseService';

const ContainerBody = styledComponent.div` 
display: flex;
width: 100%;
justify-content: space-between;
background: #f8f8f8;
align-items: center;
`;

const ContainerHeader = styledComponent.div` 
display: flex;
width: 100%;
background: #ebeaea;
justify-content: space-between;
margin-bottom: 5px;
align-items: center;
`;

const StyledHeadCell = styled(TableCell)`
  font-style: normal;
  font-weight: 325;
  font-size: 13px;
  line-height: 16px;
  border: 1px solid white;
  background: #ebeaea;
  background: ${props => props.color};
`;

const StyledTableRow = styled(TableRow)`
  border-bottom: 3px solid white;
  border-top: 3px solid white;
  th:first-child {
    border-radius: 35px 0px 0px 35px;
  }
  th:last-child {
    border-radius: 0px 35px 35px 0px;
  }
  td:first-child {
    border-radius: 35px 0px 0px 35px;
  }
  td:last-child {
    border-radius: 0px 35px 35px 0px;
  }
`;
const Button = styled.input<{ backgroundColor?: string }>`
  border: none;
  background: ${props =>
    props.backgroundColor ? props.backgroundColor : '#5c36f3'};
  color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  margin-bottom: 25px;
  width: 213.02px;
  height: 49.59px;
  margin: 0 10px;
`;

const ButtonAddres = styled.input`
  width: 50vh;
  height: 42px;
  left: 519.78px;
  top: 391.99px;
`;

const ShortInput = styled.input`
  width: 50vh;
  height: 42px;
  left: 519.78px;
  top: 391.99px;
  background: #ebeaea;
  border-radius: 30px;
  border: 0;
  padding: 10px 20px;
  @media (max-width: 767px) {
    width: 30vh;
  }
`;
const StyledTableCell = styled(TableCell)`
  align-items: center;

  font-style: normal;
  font-weight: 325;
  font-size: 13px;
  line-height: 16px;
  background: #f8f8f8;
  border: 1px solid white;
  display: table-cell;
  overflow: auto;
`;

const Add = styledComponent.div`
${props =>
  props.color === 'Add' &&
  `
  background: #B2BA86; 
  color: white;
`}
  ${props =>
    props.color === 'Remove' &&
    `
  background: #D98458; 
  color: white;`}
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  height: 33px;
  cursor: pointer;

`;

const StyledButton = styled.button`
  margin-right: 20px;
  background-color: #4f4e24;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  font-size: 16px;
`;

const StyledHeadCellMobile = styled(TableCell)`
  font-style: normal;
  font-weight: 325;
  font-size: 13px;
  line-height: 16px;
  background: #ebeaea;
  background: ${props => props.color};
  width: calc(20% - 1px);
`;

const StyledTableCellMobile = styled(TableCell)`
  align-items: center;

  font-style: normal;
  font-weight: 325;
  font-size: 13px;
  line-height: 16px;
  background: #f8f8f8;
  width: calc(20% - 1px);
`;

const Search = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const Clean = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  margin-left: 20px;
  color: #1989b2;
`;

const CleanFilter = () => (
  <svg
    width="25px"
    height="25px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 15L21 21M21 15L15 21M10 21V14.6627C10 14.4182 10 14.2959 9.97237 14.1808C9.94787 14.0787 9.90747 13.9812 9.85264 13.8917C9.7908 13.7908 9.70432 13.7043 9.53137 13.5314L3.46863 7.46863C3.29568 7.29568 3.2092 7.2092 3.14736 7.10828C3.09253 7.01881 3.05213 6.92127 3.02763 6.81923C3 6.70414 3 6.58185 3 6.33726V4.6C3 4.03995 3 3.75992 3.10899 3.54601C3.20487 3.35785 3.35785 3.20487 3.54601 3.10899C3.75992 3 4.03995 3 4.6 3H19.4C19.9601 3 20.2401 3 20.454 3.10899C20.6422 3.20487 20.7951 3.35785 20.891 3.54601C21 3.75992 21 4.03995 21 4.6V6.33726C21 6.58185 21 6.70414 20.9724 6.81923C20.9479 6.92127 20.9075 7.01881 20.8526 7.10828C20.7908 7.2092 20.7043 7.29568 20.5314 7.46863L17 11"
      stroke="#000000"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

interface TableProps {
  coffes?: any[];
  page: boolean;
  mark: any;
  origin: any;
  packaging: any;
  category: any;
  process: any;
  location: any;
  setOrigin: (value: any) => void;
  setMark: (value: any) => void;
  setPackaging: (value: any) => void;
  setCategory: (value: any) => void;
  setProcess: (value: any) => void;
  setLocation: (value: any) => void;
  search: () => void;
  cleanAndSearch: () => void;
  setLoadingData: (value: boolean) => void;
  loadingData: boolean;
  errorOffering: any;
  setSamplesAvailable: (value: any) => void;
  samplesAvailable: any;
}

export function OfferingTable({
  coffes,
  page,
  mark,
  origin,
  packaging,
  category,
  process,
  location,
  setOrigin,
  setMark,
  setPackaging,
  setCategory,
  setProcess,
  setLocation,
  search,
  cleanAndSearch,
  setLoadingData,
  loadingData,
  errorOffering,
  setSamplesAvailable,
  samplesAvailable,
}: TableProps) {
  const theme: any = useTheme();
  const [selectedIco, setSelectedIco] = useState<any[]>([]);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const [info, setInfo] = useState<any[]>([]);
  const [permissions, setPermissions] = useState<string[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [infoDetail, setInfoDetail] = useState<any[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [coffesList, setCoffesList] = useState<any[]>([]);
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortedData, setSortedData] = useState<any>([]);
  const [activeSortColumn, setActiveSortColumn] = useState<any>(null);
  const [isColumnClicked, setIsColumnClicked] = useState('');
  // Definir la interfaz para la dirección
  interface Address {
    value: number;
    label: string;
  }
  let dataToMap;
  if (sortedData.length > 0) {
    dataToMap = sortedData;
  } else {
    dataToMap = coffesList;
  }

  const sortData = columnName => {
    const sortOrderModifier = sortOrder === 'asc' ? 1 : -1;

    const sorted = [...(coffesList || [])].sort((a, b) => {
      return sortOrderModifier * a[columnName].localeCompare(b[columnName]);
    });

    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    setSortedData(sorted);
    setActiveSortColumn(columnName); // Set the active sorting column
    setIsColumnClicked(columnName);
  };

  const isMobile = useMediaQuery('(max-width:767px)');

  const { t } = useTranslation();

  const openModalWithInfo = info => {
    setModalOpen(true);
    setInfoDetail(info);
  };

  useEffect(() => {
    getImpersonateData()
      .then(response => {
        if (response.status === 200) {
          const permissionsRaw =
            response.data.Impersonate === 0
              ? response.data.permissions
              : response.data.original_permissions;
          const filteredPermissions = permissionsRaw.filter(
            value => value.name.split(';')[1] === 'Customer',
          );
          const permissionNames = filteredPermissions.map(
            module => module.name.split(';')[0],
          );
          setPermissions(permissionNames);
        }
      })
      .catch(error => {
        console.log(error.response?.data);
      });
  }, []);

  const openAndPopulate = (
    country: string,
    mark: string,
    ico: string,
    quality: string,
    location: string,
    qAvailable: string,
    starting: string,
    packaging: string,
    category: string,
    process: string,
    notes: string,
    variety: string,
    certification: string,
    milling_type: string,
  ) => {
    populateModal(
      country,
      mark,
      ico,
      quality,
      location,
      qAvailable,
      starting,
      packaging,
      category,
      process,
      notes,
      variety,
      certification,
      milling_type,
    );
    openCloseModal();
  };

  const openCloseModal = () => {
    setOpen(!open);
  };

  const searchOrigin = (selected: any) => {
    setOrigin(selected.value);
  };

  const searchMark = (selected: any) => {
    setMark(selected.value);
  };

  const searchCategory = (selected: any) => {
    setCategory(selected.value);
  };

  const searchPackaging = (selected: any) => {
    setPackaging(selected.value);
  };

  const searchProcess = (selected: any) => {
    setProcess(selected.value);
  };

  const searchLocation = (selected: any) => {
    setLocation(selected.value);
  };

  const clean = () => {
    setOrigin('');
    setCategory('');
    setMark('');
    setPackaging('');
    setProcess('');
    setLocation('');
    cleanAndSearch();
  };

  const populateModal = (
    country: string,
    mark: string,
    ico: string,
    quality: string,
    location: string,
    qAvailable: string,
    starting: string,
    packaging: string,
    category: string,
    process: string,
    notes: string,
    variety: string,
    certification: string,
    milling_type: string,
  ) => {
    const arr = [
      country,
      mark,
      ico,
      quality,
      location,
      qAvailable,
      starting,
      packaging,
      category,
      process,
      notes,
      variety,
      certification,
      milling_type,
    ];
    setInfo(arr);
  };

  useEffect(() => {}, []);

  const sendIco = () => {
    setSuccess(false);
    setError('');
    const payload = selectedIco.map(item => ({
      ico_id: item,
      quantity: 1,
    }));
    const pay = {
      offerings_list: payload,
      typeSample: typeSample,
      address: selectedAddress,
    };

    sendOffering(pay)
      .then(response => {
        if (response.status === 200) {
          if (selectedAddress != '') {
            generateNewAddress({ address: selectedAddress });
          }
          setSuccess(true);
        }
      })
      .catch(error => {
        setError(error.response?.data.message);
      })
      .finally(() => {
        clean();
        setSelectedIco([]);
        showNewAddress(false);
        setTypeSample('');
        setSelectedAddress('');
        fetchAddresses();
      });
  };

  useEffect(() => {
    if (coffes && !loadingData) {
      setCoffesList(coffes);
    }
  }, [coffes, loadingData]);

  const setIco = (ico: string) => {
    const newIcodArray: any[] = [...selectedIco, ico];
    setSelectedIco(newIcodArray);
    setSamplesAvailable(samplesAvailable - 1);
  };

  const removeIco = (ico: string) => {
    let contractDelete = selectedIco.filter(elem => elem !== ico);
    setSelectedIco(contractDelete);
    setSamplesAvailable(samplesAvailable + 1);
    if (selectedIco.length >= 0) {
      showNewAddress(false);
      setTypeSample('');
      setSelectedAddress('');
    }
  };

  let hash = {};

  const [typeSample, setTypeSample] = useState('');
  const [selectedAddress, setSelectedAddress] = useState('');
  const [addresses, setAddresses] = useState<Address[]>([]);
  const [newAddres, showNewAddress] = useState(false);

  const fetchAddresses = async () => {
    try {
      const response = await getDeliveryAddress();
      const mappedAddresses = response.data.map(address => ({
        value: address.id,
        label: address.address,
      }));
      setAddresses(mappedAddresses);
    } catch (error) {
      console.error('Error with directions:', error);
    }
  };
  useEffect(() => {
    fetchAddresses();
  }, []);

  //Definir si se va a ingresar una nueva direccion
  const addNewAddress = () => {
    if (!newAddres) {
      showNewAddress(true);
      setSelectedAddress('');
    } else {
      showNewAddress(false);
    }
  };
  const backNewAddress = () => {
    showNewAddress(false);
  };

  //Dependiendo el IdImporter se asigna el tipo de peso

  function defineTypeWeight() {
    if (dataToMap[0].idImporter == 3) {
      return `${t(translations.table.q_available)} lbs`;
    } else {
      return `${t(translations.table.q_available)} kg`;
    }
  }
  function defineImporterHeader(option, message) {
    //Se deja solo mostrar el precio para UK - idImporter = 1
    if (dataToMap[0].idImporter == 1 && option == 1) {
      return (
        <StyledHeadCell
          style={{ background: theme.palette.secondary.main }}
          align="center"
        >
          USD/Kg
        </StyledHeadCell>
      );
    } else if (dataToMap[0].idImporter == 1 && option == 2) {
      return <StyledTableCell align="center">{message}</StyledTableCell>;
    }
  }
  const getStartingPrice = offeringPrice => {
    if (dataToMap.length > 0 && dataToMap[0].idImporter == 1) {
      return offeringPrice;
    }
    return null;
  };

  return dataToMap ? (
    loadingData ? (
      <div
        style={{
          // transform: 'scale(0.2)',
          minWidth: !isMobile ? 700 : 0,
          // marginTop: '-200px',
        }}
      >
        <LoadingPage />
      </div>
    ) : (
      <ThemeProvider theme={theme}>
        {open === true ? (
          <OfferingData
            openCloseModal={openCloseModal}
            country={info[0]}
            mark={info[1]}
            ico={info[2]}
            quality={info[3]}
            location={info[4]}
            qAvailable={info[5]}
            starting={info[6]}
            packaging={info[7]}
            category={info[8]}
            process={info[9]}
            notes={info[10]}
            variety={info[11]}
            certification={info[12]}
            milling_type={info[13]}
          />
        ) : null}
        {!isMobile ? (
          <>
            <div className="d-flex mt-5 mb-1" style={{ flexWrap: 'nowrap' }}>
              <Search>
                <div
                  style={{
                    fontSize: '13px',
                    fontWeight: '625',
                    margin: '0 20px',
                  }}
                >
                  {t(translations.table.origin)}
                </div>
                <Dropdown
                  value={origin}
                  handleChange={searchOrigin}
                  // defaultValue="Origin"
                  list={
                    coffesList &&
                    coffesList
                      .map(item => ({
                        value: item.origin,
                        label: item.origin,
                      }))
                      .filter(o =>
                        hash[o.value] ? false : (hash[o.value] = true),
                      )
                  }
                />
              </Search>
              <Search>
                <div
                  style={{
                    fontSize: '13px',
                    fontWeight: '625',
                    margin: '0 20px',
                  }}
                >
                  {' '}
                  {t(translations.table.mark)}
                </div>
                <Dropdown
                  handleChange={searchMark}
                  value={mark}
                  list={
                    coffesList &&
                    coffesList
                      .map(item => ({
                        value: item.mark,
                        label: item.mark,
                      }))
                      .filter(o =>
                        hash[o.value] ? false : (hash[o.value] = true),
                      )
                  }
                />
              </Search>
              <Search>
                <div
                  style={{
                    fontSize: '13px',
                    fontWeight: '625',
                    margin: '0 20px',
                  }}
                >
                  {' '}
                  {t(translations.table.pack)}
                </div>
                <Dropdown
                  handleChange={searchPackaging}
                  value={packaging}
                  list={
                    coffesList &&
                    coffesList
                      .map(item => ({
                        value: item.packaging,
                        label: item.packaging,
                      }))
                      .filter(o =>
                        hash[o.value] ? false : (hash[o.value] = true),
                      )
                  }
                />
              </Search>
              <Search>
                <div
                  style={{
                    fontSize: '13px',
                    fontWeight: '625',
                    margin: '0 20px',
                  }}
                >
                  {' '}
                  {t(translations.table.location)}
                </div>
                <Dropdown
                  handleChange={searchLocation}
                  value={location}
                  list={
                    coffesList &&
                    coffesList
                      .map(item => ({
                        value: item.warehouse,
                        label: item.warehouse,
                      }))
                      .filter(o =>
                        hash[o.value] ? false : (hash[o.value] = true),
                      )
                  }
                />
              </Search>
              <div
                style={{
                  cursor: 'pointer',
                  marginLeft: 'auto',
                  marginTop: '20px',
                }}
              >
                {loadingData ? (
                  <CircularProgress color="primary" />
                ) : (
                  <IconButton onClick={search} aria-label="search">
                    <SearchIcon />
                  </IconButton>
                )}
                {/* <SearchIcon onClick={search} /> */}
              </div>
              <div style={{ marginLeft: 'auto', marginTop: '20px' }}>
                <MaterialTooltip
                  title={t(translations.tooltips.clean)}
                  placement="right"
                >
                  <IconButton onClick={clean}>
                    <CleanFilter />
                  </IconButton>
                </MaterialTooltip>
              </div>
              {/* <Search>
                <div
                  style={{
                    fontSize: '13px',
                    fontWeight: '625',
                    margin: '0 20px',
                  }}
                >
                  {' '}
                  {t(translations.table.process)}
                </div>
                <Dropdown
                  handleChange={searchProcess}
                  value={process}
                  list={
                    coffesList &&
                    coffesList
                      .map(item => ({
                        value: item.process,
                        label: item.process,
                      }))
                      .filter(o =>
                        hash[o.value] ? false : (hash[o.value] = true),
                      )
                  }
                />
              </Search> */}
            </div>
          </>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '20px',
              marginRight: '0px',
            }}
          >
            <StyledButton
              onClick={() => setIsModalOpen(true)}
              style={{ marginRight: '0px' }}
            >
              {t(translations.general.filter)}
            </StyledButton>
          </div>
        )}

        <>
          {page && (
            <div>
              {success && (
                <h3 style={{ color: 'green', marginTop: '20px' }}>
                  {t(translations.general.req_sub)}
                </h3>
              )}
              {error !== '' && (
                <h3 style={{ color: 'red', marginTop: '20px' }}>
                  {t(`${translations.errors_400[error]}`)}
                </h3>
              )}
            </div>
          )}
          {!isMobile
            ? errorOffering === '' && (
                <>
                  {page === true &&
                    (samplesAvailable > 0 ? (
                      <div style={{ marginBottom: '5px' }}>
                        {t(translations.general.max_sample)}
                        {samplesAvailable}
                      </div>
                    ) : (
                      <div style={{ marginBottom: '5px', color: 'red' }}>
                        {t(translations.general.limit_reached)}
                      </div>
                    ))}
                  <TableContainer>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                      <TableHead>
                        <StyledTableRow>
                          <StyledHeadCell
                            style={{
                              cursor: 'pointer',
                              background: theme.palette.secondary.main,
                            }}
                            align="center"
                            onClick={() => sortData('origin')}
                            onMouseEnter={() => setActiveSortColumn('origin')}
                            onMouseLeave={() => {
                              if (!isColumnClicked) {
                                setActiveSortColumn(null);
                              }
                            }}
                          >
                            {t(translations.table.country)}{' '}
                            {(activeSortColumn === 'origin' ||
                              isColumnClicked === 'origin') && (
                              <span style={{ fontSize: '20px' }}>
                                {sortOrder === 'asc' ? '↑' : '↓'}
                              </span>
                            )}
                          </StyledHeadCell>
                          <StyledHeadCell
                            style={{
                              cursor: 'pointer',
                              background: theme.palette.secondary.main,
                            }}
                            align="center"
                            onClick={() => sortData('mark')}
                            onMouseEnter={() => setActiveSortColumn('mark')}
                            onMouseLeave={() => {
                              if (!isColumnClicked) {
                                setActiveSortColumn(null);
                              }
                            }}
                          >
                            {t(translations.table.mark)}{' '}
                            {(activeSortColumn === 'mark' ||
                              isColumnClicked === 'mark') && (
                              <span style={{ fontSize: '20px' }}>
                                {sortOrder === 'asc' ? '↑' : '↓'}
                              </span>
                            )}
                          </StyledHeadCell>
                          <StyledHeadCell
                            style={{ background: theme.palette.secondary.main }}
                            align="center"
                          >
                            {t(translations.table.ico_solo)}
                          </StyledHeadCell>
                          <StyledHeadCell
                            style={{ background: theme.palette.secondary.main }}
                            align="center"
                          >
                            {t(translations.table.quality)}
                          </StyledHeadCell>
                          <StyledHeadCell
                            style={{
                              cursor: 'pointer',
                              background: theme.palette.secondary.main,
                            }}
                            align="center"
                            onClick={() => sortData('warehouse')}
                            onMouseEnter={() =>
                              setActiveSortColumn('warehouse')
                            }
                            onMouseLeave={() => {
                              if (!isColumnClicked) {
                                setActiveSortColumn(null);
                              }
                            }}
                          >
                            {t(translations.table.location)}{' '}
                            {(activeSortColumn === 'warehouse' ||
                              isColumnClicked === 'warehouse') && (
                              <span style={{ fontSize: '20px' }}>
                                {sortOrder === 'asc' ? '↑' : '↓'}
                              </span>
                            )}
                          </StyledHeadCell>
                          <StyledHeadCell
                            style={{ background: theme.palette.secondary.main }}
                            align="center"
                          >
                            {t(translations.table.units_solo)}
                          </StyledHeadCell>
                          <StyledHeadCell
                            style={{
                              cursor: 'pointer',
                              background: theme.palette.secondary.main,
                            }}
                            align="center"
                            onClick={() => sortData('packaging')}
                            onMouseEnter={() =>
                              setActiveSortColumn('packaging')
                            }
                            onMouseLeave={() => {
                              if (!isColumnClicked) {
                                setActiveSortColumn(null);
                              }
                            }}
                          >
                            {t(translations.table.packaging)}{' '}
                            {(activeSortColumn === 'packaging' ||
                              isColumnClicked === 'packaging') && (
                              <span style={{ fontSize: '20px' }}>
                                {sortOrder === 'asc' ? '↑' : '↓'}
                              </span>
                            )}
                          </StyledHeadCell>

                          <StyledHeadCell
                            style={{ background: theme.palette.secondary.main }}
                            align="center"
                          >
                            {dataToMap.length > 0
                              ? defineTypeWeight()
                              : t(translations.table.q_available)}
                            {/* {t(translations.table.q_available)} lbs */}
                          </StyledHeadCell>

                          {dataToMap.length > 0 &&
                            defineImporterHeader(1, null)}

                          <StyledHeadCell
                            style={{ background: theme.palette.secondary.main }}
                            align="center"
                          >
                            {t(translations.table.more)}
                          </StyledHeadCell>
                          {permissions.length > 0 &&
                            permissions.includes('Sample Request') && (
                              <StyledHeadCell
                                color={theme.palette.primary.main}
                                style={{
                                  color: theme.palette.primary.contrastText,
                                }}
                                align="center"
                              >
                                {t(translations.table.sample)}
                              </StyledHeadCell>
                            )}
                        </StyledTableRow>
                      </TableHead>
                      <TableBody>
                        {dataToMap &&
                          dataToMap.map(groupBy => (
                            <StyledTableRow key={groupBy.contract}>
                              <StyledTableCell align="center">
                                <div
                                  style={{
                                    borderRadius: '30px',
                                    background: groupBy.origin
                                      ? originToColor[
                                          groupBy.origin.toUpperCase()
                                        ] || GENERIC
                                      : GENERIC,
                                    color: 'white',
                                    padding: '15px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '40px',
                                    width: '160px',
                                  }}
                                >
                                  {groupBy.origin}
                                </div>
                              </StyledTableCell>
                              <StyledTableCell
                                style={{
                                  display: groupBy.broshure
                                    ? 'flex'
                                    : 'table-cell',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  textOverflow: 'ellipsis',
                                }}
                                align="center"
                              >
                                {groupBy.url ? (
                                  <a
                                    href={groupBy.url}
                                    style={{
                                      textDecoration: 'none',
                                      borderRadius: '30px',
                                      background: '#4F4E24',
                                      color: 'white',
                                      padding: '15px',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      height: '40px',
                                      width: '160px',
                                    }}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {groupBy.mark}
                                  </a>
                                ) : (
                                  groupBy.mark
                                )}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {groupBy.ico_id}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {groupBy.quality}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {groupBy.warehouse}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {groupBy.units_available}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {groupBy.packaging}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {groupBy.available_qty}
                              </StyledTableCell>
                              {/*StyledTableCell price*/}
                              {dataToMap.length > 0 &&
                                defineImporterHeader(2, groupBy.offering_price)}
                              {/*StyledTableCell price*/}

                              <StyledTableCell align="center">
                                <div
                                  onClick={() =>
                                    openAndPopulate(
                                      groupBy.origin,
                                      groupBy.mark,
                                      groupBy.ico_id,
                                      groupBy.quality,
                                      groupBy.warehouse,
                                      groupBy.available_qty,
                                      getStartingPrice(groupBy.offering_price),
                                      groupBy.packaging,
                                      groupBy.category,
                                      groupBy.process,
                                      groupBy.notes,
                                      groupBy.variety,
                                      groupBy.certification,
                                      groupBy.milling_type,
                                    )
                                  }
                                  style={{
                                    background: '#D9D9D9',
                                    borderRadius: '30px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color: '#545454',
                                    padding: '5px',
                                    cursor: 'pointer',
                                  }}
                                >
                                  <div
                                    style={{
                                      color: '#545454',
                                      fontSize: '20px',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    ...
                                  </div>
                                </div>
                              </StyledTableCell>
                              {permissions &&
                                permissions.includes('Sample Request') && (
                                  <StyledTableCell align="center">
                                    {!selectedIco.find(
                                      element => element === groupBy.ico_id,
                                    ) && (
                                      <Add
                                        style={{
                                          background:
                                            samplesAvailable === 0
                                              ? theme.palette.grey.A200
                                              : theme.palette.success.main,
                                          color: 'white',
                                          cursor:
                                            samplesAvailable === 0
                                              ? 'not-allowed'
                                              : 'pointer',
                                        }}
                                        color="Add"
                                        id={`add-${groupBy.contract}`}
                                        onClick={() => {
                                          if (samplesAvailable > 0) {
                                            setIco(groupBy.ico_id);
                                          }
                                        }}
                                      >
                                        {t(translations.table.add)}
                                      </Add>
                                    )}
                                    {selectedIco.find(
                                      element => element === groupBy.ico_id,
                                    ) && (
                                      <Add
                                        color="Remove"
                                        id={`remove-${groupBy.ico_id}`}
                                        onClick={() =>
                                          removeIco(groupBy.ico_id)
                                        }
                                      >
                                        {t(translations.table.remove)}
                                      </Add>
                                    )}
                                  </StyledTableCell>
                                )}
                            </StyledTableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      paddingTop: '10px',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        paddingTop: '10px',
                      }}
                    >
                      <label
                        style={{ marginBottom: '5px', marginRight: '5px' }}
                      >
                        {t(translations.table.sample_type)}
                      </label>
                      <Select
                        disabled={selectedIco.length <= 0}
                        value={selectedIco.length > 0 ? typeSample : ''}
                        onChange={e => setTypeSample(e.target.value)}
                        style={{
                          marginBottom: '10px',
                          background: '#F6F6F6',
                          borderRadius: '30px',
                          width: '200px',
                          height: '40px',
                        }}
                      >
                        <MenuItem disabled value="">
                          {t(translations.table.sample_type)}
                        </MenuItem>
                        <MenuItem value="Green">Green</MenuItem>
                        <MenuItem value="Roasted">Roasted</MenuItem>
                      </Select>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        paddingTop: '10px',
                      }}
                    >
                      <label
                        style={{ marginBottom: '5px', marginRight: '5px' }}
                      >
                        {t(translations.table.deliveryAddress)}
                      </label>
                      <Select
                        disabled={selectedIco.length <= 0 || newAddres}
                        value={selectedIco.length > 0 ? selectedAddress : ''}
                        onChange={e => setSelectedAddress(e.target.value)}
                        style={{
                          marginBottom: '10px',
                          background: '#F6F6F6',
                          borderRadius: '30px',
                          width: '200px',
                          height: '40px',
                        }}
                      >
                        {addresses.map(address => (
                          <MenuItem value={address.label}>
                            {address.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <div>
                      {newAddres ? (
                        <div>
                          <button
                            style={{
                              marginBottom: '10px',
                              marginTop: '30px',
                              background: '#D98458',
                              color: 'white',
                              cursor: 'pointer',
                              height: '42px',
                              borderRadius: '16px',
                              border: 'none',
                            }}
                            type="button"
                            onClick={backNewAddress}
                            disabled={selectedIco.length <= 0}
                          >
                            Back
                          </button>
                          <ShortInput
                            disabled={selectedIco.length <= 0}
                            value={selectedAddress}
                            style={{ marginBottom: '10px', marginTop: '30px' }}
                            onChange={e => setSelectedAddress(e.target.value)}
                            type="text"
                          />
                        </div>
                      ) : (
                        <button
                          style={{
                            marginBottom: '10px',
                            marginTop: '30px',
                            background: '#B2BA86',
                            color: 'white',
                            cursor: 'pointer',
                            height: '42px',
                            borderRadius: '16px',
                            border: 'none',
                          }}
                          disabled={selectedIco.length <= 0}
                          onClick={addNewAddress}
                        >
                          Add
                        </button>
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      paddingTop: '10px',
                    }}
                  >
                    <CustomButton
                      type="button"
                      onClick={sendIco}
                      withShadow
                      style={{
                        marginBottom: '20px',
                        background:
                          selectedIco.length <= 0 ||
                          typeSample === '' ||
                          selectedAddress === ''
                            ? theme.palette.grey.A200
                            : theme.palette.primary.main,
                        color:
                          selectedIco.length <= 0 ||
                          typeSample === '' ||
                          selectedAddress === ''
                            ? 'grey'
                            : 'white',
                        marginTop: isMobile ? '50px' : '0px',
                      }}
                      disabled={
                        selectedIco.length <= 0 ||
                        typeSample === '' ||
                        selectedAddress === ''
                      }
                    >
                      {t(translations.general.confirm)}
                    </CustomButton>
                  </div>
                </>
              )
            : errorOffering === '' && (
                <>
                  {page === true &&
                    (samplesAvailable > 0 ? (
                      <div style={{ marginBottom: '5px' }}>
                        {t(translations.general.max_sample)}
                        {samplesAvailable}
                      </div>
                    ) : (
                      <div style={{ marginBottom: '5px', color: 'red' }}>
                        {t(translations.general.limit_reached)}
                      </div>
                    ))}
                  <ContainerHeader>
                    <StyledHeadCellMobile
                      align="center"
                      style={{
                        borderBottom: '0px',
                      }}
                    ></StyledHeadCellMobile>
                    <StyledHeadCellMobile
                      align="center"
                      style={{
                        borderBottom: '0px',
                      }}
                    >
                      {t(translations.table.country)}
                    </StyledHeadCellMobile>
                    <StyledHeadCellMobile
                      align="center"
                      style={{
                        borderBottom: '0px',
                      }}
                    >
                      {t(translations.table.mark)}
                    </StyledHeadCellMobile>
                    {dataToMap.length > 0 && defineImporterHeader(1, null)}
                    {/* <StyledHeadCellMobile
                      align="center"
                      style={{
                        borderBottom: '0px',
                      }}
                    >
                      {t(translations.table.starting)}
                    </StyledHeadCellMobile> */}
                    {permissions.length > 0 &&
                      permissions.includes('Sample Request') && (
                        <StyledHeadCellMobile
                          style={{
                            borderBottom: '0px',
                          }}
                        ></StyledHeadCellMobile>
                      )}
                  </ContainerHeader>

                  {dataToMap &&
                    dataToMap.map(groupBy => (
                      <ContainerBody key={groupBy.contract}>
                        <StyledTableCellMobile
                          align="center"
                          style={{
                            borderBottom: '0px',
                          }}
                        >
                          {' '}
                          <img
                            src={drop}
                            alt="drop"
                            style={{ width: '22px', height: '22px' }}
                            onClick={() => openModalWithInfo(groupBy)}
                          />
                        </StyledTableCellMobile>
                        <StyledTableCellMobile
                          align="center"
                          style={{
                            borderBottom: '0px',
                          }}
                        >
                          {groupBy.origin}
                        </StyledTableCellMobile>
                        <StyledTableCellMobile
                          style={{
                            display: groupBy.url ? 'flex' : 'table-cell',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textOverflow: 'ellipsis',
                            borderBottom: '0px',
                          }}
                          align="center"
                        >
                          {groupBy.url ? (
                            <a
                              href={groupBy.url}
                              style={{
                                textDecoration: 'none',
                                // borderRadius: '30px',
                                // background: '#1989B2',
                                color: 'blue',
                                padding: '15px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '40px',
                                width: '160px',
                              }}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {groupBy.mark}
                            </a>
                          ) : (
                            groupBy.mark
                          )}
                        </StyledTableCellMobile>

                        <StyledTableCellMobile
                          align="center"
                          style={{
                            borderBottom: '0px',
                          }}
                        >
                          {groupBy.offering_price}
                        </StyledTableCellMobile>

                        {permissions && permissions.includes('Sample Request') && (
                          <StyledTableCellMobile
                            align="center"
                            style={{
                              borderBottom: '0px',
                            }}
                          >
                            {!selectedIco.find(
                              element => element === groupBy.ico_id,
                            ) && (
                              <Add
                                style={{
                                  background:
                                    samplesAvailable === 0
                                      ? theme.palette.grey.A200
                                      : theme.palette.success.main,
                                  color: 'white',
                                  cursor:
                                    samplesAvailable === 0
                                      ? 'not-allowed'
                                      : 'pointer',
                                }}
                                color="Add"
                                id={`add-${groupBy.contract}`}
                                onClick={() => {
                                  if (samplesAvailable > 0) {
                                    setIco(groupBy.ico_id);
                                  }
                                }}
                              >
                                +
                              </Add>
                            )}
                            {selectedIco.find(
                              element => element === groupBy.ico_id,
                            ) && (
                              <Add
                                color="Remove"
                                id={`remove-${groupBy.ico_id}`}
                                onClick={() => removeIco(groupBy.ico_id)}
                              >
                                -
                              </Add>
                            )}
                          </StyledTableCellMobile>
                        )}
                      </ContainerBody>
                    ))}
                </>
              )}
        </>
        <FilterModalOffering
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          originOptions={
            coffesList &&
            coffesList
              .map(item => ({
                value: item.origin,
                label: item.origin,
              }))
              .filter(o => (hash[o.value] ? false : (hash[o.value] = true)))
          }
          markOptions={
            coffesList &&
            coffesList
              .map(item => ({
                value: item.mark,
                label: item.mark,
              }))
              .filter(o => (hash[o.value] ? false : (hash[o.value] = true)))
          }
          packagingOptions={
            coffesList &&
            coffesList
              .map(item => ({
                value: item.packaging,
                label: item.packaging,
              }))
              .filter(o => (hash[o.value] ? false : (hash[o.value] = true)))
          }
          categoryOptions={
            coffesList &&
            coffesList
              .map(item => ({
                value: item.category,
                label: item.category,
              }))
              .filter(o => (hash[o.value] ? false : (hash[o.value] = true)))
          }
          locationOptions={
            coffesList &&
            coffesList
              .map(item => ({
                value: item.warehouse,
                label: item.warehouse,
              }))
              .filter(o => (hash[o.value] ? false : (hash[o.value] = true)))
          }
          processOptions={
            coffesList &&
            coffesList
              .map(item => ({
                value: item.process,
                label: item.process,
              }))
              .filter(o => (hash[o.value] ? false : (hash[o.value] = true)))
          }
          onSearch={search}
          clean={clean}
          searchOrigin={searchOrigin}
          searchLocation={searchLocation}
          searchMark={searchMark}
          searchPackaging={searchPackaging}
          searchCategory={searchCategory}
          searchProcess={searchProcess}
          mark={mark}
          origin={origin}
          packaging={packaging}
          category={category}
          process={process}
          location={location}
        />
        {modalOpen && infoDetail && (
          <ModalComponent
            page={'offering'}
            closeModal={() => setModalOpen(false)} // Función para cerrar el modal
            data={infoDetail} // Información de groupBy para mostrar en el modal
          />
        )}
      </ThemeProvider>
    )
  ) : null;
}
