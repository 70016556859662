import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import HappyFace from 'app/assets/SVG/happy_face';
import SadFace from 'app/assets/SVG/sad_face';
import AlertUnfilled from 'app/assets/SVG/alert_unfilled';

import {
  feedbackBoxStyle,
  FeedBackLabel,
  customTypography,
  CustomRadioGroup,
} from './components';
import { translations } from 'locales/translations';

import { styledTheme } from 'styles/theme';
import { useTranslation } from 'react-i18next';
import { CustomButton } from '../Button';

import { Box } from '@mui/system';

import Radio from '@mui/material/Radio';
import {
  FormControlLabel,
  TextareaAutosize,
  Typography,
  useMediaQuery,
} from '@mui/material';
import styled from 'styled-components/macro';
import { sendFeedback } from 'services/supportServices';
import { FeedbackPayload } from 'models/authModels';

const Text = styled(TextareaAutosize)<any>`
  border-radius: 20px;
  background: ${props =>
    props.background ? props.background : props.theme.LightGray};
  color: ${props => (props.color ? props.color : props.theme.DarkGray)};
  border: none;
  outline: none;

  font-style: normal;
  font-weight: 325;
  font-size: 20px;
  line-height: 24px;
  margin: 1%;
  padding: 0.5em 1em;
  width: 90%;
`;

const ModalCloseButton = styled.button`
  background-color: transparent;
  border: none;
  font-size: 30px;
  cursor: pointer;
  margin: 1%;
`;

const FeedbackMobilePage = ({}) => {
  const [responseMessage, setResponseMessage] = useState({
    text: '',
    error: false,
  });
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onSubmitFeedback = e => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData.entries()) as any;
    sendFeedback(data as FeedbackPayload)
      .then(response => {
        if (response.status === 201) {
          setResponseMessage({
            text: t(translations.general.successfully_sent),
            error: false,
          });
          // setTimeout(() => setOpenPopup(false), 2000);
        }
      })
      .catch(error => {
        setResponseMessage({
          text: error.response?.data?.message,
          error: true,
        });
      });
  };

  const close = () => {
    navigate(`/`);
  };
  return (
    <form onSubmit={onSubmitFeedback}>
      <ModalCloseButton onClick={close}>×</ModalCloseButton>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <FeedBackLabel>{t(translations.navbar.feedback.title)}</FeedBackLabel>

        <CustomRadioGroup name="feedback_option_id" defaultValue={3}>
          <FormControlLabel
            value={1}
            slotProps={customTypography}
            control={
              <Radio
                icon={<HappyFace color={styledTheme.DarkGray} />}
                checkedIcon={<HappyFace color={styledTheme.BlueBell} />}
              />
            }
            label={t(translations.navbar.feedback.i_like)}
          />
          <FormControlLabel
            value={2}
            slotProps={customTypography}
            control={
              <Radio
                icon={<SadFace color={styledTheme.DarkGray} />}
                checkedIcon={<SadFace color={styledTheme.BlueBell} />}
              />
            }
            label={t(translations.navbar.feedback.i_dont_like)}
          />
          <FormControlLabel
            value={3}
            slotProps={customTypography}
            control={
              <Radio
                icon={<AlertUnfilled color={styledTheme.DarkGray} />}
                checkedIcon={<AlertUnfilled color={styledTheme.BlueBell} />}
              />
            }
            label={t(translations.navbar.feedback.i_have_a_suggestion)}
          />
        </CustomRadioGroup>
        <Text
          required
          name="comments"
          minRows={3}
          background={styledTheme.Gray}
          placeholder={t(translations.navbar.feedback.leave_comments)}
        />
        {responseMessage.text !== '' &&
          (responseMessage.error ? (
            // <Typography color="error" variant="h6">
            <div style={{ color: 'red' }}>❌ {responseMessage.text}</div>
          ) : (
            // </Typography>
            <div style={{}}>
              {/* <Typography color="success.main" variant="h6"> */}✅{' '}
              {responseMessage.text}
              {/* </Typography> */}
            </div>
          ))}
        <CustomButton
          style={{
            alignSelf: 'center',
            margin: '20px',
          }}
          withShadow
        >
          {t(translations.general.confirm)}
        </CustomButton>
      </div>
    </form>
  );
};
export default FeedbackMobilePage;
