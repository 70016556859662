import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: flex-start;
  padding-top: 8%;
  padding-left: 5%;
  left: 0px;
  width: 100%;
  overflow: auto !important;
  background: ${props => props.theme.BlueBell};
  border-radius: 0px 50px 0px 0px;
  height: 100%;
  .active {
    background: ${props => props.theme.White};
    color: ${props => props.role};
    border-radius: 30px 0px 0px 30px;
  }
  box-sizing: content-box;
  @media (min-width: 767px) {
    width: 40px; /* Ancho inicial */
    overflow: hidden;
    transition: width 0.3s ease;

    &:hover {
      width: 250px; /* Ancho expandido al hacer hover */
    }
  }

  @media (max-width: 767px) {
    justify-content: space-around;
    flex-direction: row;
    align-items: center;
    height: auto;
    padding: 10px;
    border-radius: 0;
    position: fixed;
    bottom: 0;
    left: 0;
    height: 10px;
    border-radius: 0px;
    .active {
      background: ${props => props.theme.White};
      color: ${props => props.role};
      border-radius: 60px;
      padding: 8px;
      // margin-right: 10px;
    }
  }
  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
`;

export const CustomLink = styled(NavLink)`
  display: flex;
  justify-content: flex-start;
  gap: 7%;
  color: ${props => props.theme.White};
  text-decoration: none;
  align-items: center;
  padding: 5%;
  @media (min-width: 767px) {
    ${Container}:not(:hover) & {
      height: 50px;
      width: 50px;
      border-radius: 30px; /* Igual para todas las esquinas */
      margin-left: -6px;
      padding-left: 8px;
      margin-bottom: 5px;
    }
  }

  &:hover {
    color: grey;
  }

  &:focus {
    color: ${props => props.role};
  }

  &:active {
    color: ${props => props.role};
  }

  @media (max-width: 767px) {
    justify-content: space-evenly;
  }
`;

export const CustomDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  gap: 7%;
  color: ${props => props.theme.White};
  text-decoration: none;
  align-items: center;
  padding: 5%;
  &:hover {
    color: grey;
  }
  &:focus {
    color: ${props => props.role};
  }
  &:active {
    color: ${props => props.role};
  }
  @media (max-width: 767px) {
    justify-content: space-evenly;
  }
`;
export const FlexColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  // width: 20%;

  @media (max-width: 767px) {
    width: 100%;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 10px 0;
    width: 0%;
  }
`;

export const CollapsibleText = styled.div`
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  ${Container}:hover & {
    opacity: 1;
  }
`;

export const SidebarIcon = styled.div`
  /* Ajustar el tamaño del icono */
  svg {
    width: 32px; /* Cambiar al tamaño deseado */
    height: 32px; /* Cambiar al tamaño deseado */
    transition: all 0.2s ease-in-out;
  }

  ${Container}:hover & svg {
    width: 32px; /* Cambiar al tamaño deseado para el hover */
    height: 32px; /* Cambiar al tamaño deseado para el hover */
  }
`;
