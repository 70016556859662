import { OptionsPayload, PurchasePayload } from 'models/purchaseModels';
import serviceClient from 'services/serviceClientConfig';

export const getPurchaseOrder = (page, status) => {
  return serviceClient.get(
    `/purchase-orders`,
  ); /* with paginate /purchase-orders?page=2 with status /purchase-orders?status=draft */
};

export const getPurchaseOrderPage = page => {
  return serviceClient.get(
    `/purchase-orders?page=${page}`,
  ); /* with paginate /purchase-orders?page=2 with status /purchase-orders?status=draft */
};

export const getStatus = () => {
  return serviceClient.get(
    `/purchase-orders?status=draft`,
  ); /* with paginate /purchase-orders?page=2 with status /purchase-orders?status=draft */
};

export const getCoffees = () => {
  return serviceClient.get(
    '/coffees',
  ); /* with filter  /coffees?origin=COLOMBIA&warehouse=test */
};

export const getCoffessPage = value => {
  return serviceClient.get(
    `/coffees?page=${value}`,
  ); /* with filter  /coffees?origin=COLOMBIA&warehouse=test */
};

export const getCoffessOrigin = origin => {
  return serviceClient.get(
    `/coffees?origin=${origin}`,
  ); /* with filter  /coffees?origin=COLOMBIA&warehouse=test */
};

export const getCoffessWarehouse = warehouse => {
  return serviceClient.get(
    `/coffees?warehouse=${warehouse}`,
  ); /* with filter  /coffees?origin=COLOMBIA&warehouse=test */
};

export const getCoffessContract = contract => {
  return serviceClient.get(
    `/coffees?contract=${contract}`,
  ); /* with filter  /coffees?origin=COLOMBIA&warehouse=test */
};

export const getCoffessSearch = (contract?, origin?, warehouse?) => {
  return serviceClient.get(
    `/coffees?contract=${contract}&origin=${origin}&warehouse=${warehouse}`,
  ); /* with filter  /coffees?origin=COLOMBIA&warehouse=test */
};

// export const getStatus = () => {
//   return serviceClient.get('/user');
// };

export const PostPurchaseOrder = (payload: PurchasePayload) => {
  return serviceClient.post(`/purchase-orders`, payload);
};

export const PurchaseOrderStep2 = (payload: PurchasePayload) => {
  return serviceClient.post(`/purchase-orders`, payload);
};

export const PurchaseOrderStep3 = (payload: any, id: string) => {
  return serviceClient.put(`/purchase-orders/${id}`, payload);
};

export const PurchaseOrderPDF = (id: string) => {
  return serviceClient.get(`/purchase-orders-pdf/${id}`);
};

export const DeletePurchaseOrder = (id: string) => {
  return serviceClient.delete(`/purchase-orders/${id}`);
};

// export const PurchaseOrderStep3 = (payload: OptionsPayload, id: string) => {
//   return serviceClient.put(`/purchase-orders/${id}`, payload);
// };

export const getDeliveryAddress = () => {
  return serviceClient.get(`/delivery-address`);
};

export const generateNewAddress = (address: any) => {
  return serviceClient.post(`/delivery-address`, address);
};
