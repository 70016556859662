import axios from 'axios';
import serviceClient from 'services/serviceClientConfig';

export const getCoffees = (filters: any) => {
  let url = '/sales/offerings?';
  if (filters.origin && filters.origin !== '')
    url = url + `&origin=${filters.origin}`;
  if (filters.mark && filters.mark !== '') url = url + `&mark=${filters.mark}`;
  if (filters.packaging && filters.packaging !== '')
    url = url + `&packaging=${filters.packaging}`;
  if (filters.cateogry && filters.cateogry !== '')
    url = url + `&cateogry=${filters.cateogry}`;
  if (filters.process && filters.process !== '')
    url = url + `&process=${filters.process}`;
  if (filters.warehouse && filters.warehouse !== '')
    url = url + `&warehouse=${filters.warehouse}`;

  return serviceClient.get(url); //sales/offerings?origin=&mark=&packaging=&cateogry=&warehouse=&process=
};

export const editCoffe = (coffe: any) => {
  let url = '/sales/offerings?';
  const payload = {
    ico_id: coffe.ico_id,
    url: coffe.url,
    quality: coffe.quality,
    offering_price: coffe.offering_price,
    notes: coffe.notes,
    visible_for_clients: coffe.visible_for_clients,
    category: coffe.category,
    process: coffe.process,
  };
  return serviceClient.put(url, payload); //sales/offerings?origin=&mark=&packaging=&cateogry=&warehouse=&process=
};

export const exportCoffes = () => {
  let url = process.env.REACT_APP_BASE_URL + '/sales/offerings/export';
  const token = localStorage.getItem('caravela_access_token');
  return axios({
    method: 'GET',
    url,
    responseType: 'arraybuffer',
    headers: { 'Content-Type': 'blob', Authorization: `Bearer ${token}` },
  });
};

export const importCoffes = file => {
  let url = process.env.REACT_APP_BASE_URL + '/sales/offerings/import';
  const token = localStorage.getItem('caravela_access_token');
  var formData = new FormData();
  formData.append('coffees', file);
  return axios.post(url, formData, {
    headers: { Authorization: `Bearer ${token}`, 'Content-Type': file.type },
  });
};

export const getAllCountries = () => {
  return serviceClient.get('/sales-countries');
};
