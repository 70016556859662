import styled from 'styled-components/macro';
import caravela from '../../../app/assets/SVG/caravela.svg';
import { Link } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { ChangePassPayload } from 'models/authModels';
import { changePass } from 'services/authServices';

interface ChangePassProps {
  openCloseModal: () => void;
  quantity?: string;
  size?: string;
  package_type?: string;
  type?: string;
}

const Bg = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(15px);
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: #ffffff;
  border-radius: 20px;
  padding: 0px 2px 20px;
  z-index: 1000;
`;

export const Title = styled.div`
  background: #545454;
  border-radius: 20px;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
  color: #ffffff;
  width: 480px;
  height: 59px;
  padding: 10px;
  border: 1px solid white;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Text = styled.div`
  margin 0 20px
`;

export const Data = styled.div`
  width: 479px;
  height: 43px;
  color: #000000;
  font-weight: 350;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ebeaea;
  margin 1px 0;
`;

export function PurchaseData({
  openCloseModal,
  quantity,
  size,
  package_type,
  type,
}: ChangePassProps) {
  const { t } = useTranslation();

  const closeModal = () => {
    openCloseModal();
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          closeModal();
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  // const resetModal = () => {
  //   openCloseModal();
  // };

  return (
    <Bg>
      <Container ref={wrapperRef}>
        <Title>{t(translations.table.qrelava)}</Title>
        <Info>
          <Data>
            <Text>{t(translations.table.boxes)}</Text>
            <Text>{type === 'Boxes' ? quantity : 0}</Text>
          </Data>
          <Data>
            <Text>{t(translations.table.bags)}</Text>
            <Text>{type === 'Bags' ? quantity : 0}</Text>
          </Data>
          <Data>
            <Text style={{ color: '#545454' }}>
              {t(translations.table.packP)}
            </Text>
            <Text style={{ color: '#545454' }}>{package_type}</Text>
          </Data>
          <Data>
            <Text style={{ color: '#545454' }}>
              {t(translations.table.size)}
            </Text>
            <Text style={{ color: '#545454' }}>{size}Kg</Text>
          </Data>
        </Info>
      </Container>
    </Bg>
  );
}
