import React from 'react';

const SadFace = ({ color }: { color: string }) => (
  <svg
    width="62"
    height="62"
    viewBox="0 0 66 65"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M31.375 7.65625C44.0604 7.65625 54.3437 17.9397 54.3437 30.625C54.3437 43.3104 44.0604 53.5937 31.375 53.5937C18.6897 53.5937 8.40625 43.3104 8.40625 30.625C8.40625 17.9397 18.6897 7.65625 31.375 7.65625Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M21.1667 40.8334C22.4427 38.2813 25.7371 35.7292 31.375 35.7292C37.0128 35.7292 40.3073 38.2813 41.5834 40.8334"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M23.7188 28.073C25.1282 28.073 26.2709 26.3591 26.2709 24.2449C26.2709 22.1307 25.1282 20.4167 23.7188 20.4167C22.3093 20.4167 21.1667 22.1307 21.1667 24.2449C21.1667 26.3591 22.3093 28.073 23.7188 28.073Z"
      fill={color}
    />
    <path
      d="M39.0313 28.073C40.4407 28.073 41.5834 26.3591 41.5834 24.2449C41.5834 22.1307 40.4407 20.4167 39.0313 20.4167C37.6218 20.4167 36.4792 22.1307 36.4792 24.2449C36.4792 26.3591 37.6218 28.073 39.0313 28.073Z"
      fill={color}
    />
  </svg>
);

export default SadFace;
