import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import styled from 'styled-components/macro';
import { CircularProgress, ThemeProvider, useMediaQuery } from '@mui/material';
import styledComponent from 'styled-components/macro';
import { useTheme } from '@mui/material/styles';
import { CustomButton } from '../Button';
import Dropdown from '../Dropdown';
import { IconButton, Alert, Snackbar } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import TrashImg from 'app/assets/SVG/trash.png';
import FinishImg from 'app/assets/SVG/finish.png';
import SpoonB from 'app/assets/SVG/cupb.png';
import SpoonG from 'app/assets/SVG/cupg.png';
import { useRef, useState, useEffect } from 'react';
import drop from '../../assets/SVG/dropdown.svg';
import { DetailCupping } from '../DetailCupping';
import { DetailTable } from '../DetailTable';
import { CuppingModal } from '../CuppingModal';
import { AcceptRejectValueModal } from '../AcceptRejectValueModal';
import {
  deleteTable,
  getResultOptionsCup,
  finishCupping,
  confirmReceived,
} from 'services/cuppingServices';
import { AlertColor } from '@mui/material/Alert';
import { stat } from 'fs';

interface AddProps extends React.HTMLAttributes<HTMLDivElement> {
  color: 'Add' | 'Remove' | 'InProgress';
  disabled?: boolean; // Agregar la propiedad disabled
}

const Button = styled.input`
  border: none;
  background: #4f4e24;
  color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  margin-bottom: 25px;
  width: 213.02px;
  height: 49.59px;
  margin: 0 10px;
`;

export const SlimButton = styled(CustomButton)`
  font-style: normal;
  font-weight: 325;
  font-size: 13px;
  line-height: 16px;
  margin: 5px;
  cursor: default !important;
  ${props =>
    props.background === 'initial' &&
    `
      background: #dfdcca; 
    `}
  ${props =>
    props.background === 'accepted' &&
    `
      background: #B2BA86; 
    `}
  ${props =>
    props.background === 'rejected' &&
    `
      background: #D98458; 
    `}
`;

export const SlimButtonConfirm = styled(CustomButton)`
  font-style: normal;
  font-weight: 325;
  font-size: 13px;
  line-height: 16px;
  margin: 5px;
  cursor: pointer;
  ${props =>
    props.background === 'initial' &&
    `
      background: #dfdcca; 
    `}
  ${props =>
    props.background === 'accepted' &&
    `
      background: #B2BA86; 
    `}
  ${props =>
    props.background === 'rejected' &&
    `
      background: #D98458; 
    `}
`;

const Group = styledComponent.div`
  background: #929168; 
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  height: 33px;
  cursor: pointer;
`;

const StyledHeadCell = styled(TableCell)`
  font-style: normal;
  font-weight: 325;
  font-size: 13px;
  line-height: 16px;
  border: 1px solid white;
  color: black;
  background: #dfdcca;
`;

const Add = styled.div<AddProps>`
  ${props =>
    props.color === 'InProgress' &&
    `
      background: #efd394; 
      color: white;
  `}
  ${props =>
    props.color === 'Add' &&
    `
      background: #B2BA86; 
      color: white;
      cursor: pointer !important;
    `}
  ${props =>
    props.color === 'Remove' &&
    `
      background: #D98458; 
      color: white;
    `}
  ${props =>
    props.disabled &&
    `
      background: #E0E0E0; 
      color: #A0A0A0; 
      cursor: not-allowed; 
    `}
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  height: 33px;
  padding: 0 10px;
`;

const StyledTableRow = styled(TableRow)`
  border-bottom: 3px solid white;
  border-top: 3px solid white;
  th:first-child {
    border-radius: 35px 0px 0px 35px;
  }
  th:last-child {
    border-radius: 0px 35px 35px 0px;
  }
  td:first-child {
    border-radius: 35px 0px 0px 35px;
  }
  td:last-child {
    border-radius: 0px 35px 35px 0px;
  }
`;

const Search = styled.div`
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  background: #f6f6f6;
  border-radius: 30px;
  width: 205.16px;
  height: 40px;
  margin: 10px 10px;
  outline: none;
  padding: 10px;
`;

const Clean = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  margin-left: 20px;
  color: #1989b2;
  margin-bottom: 10px;
`;

const StyledTableCell = styled(TableCell)`
  align-items: center;
  font-style: normal;
  font-weight: 325;
  font-size: 13px;
  line-height: 16px;
  background: #f8f8f8;
  border: 1px solid white;
  display: table-cell;
  overflow: auto;
`;

const StyledTableCellMobile = styled(TableCell)`
  align-items: center;
  font-style: normal;
  font-weight: 325;
  font-size: 13px;
  line-height: 16px;
  background: #f8f8f8;
  // border: 1px solid white;
  width: calc(20% - 1px);
  box-sizing: border-box;
`;

const StyledHeadCellMobile = styled(TableCell)`
  font-style: normal;
  font-weight: 325;
  font-size: 13px;
  line-height: 16px;
  // border: 1px solid white;
  background: #ebeaea;
  background: ${props => props.color};
  width: calc(20% - 1px);
  box-sizing: border-box;
`;

const ContainerHeader = styled.div`
  display: flex;
  width: 100%;
  background: #ebeaea;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const ContainerBody = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  background: #f8f8f8;
`;

const InfoButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: none;
  background-color: #4f4e24;
  color: white;
  font-size: 18px;
  cursor: pointer;
  padding: 0;
  margin-left: 10px;
`;

const InfoBlock = styled.div`
  margin-top: 10px;
  padding: 10px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
`;

const CleanFilter = () => (
  <svg
    width="25px"
    height="25px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 15L21 21M21 15L15 21M10 21V14.6627C10 14.4182 10 14.2959 9.97237 14.1808C9.94787 14.0787 9.90747 13.9812 9.85264 13.8917C9.7908 13.7908 9.70432 13.7043 9.53137 13.5314L3.46863 7.46863C3.29568 7.29568 3.2092 7.2092 3.14736 7.10828C3.09253 7.01881 3.05213 6.92127 3.02763 6.81923C3 6.70414 3 6.58185 3 6.33726V4.6C3 4.03995 3 3.75992 3.10899 3.54601C3.20487 3.35785 3.35785 3.20487 3.54601 3.10899C3.75992 3 4.03995 3 4.6 3H19.4C19.9601 3 20.2401 3 20.454 3.10899C20.6422 3.20487 20.7951 3.35785 20.891 3.54601C21 3.75992 21 4.03995 21 4.6V6.33726C21 6.58185 21 6.70414 20.9724 6.81923C20.9479 6.92127 20.9075 7.01881 20.8526 7.10828C20.7908 7.2092 20.7043 7.29568 20.5314 7.46863L17 11"
      stroke="#000000"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

interface SampleRequest {
  ico: string;
  id: number;
  mark: string;
  opportunity: string;
  origin: string;
  sample_id: string;
  status: string;
}

interface TableProps {
  page: any;
  coffeRaw: any;
  coffeList?: any;
  detail: boolean;
  setDetail: (detail: boolean) => void;
  setLoading: (detail: boolean) => void;
  setPage: (value: any) => void;
  setCoffeList: (value: any) => void;
  getSamplesFunction: () => void;
  getListCupping: () => void;
  getListTables: () => void;
  getListCoffees: () => void;
  cleanFilter: () => void;
  errorInfo: any;
  origins: any;
  tables: any;
  setTables: (value: any) => void;
  users: any;
}

let hash = {};

export function CuppingTable({
  setPage,
  page,
  coffeRaw,
  coffeList,
  detail,
  setDetail,
  getSamplesFunction,
  getListCupping,
  getListTables,
  getListCoffees,
  errorInfo,
  origins,
  cleanFilter,
  setCoffeList,
  setLoading,
  tables,
  setTables,
  users,
}: TableProps) {
  const theme = useTheme();
  const { t } = useTranslation();

  const [dateInit, setDateInit] = useState('');
  const [originSelected, setOriginSelected] = useState('');
  const [loadingData, setLoadingData] = useState(false);
  const [info, setInfo] = useState<any[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [detailOpen, setDetailOpen] = useState(false);
  const [modalOpenUsers, setModalOpenUsers] = useState(false);
  const [modalOpenCoffee, setModalOpenCoffee] = useState(false);
  const [value, setValue] = useState<number>(0);
  const [id, setId] = useState('');
  const [type, setType] = useState('Confirm');
  const [open, setOpen] = useState(false);
  const [coffeListFiltered, setcoffeListFiltered] = useState<any>([]);
  const [disabledOptions, setDisabledOptions] = useState<any[]>([]);
  const [arraySampleTable, setArraySampleTable] = useState<any[]>([]);
  const [tableId, setTableId] = useState<number>(0);
  const [isInfoVisible, setIsInfoVisible] = useState(false);
  const [visibleRows, setVisibleRows] = useState<SampleRequest[]>([]);
  const [infoTable, setInfoTable] = useState({});
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState<AlertColor | undefined>(
    undefined,
  );
  const [isVisible, setIsVisible] = useState(false);
  const [visibleItemId, setVisibleItemId] = useState(null);

  const sampleIdsArray: string[] = [];
  tables.forEach(table => {
    table.cupping_table_coffees.forEach(coffee => {
      sampleIdsArray.push(coffee.sample_id as string);
    });
  });

  const handleDetail = (detailStatus, tableId) => {
    setDetail(detailStatus);
    setTableId(tableId);
  };

  const tableInfo = async (detail, tableId) => {
    //setDetailOpen(true)
    setTableId(tableId);
    //setLoading(true);
    const resultsCup: any[] = [];

    const promises = tables
      .filter(item => item.table_id === tableId)
      .flatMap(item =>
        item.cupping_table_coffees.map(sample =>
          getResultOptionsCup(sample.sample_id)
            .then(res => {
              if (res.status === 200) {
                if (Object.keys(res.data).length > 0) {
                  resultsCup.push(res.data);
                }
              }
            })
            .catch(error => {
              console.log(error);
            }),
        ),
      );

    await Promise.all(promises);

    if (resultsCup.length > 0) {
      setInfoTable(resultsCup);
      setDetailOpen(detail);
    } else {
      setDetailOpen(false);
      setLoading(false);
      setAlertMessage(t('cupping.no_data'));
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const cleanFilterAndInput = () => {
    setLoading(true);
    setOriginSelected('');
    setcoffeListFiltered([]);
    if (initInputRef.current) {
      initInputRef.current.value = '';
    }
    cleanFilter();
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const openModalWithInfo = info => {
    setModalOpen(true);
    setInfo(info);
  };

  const deleteTableCup = async table => {
    try {
      const response = await deleteTable(table);
      if (response.status === 204) {
        setAlertMessage(t('general.cupped'));
        setAlertSeverity('error');
        setAlertOpen(true);
      } else if (response.status === 200) {
        setAlertMessage(t('general.delete'));
        setAlertSeverity('success');
        setAlertOpen(true);
        getListTables();
      }
    } catch (error) {
      console.error('Ocurrió un error:', error);
    }
  };

  const finishCup = table => {
    finishCupping({ payload: table })
      .then(response => {
        if (response.status === 200) {
          getListTables();
          setAlertMessage(t('cupping.finish_cup'));
          setAlertSeverity('success');
          setAlertOpen(true);
        }
      })
      .catch(error => {
        setAlertMessage(t('cupping.error_finish'));
        setAlertSeverity('error');
        setAlertOpen(true);
      });
  };

  const confirmDenie = (value, id) => {
    setAlertMessage('Successfully');
    setAlertSeverity('success');
    setAlertOpen(true);
  };

  const confirmReceivedButton = item => {
    confirmReceived({ payload: item })
      .then(response => {
        if (response.status === 200) {
          setAlertMessage('Sample received');
          setAlertSeverity('success');
          setAlertOpen(true);
          getSamplesFunction();
        }
      })
      .catch(error => {
        setAlertMessage('Error');
        setAlertSeverity('error');
        setAlertOpen(true);
      });
  };

  const searchCup = () => {
    setCoffeList(coffeList);
    setLoading(true);
    const filteredList = coffeList.filter(sample => {
      const originMatch = !originSelected || sample.origin === originSelected;
      const dateMatch =
        !dateInit || new Date(sample.date) >= new Date(dateInit);

      return originMatch && dateMatch;
    });

    setCoffeList(filteredList);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const isMobile = useMediaQuery('(max-width:767px)');

  const initInputRef = useRef<HTMLInputElement | null>(null);

  const openModal = () => {
    setIsInfoVisible(!isInfoVisible);
  };

  const clean = () => {
    // setLoadingData(true);
    if (initInputRef.current) {
      initInputRef.current.value = '';
    }
    setOriginSelected('');
  };

  const search = () => {};

  const searchTable = dateInit => {
    setTables(tables);
    setLoading(true);
    const filteredList = tables.filter(sample => {
      const dateMatch =
        !dateInit || new Date(sample.created_at) >= new Date(dateInit);

      return dateMatch;
    });

    setCoffeList(filteredList);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const searchOrigin = (selected: any) => {
    setOriginSelected(selected.value);
  };

  const handleSetCoffee = item => {
    setArraySampleTable(prevItems => [...prevItems, item]);
    setCoffee(item.sample_id);
  };

  const handleRemoveCoffee = item => {
    setArraySampleTable(prevItems => prevItems.filter(elem => elem !== item));
    removeCoffee(item.sample_id);
  };

  const setCoffee = (coffee: string) => {
    const newDisabledArray: any[] = [...disabledOptions, coffee];
    setDisabledOptions(newDisabledArray);
  };

  const removeCoffee = (coffee: string) => {
    let coffeeDelete = disabledOptions.filter(elem => elem !== coffee);
    setDisabledOptions(coffeeDelete);
  };

  const addUser = () => {};
  const addCoffee = () => {};

  const groupedData = {};

  const extractSampleRequests = item => {
    if (item && item.cupping_table_coffees) {
      const sampleRequestsArray = item.cupping_table_coffees.map(
        coffee => coffee.sample_request,
      );
      return sampleRequestsArray.flat();
    }
    return [];
  };

  const toggleInfo = item => {
    console.log(item);
    const sampleRequestsArray = extractSampleRequests(item);
    sampleRequestsArray.forEach(element => {
      element.status = item.status;
    });

    setVisibleRows(sampleRequestsArray);
    setIsVisible(!isVisible);
    setVisibleItemId(visibleItemId === item.table_id ? null : item.table_id);
  };

  // Agrupar los elementos por origen
  coffeRaw &&
    coffeRaw.forEach(item => {
      const origin = item.origin;
      if (!groupedData[origin]) {
        groupedData[origin] = [];
      }
      groupedData[origin].push(item);
    });

  const getBackgroundColor = origin => {
    switch (origin) {
      case 'NICARAGUA':
        return '#A8A2CC';
      case 'MEXICO':
        return '#D98458';
      case 'GUATEMALA':
        return '#F5ABB8';
      case 'EL SALVADOR':
        return '#B2D9D1';
      case 'COLOMBIA':
        return '#B2BA86';
      case 'ECUADOR':
        return '#D7BE5A';
      case 'PERU':
        return '#D5594B';
      default:
        return '#4f4e24';
    }
  };

  return (
    <>
      {' '}
      {modalOpenUsers ? (
        <CuppingModal
          setModalOpenUsers={setModalOpenUsers}
          getListTables={getListTables}
          onSubmit={addUser}
          modal={'user'}
          data={arraySampleTable}
          users={users}
        />
      ) : modalOpenCoffee ? (
        <CuppingModal
          setModalOpenUsers={setModalOpenCoffee}
          getListTables={getListTables}
          onSubmit={addCoffee}
          modal={'coffee'}
          data={arraySampleTable}
          users={users}
        />
      ) : page === 1 ? (
        open === true ? (
          <AcceptRejectValueModal
            setOpen={setOpen}
            type={type}
            id={id}
            value={value}
            getCoffeesWithFiltersFirstCall={getSamplesFunction}
          />
        ) : (
          <>
            {/* <Search>
          <div
            style={{
              fontSize: '13px',
              fontWeight: '625',
              margin: '0 20px',
            }}
          >
            {' '}
            {t(translations.table.origin)}
          </div>
          <Dropdown
            handleChange={searchOrigin}
            value={originSelected}
            defaultValue="Origin"
            width={false}
            list={[]}
            // list={
            //   origin &&
            //   origin.filter(o =>
            //     hash[o.value] ? false : (hash[o.value] = true),
            //   )
            // }
          />
          <Clean onClick={clean}>
            <div>{t(translations.table.clean_filter)}</div>
          </Clean>
        </Search> */}
            {errorInfo !== '' ? (
              <h3
                style={{
                  color: 'gray',
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '50px',
                  textAlign: 'center',
                }}
              >
                {errorInfo === t(translations.general.no_search)
                  ? errorInfo
                  : t(`${translations.errors_400[errorInfo]}`)}
              </h3>
            ) : (
              <ThemeProvider theme={theme}>
                {!isMobile ? (
                  <>
                    {Object.keys(groupedData).length > 0 ? (
                      Object.keys(groupedData).map(origin => (
                        <div key={origin}>
                          <div className="d-flex align-items-center mt-5 mb-4">
                            <h1 style={{ color: '#1989B2' }}></h1>
                          </div>
                          <TableContainer>
                            <Table
                              sx={{ minWidth: 700 }}
                              aria-label="customized table"
                            >
                              <TableHead>
                                <StyledTableRow>
                                  <StyledHeadCell align="center">
                                    {t(translations.table.origin)}
                                  </StyledHeadCell>
                                  <StyledHeadCell align="center">
                                    {t(translations.table.ico)}
                                  </StyledHeadCell>
                                  <StyledHeadCell align="center">
                                    {t(translations.table.marks)}
                                  </StyledHeadCell>
                                  <StyledHeadCell align="center">
                                    {t(translations.table.grade)}
                                  </StyledHeadCell>

                                  <StyledHeadCell align="center">
                                    {t(translations.table.sample_type)}
                                  </StyledHeadCell>
                                  <StyledHeadCell
                                    style={{
                                      color: 'white',
                                      background: '#4f4e24',
                                    }}
                                    align="center"
                                  >
                                    {t(translations.general.received)}
                                  </StyledHeadCell>
                                </StyledTableRow>
                              </TableHead>
                              <TableBody>
                                {groupedData[origin] &&
                                  groupedData[origin].map(item => (
                                    <StyledTableRow key={Math.random()}>
                                      <StyledTableCell
                                        align="center"
                                        style={{
                                          color: 'white',
                                          background: getBackgroundColor(
                                            item.origin,
                                          ),
                                        }}
                                      >
                                        {item.origin}
                                      </StyledTableCell>
                                      <StyledTableCell align="center">
                                        {item.ico}
                                      </StyledTableCell>
                                      <StyledTableCell align="center">
                                        <Group>{item.mark}</Group>
                                      </StyledTableCell>
                                      <StyledTableCell align="center">
                                        {item.quality}
                                      </StyledTableCell>
                                      <StyledTableCell align="center">
                                        {item.sample_type}
                                      </StyledTableCell>
                                      <StyledTableCell align="center">
                                        {' '}
                                        <>
                                          <SlimButtonConfirm
                                            onClick={() =>
                                              confirmReceivedButton(item)
                                            }
                                            background="#B2BA86"
                                            color="#F3F1F1"
                                          >
                                            {t(translations.general.yes)}
                                          </SlimButtonConfirm>
                                          {/* <SlimButton
                                        onClick={() => confirmDenie(0, item.id)}
                                        background="#D98458"
                                        color="#F3F1F1"
                                      >
                                        {t(translations.general.no)}
                                      </SlimButton> */}
                                        </>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      ))
                    ) : (
                      <h2>No hay datos que mostrar</h2>
                    )}
                  </>
                ) : (
                  <>
                    <ContainerHeader>
                      <StyledHeadCellMobile align="center"></StyledHeadCellMobile>
                      <StyledHeadCellMobile align="center">
                        {t(translations.table.origin)}
                      </StyledHeadCellMobile>
                      <StyledHeadCellMobile align="center">
                        {t(translations.table.ico)}
                      </StyledHeadCellMobile>
                      {/* <StyledHeadCellMobile align="center">
              {t(translations.table.marks)}
            </StyledHeadCellMobile>
            <StyledHeadCellMobile align="center">
              {t(translations.table.quality)}
            </StyledHeadCellMobile>

            <StyledHeadCellMobile align="center">
              {t(translations.table.status)}
            </StyledHeadCellMobile> */}
                      <StyledHeadCellMobile
                        style={{ color: 'white', background: '#4f4e24' }}
                        align="center"
                      >
                        {t(translations.general.received)}
                      </StyledHeadCellMobile>
                    </ContainerHeader>

                    {/* {historyContract.map(groupBy =>  */}
                    {coffeRaw &&
                      coffeRaw.map(item => (
                        <ContainerBody key={Math.random()}>
                          <StyledTableCellMobile align="center">
                            <img
                              src={drop}
                              alt="drop"
                              style={{ width: '22px', height: '22px' }}
                              onClick={() => openModalWithInfo(item)}
                            />
                          </StyledTableCellMobile>
                          <StyledTableCellMobile
                            align="center"
                            style={{ color: 'white', background: 'blue' }}
                          >
                            {item.origin}
                          </StyledTableCellMobile>
                          <StyledTableCellMobile align="center">
                            {item.ico}
                          </StyledTableCellMobile>
                          {/* <StyledTableCellMobile align="center">
                <Group>{item.mark}</Group>
              </StyledTableCellMobile>
              <StyledTableCellMobile align="center">AA</StyledTableCellMobile>
              <StyledTableCellMobile align="center">
                {item.sample_type}
              </StyledTableCellMobile> */}
                          <StyledTableCellMobile align="center">
                            <>
                              <SlimButton
                                onClick={() => confirmDenie(1, item.id)}
                                background="#5C36F3"
                                color="#F3F1F1"
                              >
                                {t(translations.general.yes)}
                              </SlimButton>
                              <SlimButton
                                onClick={() => confirmDenie(0, item.id)}
                                background="#F33636"
                                color="#F3F1F1"
                              >
                                {t(translations.general.no)}
                              </SlimButton>
                            </>
                          </StyledTableCellMobile>
                        </ContainerBody>
                      ))}
                  </>
                )}
              </ThemeProvider>
            )}
          </>
        )
      ) : page === 2 ? (
        <ThemeProvider theme={theme}>
          <div className="d-flex justify-content-center mb-5"></div>
          {page === 1 && (
            <div className="d-flex align-items-center">
              <Search>
                <div
                  style={{
                    fontSize: '13px',
                    fontWeight: '625',
                    margin: '0 20px',
                  }}
                >
                  {' '}
                  {t(translations.table.origin)}
                </div>
                <Dropdown
                  handleChange={searchOrigin}
                  value={originSelected}
                  defaultValue="Origin"
                  width={false}
                  list={[]}
                  // list={
                  //   origin &&
                  //   origin.filter(o =>+
                  //     hash[o.value] ? false : (hash[o.value] = true),
                  //   )
                  // }
                />
              </Search>
              <div
                style={{
                  cursor: 'pointer',
                  marginTop: '20px',
                  marginLeft: '5px',
                }}
              >
                {loadingData ? (
                  <CircularProgress color="primary" />
                ) : (
                  <IconButton onClick={search} aria-label="search">
                    <SearchIcon />
                  </IconButton>
                )}
                <IconButton onClick={cleanFilterAndInput}>
                  <CleanFilter />
                </IconButton>
              </div>
              <div style={{ marginLeft: 'auto', display: 'flex' }}>
                {/* <div
                  style={{
                    color: '#4f4e24',
                    cursor: 'pointer',
                    marginRight: '20px',
                  }}
                  onClick={() => {
                    setModalOpenCoffee(true);
                  }}
                >
                  + Add a new
                </div> */}
                <Button
                  style={{ cursor: 'pointer' }}
                  type="button"
                  onClick={() => {
                    setModalOpenUsers(true);
                  }}
                  value="Add Users"
                />
              </div>
            </div>
          )}
          {page === 2 && (
            <div className="d-flex align-items-center">
              <Search>
                <div
                  style={{
                    fontSize: '13px',
                    fontWeight: '625',
                    margin: '0 20px',
                  }}
                >
                  {t(translations.general.initial_d)}
                </div>
                <div className="d-flex align-items-center">
                  <div style={{ marginLeft: 'auto', cursor: 'pointer' }}></div>
                  <Input
                    type="date"
                    ref={initInputRef}
                    onChange={e => setDateInit(e.target.value)}
                    placeholder="Initial Date"
                  />
                </div>
              </Search>
              <Search>
                <div
                  style={{
                    fontSize: '13px',
                    fontWeight: '625',
                    margin: '0 20px',
                  }}
                >
                  {' '}
                  {t(translations.table.origin)}
                </div>
                <Dropdown
                  handleChange={searchOrigin}
                  value={originSelected}
                  defaultValue="Origin"
                  width={false}
                  list={
                    origins &&
                    origins.map(item => ({
                      value: item,
                      label: item,
                    }))
                  }
                />
              </Search>
              <div
                style={{
                  cursor: 'pointer',
                  marginTop: '20px',
                  marginLeft: '5px',
                }}
              >
                {loadingData ? (
                  <CircularProgress color="primary" />
                ) : (
                  <IconButton onClick={searchCup} aria-label="search">
                    <SearchIcon />
                  </IconButton>
                )}
                <IconButton onClick={cleanFilterAndInput}>
                  <CleanFilter />
                </IconButton>
              </div>
              <div
                style={{
                  marginLeft: 'auto',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {/* <div
                  style={{ color: '#4f4e24', cursor: 'pointer' }}
                  onClick={() => {
                    setModalOpenCoffee(true);
                  }}
                >
                  + Add a new
                </div> */}
                <Button
                  style={{ cursor: 'pointer' }}
                  type="button"
                  onClick={() => {
                    setModalOpenUsers(true);
                  }}
                  value="Add Users"
                />
              </div>
            </div>
          )}
          {page === 3 && (
            <div className="d-flex align-items-center">
              <Search>
                <div
                  style={{
                    fontSize: '13px',
                    fontWeight: '625',
                    margin: '0 20px',
                  }}
                >
                  {t(translations.general.initial_d)}
                </div>
                <div className="d-flex align-items-center">
                  <div style={{ marginLeft: 'auto', cursor: 'pointer' }}></div>
                  <Input
                    type="date"
                    ref={initInputRef}
                    onChange={e => setDateInit(e.target.value)}
                    placeholder="Initial Date"
                  />
                </div>
              </Search>
              <Search>
                <div
                  style={{
                    fontSize: '13px',
                    fontWeight: '625',
                    margin: '0 20px',
                  }}
                >
                  {' '}
                  {t(translations.table.origin)}
                </div>
                <Dropdown
                  handleChange={searchOrigin}
                  value={originSelected}
                  defaultValue="Origin"
                  width={false}
                  list={[]}
                  // list={
                  //   origin &&
                  //   origin.filter(o =>
                  //     hash[o.value] ? false : (hash[o.value] = true),
                  //   )
                  // }
                />
              </Search>

              <div
                style={{
                  cursor: 'pointer',
                  marginTop: '20px',
                  marginLeft: '5px',
                }}
              >
                {loadingData ? (
                  <CircularProgress color="primary" />
                ) : (
                  <IconButton onClick={search} aria-label="search">
                    <SearchIcon />
                  </IconButton>
                )}
                <IconButton onClick={cleanFilterAndInput}>
                  <CleanFilter />
                </IconButton>
              </div>
              <div style={{ marginLeft: 'auto', display: 'flex' }}>
                {/* <div
                  style={{ color: '#4f4e24', cursor: 'pointer' }}
                  onClick={() => {
                    setModalOpenCoffee(true);
                  }}
                >
                  + Add a new
                </div> */}
                <Button
                  style={{ cursor: 'pointer' }}
                  type="button"
                  onClick={() => {
                    setModalOpenUsers(true);
                  }}
                  value="Add Users"
                />
              </div>
            </div>
          )}
          {/*  <Clean onClick={cleanFilterAndInput}>
        <div>{t(translations.table.clean_filter)}</div>
      </Clean> */}
          <>
            {!isMobile ? (
              <TableContainer>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <StyledTableRow>
                      <StyledHeadCell align="center">
                        {t(translations.table.origin)}
                      </StyledHeadCell>
                      <StyledHeadCell align="center">
                        {t(translations.table.ico)}
                      </StyledHeadCell>
                      <StyledHeadCell align="center">
                        {t(translations.table.contract)}
                      </StyledHeadCell>
                      <StyledHeadCell align="center">
                        {t(translations.table.id_sample)}
                      </StyledHeadCell>
                      <StyledHeadCell align="center">
                        {t(translations.table.grade)}
                      </StyledHeadCell>
                      <StyledHeadCell align="center">
                        {t(translations.table.date)}
                      </StyledHeadCell>
                      <StyledHeadCell
                        style={{ color: 'white', background: '#4f4e24' }}
                        align="center"
                      >
                        {t(translations.table.table)}
                      </StyledHeadCell>
                      <StyledHeadCell
                        style={{ color: 'white', background: '#4f4e24' }}
                        align="center"
                      >
                        {t(translations.table.status)}
                      </StyledHeadCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {/* {historyContract.map(groupBy =>  */}
                    {coffeList &&
                      coffeList.map(item => (
                        <StyledTableRow key={Math.random()}>
                          <StyledTableCell
                            align="center"
                            style={{
                              color: 'white',
                              background: getBackgroundColor(item.origin),
                            }}
                          >
                            {item.origin}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {item.ico}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {item.contract}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {item.sample_id}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {item.score}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {item.date.split(' ')[0]}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {sampleIdsArray.includes(item.sample_id) &&
                            item.state === 'initial' ? (
                              <Add
                                color="InProgress"
                                id={`add-${item.sample_id}`}
                                onClick={() => handleSetCoffee(item)}
                                disabled={true}
                              >
                                {t(translations.table.in_process)}
                              </Add>
                            ) : item.state === 'initial' ? (
                              !disabledOptions.find(
                                element => element === item.sample_id,
                              ) ? (
                                <Add
                                  color="Add"
                                  id={`add-${item.sample_id}`}
                                  onClick={() => handleSetCoffee(item)}
                                  disabled={item.state !== 'initial'}
                                >
                                  {t(translations.table.add)}
                                </Add>
                              ) : (
                                <Add
                                  color="Remove"
                                  id={`remove-${item.sample_id}`}
                                  onClick={() => handleRemoveCoffee(item)}
                                  disabled={item.state !== 'initial'}
                                >
                                  {t(translations.table.remove)}
                                </Add>
                              )
                            ) : (
                              <Add
                                color="InProgress"
                                id={`add-${item.sample_id}`}
                                disabled={true}
                              >
                                {t(translations.general.cupped)}
                              </Add>
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <SlimButton
                              background={item.state}
                              key={item.state}
                            >
                              {item.state === 'initial'
                                ? t(translations.table.available)
                                : item.state === 'accepted'
                                ? t(translations.general.accepted)
                                : item.state === 'rejected'
                                ? t(translations.general.rejected)
                                : null}
                            </SlimButton>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <TableContainer>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <StyledTableRow>
                      <StyledHeadCell align="center">
                        {t(translations.table.origin)}
                      </StyledHeadCell>
                      <StyledHeadCell align="center">
                        {t(translations.table.ico)}
                      </StyledHeadCell>
                      <StyledHeadCell align="center">
                        {t(translations.table.id_sample)}
                      </StyledHeadCell>
                      <StyledHeadCell align="center">
                        {t(translations.table.cup)}
                      </StyledHeadCell>
                      <StyledHeadCell align="center">
                        {t(translations.table.date)}
                      </StyledHeadCell>
                      <StyledHeadCell
                        style={{ color: 'white', background: '#4f4e24' }}
                        align="center"
                      >
                        {t(translations.table.table)}
                      </StyledHeadCell>
                      <StyledHeadCell
                        style={{ color: 'white', background: '#4f4e24' }}
                        align="center"
                      >
                        {t(translations.table.status)}
                      </StyledHeadCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {coffeList &&
                      coffeList.map(item => (
                        <StyledTableRow key={Math.random()}>
                          <StyledTableCell
                            align="center"
                            style={{
                              color: 'white',
                              background: getBackgroundColor(item.origin),
                            }}
                          >
                            {item.origin}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {item.ico}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {item.sample_id}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {item.score}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {item.date}
                          </StyledTableCell>
                          {/* <StyledTableCell align="center">
                        <Add color="Add">{t(translations.table.add)}</Add> */}
                          {/* <Add color="Remove">{t(translations.table.remove)}</Add> */}
                          {/* </StyledTableCell> */}
                          <StyledTableCell align="center">
                            {!disabledOptions.find(
                              element => element === item.sample_id,
                            ) && (
                              <Add
                                color="Add"
                                id={`add-${item.sample_id}`}
                                onClick={() => setCoffee(item.sample_id)}
                              >
                                {t(translations.table.remove)}
                              </Add>
                            )}
                            {disabledOptions.find(
                              element => element === item.sample_id,
                            ) && (
                              <Add
                                color="Remove"
                                id={`remove-${item.sample_id}`}
                                onClick={() => removeCoffee(item.sample_id)}
                              >
                                {t(translations.table.remove)}
                              </Add>
                            )}
                            {/* <Add color="Add">{t(translations.table.add)}</Add> */}
                            {/* <Add color="Remove">{t(translations.table.remove)}</Add> */}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {/* <SlimButton background="#1989B2" color="#F3F1F1">
                    {t(translations.general.pending)}
                  </SlimButton> */}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </>
        </ThemeProvider>
      ) : detail ? (
        <DetailCupping
          setOpen={setDetail}
          setPage={setPage}
          setDetailOpen={setDetailOpen}
          tableIdCup={tableId}
          getSamplesFunction={getSamplesFunction}
        />
      ) : !detailOpen ? (
        <ThemeProvider theme={theme}>
          <div className="d-flex justify-content-center mb-5"></div>
          <div className="d-flex align-items-center">
            <Search>
              <div
                style={{
                  fontSize: '13px',
                  fontWeight: '625',
                  margin: '0 20px',
                }}
              >
                {t(translations.general.initial_d)}
              </div>
              <div className="d-flex align-items-center">
                <div style={{ marginLeft: 'auto', cursor: 'pointer' }}></div>
                <Input
                  type="date"
                  ref={initInputRef}
                  onChange={e => setDateInit(e.target.value)}
                  placeholder="Initial Date"
                />
              </div>
            </Search>
            <div
              style={{
                cursor: 'pointer',
                marginTop: '20px',
                marginLeft: '5px',
              }}
            >
              {loadingData ? (
                <CircularProgress color="primary" />
              ) : (
                <IconButton onClick={searchTable} aria-label="search">
                  <SearchIcon />
                </IconButton>
              )}
              <IconButton onClick={cleanFilter}>
                <CleanFilter />
              </IconButton>
            </div>
          </div>
          {/* <Clean onClick={cleanFilter}>
        <div>{t(translations.table.clean_filter)}</div>
      </Clean> */}
          <>
            <TableContainer>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <StyledTableRow>
                    <StyledHeadCell align="center">
                      {t(translations.table.c_table)}
                    </StyledHeadCell>
                    <StyledHeadCell align="center"></StyledHeadCell>
                    <StyledHeadCell align="center">
                      {t(translations.table.date_c)}
                    </StyledHeadCell>
                    <StyledHeadCell align="center">
                      {t(translations.table.c_date)}
                    </StyledHeadCell>
                    <StyledHeadCell align="center">
                      {t(translations.table.sample_quantity)}
                    </StyledHeadCell>
                    <StyledHeadCell
                      style={{ color: 'white', background: '#4f4e24' }}
                      align="center"
                    >
                      {t(translations.table.action)}
                    </StyledHeadCell>
                    <StyledHeadCell
                      style={{ color: 'white', background: '#4f4e24' }}
                      align="center"
                    >
                      {t(translations.table.status)}
                    </StyledHeadCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {tables.map(item => (
                    <React.Fragment key={item.table_id}>
                      <StyledTableRow>
                        <StyledTableCell
                          align="center"
                          style={{
                            color: 'white',
                            background: '#9f9f94',
                            cursor: 'pointer',
                          }}
                        >
                          {item.table_id}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <InfoButton
                            onClick={() =>
                              tableInfo(!detailOpen, item.table_id)
                            }
                          >
                            ¡
                          </InfoButton>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {item.created_at && item.created_at.split(' ')[0]}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {item.cupping_at && item.cupping_at.split(' ')[0]}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {item.cupping_table_coffees.length}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <div
                            style={{
                              cursor:
                                item.cupping_at !== null
                                  ? 'not-allowed'
                                  : 'pointer',
                              pointerEvents:
                                item.cupping_at !== null ? 'none' : 'auto',
                            }}
                          >
                            <img
                              src={SpoonB}
                              alt="SpoonB"
                              style={{
                                width: '33px',
                                height: '33px',
                                cursor: 'pointer',
                              }}
                              onClick={() =>
                                handleDetail(!detail, item.table_id)
                              }
                            />
                            <img
                              onClick={() => deleteTableCup(item.table_id)}
                              src={TrashImg}
                              alt="trash"
                              style={{
                                width: '33px',
                                height: '33px',
                                cursor: 'pointer',
                              }}
                            />
                            <img
                              onClick={() => finishCup(item.table_id)}
                              src={FinishImg}
                              alt="finish"
                              style={{
                                width: '33px',
                                height: '33px',
                                cursor: 'pointer',
                              }}
                            />
                          </div>
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          onClick={() => toggleInfo(item)}
                        >
                          {item.cupping_at === null
                            ? t(translations.general.pending)
                            : t(translations.general.cupped)}
                          <ArrowDropDownIcon />
                        </StyledTableCell>
                      </StyledTableRow>
                      {visibleItemId === item.table_id && (
                        <>
                          <StyledTableRow
                            style={{ color: 'black', background: '#e7e4e4' }}
                          >
                            <StyledHeadCell
                              style={{ color: 'black', background: '#e7e4e4' }}
                              align="center"
                              colSpan={2}
                            >
                              <strong>{t(translations.table.origin)}</strong>
                            </StyledHeadCell>
                            <StyledHeadCell
                              style={{ color: 'black', background: '#e7e4e4' }}
                              align="center"
                            >
                              <strong>{t(translations.table.id_sample)}</strong>
                            </StyledHeadCell>
                            <StyledHeadCell
                              style={{ color: 'black', background: '#e7e4e4' }}
                              align="center"
                            >
                              <strong>{t(translations.table.ico)}</strong>
                            </StyledHeadCell>
                            <StyledHeadCell
                              style={{ color: 'black', background: '#e7e4e4' }}
                              align="center"
                              colSpan={2}
                            >
                              <strong>{t(translations.table.mark)}</strong>
                            </StyledHeadCell>
                            <StyledHeadCell
                              style={{ color: 'black', background: '#e7e4e4' }}
                              align="center"
                            >
                              <strong>{t(translations.table.status)}</strong>
                            </StyledHeadCell>
                          </StyledTableRow>
                          {visibleRows.map((sample, index) => (
                            <StyledTableRow
                              style={{ color: 'black', background: '#e7e4e4' }}
                            >
                              <StyledTableCell
                                style={{
                                  color: 'black',
                                  background: '#e7e4e4',
                                }}
                                align="center"
                                colSpan={2}
                              >
                                {sample.origin}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {sample.sample_id}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {sample.ico}
                              </StyledTableCell>
                              <StyledTableCell align="center" colSpan={2}>
                                {sample.mark}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {sample.status}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </>
                      )}
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        </ThemeProvider>
      ) : (
        <DetailTable
          setDetailOpen={setDetailOpen}
          idTable={tableId}
          infoTable={infoTable}
        />
      )}
      <Snackbar
        open={alertOpen}
        autoHideDuration={4000}
        onClose={() => setAlertOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setAlertOpen(false)}
          severity={alertSeverity}
          sx={{
            width: '100%',
            fontSize: '1.5rem',
            fontWeight: 'bold',
            padding: '20px',
            maxWidth: '600px',
            margin: '0 auto',
          }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
