//ORIGINAL
import { OfferingTable } from 'app/components/OfferingTable';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { getCountries, getMarks } from 'services/generalServices';
import { getMyPosition, getOffering } from 'services/sampleServices';
import { getReviewHistorical, getReviewPending } from 'services/reviewServices';
import styled from 'styled-components/macro';
import { LoadingPage } from '../LoadingPage';
import { MyPositionPage } from '../MyPositionPage';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { useMediaQuery } from '@mui/material';
import { Hero } from 'app/components/Hero';

const Tab = styled.div<{ state: string }>`
  background-color: ${props =>
    props.state === 'active' ? '#ffffff' : '#33BA79'};
  color: ${props => (props.state === 'active' ? '#000000' : '#F8F8F8')};
  transition: all 0.5s ease-in-out;
  border-radius: 30px;
  border: 4px solid #33ba79;
  width: 245px;
  height: 38px;
  display: flex;
  justify-content: center;
  margin: 10px;
  cursor: ${props => (props.state === 'active' ? 'default' : 'pointer')};
  margin-left: -40px;

  @media (max-width: 768px) {
    border-radius: 0; /* Remove border-radius for mobile */
    margin: 5px; /* Reduce the margin for mobile */
    margin-left: 0; /* Remove negative margin for mobile */
  }
`;

const Text = styled.div`
  font-weight: 325;
  font-size: 20px;
`;

export function SampleRequestPage() {
  const [origin, setOrigin] = useState<any>('');
  const [mark, setMark] = useState<any>('');
  const [packaging, setPackaging] = useState<any>('');
  const [category, setCategory] = useState<any>('');
  const [location, setLocation] = useState<any>('');
  const [process, setProcess] = useState<any>('');
  const [coffes, setCoffes] = useState<any[]>();
  const [loadingData, setLoadingData] = useState(false);
  const [errorOffering, setErrorOffering] = useState('');
  const [errorInfo, setErrorInfo] = useState<any>('');
  const [samplesAvailable, setSamplesAvailable] = useState<number>();

  const isMobile = useMediaQuery('(max-width:767px)');

  const { t } = useTranslation();

  /* const search = () => {
    setErrorOffering('');
    setLoadingData(true);
    getOffering(origin, mark, packaging, category, process, location)
      .then(response => {
        if (response.status === 200) {
          setLoadingData(false);
          setCoffes(response.data.coffees);
          setSamplesAvailable(response.data.available_samples);
        }
      })
      .catch(error => {
        setErrorOffering(error.response?.data?.message);
        setLoadingData(false);
        console.log(error.response?.data);
      });
  };

  const cleanAndSearch = () => {
    setErrorOffering('');
    setLoadingData(true);
    getOffering('', '', '', '', '', '')
      .then(response => {
        if (response.status === 200) {
          setLoadingData(false);
          setCoffes(response.data.coffees);
          setSamplesAvailable(response.data.available_samples);
        }
      })
      .catch(error => {
        setErrorOffering(error.response?.data?.message);
        setLoadingData(false);
        console.log(error.response?.data);
      });
  };

  /*useEffect(() => {
    setErrorOffering('');
    getOffering(origin, mark, packaging, category, process, location)
      .then(response => {
        if (response.status === 200) {
          setCoffes(response.data.coffees);
          setSamplesAvailable(response.data.available_samples);
        }
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
        setErrorOffering(error.response?.data?.message);
      });
    setLoading(false);
  }, []);*/

  return (
    <>
      <Helmet>
        <title>Sample Request</title>
        <meta name="description" content="Roasterhub sample request" />
      </Helmet>
      {/*{loading ? (
        <LoadingPage />
      ) : ( */}
      <div>
        <div
          className="d-flex"
          style={{
            padding: !isMobile ? '30px 96px' : '0 15px',

            flexDirection: 'column',
          }}
        >
          <div
            className="d-flex"
            style={{
              justifyContent: 'center',
              marginLeft: !isMobile ? '10px' : '0px',
              flexDirection: !isMobile ? 'row' : 'column',
              alignItems: 'center',
            }}
          >
            <div className="d-flex justify-content-start w-100">
              <Hero
                title={t(translations.pages.home.samples)}
                subtitle={t(translations.sample_request.description)}
              />
            </div>
          </div>
          <>
            <MyPositionPage errorInfo={errorInfo} setErrorInfo={setErrorInfo} />
            {/* {errorOffering !== '' && (
                <h3
                  style={{
                    color: 'gray',
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '50px',
                    textAlign: 'center',
                  }}
                >
                  {t(`${translations.errors_400[errorOffering]}`)}
                </h3>
              )} */}
          </>
        </div>
      </div>
      {/* )} */}
    </>
  );
}
