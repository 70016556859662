// FilterModal.js
import React, { useRef } from 'react';
import Modal from '@mui/material/Modal';
import Dropdown from '../Dropdown';
import styled from 'styled-components/macro';
import { translations } from 'locales/translations';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@mui/material';

const Clean = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  margin-left: 20px;
  margin-bottom: 5px;
  color: #1989b2;
  width: 100%;
`;

const Search = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledButton = styled.button`
  margin-right: 20px;
  background-color: #1989b2;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
`;

const Input = styled.input`
  background: #f6f6f6;
  border-radius: 30px;
  width: 205.16px;
  height: 40px;
  margin: 10px 10px;
  outline: none;
  padding: 10px;
`;

const FilterModalHistoryI = ({
  isOpen,
  onClose,
  onSearch,
  clean,
  loadingData,
  t,
  setDateInitI,
  setDateEndI,
}) => {
  // const [originSelectedC, setOriginSelectedC] = React.useState('');
  const initInputRef = useRef<HTMLInputElement | null>(null);
  const endInputRef = useRef<HTMLInputElement | null>(null);

  let hash = {};

  const handleSearch = () => {
    if (initInputRef.current && endInputRef.current) {
      onSearch({
        dateInitC: initInputRef.current.value,
        dateEndC: endInputRef.current.value,
      });
      onClose();
    }
  };

  const handleClear = () => {
    clean();
    onClose();
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <div
        style={{
          padding: '20px',
          background: 'white',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {/* Add the filter fields inside the modal */}

        <Search>
          <div
            style={{
              fontSize: '13px',
              fontWeight: '625',
              margin: '0 20px',
            }}
          >
            {t(translations.general.initial_d)}
          </div>
          <div className="d-flex align-items-center">
            <div style={{ marginLeft: 'auto', cursor: 'pointer' }}></div>
            <Input
              type="date"
              ref={initInputRef}
              onChange={e => setDateInitI(e.target.value)}
              placeholder="Initial Date"
            />
          </div>
        </Search>
        <Search>
          <div
            style={{
              fontSize: '13px',
              fontWeight: '625',
              margin: '0 20px',
            }}
          >
            {t(translations.general.end_d)}
          </div>
          <div className="d-flex align-items-center">
            <Input
              ref={endInputRef}
              onChange={e => setDateEndI(e.target.value)}
              type="date"
              placeholder="End Date"
            />
            <div style={{ marginLeft: 'auto', cursor: 'pointer' }}></div>
          </div>
        </Search>
        <StyledButton onClick={handleSearch}>
          {t(translations.navbar.search)}
        </StyledButton>

        <Clean onClick={handleClear}>
          <div>{t(translations.table.clean_filter)}</div>
        </Clean>
      </div>
    </Modal>
  );
};

export default FilterModalHistoryI;
