import styled, { useTheme } from 'styled-components/macro';
import caravela from '../../../app/assets/SVG/logo-caravela-2-SIN-LINEAS 2.png';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { DeletePurchaseOrder } from 'services/purchaseService';
import { useNavigate } from 'react-router';
import { useMediaQuery } from '@mui/material';

interface DeletePurchaseProps {
  id: string;
  setDeletedModal: (value: Boolean) => void;
  deletedModal: Boolean;
}

const Button = styled.input`
  border: none;
  background: #5c36f3;
  color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  margin-bottom: 25px;
  width: 213.02px;
  height: 49.59px;
  margin: 0 10px;
`;
const Bg = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(15px);
`;

const Login = styled.div`
  width: 518px;
  height: 468px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: #ffffff;
  border-radius: 30px;
  z-index: 1000;

  @media (max-width: 767px) {
    width: 318px;
  }
`;

const InputText = styled.div`
  font-style: normal;
  font-weight: 350;
  font-size: 20px;
  line-height: 24px;
  color: #545454;
  margin: 10px 0;
  padding: 0 50px;
  text-align: center;
`;

export function DeletePurchaseModal({
  id,
  setDeletedModal,
  deletedModal,
}: DeletePurchaseProps) {
  const { t } = useTranslation();
  const theme: any = useTheme();
  const isMobile = useMediaQuery('(max-width:767px)');

  const closeModal = () => {
    setDeletedModal(!deletedModal);
  };

  const navigate = useNavigate();

  const deleteOrder = (id: string) => {
    DeletePurchaseOrder(id)
      .then(response => {
        if (response.status === 204) {
          localStorage.removeItem('dataToBeSent');
          localStorage.removeItem('deletedContracts');
          localStorage.removeItem('contracts');
          localStorage.removeItem('order-id');
          navigate(0);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          closeModal();
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <Bg>
      <Login ref={wrapperRef}>
        <img
          src={caravela}
          alt="caravela logo"
          style={{ marginTop: '70px', marginBottom: '70px' }}
        />
        <div className="d-flex flex-column">
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <InputText
              style={{
                margin: '0',
                padding: '0',
                marginBottom: !isMobile ? '50px' : '0px',
              }}
            >
              {t(translations.general.delete_purchase)}
            </InputText>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Button
              style={{
                marginTop: '35px',
                background: theme.palette.success.main,
              }}
              type="button"
              onClick={() => deleteOrder(id)}
              value={t(translations.general.yes)}
            />

            <Button
              style={{
                marginTop: '35px',
                background: theme.palette.error.main,
              }}
              type="button"
              onClick={closeModal}
              value={t(translations.general.no_return)}
            />
          </div>
        </div>
      </Login>
    </Bg>
  );
}
