import serviceClient from './serviceClientConfig';

export const getCountries = () => {
  return serviceClient.get('/countries');
};

export const getMarks = () => {
  return serviceClient.get('/marks');
};

export const getHomeCoffees = () => {
  return serviceClient.get('/home');
};

export const usageMetrics = payload => {
  return serviceClient.post('/aplication-usage', payload);
};
