import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import styled from 'styled-components/macro';
import { ThemeProvider, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import drop from '../../assets/SVG/dropdown.svg';
import { useState } from 'react';
import { originToColor, GENERIC } from '../../constants';
import ModalComponent from '../ModalComponent';

const StyledHeadCell = styled(TableCell)`
  font-style: normal;
  font-weight: bold !important;
  //font-weight: 325;
  font-size: 13px;
  line-height: 16px;
  border: 1px solid white;
  color: black !important;
  background: #dfdccb;
`;

const StyledTableRow = styled(TableRow)`
  border-bottom: 3px solid white;
  border-top: 3px solid white;
  th:first-child {
    border-radius: 35px 0px 0px 35px;
  }
  th:last-child {
    border-radius: 0px 35px 35px 0px;
  }
  td:first-child {
    border-radius: 35px 0px 0px 35px;
  }
  td:last-child {
    border-radius: 0px 35px 35px 0px;
  }
`;

const StyledTableCell = styled(TableCell)`
  align-items: center;

  font-style: normal;
  font-weight: 325;
  font-size: 13px;
  line-height: 16px;
  background: #f8f8f8;
  border: 1px solid white;
  display: table-cell;
  overflow: auto;
`;

const StyledTableCellMobile = styled(TableCell)`
  align-items: center;

  font-style: normal;
  font-weight: 325;
  font-size: 13px;
  line-height: 16px;
  background: #f8f8f8;
  // border: 1px solid white;
  width: calc(20% - 1px);
  box-sizing: border-box;
`;

const StyledHeadCellMobile = styled(TableCell)`
  font-style: normal;
  font-weight: 325;
  font-size: 13px;
  line-height: 16px;
  // border: 1px solid white;
  background: #ebeaea;
  background: ${props => props.color};
  width: calc(20% - 1px);
  box-sizing: border-box;
`;

const ContainerHeader = styled.div`
  display: flex;
  width: 100%;
  background: #ebeaea;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const ContainerBody = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  background: #f8f8f8;
`;

interface TableProps {
  historyContract?: any[];
  page?: boolean;
  setHistoryContract?: (value: any) => void;
  setActualPageContract: (value: any) => void;
  setPagesContract: (value: any) => void;
  pages?: any;
  actualPageContract?: any;
  setOrigin: (value: any) => void;
  handleChangeContract: (event: any, value: any) => void;
}

export function HistoryCTable({
  historyContract,
  page,
  setHistoryContract,
  setActualPageContract,
  setPagesContract,
  pages,
  actualPageContract,
  setOrigin,
  handleChangeContract,
}: TableProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  const [info, setInfo] = useState<any[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortedData, setSortedData] = useState<any>([]);
  const [activeSortColumn, setActiveSortColumn] = useState<any>(null);
  const [isColumnClicked, setIsColumnClicked] = useState('');

  let dataToMap;
  if (sortedData.length > 0) {
    dataToMap = sortedData;
  } else {
    dataToMap = historyContract;
  }

  const sortData = columnName => {
    const sortOrderModifier = sortOrder === 'asc' ? 1 : -1;

    const sorted = [...(historyContract || [])].sort((a, b) => {
      return sortOrderModifier * a[columnName].localeCompare(b[columnName]);
    });

    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    setSortedData(sorted);
    setActiveSortColumn(columnName); // Set the active sorting column
    setIsColumnClicked(columnName);
  };

  const isMobile = useMediaQuery('(max-width:767px)');

  const opciones = {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  const openModalWithInfo = info => {
    setModalOpen(true);
    setInfo(info);
  };

  return dataToMap ? (
    <ThemeProvider theme={theme}>
      <div className="d-flex justify-content-center mt-5 mb-5">
        <Stack spacing={2}>
          <Pagination
            count={pages}
            page={actualPageContract}
            onChange={handleChangeContract}
          />
        </Stack>
      </div>
      {!isMobile ? (
        <>
          <TableContainer>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <StyledTableRow>
                  {/* <StyledHeadCell style={{ color: 'white' }} align="center">
                    {t(translations.table.origin)}
                  </StyledHeadCell> */}
                  <StyledHeadCell
                    style={{ color: 'white', cursor: 'pointer' }}
                    align="center"
                    onClick={() => sortData('origin')}
                    onMouseEnter={() => setActiveSortColumn('origin')}
                    onMouseLeave={() => {
                      if (!isColumnClicked) {
                        setActiveSortColumn(null);
                      }
                    }}
                  >
                    {t(translations.table.origin)}{' '}
                    {(activeSortColumn === 'origin' ||
                      isColumnClicked === 'origin') && (
                      <span style={{ fontSize: '20px' }}>
                        {sortOrder === 'asc' ? '↑' : '↓'}
                      </span>
                    )}
                  </StyledHeadCell>
                  <StyledHeadCell style={{ color: 'white' }} align="center">
                    MC
                  </StyledHeadCell>
                  <StyledHeadCell style={{ color: 'white' }} align="center">
                    {t(translations.table.ico)}
                  </StyledHeadCell>
                  <StyledHeadCell
                    style={{ color: 'white', cursor: 'pointer' }}
                    align="center"
                    onClick={() => sortData('date')}
                    onMouseEnter={() => setActiveSortColumn('date')}
                    onMouseLeave={() => {
                      if (!isColumnClicked) {
                        setActiveSortColumn(null);
                      }
                    }}
                  >
                    {t(translations.table.boarding)}{' '}
                    {(activeSortColumn === 'date' ||
                      isColumnClicked === 'date') && (
                      <span style={{ fontSize: '20px' }}>
                        {sortOrder === 'asc' ? '↑' : '↓'}
                      </span>
                    )}
                  </StyledHeadCell>
                  {/* <StyledHeadCell style={{ color: 'white' }} align="center">
                    {t(translations.table.boarding)}
                  </StyledHeadCell> */}
                  <StyledHeadCell style={{ color: 'white' }} align="center">
                    {t(translations.table.marks)}
                  </StyledHeadCell>
                  <StyledHeadCell style={{ color: 'white' }} align="center">
                    {t(translations.table.quality)}
                  </StyledHeadCell>
                  {/* <StyledHeadCell style={{ color: 'white' }} align="center">
                    {t(translations.table.quantity)}
                  </StyledHeadCell> */}
                  <StyledHeadCell
                    style={{ color: 'white', cursor: 'pointer' }}
                    align="center"
                    onClick={() => sortData('quantity')}
                    onMouseEnter={() => setActiveSortColumn('quantity')}
                    onMouseLeave={() => {
                      if (!isColumnClicked) {
                        setActiveSortColumn(null);
                      }
                    }}
                  >
                    {t(translations.table.quantity)}{' '}
                    {(activeSortColumn === 'quantity' ||
                      isColumnClicked === 'quantity') && (
                      <span style={{ fontSize: '20px' }}>
                        {sortOrder === 'asc' ? '↑' : '↓'}
                      </span>
                    )}
                  </StyledHeadCell>
                  {/* <StyledHeadCell style={{ color: 'white' }} align="center">
                    {t(translations.table.price)}
                  </StyledHeadCell> */}
                  <StyledHeadCell
                    style={{ color: 'white', cursor: 'pointer' }}
                    align="center"
                    onClick={() => sortData('price')}
                    onMouseEnter={() => setActiveSortColumn('price')}
                    onMouseLeave={() => {
                      if (!isColumnClicked) {
                        setActiveSortColumn(null);
                      }
                    }}
                  >
                    {t(translations.table.price)}{' '}
                    {(activeSortColumn === 'price' ||
                      isColumnClicked === 'price') && (
                      <span style={{ fontSize: '20px' }}>
                        {sortOrder === 'asc' ? '↑' : '↓'}
                      </span>
                    )}
                  </StyledHeadCell>
                  <StyledHeadCell style={{ color: 'white' }} align="center">
                    {t(translations.table.pack)}
                  </StyledHeadCell>
                  <StyledHeadCell style={{ color: 'white' }} align="center">
                    {t(translations.table.price_t)}
                  </StyledHeadCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {dataToMap.map(groupBy => (
                  <StyledTableRow key={Math.random()}>
                    <StyledTableCell
                      align="center"
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <div
                        style={{
                          borderRadius: '30px',
                          background:
                            originToColor[groupBy.origin.toUpperCase()] ||
                            GENERIC,
                          color: 'white',
                          padding: '15px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '40px',
                          width: '160px',
                        }}
                      >
                        {groupBy.origin}
                      </div>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {groupBy.mc}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {groupBy.ico_number}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {new Date(groupBy.date).toLocaleDateString('es-ES', {
                        month: 'numeric',
                        year: 'numeric',
                      })}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {groupBy.mark}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {groupBy.quality}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {groupBy.quantity}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {new Intl.NumberFormat('en-US', opciones).format(
                        Number(groupBy.price),
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {groupBy.packing}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {groupBy.price_type}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <>
          <ContainerHeader>
            <StyledHeadCellMobile></StyledHeadCellMobile>
            <StyledHeadCellMobile style={{ color: 'black' }} align="center">
              {t(translations.table.origin)}
            </StyledHeadCellMobile>
            <StyledHeadCellMobile style={{ color: 'black' }} align="center">
              MC
            </StyledHeadCellMobile>
            <StyledHeadCellMobile style={{ color: 'black' }} align="center">
              {t(translations.table.quantity)}
            </StyledHeadCellMobile>
            <StyledHeadCellMobile style={{ color: 'black' }} align="center">
              {t(translations.table.price)}
            </StyledHeadCellMobile>
          </ContainerHeader>
          {dataToMap.map(groupBy => (
            <ContainerBody key={Math.random()}>
              <StyledTableCellMobile align="center">
                <img
                  src={drop}
                  alt="drop"
                  style={{ width: '22px', height: '22px' }}
                  onClick={() => openModalWithInfo(groupBy)}
                />
              </StyledTableCellMobile>
              <StyledTableCellMobile
                align="center"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <div
                  style={{
                    borderRadius: '30px',
                    background:
                      originToColor[groupBy.origin.toUpperCase()] || GENERIC,
                    color: 'white',
                    padding: '15px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '40px',
                    width: '160px',
                  }}
                >
                  {groupBy.origin}
                </div>
              </StyledTableCellMobile>
              <StyledTableCellMobile align="center">
                {groupBy.mc}
              </StyledTableCellMobile>
              <StyledTableCellMobile align="center">
                {groupBy.quantity}
              </StyledTableCellMobile>
              <StyledTableCellMobile align="center">
                {groupBy.price_type}
              </StyledTableCellMobile>
            </ContainerBody>
          ))}
        </>
      )}
      {modalOpen && info && (
        <ModalComponent
          // page={page}
          closeModal={() => setModalOpen(false)} // Función para cerrar el modal
          data={info} // Información de groupBy para mostrar en el modal
          page="historyC"
        />
      )}
    </ThemeProvider>
  ) : null;
}
