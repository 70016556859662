// FilterModal.js
import React, { useRef } from 'react';
import Modal from '@mui/material/Modal';
import Dropdown from '../Dropdown';
import styled, { useTheme } from 'styled-components/macro';
import { translations } from 'locales/translations';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@mui/material';

const Clean = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  margin-left: 20px;
  margin-bottom: 5px;
  color: #1989b2;
  width: 100%;
`;

const Search = styled.div`
  display: flex;
  flex-direction: column;
`;

const CleanFilter = () => (
  <svg
    width="25px"
    height="25px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 15L21 21M21 15L15 21M10 21V14.6627C10 14.4182 10 14.2959 9.97237 14.1808C9.94787 14.0787 9.90747 13.9812 9.85264 13.8917C9.7908 13.7908 9.70432 13.7043 9.53137 13.5314L3.46863 7.46863C3.29568 7.29568 3.2092 7.2092 3.14736 7.10828C3.09253 7.01881 3.05213 6.92127 3.02763 6.81923C3 6.70414 3 6.58185 3 6.33726V4.6C3 4.03995 3 3.75992 3.10899 3.54601C3.20487 3.35785 3.35785 3.20487 3.54601 3.10899C3.75992 3 4.03995 3 4.6 3H19.4C19.9601 3 20.2401 3 20.454 3.10899C20.6422 3.20487 20.7951 3.35785 20.891 3.54601C21 3.75992 21 4.03995 21 4.6V6.33726C21 6.58185 21 6.70414 20.9724 6.81923C20.9479 6.92127 20.9075 7.01881 20.8526 7.10828C20.7908 7.2092 20.7043 7.29568 20.5314 7.46863L17 11"
      stroke="#000000"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const StyledButton = styled.button`
  margin-right: 20px;
  background-color: #4f4e24;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
`;

const Input = styled.input`
  background: #f6f6f6;
  border-radius: 30px;
  width: 205.16px;
  height: 40px;
  margin: 10px 10px;
  outline: none;
  padding: 10px;
`;

const FilterModalDelivery = ({
  isOpen,
  onClose,
  onSearch,
  clean,
  loadingData,
  originOptions,
  markOptions,
  t,
  setDateInit,
  setDateEnd,
  setOriginSelected,
  originSelected,
  markSelected,
  setMarkSelected,
}) => {
  // const [originSelectedC, setOriginSelectedC] = React.useState('');
  const initInputRef = useRef<HTMLInputElement | null>(null);
  const endInputRef = useRef<HTMLInputElement | null>(null);

  const theme: any = useTheme();

  let hash = {};

  const handleSearch = () => {
    if (initInputRef.current && endInputRef.current) {
      onSearch({
        dateInitC: initInputRef.current.value,
        dateEndC: endInputRef.current.value,
      });
      onClose();
    }
  };

  const handleClear = () => {
    clean();
    onClose();
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <div
        style={{
          padding: '20px',
          background: 'white',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {/* Add the filter fields inside the modal */}

        <Dropdown
          handleChange={selected => setOriginSelected(selected.value)}
          defaultValue="Origin"
          value={originSelected}
          list={originOptions}
          width={false}
          noMargin={false}
        />
        <Dropdown
          handleChange={selected => setMarkSelected(selected.value)}
          value={markSelected}
          defaultValue="Warehouse"
          list={markOptions}
          width={false}
          noMargin={false}
        />

        <Search>
          <div
            style={{
              fontSize: '13px',
              fontWeight: '625',
              margin: '0 20px',
            }}
          >
            {t(translations.general.initial_d)}
          </div>
          <div className="d-flex align-items-center">
            <div style={{ marginLeft: 'auto', cursor: 'pointer' }}></div>
            <Input
              type="date"
              ref={initInputRef}
              onChange={e => setDateInit(e.target.value)}
              placeholder="Initial Date"
            />
          </div>
        </Search>
        <Search>
          <div
            style={{
              fontSize: '13px',
              fontWeight: '625',
              margin: '0 20px',
            }}
          >
            {t(translations.general.end_d)}
          </div>
          <div className="d-flex align-items-center">
            <Input
              ref={endInputRef}
              onChange={e => setDateEnd(e.target.value)}
              type="date"
              placeholder="End Date"
            />
            <div style={{ marginLeft: 'auto', cursor: 'pointer' }}></div>
          </div>
        </Search>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <StyledButton onClick={handleSearch}>
            {t(translations.navbar.search)}
          </StyledButton>

          <IconButton onClick={handleClear}>
            <CleanFilter />
          </IconButton>
        </div>
      </div>
    </Modal>
  );
};

export default FilterModalDelivery;
