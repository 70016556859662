import React, { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import { Select, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CustomButton } from '../Button';

import { Box } from '@mui/system';
import Slide from '@mui/material/Slide';

import { styledTheme } from 'styles/theme';

import styled, { useTheme } from 'styled-components';
import { InputUnstyled } from '@mui/base';
import { translations } from 'locales/translations';
import TextArea from '../TextArea';
import { Switch, ThemeProvider } from '@mui/material';

const CustomTextField = styled(InputUnstyled)`
  width: 100%;

  input {
    width: 100%;
    border-radius: 40px;
    border: solid 0.5px ${styledTheme.Gray};
    background: ${styledTheme.LightGray};
    height: 35px;
    padding: 10px;
    ${props =>
      props.disabled &&
      `
      background: ${styledTheme.Gray};

       border: solid 0.5px ${styledTheme.WhitePowder};
    `}
`;
const CustomSelect = styled(Select)`
  width: 100%; //
  height: 35px; // Asegura la misma altura que el TextField
  border-radius: 40px;
  border-radius: 40px;
  border: none;
  background: ${styledTheme.LightGray};
  padding: 10px;
`;

export const feedbackBoxStyle = () => ({
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-40%, -50%) !important',
  minHeight: '15vh',
  maxHeight: '90vh',
  minWidth: '50vw',
  maxWidth: '70vw',
  overflowY: 'auto',
  backgroundColor: styledTheme.WhitePowder,
  border: 'none',
  boxShadow: '0px 24px 24px rgba(0, 0, 0, 0.25);',
  padding: '30px 20px',
  borderRadius: '20px',
  fontStyle: 'normal',
  fontWeight: 325,
  fontSize: '20px',
  lineHeight: '24px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  label: {
    padding: '5px',
  },
  '&::-webkit-scrollbar': {
    // Estilos del scrollbar para navegadores WebKit (Chrome, Safari, etc.)
    width: '10px', // Ancho del scrollbar
  },
  '&::-webkit-scrollbar-thumb': {
    // Estilos del thumb (el control deslizante) del scrollbar
    backgroundColor: '#888', // Color del thumb
    borderRadius: '10px', // Border radius del thumb para hacerlo redondeado
  },
});

const SalesModal = ({
  open,
  onSubmit,
  setFields,
  onClose,
  fields,
  visibleClient,
  setVisibleClient,
  importId,
}: {
  open: boolean;
  onSubmit: any;
  onClose: any;
  setFields: any;
  fields: any;
  visibleClient: any;
  setVisibleClient: (value: any) => void;
  importId: any;
}) => {
  const { t } = useTranslation();
  const theme: any = useTheme();
  const qualityOptions = ['RTB', 'A', 'AA', 'AAA', 'ML', 'XGRADE'];

  const onChangeField = (e, field) => {
    let value = e.target.value;

    if (field === 'offering_price') {
      // Verificar si el valor ingresado es un número o decimal
      const isNumeric = /^\d*\.?\d*$/.test(value);
      if (!isNumeric) {
        return; // No actualizar el estado si no es un número o decimal
      }
    }
    setFields({
      ...fields,
      [field]: {
        ...fields[field],
        value: e.target.value,
      },
    });
  };

  return (
    <Modal onClose={onClose} open={open} closeAfterTransition>
      <Slide
        in={open}
        direction="left"
        timeout={400}
        mountOnEnter
        unmountOnExit
      >
        <Box sx={feedbackBoxStyle}>
          <ThemeProvider theme={theme}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {Object.keys(fields).map(field => (
                <div
                  className="d-flex g-3 justify-content-start align-items-center mb-3"
                  key={field}
                >
                  {/* Renderiza el label solo si 'field' es 'offering_price' y 'importerId' es 1 */}
                  {field == 'offering_price' ? (
                    field == 'offering_price' &&
                    importId == 1 && (
                      <label style={{ width: '60%' }}>USD/Kg</label>
                    )
                  ) : (
                    <label style={{ width: '60%' }}>
                      {t(translations.table[fields[field].label ?? field])}
                    </label>
                  )}

                  {field == 'notes' ? (
                    <TextArea
                      required
                      key={fields[field]}
                      name="notes"
                      minRows={3}
                      maxRows={4}
                      placeholder={'additional notes ...'}
                      value={fields[field].value}
                      onChange={e => onChangeField(e, field)}
                    />
                  ) : field == 'visible_for_clients' ? (
                    <Switch
                      defaultChecked
                      checked={visibleClient}
                      onChange={
                        e => setVisibleClient(!visibleClient)
                        /* setFields({
                        ...fields,
                        visible_for_clients: {
                          ...fields.visible_for_clients,
                          value: !fields.visible_for_clients.value,
                        },
                      }) */
                      }
                    />
                  ) : field == 'url' ? null : field == 'offering_price' ? (
                    field == 'offering_price' &&
                    importId == 1 && (
                      <CustomTextField
                        key={fields[field]}
                        value={fields[field].value}
                        disabled={!fields[field].editable}
                        onChange={e => onChangeField(e, field)}
                      />
                    )
                  ) : field === 'quality' ? (
                    <CustomSelect
                      value={fields[field].value}
                      onChange={e => onChangeField(e, field)}
                    >
                      {qualityOptions.map(score => (
                        <MenuItem key={score} value={score}>
                          {score}
                        </MenuItem>
                      ))}
                    </CustomSelect>
                  ) : (
                    <CustomTextField
                      key={fields[field]}
                      value={fields[field].value}
                      disabled={!fields[field].editable}
                      onChange={e => onChangeField(e, field)}
                    />
                  )}
                </div>
              ))}
            </div>
            <div style={{ alignSelf: 'flex-end', margin: '10px 25px 0px 0px' }}>
              <CustomButton
                withShadow
                background={theme.palette.success.main}
                onClick={() => {
                  onSubmit(fields);
                  onClose();
                }}
              >
                {t(translations.general.save)}
              </CustomButton>
              <CustomButton
                withShadow
                background={theme.palette.error.main}
                onClick={() => {
                  onClose();
                }}
              >
                {t(translations.general.cancel)}
              </CustomButton>
            </div>
          </ThemeProvider>
        </Box>
      </Slide>
    </Modal>
  );
};
export default SalesModal;
