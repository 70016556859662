import Dropdown from 'app/components/Dropdown';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { HistoryCTable } from 'app/components/HistoryCTable';
import { HistoryITable } from 'app/components/HistoryITable';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { Helmet } from 'react-helmet-async';
import { getCountries, getMarks } from 'services/generalServices';
import { IconButton, useMediaQuery } from '@mui/material';
import {
  getHistoryContracts,
  getHistoryInvoices,
} from 'services/historyServices';
import styled from 'styled-components/macro';
import { LoadingPage } from '../LoadingPage';
import { CircularProgress } from '@mui/material';
import FilterModalHistory from 'app/components/FilterModalHistory';
import FilterModalHistoryI from 'app/components/FilterModalHistoryI';
import Tooltip from '@mui/material/Tooltip';

const Tab = styled.div<{ state: string }>`
  background-color: ${props =>
    props.state === 'active' ? '#ffffff' : '#4F4E24'};
  color: ${props => (props.state === 'active' ? '#000000' : '#F8F8F8')};
  border-radius: 30px;
  border: 4px solid #4f4e24;
  width: 245px;
  height: 38px;
  display: flex;
  justify-content: center;
  margin: 10px;
  cursor: ${props => (props.state === 'active' ? 'default' : 'pointer')};
  transition: all 0.5s ease-in-out;
  margin-left: -40px;
  @media (max-width: 768px) {
    border-radius: 0; /* Remove border-radius for mobile */
    margin: 5px; /* Reduce the margin for mobile */
    margin-left: 0; /* Remove negative margin for mobile */
  }
`;

const Text = styled.div`
  font-weight: 325;
  font-size: 20px;
`;

const StyledButton = styled.button`
  background-color: #dfdccb;
  color: black !important;
  padding: 10px 20px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  font-size: 16px;
  margin-top: '30px';
`;

const Input = styled.input`
  background: #f6f6f6;
  border-radius: 30px;
  width: 205.16px;
  height: 40px;
  margin: 10px 10px;
  outline: none;
  padding: 10px;
`;

const ButtonStyled = styled.input`
  border: none;
  background: #5c36f3;
  color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  margin-bottom: 25px;
  width: 213.02px;
  height: 49.59px;
  margin: 0 10px;
`;

const Search = styled.div`
  display: flex;
  flex-direction: column;
`;

const Clean = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  margin-left: 20px;
  color: #1989b2;
`;

const CleanFilter = () => (
  <svg
    width="25px"
    height="25px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 15L21 21M21 15L15 21M10 21V14.6627C10 14.4182 10 14.2959 9.97237 14.1808C9.94787 14.0787 9.90747 13.9812 9.85264 13.8917C9.7908 13.7908 9.70432 13.7043 9.53137 13.5314L3.46863 7.46863C3.29568 7.29568 3.2092 7.2092 3.14736 7.10828C3.09253 7.01881 3.05213 6.92127 3.02763 6.81923C3 6.70414 3 6.58185 3 6.33726V4.6C3 4.03995 3 3.75992 3.10899 3.54601C3.20487 3.35785 3.35785 3.20487 3.54601 3.10899C3.75992 3 4.03995 3 4.6 3H19.4C19.9601 3 20.2401 3 20.454 3.10899C20.6422 3.20487 20.7951 3.35785 20.891 3.54601C21 3.75992 21 4.03995 21 4.6V6.33726C21 6.58185 21 6.70414 20.9724 6.81923C20.9479 6.92127 20.9075 7.01881 20.8526 7.10828C20.7908 7.2092 20.7043 7.29568 20.5314 7.46863L17 11"
      stroke="#000000"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export function HistoryPage() {
  const [page, setPage] = useState(false);
  const [actualPageContract, setActualPageContract] = useState(1);
  const [actualPageInvoices, setActualPageInvoices] = useState(1);
  const [pagesContract, setPagesContract] = useState();
  const [pagesInvoices, setPagesInvoices] = useState();
  const [historyContract, setHistoryContract] = useState<any[]>();
  const [historyInvoices, setHistoryInvoices] = useState<any[]>();
  const [origin, setOrigin] = useState<any[]>();
  const [originSelectedI, setOriginSelectedI] = useState('');
  const [dateInitI, setDateInitI] = useState('');
  const [dateEndI, setDateEndI] = useState('');
  const [originSelectedC, setOriginSelectedC] = useState('');
  const [statusSelected, setStatusSelected] = useState('');
  const [dateInitC, setDateInitC] = useState('');
  const [dateEndC, setDateEndC] = useState('');
  const [loading, setLoading] = useState<Boolean>(true);
  const [errorInfoI, setErrorInfoI] = useState('');
  const [errorInfoC, setErrorInfoC] = useState('');
  const [loadingData, setLoadingData] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isMobile = useMediaQuery('(max-width:767px)');

  const initInputRef = useRef<HTMLInputElement | null>(null);
  const endInputRef = useRef<HTMLInputElement | null>(null);

  const status = [
    { value: 'Late', label: 'Late' },
    { value: 'Pending', label: 'Pending' },
    { value: 'Paid', label: 'Paid' },
  ];

  const { t } = useTranslation();

  const searchOriginI = (selected: any) => {
    setOriginSelectedI(selected.value);
  };

  const searchOriginC = (selected: any) => {
    setOriginSelectedC(selected.value);
  };

  const searchStatus = (selected: any) => {
    setStatusSelected(selected.value);
  };

  const handleSearchFromModal = ({ originSelected, dateInitC, dateEndC }) => {
    setLoadingData(true);
    setErrorInfoC('');
    setOriginSelectedC(originSelected);
    setDateInitC(dateInitC);
    setDateEndC(dateEndC);

    getHistoryContracts('', originSelectedC, dateInitC, dateEndC)
      .then(response => {
        if (response.status === 200) {
          setLoadingData(false);
          setActualPageContract(response.data.meta.current_page);
          setPagesContract(response.data.meta.last_page);
          setHistoryContract(response.data.data);
          response.data.data.length === 0 &&
            setErrorInfoC(t(translations.general.no_search));
        }
      })
      .catch(error => {
        setLoadingData(false);
        setErrorInfoC(error.response?.data.message);
        console.log(error.response?.data, 'error.response.data');
      });
  };

  const handleSearchFromModalI = () => {
    setLoadingData(true);
    setErrorInfoI('');
    // setDateInitI(dateInitI);
    // setDateEndI(dateEndI);

    getHistoryInvoices('', '', dateInitI, dateEndI)
      .then(response => {
        if (response.status === 200) {
          setLoadingData(false);
          setActualPageInvoices(response.data.meta.current_page);
          setPagesInvoices(response.data.meta.last_page);
          setHistoryInvoices(response.data.data);
          response.data.data.length === 0 &&
            setErrorInfoI(t(translations.general.no_search));
        }
      })
      .catch(error => {
        setLoadingData(false);
        setErrorInfoI(error.response?.data.message);
        console.log(error.response?.data, 'error.response.data');
      });
  };

  // Define la función para manejar la limpieza desde el modal
  const handleCleanFromModal = () => {
    setLoadingData(true);
    if (initInputRef.current) {
      initInputRef.current.value = '';
    }
    if (endInputRef.current) {
      endInputRef.current.value = '';
    }
    setErrorInfoC('');
    setOriginSelectedC('');
    setDateInitC('');
    setDateEndC('');

    getHistoryContracts()
      .then(response => {
        if (response.status === 200) {
          setLoadingData(false);
          setActualPageContract(response.data.meta.current_page);
          setPagesContract(response.data.meta.last_page);
          setHistoryContract(response.data.data);
          response.data.data.length === 0 &&
            setErrorInfoC(t(translations.general.no_search));
        }
      })
      .catch(error => {
        setLoadingData(false);
        setErrorInfoC(error.response?.data.message);
        console.log(error.response?.data, 'error.response.data');
      });
  };

  const handleCleanFromModalI = () => {
    setLoadingData(true);
    if (initInputRef.current) {
      initInputRef.current.value = '';
    }
    if (endInputRef.current) {
      endInputRef.current.value = '';
    }
    setErrorInfoI('');
    setDateInitI('');
    setDateEndI('');

    getHistoryInvoices()
      .then(response => {
        if (response.status === 200) {
          setLoadingData(false);
          setActualPageInvoices(response.data.meta.current_page);
          setPagesInvoices(response.data.meta.last_page);
          setHistoryInvoices(response.data.data);
          response.data.data.length === 0 &&
            setErrorInfoI(t(translations.general.no_search));
        }
      })
      .catch(error => {
        setLoadingData(false);
        setErrorInfoI(error.response?.data.message);
        console.log(error.response?.data, 'error.response.data');
      });
  };

  const handleChangeInvoices = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setLoadingData(true);
    getHistoryInvoices(value)
      .then(response => {
        if (response.status === 200) {
          setLoadingData(false);
          setActualPageInvoices(response.data.meta.current_page);
          setPagesInvoices(response.data.meta.last_page);
          setHistoryInvoices(response.data.data);
          response.data.data.length === 0 &&
            setErrorInfoI(t(translations.general.no_search));
        }
      })
      .catch(error => {
        setLoadingData(false);
        setErrorInfoI(error.response?.data.message);
      });
  };

  const handleChangeContract = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setLoadingData(true);
    getHistoryContracts(value)
      .then(response => {
        if (response.status === 200) {
          setLoadingData(false);
          setActualPageContract(response.data.meta.current_page);
          setPagesContract(response.data.meta.last_page);
          setHistoryContract(response.data.data);
          response.data.data.length === 0 &&
            setErrorInfoC(t(translations.general.no_search));
        }
      })
      .catch(error => {
        setLoadingData(false);
        setErrorInfoC(error.response?.data.message);
        console.log(error.response?.data.message, 'error.data');
      });
  };

  // useEffect(() => {
  //   getHistoryInvoices(originSelectedI, dateInitI, dateEndI)
  //     .then(response => {
  //       console.log(response, 'response');
  //       if (response.status === 200) {
  //         // formatCoffe(response.data.data);
  //         setActualPageInvoices(response.data.meta.current_page);
  //         setPagesInvoices(response.data.meta.last_page);
  //         setHistoryInvoices(response.data.data);
  //       }
  //     })
  //     .catch(error => {
  //       console.log(error.response?.data);
  //     });
  // }, [dateInitI, dateEndI, originSelectedI]);

  // useEffect(() => {
  //   getHistoryContracts(originSelectedC, dateInitC, dateEndC)
  //     .then(response => {
  //       // console.log(response, 'response origin');
  //       if (response.status === 200) {
  //         setActualPageContract(response.data.meta.current_page);
  //         setPagesContract(response.data.meta.last_page);
  //         setHistoryContract(response.data.data);
  //       }
  //     })
  //     .catch(error => {
  //       console.log(error.response?.data);
  //     });
  // }, [dateInitC, dateEndC, originSelectedC]);

  const search = () => {
    setLoadingData(true);
    setErrorInfoI('');
    setErrorInfoC('');
    if (page !== false) {
      getHistoryInvoices(
        '',
        originSelectedI,
        dateInitI,
        dateEndI,
        statusSelected,
      )
        .then(response => {
          if (response.status === 200) {
            setLoadingData(false);
            // formatCoffe(response.data.data);
            setActualPageInvoices(response.data.meta.current_page);
            setPagesInvoices(response.data.meta.last_page);
            setHistoryInvoices(response.data.data);
            response.data.data.length === 0 &&
              setErrorInfoI(t(translations.general.no_search));
          }
        })
        .catch(error => {
          setLoadingData(false);
          setErrorInfoI(error.response?.data.message);
          console.log(error.response?.data.message, 'error.data');
        });
    } else {
      getHistoryContracts('', originSelectedC, dateInitC, dateEndC)
        .then(response => {
          if (response.status === 200) {
            setLoadingData(false);
            setActualPageContract(response.data.meta.current_page);
            setPagesContract(response.data.meta.last_page);
            setHistoryContract(response.data.data);
            response.data.data.length === 0 &&
              setErrorInfoC(t(translations.general.no_search));
          }
        })
        .catch(error => {
          setLoadingData(false);
          setErrorInfoC(error.response?.data.message);
          console.log(error.response?.data.message, 'error.data');
        });
    }
  };

  useEffect(() => {
    getHistoryContracts()
      .then(response => {
        setLoading(!loading);
        if (response.status === 200) {
          setHistoryContract(response.data.data);
          setActualPageContract(response.data.meta.current_page);
          setPagesContract(response.data.meta.last_page);
          response.data.data.length === 0 &&
            setErrorInfoC(t(translations.general.no_search));
        }
      })
      .catch(error => {
        setErrorInfoC(error.response?.data.message);
        setLoading(false);
        console.log(error, 'error');
      });
    getHistoryInvoices()
      .then(response => {
        if (response.status === 200) {
          setActualPageInvoices(response.data.meta.current_page);
          setPagesInvoices(response.data.meta.last_page);
          setHistoryInvoices(response.data.data);
          response.data.data.length === 0 &&
            setErrorInfoI(t(translations.general.no_search));
        }
      })
      .catch(error => {
        setErrorInfoI(error.response?.data.message);
        setLoading(false);
        console.log(error, 'error');
      });
  }, []);

  useEffect(() => {
    getCountries()
      .then(response => {
        if (response.status === 200) {
          let arrO = response.data.map(item => ({
            value: item.name,
            label: item.name,
          }));
          setOrigin(arrO);
        }
      })
      .catch(error => {
        console.log(error.response?.data, 'error.response.data');
      });
  }, []);

  const clean = () => {
    setLoadingData(true);
    if (initInputRef.current) {
      initInputRef.current.value = '';
    }
    if (endInputRef.current) {
      endInputRef.current.value = '';
    }
    setErrorInfoI('');
    setErrorInfoC('');
    setOriginSelectedI('');
    setDateInitI('');
    setDateEndI('');
    setOriginSelectedC('');
    setDateInitC('');
    setDateEndC('');
    if (page !== false) {
      getHistoryInvoices()
        .then(response => {
          if (response.status === 200) {
            setLoadingData(false);
            // formatCoffe(response.data.data);
            setActualPageInvoices(response.data.meta.current_page);
            setPagesInvoices(response.data.meta.last_page);
            setHistoryInvoices(response.data.data);
            response.data.data.length === 0 &&
              setErrorInfoI(t(translations.general.no_search));
          }
        })
        .catch(error => {
          setLoadingData(false);
          setErrorInfoI(error.response?.data.message);
          console.log(error.response?.data, 'error.response.data');
        });
    } else {
      getHistoryContracts()
        .then(response => {
          if (response.status === 200) {
            setLoadingData(false);
            setActualPageContract(response.data.meta.current_page);
            setPagesContract(response.data.meta.last_page);
            setHistoryContract(response.data.data);
            response.data.data.length === 0 &&
              setErrorInfoC(t(translations.general.no_search));
          }
        })
        .catch(error => {
          setLoadingData(false);
          setErrorInfoC(error.response?.data.message);
          console.log(error.response?.data, 'error.response.data');
        });
    }
  };

  let hash = {};

  return (
    <>
      <Helmet>
        <title>{t(translations.general.history)}</title>
        <meta name="description" content="Roasterhub delivery review" />
      </Helmet>
      <div>
        <div
          className="d-flex"
          style={{
            padding: !isMobile ? '30px 96px' : '0px',
            flexDirection: 'column',
          }}
        >
          <div
            className="d-flex"
            style={{
              justifyContent: 'center',
              marginLeft: !isMobile ? '10px' : '0px',
              flexDirection: !isMobile ? 'row' : 'column',
              alignItems: 'center',
            }}
          >
            <div onClick={() => setPage(!page)}>
              <Tab state={!page ? 'active' : 'inactive'}>
                <Text>{t(translations.general.contracts)}</Text>
              </Tab>
            </div>
            <div onClick={() => setPage(!page)}>
              <Tab state={page ? 'active' : 'inactive'}>
                <Text>{t(translations.general.invoices)}</Text>
              </Tab>
            </div>
          </div>

          {page === false ? (
            <>
              {!isMobile ? (
                <>
                  <div className="d-flex mt-5 align-items-center">
                    <Search>
                      <div
                        style={{
                          fontSize: '13px',
                          fontWeight: '625',
                          margin: '0 20px',
                        }}
                      >
                        {t(translations.general.initial_d)}
                      </div>
                      <div className="d-flex align-items-center">
                        <div
                          style={{ marginLeft: 'auto', cursor: 'pointer' }}
                        ></div>
                        <Input
                          type="date"
                          ref={initInputRef}
                          onChange={e => setDateInitC(e.target.value)}
                          placeholder="Initial Date"
                        />
                      </div>
                    </Search>
                    <Search>
                      <div
                        style={{
                          fontSize: '13px',
                          fontWeight: '625',
                          margin: '0 20px',
                        }}
                      >
                        {t(translations.general.end_d)}
                      </div>
                      <div className="d-flex align-items-center">
                        <Input
                          ref={endInputRef}
                          onChange={e => setDateEndC(e.target.value)}
                          type="date"
                          placeholder="End Date"
                        />
                        <div
                          style={{ marginLeft: 'auto', cursor: 'pointer' }}
                        ></div>
                      </div>
                    </Search>
                    <Search>
                      <div
                        style={{
                          fontSize: '13px',
                          fontWeight: '625',
                          margin: '0 20px',
                        }}
                      >
                        {' '}
                        {t(translations.table.origin)}
                      </div>
                      <Dropdown
                        handleChange={searchOriginC}
                        value={originSelectedC}
                        defaultValue="Origin"
                        list={
                          origin &&
                          origin.filter(o =>
                            hash[o.value] ? false : (hash[o.value] = true),
                          )
                        }
                      />
                    </Search>
                    <div
                      style={{
                        cursor: 'pointer',
                        marginTop: '20px',
                        marginLeft: '5px',
                      }}
                    >
                      {loadingData ? (
                        <CircularProgress color="primary" />
                      ) : (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <IconButton onClick={search} aria-label="search">
                            <SearchIcon />
                          </IconButton>
                          <Tooltip
                            title={t(translations.tooltips.clean)}
                            placement="right"
                          >
                            <IconButton onClick={clean}>
                              <CleanFilter />
                            </IconButton>
                          </Tooltip>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginRight: '0px',
                    marginTop: '30px',
                  }}
                >
                  <StyledButton onClick={() => setIsModalOpen(true)}>
                    {t(translations.general.filter)}
                  </StyledButton>
                </div>
              )}
              {errorInfoC !== '' ? (
                <h3
                  style={{
                    color: 'gray',
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '70px',
                    textAlign: 'center',
                  }}
                >
                  {errorInfoC === t(translations.general.no_search)
                    ? errorInfoC
                    : t(`${translations.errors_400[errorInfoC]}`)}
                </h3>
              ) : loadingData ? (
                <div
                  style={{
                    // transform: 'scale(0.2)',
                    minWidth: !isMobile ? 700 : 0,
                    // marginTop: '-200px',
                  }}
                >
                  <LoadingPage />
                </div>
              ) : (
                <HistoryCTable
                  historyContract={historyContract}
                  page={page}
                  setHistoryContract={setHistoryContract}
                  setActualPageContract={setActualPageContract}
                  actualPageContract={actualPageContract}
                  setPagesContract={setPagesContract}
                  pages={pagesContract}
                  setOrigin={setOrigin}
                  handleChangeContract={handleChangeContract}
                ></HistoryCTable>
              )}
            </>
          ) : (
            <>
              {!isMobile ? (
                <>
                  <div className="d-flex mt-5">
                    <Search>
                      <div
                        style={{
                          fontSize: '13px',
                          fontWeight: '625',
                          margin: '0 20px',
                        }}
                      >
                        {t(translations.general.initial_d)}
                      </div>
                      <div className="d-flex align-items-center">
                        <div
                          style={{ marginLeft: 'auto', cursor: 'pointer' }}
                        ></div>
                        <Input
                          ref={initInputRef}
                          type="date"
                          onChange={e => setDateInitI(e.target.value)}
                          placeholder="Initial Date"
                        />
                      </div>
                    </Search>
                    <Search>
                      <div
                        style={{
                          fontSize: '13px',
                          fontWeight: '625',
                          margin: '0 20px',
                        }}
                      >
                        {t(translations.general.final_d)}
                      </div>
                      <div className="d-flex align-items-center">
                        <Input
                          ref={endInputRef}
                          onChange={e => setDateEndI(e.target.value)}
                          type="date"
                          placeholder="End Date"
                        />
                      </div>
                    </Search>
                    <div
                      style={{
                        marginTop: '10px',
                      }}
                    >
                      <Search>
                        <div
                          style={{
                            fontSize: '13px',
                            fontWeight: '625',
                            margin: '0 20px',
                          }}
                        >
                          {' '}
                          {t(translations.table.status)}
                        </div>

                        <Dropdown
                          handleChange={searchStatus}
                          value={statusSelected}
                          defaultValue="Status"
                          list={status}
                        />
                      </Search>
                    </div>
                    <div
                      style={{
                        cursor: 'pointer',
                        marginTop: '27px',
                        marginLeft: '5px',
                      }}
                    >
                      {loadingData ? (
                        <CircularProgress color="primary" />
                      ) : (
                        <IconButton onClick={search} aria-label="search">
                          <SearchIcon />
                        </IconButton>
                      )}
                    </div>
                    {/* <div
                    style={{
                      cursor: 'pointer',
                      marginTop: '35px',
                      marginLeft: '5px',
                    }}
                  >
                    <SearchIcon onClick={search} />
                  </div> */}
                  </div>
                  <Clean onClick={clean}>
                    <div>{t(translations.table.clean_filter)}</div>
                  </Clean>
                </>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginRight: '0px',
                    marginTop: '30px',
                  }}
                >
                  <StyledButton onClick={() => setIsModalOpen(true)}>
                    {t(translations.general.filter)}
                  </StyledButton>
                </div>
              )}

              {errorInfoI !== '' ? (
                <h3
                  style={{
                    color: 'gray',
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '70px',
                    textAlign: 'center',
                  }}
                >
                  {errorInfoI === t(translations.general.no_search)
                    ? errorInfoI
                    : t(`${translations.errors_400[errorInfoI]}`)}
                </h3>
              ) : loading ? (
                <LoadingPage />
              ) : loadingData ? (
                <div
                  style={{
                    // transform: 'scale(0.2)',
                    minWidth: !isMobile ? 700 : 0,
                    // marginTop: '-200px',
                  }}
                >
                  <LoadingPage />
                </div>
              ) : (
                <HistoryITable
                  historyInvoices={historyInvoices}
                  page={page}
                  setHistoryInvoices={setHistoryInvoices}
                  setActualPageInvoices={setActualPageInvoices}
                  actualPageInvoices={actualPageInvoices}
                  setPagesInvoices={setPagesInvoices}
                  pages={pagesInvoices}
                  setOrigin={setOrigin}
                  handleChangeInvoices={handleChangeInvoices}
                ></HistoryITable>
              )}
            </>
          )}
          {page === false ? (
            <FilterModalHistory
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              originOptions={
                origin &&
                origin.filter(o =>
                  hash[o.value] ? false : ((hash[o.value] = true), true),
                )
              }
              onSearch={handleSearchFromModal}
              clean={handleCleanFromModal}
              loadingData={loadingData}
              t={t}
              setDateInitC={setDateInitC} // Pasa las funciones necesarias al componente
              setDateEndC={setDateEndC}
              setOriginSelectedC={setOriginSelectedC}
              originSelectedC={originSelectedC}
              searchOriginC={searchOriginC}
            />
          ) : (
            <FilterModalHistoryI
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              onSearch={handleSearchFromModalI}
              clean={handleCleanFromModalI}
              loadingData={loadingData}
              t={t}
              setDateInitI={setDateInitI} // Pasa las funciones necesarias al componente
              setDateEndI={setDateEndI}
            />
          )}
        </div>
      </div>
    </>
  );
}
