import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import styledComponent from 'styled-components/macro';
import styled, { useTheme } from 'styled-components/macro';
import { ThemeProvider, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { PurchaseData } from '../PurchaseData';
import axios from 'axios';
import { getImpersonateData, getUser } from 'services/authServices';
import { InfoModal } from '../InfoModal';
import drop from '../../assets/SVG/dropdown.svg';
import plus from '../../assets/SVG/plusMobile.png';
import { margin } from '@mui/system';
import ModalComponent from '../ModalComponent';

const StyledHeadCell = styled(TableCell)<{
  backgroundColor?: string;
  textColor?: string;
}>`
  font-style: normal;
  font-weight: 325;
  font-size: 13px;
  line-height: 16px;

  border-bottom: 2px solid white;
  background: #ebeaea;
  background: ${props =>
    props.backgroundColor ? props.backgroundColor : '#ebeaea'};
  color: ${props => (props.textColor ? props.textColor : '#ffffff')};
  text-align: center;
  @media (max-width: 767px) {
    border-bottom: 0px;
    flex-basis: ${({ theme }) => {
      const page = theme.page;
      if (page === 'purchase') {
        return '20%';
      } else if (page === 'report') {
        return '30%';
      } else if (page === 'home') {
        return '25%';
      } else {
        return '25%';
      }
    }};
  }
`;

const StyledTableRow = styled(TableRow)`
  border-bottom: 3px solid white;
  border-top: 3px solid white;
  th:first-child {
    border-radius: 35px 0px 0px 35px;
  }
  th:last-child {
    border-radius: 0px 35px 35px 0px;
  }

  td:first-child {
    border-radius: 35px 0px 0px 35px;
  }
  td:last-child {
    border-radius: 0px 35px 35px 0px;
  }
`;

const StyledTableCell = styled(TableCell)<{ page: string }>`
  align-items: center;

  font-style: normal;
  font-weight: 325;
  font-size: 13px;
  line-height: 16px;
  background: #f8f8f8;
  border-bottom: 1px solid white;
  display: table-cell;
  overflow: auto;
  @media (max-width: 767px) {
    overflow: hidden;
    color: ${props => props.page};
    flex-basis: ${props => {
      const page = props.page; // Accede al valor de 'page' desde el tema
      if (page === 'purchase') {
        return '20%';
      } else if (page === 'report') {
        return '30%';
      } else if (page === 'home') {
        return '25%';
      } else {
        return 'auto';
      }
    }};
  }
`;

const Total = styled.div`
  display: flex;
  aling-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

const Element = styled.div<{ color: string }>`
  color: #ffffff;
  margin-top: 30px;
  border-radius: 35px;
  font-weight: 350;
  height: 59px;
  background: ${props => props.color};
  flex-grow: 1;
  margin: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 767px) {
    width: 100%;
    border-radius: 0;
    margin: 0 0 1px 0;
  }
`;

const Add = styledComponent.div`
${props =>
  props.color === 'Add' &&
  `
  background: #4F4E24; 
  color: white;
`}
  ${props =>
    props.color === 'Remove' &&
    `
  background: #D98458; 
  color: white;`}
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  height: 33px;
  cursor: pointer;

`;

const Group = styledComponent.div`
  background: #545454; 
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  height: 33px;
  cursor: pointer;
`;

const Warehouse = styledComponent.div` 
background: #1989b2;
color: white;
display: flex;
justify-content: center;
align-items: center;
border-radius: 30px;
width: 201px;
height: 49px;
margin-left: 14px;
font-weight: 325;
font-size: 15px;
overflow: hidden;
text-align: center;
`;

const ContainerHeader = styledComponent.div<{
  backgroundColor?: string;
  textColor?: string;
}>`
display: flex;
width: 100%;
justify-content: space-around;
margin-bottom: 5px;
`;

const ContainerBody = styledComponent.div` 
display: flex;
width: 100%;
justify-content: space-around;
background: #f8f8f8;
`;

interface TableProps {
  coffes?: any[];
  page: string;
  setLoading?: (value: any) => void;
  setLoadingData?: (value: any) => void;
  setIsDownload?: (value: any) => void;
  loading?: any;
  setError: (value: any) => void;
}

export function GroupedMobileTable({
  coffes,
  page,
  loading,
  setLoading,
  setLoadingData,
  setIsDownload,
  setError,
}: TableProps) {
  const theme: any = useTheme();
  const [disabledOptions, setDisabledOptions] = useState<any[]>([]);
  const [open, setOpen] = useState(false);
  const [openModalSign, setOpenModalSign] = useState(false);
  const [info, setInfo] = useState<any[]>([]);
  const [permissions, setPermissions] = useState<string[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [reportDetail, setReportDetail] = useState(false);

  const openModalWithInfo = groupByData => {
    setReportDetail(true);
    setModalOpen(true);
    setInfo(groupByData);
  };

  const openModalWithDownload = groupByData => {
    setReportDetail(false);
    setModalOpen(true);
    setInfo(groupByData);
  };

  const { t } = useTranslation();

  // const openModal = (ico, type) => {

  const openModal = (ico, type) => {
    setOpenModalSign(!openModalSign);
    const token = localStorage.getItem('caravela_access_token');
    const url = `${process.env.REACT_APP_BASE_URL}/report-error`;
    axios
      .request({
        method: 'POST', // Change the request method to POST
        url: url,
        data: { ico: ico, report: type }, // Use the data object to send the request body
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(response => {
        if (response.status === 200) {
          // Additional code here if needed
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const closeModal = () => {
    setOpenModalSign(!openModalSign);
  };

  useEffect(() => {
    getImpersonateData()
      .then(response => {
        if (response.status === 200) {
          const permissionsRaw =
            response.data.Impersonate === 0
              ? response.data.permissions
              : response.data.original_permissions;
          const filteredPermissions = permissionsRaw.filter(
            value => value.name.split(';')[1] === 'Customer',
          );
          const permissionNames = filteredPermissions.map(
            module => module.name.split(';')[0],
          );
          setPermissions(permissionNames);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  const openAndPopulate = (
    quantity: string,
    size: string,
    package_type: string,
    type: string,
  ) => {
    populateModal(quantity, size, package_type, type);
    openCloseModal();
  };

  const openCloseModal = () => {
    setOpen(!open);
  };

  const getAvailabilityCall = (contract, ico) => {
    setLoadingData && setLoadingData(true);
    setIsDownload && setIsDownload(true);
    let url =
      process.env.REACT_APP_BASE_URL +
      `/reports/availability?contract=${contract}&ico=${ico}`;
    const token = localStorage.getItem('caravela_access_token');
    axios({
      method: 'GET',
      url,
      responseType: 'blob',
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(response => {
        if (response.status === 200) {
          setLoadingData && setLoadingData(false);
          setIsDownload && setIsDownload(false);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Availability.pdf'); //or any other extension
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch(error => {
        setLoadingData && setLoadingData(false);
        setIsDownload && setIsDownload(true);
        error && error.message === 'Request failed with status code 400'
          ? setError('report_not_found')
          : console.error('Se ha producido un error:', error?.message || error);
      });
  };

  const getTraceabilityCall = (contract, ico) => {
    setLoadingData && setLoadingData(true);
    setIsDownload && setIsDownload(false);
    let url =
      process.env.REACT_APP_BASE_URL +
      `/reports/traceability?contract=${contract}&ico=${ico}`;
    const token = localStorage.getItem('caravela_access_token');
    axios({
      method: 'GET',
      url,
      responseType: 'blob',
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(response => {
        if (response.status === 200) {
          setLoadingData && setLoadingData(false);
          setIsDownload && setIsDownload(true);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Traceability.pdf'); //or any other extension
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch(error => {
        setLoadingData && setLoadingData(false);
        setIsDownload && setIsDownload(false);
        error && error.message === 'Request failed with status code 400'
          ? setError('report_not_found')
          : console.error('Se ha producido un error:', error?.message || error);
      });
  };

  const getTransparencyCall = (contract, ico) => {
    setLoadingData && setLoadingData(true);
    setIsDownload && setIsDownload(true);
    let url =
      process.env.REACT_APP_BASE_URL +
      `/reports/transparency?contract=${contract}&ico=${ico}`;
    const token = localStorage.getItem('caravela_access_token');
    axios({
      method: 'GET',
      url,
      responseType: 'blob',
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(response => {
        if (response.status === 200) {
          setLoadingData && setLoadingData(false);
          setIsDownload && setIsDownload(false);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Transparency.pdf'); //or any other extension
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch(error => {
        setLoadingData && setLoadingData(false);
        setIsDownload && setIsDownload(false);
        error && error.message === 'Request failed with status code 400'
          ? setError('report_not_found')
          : console.error('Se ha producido un error:', error?.message || error);
      });
  };

  const populateModal = (
    quantity: string,
    size: string,
    package_type: string,
    type: string,
  ) => {
    const arr = [quantity, size, package_type, type];
    setInfo(arr);
  };

  useEffect(() => {
    let array = [];
    let contracts = JSON.parse(localStorage.getItem('contracts') || '[]');
    contracts
      ? setDisabledOptions(contracts)
      : localStorage.setItem('contracts', JSON.stringify(array));
  }, []);

  const setContract = (contract: string) => {
    const newDisabledArray: any[] = [...disabledOptions, contract];
    setDisabledOptions(newDisabledArray);
    localStorage.setItem('contracts', JSON.stringify(newDisabledArray));
  };

  const removeContract = (contract: string) => {
    let contractDelete = disabledOptions.filter(elem => elem !== contract);
    setDisabledOptions(contractDelete);
    localStorage.setItem('contracts', JSON.stringify(contractDelete));
  };

  return openModalSign === true ? (
    <InfoModal closeModal={closeModal} openModalSign={openModalSign} />
  ) : coffes ? (
    <ThemeProvider theme={theme}>
      {open === true ? (
        <PurchaseData
          openCloseModal={openCloseModal}
          quantity={info[0]}
          size={info[1]}
          package_type={info[2]}
          type={info[3]}
        />
      ) : null}
      {coffes
        .filter(group => group.length > 0)
        .map(group => (
          // item.map(group => (
          <>
            <div
              className="d-flex align-items-center mt-5 mb-4"
              key={
                group[0].status === 'Afloat' || group[0].status === 'Contracted'
                  ? group[0].status
                  : group[0].warehouse
              }
            >
              <h1 style={{ color: theme.palette.primary.main }}>
                {group[0].status === 'Afloat' ||
                group[0].status === 'Contracted'
                  ? group[0].status
                  : group[0].warehouse}
              </h1>
              {/* <Warehouse>{group[0].warehouse}</Warehouse> */}
            </div>
            {/* <TableContainer> */}
            {/* <Table sx={{ minWidth: 700 }} aria-label="customized table"> */}
            {/* <TableHead> */}
            {/* <StyledTableRow> */}
            <ContainerHeader>
              <StyledHeadCell
                backgroundColor={theme.palette.secondary.main}
                textColor={theme.palette.secondary.contrastText}
                align="center"
              ></StyledHeadCell>
              <StyledHeadCell
                backgroundColor={theme.palette.secondary.main}
                textColor={theme.palette.secondary.contrastText}
              >
                {t(translations.table.contract)}
              </StyledHeadCell>
              <StyledHeadCell
                backgroundColor={theme.palette.secondary.main}
                textColor={theme.palette.secondary.contrastText}
                align="center"
              >
                {t(translations.table.ico)}
              </StyledHeadCell>
              {page !== 'report' && (
                <StyledHeadCell
                  backgroundColor={theme.palette.secondary.main}
                  textColor={theme.palette.secondary.contrastText}
                  align="center"
                >
                  {t(translations.table.marks)}
                </StyledHeadCell>
              )}
              {page === 'report' &&
                permissions &&
                permissions.includes('Downloads') && (
                  <StyledHeadCell
                    backgroundColor={theme.palette.secondary.main}
                    textColor={theme.palette.secondary.contrastText}
                    align="center"
                  >
                    {t(translations.table.downloads)}
                  </StyledHeadCell>
                )}
              {/* {page === 'purchase' ? null : (
                <StyledHeadCell align="center"></StyledHeadCell>
              )} */}
              {/* <StyledHeadCell align="center">
                      {t(translations.table.quality)}
                    </StyledHeadCell> */}
              {/* {page !== 'report' && (
                      <>
                        <StyledHeadCell align="center">
                          {t(translations.table.av_date)}
                        </StyledHeadCell>
                        <StyledHeadCell align="center">
                          {t(translations.table.q_released)}
                        </StyledHeadCell>
                        <StyledHeadCell align="center">
                          {t(translations.table.q_available)}
                        </StyledHeadCell>
                        <StyledHeadCell align="center">
                          {t(translations.table.warehouse_reference)}
                        </StyledHeadCell>
                      </>
                    )}
                    {page === 'report' &&
                      permissions &&
                      permissions.includes('Downloads') && (
                        <>
                          <StyledHeadCell align="center">
                            {t(translations.table.availability)}
                          </StyledHeadCell>
                          <StyledHeadCell align="center">
                            {t(translations.table.traceability)}
                          </StyledHeadCell>
                          <StyledHeadCell align="center">
                            {t(translations.table.transparency)}
                          </StyledHeadCell>
                        </>
                      )}*/}
              {page === 'purchase' &&
              permissions &&
              permissions.includes('Purchase Order') ? (
                <StyledHeadCell
                  backgroundColor={theme.palette.secondary.main}
                  textColor={theme.palette.secondary.contrastText}
                  align="center"
                >
                  {t(translations.table.p_progress)}
                </StyledHeadCell>
              ) : null}
            </ContainerHeader>
            {/* </StyledTableRow> */}
            {/* </TableHead> */}
            {/* <TableBody> */}
            {group.map(
              groupBy => (
                // groupBy.origin === group[0].origin &&

                // <StyledTableRow key={groupBy.contract}>
                <ContainerBody>
                  <StyledTableCell page={page} align="center">
                    {' '}
                    <img
                      src={drop}
                      alt="drop"
                      style={{ width: '22px', height: '22px' }}
                      onClick={() => openModalWithInfo(groupBy)}
                    />
                  </StyledTableCell>
                  <StyledTableCell page={page} align="center">
                    {groupBy.contract}
                  </StyledTableCell>
                  <StyledTableCell page={page} align="center">
                    {page !== 'report' ? groupBy.ico_number : groupBy.ico}
                  </StyledTableCell>

                  {page !== 'report' && (
                    <StyledTableCell
                      page={page}
                      align="center"
                      style={{ textOverflow: 'ellipsis' }}
                    >
                      {/* {groupBy.broshure ? (
                            <a
                              href={groupBy.broshure}
                              style={{
                                textDecoration: 'none',
                                borderRadius: '30px',
                                background: '#1989B2',
                                color: 'white',
                                padding: '15px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '40px',
                                width: '160px',
                              }}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {groupBy.mark}
                            </a>
                          ) : ( */}
                      {groupBy.mark}
                      {/* )} */}
                    </StyledTableCell>
                  )}
                  {page === 'report' &&
                    permissions &&
                    permissions.includes('Downloads') && (
                      <StyledTableCell
                        page={page}
                        align="center"
                        onClick={() => openModalWithDownload(groupBy)}
                      >
                        {' '}
                        <FileDownloadIcon
                          style={{
                            color: '#4F4E24',
                            cursor: 'pointer',
                          }}
                        />
                      </StyledTableCell>
                    )}
                  {/* <StyledTableCell align="center">
                          {' '}
                          <img
                            src={plus}
                            alt="plus"
                            style={{ width: '33px', height: '33px' }}
                          />
                        </StyledTableCell> */}
                  {/* <StyledTableCell align="center">
                                {groupBy.quality}
                              </StyledTableCell> */}
                  {page !== 'report' && (
                    <>
                      {/* <StyledTableCell align="center">
                              {groupBy.warehouse_reference}
                            </StyledTableCell> */}
                      {page === 'purchase' &&
                      permissions &&
                      permissions.includes('Purchase Order') ? (
                        <StyledTableCell page={page} align="center">
                          {!disabledOptions.find(
                            element => element === groupBy.contract,
                          ) && (
                            <Add
                              color="Add"
                              id={`add-${groupBy.contract}`}
                              onClick={() => setContract(groupBy.contract)}
                            >
                              +{/* {t(translations.table.add)} */}
                            </Add>
                          )}
                          {disabledOptions.find(
                            element => element === groupBy.contract,
                          ) && (
                            <Add
                              color="Remove"
                              id={`remove-${groupBy.contract}`}
                              onClick={() => removeContract(groupBy.contract)}
                            >
                              -{/* {t(translations.table.remove)} */}
                            </Add>
                          )}
                        </StyledTableCell>
                      ) : null}
                    </>
                  )}
                  {/* {page === 'report' &&
                          permissions &&
                          permissions.includes('Downloads') && (
                            <>
                              <StyledTableCell
                                onClick={() =>
                                  groupBy.visible === '1'
                                    ? getAvailabilityCall(
                                        groupBy.contract,
                                        groupBy.ico,
                                      )
                                    : openModal(groupBy.ico, 'Availability')
                                }
                                align="center"
                              >
                                <FileDownloadIcon
                                  style={{
                                    color: '#1989B2',
                                    cursor: 'pointer',
                                  }}
                                />
                              </StyledTableCell>
                              <StyledTableCell
                                onClick={() =>
                                  groupBy.visible === '1'
                                    ? getTraceabilityCall(
                                        groupBy.contract,
                                        groupBy.ico,
                                      )
                                    : openModal(groupBy.ico, 'Traceability')
                                }
                                align="center"
                              >
                                <FileDownloadIcon
                                  style={{
                                    color: '#1989B2',
                                    cursor: 'pointer',
                                  }}
                                />
                              </StyledTableCell>
                              <StyledTableCell
                                onClick={() =>
                                  groupBy.visible === '1'
                                    ? getTransparencyCall(
                                        groupBy.contract,
                                        groupBy.ico,
                                      )
                                    : openModal(groupBy.ico, 'Transparency')
                                }
                                align="center"
                              >
                                <FileDownloadIcon
                                  style={{
                                    color: '#1989B2',
                                    cursor: 'pointer',
                                  }}
                                />
                              </StyledTableCell>
                            </>
                          )} */}
                </ContainerBody>
              ),
              // </StyledTableRow>
              // ),
              // ),
              // ),
            )}
            {/* </TableBody> */}
            {/* </Table> */}
            {/* </TableContainer> */}
            {/* {page !== 'report' && (
            <Total>
              <div style={{ fontWeight: '350', fontSize: '30px' }}>
                {t(translations.table.total)}
              </div>
              <Element color={'#EBEAEA'} style={{ color: 'black' }}>
                {t(translations.table.boxes)}{' '}
                {suma(
                  flat(
                    coffes.map(item =>
                      item.map(subitem =>
                        subitem
                          .filter(value => value.package_type === 'Boxes')
                          .map(groupBy =>
                            groupBy.origin === group[0].origin &&
                            groupBy.warehouse === group[0].warehouse
                              ? groupBy.released_quantity
                              : 0,
                          )
                          .reduce((a, b) => parseInt(a) + parseInt(b), 0),
                      ),
                    ),
                  ).filter(value => value !== 0),
                  flat(
                    coffes.map(item =>
                      item.map(subitem =>
                        subitem
                          .filter(value => value.package_type === 'Boxes')
                          .map(groupBy =>
                            groupBy.origin === group[0].origin &&
                            groupBy.warehouse === group[0].warehouse
                              ? groupBy.available_quantity
                              : 0,
                          )
                          .reduce((a, b) => parseInt(a) + parseInt(b), 0),
                      ),
                    ),
                  ).filter(value => value !== 0),
                )}
              </Element>
              <Element color={'#EBEAEA'} style={{ color: 'black' }}>
                {t(translations.table.bags)}{' '}
                {suma(
                  flat(
                    coffes.map(item =>
                      item.map(subitem =>
                        subitem
                          .filter(value => value.package_type === 'Bags')
                          .map(groupBy =>
                            groupBy.origin === group[0].origin &&
                            groupBy.warehouse === group[0].warehouse &&
                            groupBy.package_type === 'Bags'
                              ? groupBy.released_quantity
                              : 0,
                          )
                          .reduce((a, b) => parseInt(a) + parseInt(b), 0),
                      ),
                    ),
                  ).filter(value => value !== 0),
                  flat(
                    coffes.map(item =>
                      item.map(subitem =>
                        subitem
                          .filter(value => value.package_type === 'Bags')
                          .map(groupBy =>
                            groupBy.origin === group[0].origin &&
                            groupBy.warehouse === group[0].warehouse
                              ? groupBy.available_quantity
                              : 0,
                          )
                          .reduce((a, b) => parseInt(a) + parseInt(b), 0),
                      ),
                    ),
                  ).filter(value => value !== 0),
                )}
              </Element>
              <Element color={'#1989B2'}>
                {t(translations.table.net_weight)}{' '}
                {flat(
                  coffes.map(item =>
                    item.map(subitem =>
                      subitem
                        .map(groupBy =>
                          groupBy.origin === group[0].origin &&
                          groupBy.warehouse === group[0].warehouse
                            ? groupBy.net_weight
                            : 0,
                        )
                        .reduce((a, b) => parseInt(a) + parseInt(b), 0),
                    ),
                  ),
                ).filter(value => value !== 0)}{' '}
                lbs
              </Element>
              <Element color={'#5C36F3'}>
                {t(translations.table.released)}{' '}
                {coffes
                  .reduce(
                    (total, item) =>
                      total +
                      item.reduce(
                        (subtotal, subitem) =>
                          subtotal +
                          subitem.reduce(
                            (sum, groupBy) =>
                              sum +
                              (groupBy.origin === group[0].origin &&
                              groupBy.warehouse === group[0].warehouse &&
                              Number.isInteger(groupBy.released_quantity)
                                ? groupBy.released_quantity
                                : 0),
                            0,
                          ),
                        0,
                      ),
                    0,
                  )
                  .toLocaleString('es-ES', { minimumIntegerDigits: 1 })}
              </Element>
              <Element color={'#33BA79'}>
                {t(translations.table.available)}{' '}
                {coffes
                  .reduce(
                    (total, item) =>
                      total +
                      item.reduce(
                        (subtotal, subitem) =>
                          subtotal +
                          subitem.reduce(
                            (sum, groupBy) =>
                              sum +
                              (groupBy.origin === group[0].origin &&
                              groupBy.warehouse === group[0].warehouse &&
                              Number.isInteger(groupBy.available_quantity)
                                ? groupBy.available_quantity
                                : 0),
                            0,
                          ),
                        0,
                      ),
                    0,
                  )
                  .toLocaleString('es-ES', { minimumIntegerDigits: 1 })
                  .replace(/^0+/, '') || 0}
              </Element>
            </Total>
          )} */}
          </>
        ))}
      {modalOpen && info && (
        <ModalComponent
          page={page}
          closeModal={() => setModalOpen(false)} // Función para cerrar el modal
          data={info} // Información de groupBy para mostrar en el modal
          getAvailabilityCall={getAvailabilityCall}
          getTraceabilityCall={getTraceabilityCall}
          getTransparencyCall={getTransparencyCall}
          openModal={openModal}
          reportDetail={reportDetail}
          setReportDetail={setReportDetail}
        />
      )}
    </ThemeProvider>
  ) : null;
}
function flat(arr: any[]): any {
  let flatArray = arr.reduce((acc, curVal) => {
    return acc.concat(curVal);
  }, []);
  return flatArray;
}

function suma(
  arg0: any,
  arg1: any,
):
  | import('react-i18next').ReactI18NextChild
  | Iterable<import('react-i18next').ReactI18NextChild> {
  return arg0[0] !== undefined && arg1[0] !== undefined
    ? parseInt(arg0) + parseInt(arg1)
    : arg0[0] === undefined && arg1[0] !== undefined
    ? parseInt(arg1)
    : arg1[0] === undefined && arg0[0] !== undefined
    ? parseInt(arg0)
    : 0;
}
