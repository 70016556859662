import styled from 'styled-components/macro';
import caravela from '../../../app/assets/SVG/caravela.svg';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { createRole } from 'services/adminServices';
import axios from 'axios';

interface AddDeleteProps {
  setAddDeleteOption: (value: string) => void;
  type: string;
  role?: string;
  selectedRol?: string;
}

const Button = styled.input`
  border: none;
  background: #5c36f3;
  color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  margin-bottom: 25px;
  width: 213.02px;
  height: 49.59px;
  margin: 0 10px;
`;
const Bg = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(15px);
`;

const Login = styled.div`
  width: 518px;
  height: 468px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: #ffffff;
  border-radius: 30px;
  z-index: 1000;
`;

const InputPass = styled.input`
  width: 426.84px;
  height: 48.71px;
  left: 519.78px;
  top: 391.99px;
  background: #f9f9f9;
  border-radius: 30px;
  border: 0;
  padding: 0 20px;
`;

const InputText = styled.div`
  font-style: normal;
  font-weight: 350;
  font-size: 20px;
  line-height: 24px;
  color: #545454;
  margin: 10px 0;
  padding: 0 50px;
`;

export function AddDeleteModal({
  setAddDeleteOption,
  type,
  role,
  selectedRol,
}: AddDeleteProps) {
  const { t } = useTranslation();

  const closeModal = () => {
    setAddDeleteOption('');
    window.location.reload();
  };

  const deleteRole = () => {
    const token = localStorage.getItem('caravela_access_token');
    const url = `${process.env.REACT_APP_BASE_URL}/delete-role`;
    axios
      .delete(url, {
        params: {
          role: selectedRol,
        },
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(response => {
        if (response.status === 200) {
          setSuccess(t(translations.general.role_deleted));
          setError('');
        }
      })
      .catch(error => {
        setError(t(translations.general.error_something));
      });
  };

  const addRole = () => {
    createRole(new_role)
      .then(response => {
        if (response.status === 200) {
          setSuccess(t(translations.general.role_created));
          setError('');
        }
      })
      .catch(error => {
        setError(t(translations.general.error_something));
      });
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          closeModal();
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const [error, setError] = useState('');
  const [changed, setChanged] = useState(false);
  const [current_password, setCurrent] = useState('');
  const [new_role, setNewR] = useState('');
  const [success, setSuccess] = useState('');
  const [repeat, setRepeat] = useState('');

  const resetModal = () => {
    setChanged(!changed);
    setAddDeleteOption(' ');
  };

  const setNewRole = e => {
    setNewR(e.target.value);
  };

  return (
    <Bg>
      <Login ref={wrapperRef}>
        <img
          src={caravela}
          alt="caravela logo"
          style={{ marginTop: '51px', marginBottom: '11px' }}
        />
        {type === 'add' ? (
          <div className="d-flex flex-column">
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <InputText
                style={{
                  color:
                    error !== '' ? 'red' : success !== '' ? 'green' : 'black',
                }}
              >
                {success !== ''
                  ? success
                  : error !== ''
                  ? error
                  : t(translations.general.role_name)}
              </InputText>
              {success === '' && <InputPass onChange={e => setNewRole(e)} />}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {success === '' && (
                <Button
                  style={{ marginTop: '35px' }}
                  type="button"
                  onClick={addRole}
                  value={t(translations.general.confirm)}
                />
              )}

              <Button
                style={{
                  marginTop: '35px',
                  background: success === '' ? 'red' : 'blue',
                }}
                type="button"
                onClick={closeModal}
                value={
                  success === ''
                    ? t(translations.general.cancel)
                    : t(translations.general.close)
                }
              />
            </div>
          </div>
        ) : (
          <div className="d-flex flex-column">
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <InputText
                style={{ margin: '0', padding: '0', marginBottom: '30px' }}
              >
                {success === '' ? t(translations.general.sure_role) : success}
              </InputText>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <InputText>{selectedRol}</InputText>
            </div>
            {error !== '' ? (
              <div
                style={{
                  color: 'red',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {error}
              </div>
            ) : null}
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {success === '' && (
                <Button
                  style={{ marginTop: '35px' }}
                  type="button"
                  onClick={() => deleteRole()}
                  value={t(translations.general.confirm)}
                />
              )}
              <Button
                style={{
                  marginTop: '35px',
                  background: success !== '' ? 'blue' : 'red',
                }}
                type="button"
                onClick={closeModal}
                value={
                  success !== ''
                    ? t(translations.general.close)
                    : t(translations.general.cancel)
                }
              />
            </div>
          </div>
        )}
      </Login>
    </Bg>
  );
}
