import styled from 'styled-components/macro';

import caravela from '../../../app/assets/SVG/caravela.svg';
import { Link } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { ChangePassPayload } from 'models/authModels';
import { changePass } from 'services/authServices';
import Select from 'react-select';
import Dropdown from '../Dropdown';
import { createExternalSamples } from 'services/cuppingServices';
import { getCountries } from 'services/generalServices';
import { createTable, getCountriesSamples } from 'services/cuppingServices';
import { AlertColor } from '@mui/material/Alert';
import { Alert, Snackbar } from '@mui/material';

interface CuppingProps {
  setModalOpenUsers: (value: boolean) => void;
  onSubmit: () => void;
  getListTables: () => void;
  modal: string;
  data: any;
  users: any;
}

const Text = styled.div`
  font-size: 15px;
  font-weight: 350;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  margin-right: 20px;
`;

const Input = styled.input`
  background: #f6f6f6;
  border-radius: 30px;
  width: 400px;
  height: 40px;
  outline: none;
  padding: 10px;
`;

const Button = styled.input`
  border: none;
  background: #4f4e24;
  color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  margin-bottom: 25px;
  width: 213.02px;
  height: 49.59px;
  margin: 0 10px;
`;
const Bg = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(15px);
`;

const Login = styled.div`
  width: 518px;
  height: 708px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: #ffffff;
  border-radius: 30px;
  z-index: 1000;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 350;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #4f4e24;
  margin-bottom: 20px;
`;

const CloseButton = styled.div`
  font-size: 34px;
  font-weight: bold;
  cursor: pointer;
  margin-left: auto;
  padding: 0 15px;
`;

const ContainerDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
  margin-bottom: 10px;
`;

const InputLarge = styled.textarea`
  background: #f6f6f6;
  border-radius: 30px;
  width: 400px;
  height: 135px;
  margin: 10px 0;
  outline: none;
  padding: 10px 20px;
  @media (max-width: 767px) {
    width: 30vh;
  }
`;

interface Member {
  value: string;
  label: string;
  email: string;
}

export function CuppingModal({
  setModalOpenUsers,
  getListTables,
  onSubmit,
  modal,
  data,
  users,
}: CuppingProps) {
  const { t } = useTranslation();

  useEffect(() => {
    getCountriesSamples()
      .then(response => {
        if (response.status === 200) {
          let arrO = response.data.map(item => ({
            value: item.id,
            label: item.name,
          }));
          setOrigin(arrO);
        }
      })
      .catch(error => {
        console.log(error.response?.data, 'error.response.data');
      });
  }, []);

  const options = users.map(user => ({
    value: user.id,
    label: user.ContactName.trim(),
    email: user.Email.trim(),
  }));

  const closeModal = () => {
    setModalOpenUsers(false);
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          closeModal();
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const [error, setError] = useState('');
  const [changed, setChanged] = useState(false);
  const [origin, setOrigin] = useState<any[]>();
  const [sample, setSample] = useState('');
  const [company, setCompany] = useState('');
  const [comments, setComments] = useState('');
  const [originSelected, setOriginSelected] = useState('');
  const [selectedMembers, setSelectedMembers] = useState<Member[]>([]);
  const [isCheckboxMember, setCheckboxMember] = useState(false);
  const [arrayMembersSamples, setArrayMembersSamples] = useState<any[]>([]);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState<AlertColor | undefined>(
    undefined,
  );

  let hash = {};

  const createTableSamples = () => {
    if (selectedMembers.length > 0 || isCheckboxMember) {
      const formattedMembers = selectedMembers.map(member => ({
        member_id: member.value,
        email: member.email,
      }));

      const payload = {
        coffees: data.map(coffee => ({
          sample_id: coffee.sample_id,
          external_sample: 0,
        })),
        members: isCheckboxMember ? [] : formattedMembers,
      };

      createTable(payload)
        .then(response => {
          if (response.status === 200) {
            setModalOpenUsers(false);
            setAlertMessage(t('cupping.cup_table_created'));
            setAlertSeverity('success');
            getListTables();
            setAlertOpen(true);
          }
        })
        .catch(error => {
          setError(error.response?.data?.message);
        });
    } else {
      setAlertMessage(t('cupping.error_table_created'));
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleDropdownChange = selected => {
    setSelectedMembers(selected);
  };

  const handleCheckboxChange = event => {
    setCheckboxMember(event.target.checked);
  };

  const handleSubmit = () => {
    const payload = {
      origin_id: originSelected,
      sample_id: sample,
      company: company,
      comments: comments,
    };
    createExternalSamples(payload)
      .then(response => {
        if (response.status === 200) {
          setModalOpenUsers(true);
          setChanged(!changed);
        } else if (response.status === 400) {
          console.log('error');
        }
      })
      .catch(error => {
        setError(error);
      });
  };

  const searchOrigin = (selected: any) => {
    setOriginSelected(selected.value);
  };

  return (
    <Bg>
      <Login
        ref={wrapperRef}
        style={{
          width: modal === 'user' ? '538px' : '665px',
          height: modal === 'user' ? '301px' : '520px',
        }}
      >
        <CloseButton onClick={() => closeModal()}>X</CloseButton>
        {modal === 'user' && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
            }}
          >
            <Title>{t(translations.general.select_user)}</Title>
            <Dropdown
              handleChange={handleDropdownChange}
              defaultValue="User"
              list={options}
              isMulti={true}
              isDisabled={isCheckboxMember}
              // list={
              //   origin &&
              //   origin.filter(o =>
              //     hash[o.value] ? false : (hash[o.value] = true),
              //   )
              // }
            />
            <div
              style={{
                display: 'flex',
                marginRight: 'auto',
                alignItems: 'center',
                marginTop: '15px',
              }}
            >
              <input
                type="checkbox"
                style={{
                  marginRight: '10px',
                  height: '15px',
                  width: '15px',
                }}
                onChange={handleCheckboxChange}
                checked={isCheckboxMember}
                disabled={selectedMembers.length > 0}
              />
              {t(translations.general.only_me)}
            </div>
            <Button
              style={{ cursor: 'pointer', marginTop: '30px' }}
              type="button"
              onClick={() => {
                createTableSamples();
              }}
              value="Confirm"
            />
          </div>
        )}
        {modal === 'coffee' && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
            }}
          >
            <Title>{t(translations.general.add_sample)}</Title>
            <ContainerDiv>
              <Text>{t(translations.table.id_sample)}</Text>
              <Input
                style={{ border: '1px gray' }}
                onChange={e => setSample(e.target.value)}
              ></Input>
            </ContainerDiv>
            <ContainerDiv>
              <Text>{t(translations.pages.register.country)}</Text>
              {/* <Input onChange={e => setOrigin(e.target.value)}></Input> */}
              <Dropdown
                handleChange={searchOrigin}
                // width={false}
                defaultValue={t(translations.table.origin)}
                // defaultValue="Origin"
                list={
                  origin &&
                  origin.filter(o =>
                    hash[o.value] ? false : (hash[o.value] = true),
                  )
                }
              />
            </ContainerDiv>
            <ContainerDiv>
              <Text> {t(translations.pages.register.company_name)}</Text>
              <Input
                style={{ border: '1px gray' }}
                onChange={e => setCompany(e.target.value)}
              ></Input>
            </ContainerDiv>
            <ContainerDiv>
              <Text>{t(translations.general.comments)}</Text>
              <InputLarge
                style={{ border: '1px gray' }}
                onChange={e => setComments(e.target.value)}
              ></InputLarge>
            </ContainerDiv>
            {error !== '' ? (
              <div
                style={{
                  color: 'red',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {error}
              </div>
            ) : null}
            <Button
              style={{ cursor: 'pointer', marginTop: '30px' }}
              type="button"
              onClick={() => {
                handleSubmit();
              }}
              value={t(translations.table.add)}
            />
          </div>
        )}
      </Login>
      <Snackbar
        open={alertOpen}
        autoHideDuration={4000}
        onClose={() => setAlertOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setAlertOpen(false)}
          severity={alertSeverity}
          sx={{
            width: '100%',
            fontSize: '1.5rem',
            fontWeight: 'bold',
            padding: '20px',
            maxWidth: '600px',
            margin: '0 auto',
          }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </Bg>
  );
}
