import serviceClient from './serviceClientConfig';

export const getOffering = (
  origin?,
  mark?,
  packaging?,
  category?,
  process?,
  location?,
) => {
  let queryParams = '';

  if (origin !== '') {
    queryParams += `&origin=${origin}`;
  }
  if (mark !== '') {
    queryParams += `&mark=${mark}`;
  }
  if (packaging !== '') {
    queryParams += `&packaging=${packaging}`;
  }
  if (location !== '') {
    queryParams += `&warehouse=${location}`;
  }
  if (category !== '') {
    queryParams += `&category=${category}`;
  }
  if (process !== '') {
    queryParams += `&process=${process}`;
  }

  return serviceClient.get(`/offerings?${queryParams}`);
};

export const getMyPosition = () => {
  return serviceClient.get(`/my_position`);
};

export const sendOffering = (payload: any) => {
  return serviceClient.post('/offerings', payload);
};

export const getSample = () => {
  return serviceClient.get(`/sample-request`);
};

export const setRecieved = (payload: any) => {
  return serviceClient.put(`/sample-request/${payload.id}`, payload);
};

export const setAcceptedOrRejected = (payload: any) => {
  return serviceClient.post(`/sample-request`, payload);
};
