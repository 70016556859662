import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
    font-family: "Roboto Condensed", sans-serif;
    font-style: normal;
    font-weight: 350;
    
  }

  #root {
    min-height: 100%;
    min-width: 100%;
  }

  .leaflet-container {
    height: 329px;
    width: 90%;
    border-radius: 20px;
  }  

`;
