/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom/client';

// Use consistent styling
import 'sanitize.css/sanitize.css';
import { useEffect } from 'react';
// Import root app
import App from 'app';

import { HelmetProvider } from 'react-helmet-async';

import reportWebVitals from 'reportWebVitals';

// Initialize languages
import './locales/i18n';
import { BrowserRouter, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
ReactGA.initialize('G-GBGXRJWLVC');

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const TrackPageView = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }, [location]);

  return null;
};

root.render(
  <HelmetProvider>
    <React.StrictMode>
      <BrowserRouter>
        <TrackPageView />
        <App />
      </BrowserRouter>
    </React.StrictMode>
  </HelmetProvider>,
);

// Hot reloadable translation json files
if (module.hot) {
  module.hot.accept(['./locales/i18n'], () => {
    // No need to render the App again because i18next works with the hooks
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
