//ORIGINAL
import { OfferingTable } from 'app/components/OfferingTable';
import * as React from 'react';
import { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { getCountries, getMarks, usageMetrics } from 'services/generalServices';
import { getMyPosition, getOffering } from 'services/sampleServices';
import { getReviewHistorical, getReviewPending } from 'services/reviewServices';
import styled, { useTheme } from 'styled-components/macro';
import { LoadingPage } from '../LoadingPage';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { ThemeProvider, useMediaQuery } from '@mui/material';
import { Hero } from 'app/components/Hero';

const Tab = styled.div<{ state: string }>`
  background-color: ${props =>
    props.state === 'active' ? '#ffffff' : '#33BA79'};
  color: ${props => (props.state === 'active' ? '#000000' : '#F8F8F8')};
  transition: all 0.5s ease-in-out;
  border-radius: 30px;
  border: 4px solid #33ba79;
  width: 245px;
  height: 38px;
  display: flex;
  justify-content: center;
  margin: 10px;
  cursor: ${props => (props.state === 'active' ? 'default' : 'pointer')};
  margin-left: -40px;

  @media (max-width: 768px) {
    border-radius: 0; /* Remove border-radius for mobile */
    margin: 5px; /* Reduce the margin for mobile */
    margin-left: 0; /* Remove negative margin for mobile */
  }
`;

const Text = styled.div`
  font-weight: 325;
  font-size: 20px;
`;

export function SpotOfferingPage(selectedPage: any) {
  const [page, setPage] = useState(true);
  const [actualPage, setActualPage] = useState(1);
  const [pages, setPages] = useState();
  const [delivery, setDelivery] = useState<any[]>();
  const [deliveryHistorical, setDeliveryHistorical] = useState<any[]>();
  const [origin, setOrigin] = useState<any>('');
  const [mark, setMark] = useState<any>('');
  const [packaging, setPackaging] = useState<any>('');
  const [category, setCategory] = useState<any>('');
  const [location, setLocation] = useState<any>('');
  const [process, setProcess] = useState<any>('');
  const [originSelected, setOriginSelected] = useState('');
  const [markSelected, setMarkSelected] = useState('');
  const [dateInit, setDateInit] = useState('');
  const [dateEnd, setDateEnd] = useState('');
  const [loading, setLoading] = useState<Boolean>(true);
  const [coffes, setCoffes] = useState<any[]>();
  const [loadingData, setLoadingData] = useState(false);
  const [errorPosition, setErrorPosition] = useState('');
  const [errorOffering, setErrorOffering] = useState('');
  const [errorInfo, setErrorInfo] = useState<any>('');
  const [samplesAvailable, setSamplesAvailable] = useState<number>();

  const isMobile = useMediaQuery('(max-width:767px)');

  const { t } = useTranslation();
  const theme: any = useTheme();

  const search = () => {
    setErrorOffering('');
    setLoadingData(true);
    getOffering(origin, mark, packaging, category, process, location)
      .then(response => {
        if (response.status === 200) {
          setLoadingData(false);
          setCoffes(response.data.coffees);
          setSamplesAvailable(response.data.available_samples);
        }
      })
      .catch(error => {
        setErrorOffering(error.response?.data?.message);
        setLoadingData(false);
        console.log(error.response?.data);
      });
  };

  const cleanAndSearch = () => {
    setErrorOffering('');
    setLoadingData(true);
    getOffering('', '', '', '', '', '')
      .then(response => {
        if (response.status === 200) {
          setLoadingData(false);
          setCoffes(response.data.coffees);
          setSamplesAvailable(response.data.available_samples);
        }
      })
      .catch(error => {
        setErrorOffering(error.response?.data?.message);
        setLoadingData(false);
        console.log(error.response?.data);
      });
  };

  const hasMounted = useRef(false);
  useEffect(() => {
    if (!hasMounted.current) {
      hasMounted.current = true;
      setErrorOffering('');
      getOffering(origin, mark, packaging, category, process, location)
        .then(response => {
          if (response.status === 200) {
            setCoffes(response.data.coffees);
            setSamplesAvailable(response.data.available_samples);
          }
        })
        .catch(error => {
          setLoading(false);
          console.log(error);
          setErrorOffering(error.response?.data?.message);
        });
      setLoading(false);
      const role = localStorage.getItem('role');
      if (role === 'Customer') {
        const payload = {
          module: 'Spot Offerings',
          role: 'Customer',
        };

        usageMetrics(payload)
          .then(response => {
            if (response.status === 200) {
              console.log('Metrica enviada');
            }
          })
          .catch(error => {
            console.log(error.response?.data, 'error.response.data');
          });
      }
    }
  }, []);

  return (
    <>
      <ThemeProvider theme={theme}>
        <Helmet>
          <title>Spot Offerings</title>
          <meta name="description" content="Roasterhub spot offerings" />
        </Helmet>
        {loading ? (
          <LoadingPage />
        ) : (
          <div>
            <div
              className="d-flex"
              style={{
                padding: !isMobile ? '30px 96px' : '0px',

                flexDirection: 'column',
              }}
            >
              <div
                className="d-flex"
                style={{
                  justifyContent: 'center',
                  marginLeft: !isMobile ? '10px' : '0px',
                  flexDirection: !isMobile ? 'row' : 'column',
                  alignItems: 'center',
                }}
              >
                <div className="d-flex justify-content-start w-100">
                  <Hero
                    title={t(translations.pages.home.offerings)}
                    subtitle={t(translations.offerings.description)}
                  />
                </div>
              </div>
              <>
                <OfferingTable
                  coffes={coffes}
                  page={page}
                  origin={origin}
                  mark={mark}
                  packaging={packaging}
                  category={category}
                  process={process}
                  location={location}
                  setOrigin={setOrigin}
                  setMark={setMark}
                  setPackaging={setPackaging}
                  setCategory={setCategory}
                  setProcess={setProcess}
                  setLocation={setLocation}
                  search={search}
                  cleanAndSearch={cleanAndSearch}
                  setLoadingData={setLoadingData}
                  loadingData={loadingData}
                  errorOffering={errorOffering}
                  setSamplesAvailable={setSamplesAvailable}
                  samplesAvailable={samplesAvailable}
                />
                {errorOffering !== '' && (
                  <h3
                    style={{
                      color: 'gray',
                      display: 'flex',
                      justifyContent: 'center',
                      marginTop: '50px',
                      textAlign: 'center',
                    }}
                  >
                    {t(`${translations.errors_400[errorOffering]}`)}
                  </h3>
                )}
              </>
            </div>
          </div>
        )}
      </ThemeProvider>
    </>
  );
}
