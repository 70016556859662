import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { PopperUnstyled } from '@mui/base';
import ClickAwayListener from '@mui/base/ClickAwayListener';

const StyledPopperDiv = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 1em 2em;
  gap: 1em;
  background-color: ${props => props.theme.White};
  border-radius: 4px;
  opacity: 1;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

const SimplePopper = ({
  onClick,
  clickable,
  popupContent,
}: {
  onClick?: Function;
  clickable: any;
  popupContent: any;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    console.log(event.currentTarget);
    onClick && onClick();
  };
  const handleClickAway = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  return (
    <>
      <button
        type="button"
        style={{ border: 'none', background: 'none', outline: 'none' }}
        onClick={handleClick}
      >
        {clickable}
      </button>

      <PopperUnstyled open={open} anchorEl={anchorEl}>
        <ClickAwayListener onClickAway={handleClickAway}>
          <StyledPopperDiv>{popupContent}</StyledPopperDiv>
        </ClickAwayListener>
      </PopperUnstyled>
    </>
  );
};
export default SimplePopper;
