import * as React from 'react';
import styled from 'styled-components/macro';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { NewPassModal } from 'app/components/NewPassModal';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { CustomButton } from '../../components/Button';
import { setUserId } from 'services/authServices';
import { ProfilePayload } from 'models/authModels';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Hero } from 'app/components/Hero';
import { useMediaQuery } from '@mui/material';

const Button = styled.input`
  border: none;
  background: #5c36f3;
  color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  margin-bottom: 25px;
  width: 213.02px;
  height: 49.59px;
  margin: 0 10px;
  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const Text = styled.div`
  font-style: normal;
  font-weight: 350;
  font-size: 20px;
  line-height: 24px;
  color: #545454;
  margin: 10px 0;
  margin-right: 20px;
  flex-basis: 180px;
  @media (max-width: 767px) {
    flex-basis: auto;
  }
`;

const Line = styled.div`
  display: flex;
  margin: 18px 0;
  align-items: center;
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: baseline;
    margin-left: 20px;
  }
`;

const Input = styled.input<{
  edit?: boolean;
}>`
  width: 426.84px;
  height: 48.71px;
  left: 519.78px;
  top: 391.99px;
  background: #f5f5f5;
  // background: ${props => (props.edit ? '#f5f5f5' : '#a3a3a3')};
  border-radius: 30px;
  border: 0;
  padding: 0 20px;
  flex-basis: 500px;
  @media (max-width: 767px) {
    flex-basis: auto;
  }
  @media (max-width: 430px) {
    width: 300px;
  }
`;

const Container = styled.div`
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

export function AdminProfilePage() {
  const { t, i18n } = useTranslation();
  const { id: userId } = useParams();
  const [id, setId] = useState('');
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [error, setError] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [fiscal_id, setFiscalID] = useState('');
  const [country, setCountry] = useState('');
  const [contact_name, setContactName] = useState('');
  const [contact_number, setPhone] = useState('');
  const [email, setMail] = useState('');
  const [name_customer, setCompanyName] = useState('');
  const [invoice_address, setInvoiceAddress] = useState('');
  const [company_address, setCompanyAddress] = useState('');
  const [account_owner, SetAccountOwner] = useState('');
  const [customer, setCustomer] = useState('');
  const [zip_code, setZip] = useState('');
  const [isUploadedLogo, setIsUploadedLogo] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);

  const openCloseModal = () => {
    setOpen(!open);
  };

  const openCloseEdit = () => {
    setEdit(!edit);
  };

  const handleSubmit = event => {
    setSuccess(false);
    setIsConfirm(true);
    setError('');
    event.preventDefault();
    const payload: ProfilePayload = {
      name_customer,
      fiscal_id,
      country,
      email,
      contact_name,
      contact_number,
      invoice_address,
      company_address,
      account_owner,
      customer,
      zip_code,
    };
    setUserId(payload, id)
      .then(response => {
        if (response.status === 200) {
          setSuccess(true);
        } else if (response.status === 404) {
          setError('');
        }
      })
      .catch(error => {
        error.response?.data?.message === 'validation.message' &&
          setErrorMessage(error.response?.data?.errors[0].type);
        setError(error.response?.data?.message);
      });
  };

  useEffect(() => {
    const token = localStorage.getItem('caravela_access_token');
    const url = `${process.env.REACT_APP_BASE_URL}/user-id`;

    userId && setId(userId);
    axios
      .get(url, {
        params: {
          id: userId,
        },
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(response => {
        if (response.status === 200) {
          setContactName(response.data.contact_name);
          setFiscalID(response.data.fiscal_id);
          setCountry(response.data.country);
          setPhone(response.data.contact_number);
          setMail(response.data.email);
          setCompanyName(response.data.name_customer);
          setInvoiceAddress(response.data.invoice_address);
          setCompanyAddress(response.data.company_address);
          SetAccountOwner(response.data.account_owner);
          setCustomer(response.data.customer);
          setZip(response.data.zip_code);
        }
      })
      .catch(error => {
        console.log(error.response?.data);
      });
  }, []);

  return open === true ? (
    // <ChangePassModal openCloseModal={openCloseModal} />
    <NewPassModal openCloseModal={openCloseModal} email={email} />
  ) : (
    <div>
      <Helmet>
        <title>{t(translations.pages.profile.title)}</title>
        <meta name="description" content="My Roasterhub client profile" />
      </Helmet>
      <div
        className="d-flex flex-column"
        style={{ padding: '0 96px', justifyContent: 'space-between' }}
      >
        <div
          style={{
            textAlign: 'left',
            color: 'red',
            marginRight: '20px',
          }}
        >
          {t(translations.general.mandatory)}
        </div>
        <form onSubmit={handleSubmit}>
          <div className="d-flex justify-content-end w-100 mb-3">
            {success ? (
              <h1 style={{ color: 'green', marginRight: 'auto' }}>
                {t(translations.pages.profile.success)}
              </h1>
            ) : null}
            {error !== '' && error !== 'validation.message' ? (
              <h1 style={{ color: 'red', marginRight: 'auto' }}>
                {t(`${translations.errors_400[error]}`)}
              </h1>
            ) : null}
            {error === 'validation.message' ? (
              <h1 style={{ color: 'red', marginRight: 'auto' }}>
                {errorMessage === 'contact_name'
                  ? `${t(translations.errors_422.validation_required)}: ${t(
                      translations.pages.profile.username,
                    ).slice(0, -1)}`
                  : errorMessage === 'email'
                  ? `${t(translations.errors_422.validation_required)}: ${t(
                      translations.pages.profile.email,
                    ).slice(0, -1)}`
                  : errorMessage === 'name_customer'
                  ? `${t(translations.errors_422.validation_required)}: ${t(
                      translations.pages.profile.companyName,
                    ).slice(0, -1)}`
                  : errorMessage === 'customer'
                  ? `${t(translations.errors_422.validation_required)}: ${t(
                      translations.pages.profile.costumer,
                    ).slice(0, -1)}`
                  : t(translations.errors_422.validation_required)}
              </h1>
            ) : null}
            <Button
              style={{ background: '#33BA79' }}
              type="submit"
              value={t(translations.general.confirm)}
            />
          </div>
          <Container>
            <Line>
              <Text>{t(translations.general.logo) + ':'}</Text>
              <Hero
                id={id}
                isConfirm={isConfirm}
                setIsUploadedLogo={setIsUploadedLogo}
                isEdit
                isAdmin
              />
            </Line>
            <Line>
              <Text>{t(translations.pages.profile.username)}</Text>
              <Input
                edit={edit}
                onChange={e => setContactName(e.target.value)}
                value={contact_name}
              ></Input>
              <div
                style={{
                  color: 'red',
                  marginLeft: '10px',
                  marginBottom: '6px',
                }}
              >
                {' '}
                *
              </div>
            </Line>
            <Line>
              <Text>{t(translations.pages.profile.fiscalId)}</Text>
              <Input
                onChange={e => setFiscalID(e.target.value)}
                value={fiscal_id}
              ></Input>
            </Line>
            <Line>
              <Text>{t(translations.pages.profile.country)}</Text>
              <Input
                disabled
                onChange={e => setCountry(e.target.value)}
                value={country}
              ></Input>
              <Text style={{ marginLeft: '60px' }}>
                {t(translations.pages.profile.contact)}
              </Text>
              <Input
                edit={edit}
                onChange={e => setPhone(e.target.value)}
                // value={contact_name}
                value={contact_number}
              ></Input>
            </Line>
            <Line>
              <Text>{t(translations.pages.profile.email)}</Text>
              <Input
                onChange={e => setMail(e.target.value)}
                value={email}
              ></Input>
              <div
                style={{
                  color: 'red',
                  marginLeft: '10px',
                  marginBottom: '6px',
                }}
              >
                {' '}
                *
              </div>
              <Button
                style={{ marginLeft: '60px' }}
                type="button"
                value={t(translations.pages.profile.changePass)}
                onClick={openCloseModal}
              />
            </Line>
            <Line>
              <Text>{t(translations.pages.profile.companyName)}</Text>
              <Input
                onChange={e => setCompanyName(e.target.value)}
                value={name_customer}
              ></Input>
              <div
                style={{
                  color: 'red',
                  marginLeft: '10px',
                  marginBottom: '6px',
                }}
              >
                {' '}
                *
              </div>
            </Line>
            <Line>
              <Text>{t(translations.pages.profile.invoice)}</Text>
              <Input
                edit={edit}
                onChange={e => setInvoiceAddress(e.target.value)}
                value={invoice_address}
              ></Input>
            </Line>
            <Line>
              <Text>{t(translations.pages.profile.companyAddress)}</Text>
              <Input
                edit={edit}
                onChange={e => setCompanyAddress(e.target.value)}
                value={company_address}
              ></Input>
            </Line>
            <Line>
              <Text>{t(translations.pages.profile.accountOwner)}</Text>
              <Input
                onChange={e => SetAccountOwner(e.target.value)}
                value={account_owner}
              ></Input>
            </Line>
            <Line>
              <Text>{t(translations.pages.profile.costumer)}</Text>
              <Input
                onChange={e => setCustomer(e.target.value)}
                value={customer}
              ></Input>
              <div
                style={{
                  color: 'red',
                  marginLeft: '10px',
                  marginBottom: '6px',
                }}
              >
                {' '}
                *
              </div>
              <Text style={{ marginLeft: '60px' }}>
                {t(translations.pages.profile.zip)}
              </Text>
              <Input
                edit={edit}
                onChange={e => setZip(e.target.value)}
                value={zip_code}
              ></Input>
            </Line>
          </Container>
        </form>
      </div>
    </div>
  );
}
