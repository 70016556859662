import React from 'react';
import Select from 'react-select';

interface DropdownProps {
  list: any;
  defaultValue?: string;
  handleChange: (string) => void;
  value?: string;
  noMargin?: boolean;
  width?: boolean;
  placeholder?: string;
  isMulti?: boolean;
  isDisabled?: boolean;
}

const Dropdown = ({
  list,
  defaultValue,
  handleChange,
  value,
  noMargin,
  placeholder,
  width,
  isMulti,
  isDisabled,
}: DropdownProps) => {
  const selectProps = {
    styles: {
      control: (baseStyles, state) => ({
        ...baseStyles,
        background: '#F6F6F6',
        borderRadius: '30px',
        width: width === false ? '205.16px' : '400px',
        height: '40px',
        margin: noMargin === false ? '10px 10px' : '0px',
      }),
    },
    options: list,
    value: value ? { value: value, label: value } : value,
    placeholder: placeholder,
    onChange: handleChange,
  };
  return (
    <Select
      {...selectProps}
      isMulti={isMulti || false} // Agregar isMulti aquí
      isDisabled={isDisabled || false}
    />
  );
};

export default Dropdown;
