import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import styledComponent from 'styled-components/macro';
import { ThemeProvider } from '@mui/material';
import { useTheme } from 'styled-components';
import styled from 'styled-components/macro';
import { useEffect, useState } from 'react';
import { InputUnstyled } from '@mui/base';

const StyledHeadCell = styled(TableCell)`
  font-style: normal;
  font-weight: 325;
  font-size: 13px;
  line-height: 16px;
  border: 1px solid white;
  background-color: ${props => props.color} !important;
`;

const StyledTableCell = styled(TableCell)`
  align-items: center;

  font-style: normal;
  font-weight: 325;
  font-size: 13px;
  line-height: 16px;
  background: #f8f8f8;
  border: 1px solid white;
`;
const StyledTableRow = styled(TableRow)`
  border-bottom: 3px solid white;
  border-top: 3px solid white;
  th:first-child {
    border-radius: 35px 0px 0px 35px;
  }
  th:last-child {
    border-radius: 0px 35px 35px 0px;
  }

  td:first-child {
    border-radius: 35px 0px 0px 35px;
  }
  td:last-child {
    border-radius: 0px 35px 35px 0px;
  }
`;

const Add = styledComponent.div<{ backgroundColor?: string }>`
  background: ${props =>
    props.backgroundColor ? props.backgroundColor : '#F33636'}; 
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  height: 33px;
  cursor: pointer;

`;

const CustomTextField = styled(InputUnstyled)({
  '& input': {
    background: '#f9f9f9',
    borderRadius: '40px',
    borderColor: 'black',
    border: 'solid 1px black',
    width: '102px',
    height: '35px',
    padding: '0 10px',
  },
});

const Input = styled.input`
  width: 102px;
  height: 35px;
  left: 519.78px;
  top: 391.99px;
  background: #f9f9f9;
  border-radius: 40px;
  border-color: black;
  padding: 0 20px;
`;

interface QuantityProps {
  data: any;
  fullData: any;
  setTotal: (value: any) => void;
  setTotalShow: (value: any) => void;
  setBag: (value: any) => void;
  setBoxes: (value: any) => void;
  total: any;
  storedData: any;
  setStoredData: (value: any) => void;
  deletedContracts: any;
  setDeletedContracts: (value: any) => void;
  dataToBeSent: any;
  setDataToBeSent: (value: any) => void;
}

export function QuantityTable({
  data,
  fullData,
  setTotal,
  total,
  setTotalShow,
  setBoxes,
  setBag,
  storedData,
  setStoredData,
  deletedContracts,
  setDeletedContracts,
  dataToBeSent,
  setDataToBeSent,
}: QuantityProps) {
  const theme: any = useTheme();

  const { t } = useTranslation();

  useEffect(() => {
    let total = dataToBeSent
      .map(value => parseInt(value.quantity))
      .reduce((a, b) => a + b, 0);
    setTotalShow(total || 0);
    let bags = dataToBeSent
      .map(value => (value.type === 'Bags' ? parseInt(value.quantity) : 0))
      .reduce((a, b) => a + b, 0);
    setBag(bags || 0);
    let boxes = dataToBeSent
      .map(value => (value.type === 'Boxes' ? parseInt(value.quantity) : 0))
      .reduce((a, b) => a + b, 0);
    setBoxes(boxes || 0);
  }, []);

  const removeContract = contract => {
    let deletedContractsStored = JSON.parse(
      localStorage.getItem('deletedContracts') || '[]',
    );
    deletedContractsStored.push(contract);
    localStorage.setItem(
      'deletedContracts',
      JSON.stringify(deletedContractsStored),
    );
    setDeletedContracts(deletedContractsStored);
    let arr = dataToBeSent.filter(value => value.contrato !== contract);

    setDataToBeSent(arr);
    let total = arr
      .map(value => parseInt(value.quantity))
      .reduce((a, b) => a + b, 0);

    setTotalShow(total || 0);
    let bags = arr
      .map(value => (value.type === 'Bags' ? parseInt(value.quantity) : 0))
      .reduce((a, b) => a + b, 0);

    setBag(bags || 0);
    let boxes = arr
      .map(value => (value.type === 'Boxes' ? parseInt(value.quantity) : 0))
      .reduce((a, b) => a + b, 0);

    setBoxes(boxes || 0);
    localStorage.setItem('dataToBeSent', JSON.stringify(arr));
  };

  // const handleNumericInputChange = (
  //   contract,
  //   value,
  //   units,
  //   available_quantity,
  // ) => {
  //   const number = parseInt(value);
  //   if (!isNaN(number)) {
  //     setQuantity(contract, value, units, available_quantity);
  //   } else {
  //     setQuantity(contract, '', units, available_quantity); // Dejar el campo vacío
  //   }
  // };

  const handleNumericInputChange = (ico, value, units, available_quantity) => {
    const number = parseInt(value);
    if (!isNaN(number) && number !== 0) {
      setQuantity(ico, value, units, available_quantity);
    } else {
      setQuantity(ico, '', units, available_quantity);
    }
  };

  const setQuantity = (ico, value, type, available_quantity) => {
    if (!dataToBeSent.find(item => item.ico_f === ico) && value !== '') {
      dataToBeSent.push({
        ico_f: ico,
        quantity: value,
        ref_number: '',
        type: type,
        available_quantity: available_quantity,
      });
      let total = dataToBeSent
        .map(value => parseInt(value.quantity))
        .reduce((a, b) => a + b, 0);

      setTotalShow(total || 0);
      let bags = dataToBeSent
        .map(value => (value.type === 'Bags' ? parseInt(value.quantity) : 0))
        .reduce((a, b) => a + b, 0);

      setBag(bags || 0);
      let boxes = dataToBeSent
        .map(value => (value.type === 'Boxes' ? parseInt(value.quantity) : 0))
        .reduce((a, b) => a + b, 0);

      setBoxes(boxes || 0);
      setDataToBeSent(dataToBeSent);
    }
    if (dataToBeSent.find(item => item.ico_f === ico)) {
      const copy = [...dataToBeSent];
      let index = dataToBeSent.findIndex(item => item.ico_f === ico);
      dataToBeSent[index].quantity = value;
      dataToBeSent[index].available_quantity = available_quantity;
      if (
        dataToBeSent[index].ref_number === '' &&
        dataToBeSent[index].quantity === ''
      ) {
        dataToBeSent.splice(index, 1);
        copy.splice(index, 1);
      }
      let total = dataToBeSent
        .map(value => parseInt(value.quantity))
        .reduce((a, b) => a + b, 0);

      setTotalShow(total || 0);
      let bags = dataToBeSent
        .map(value => (value.type === 'Bags' ? parseInt(value.quantity) : 0))
        .reduce((a, b) => a + b, 0);

      setBag(bags || 0);
      let boxes = dataToBeSent
        .map(value => (value.type === 'Boxes' ? parseInt(value.quantity) : 0))
        .reduce((a, b) => a + b, 0);

      setBoxes(boxes || 0);
      setDataToBeSent(dataToBeSent);
    }
    localStorage.setItem('total', JSON.stringify(total));
    localStorage.setItem('dataToBeSent', JSON.stringify(dataToBeSent));
  };

  const setRefNumber = (ico, contract, value, type) => {
    let updatedData = [...dataToBeSent];
    let existingItem = updatedData.find(item => item.ico_f === ico);

    if (existingItem) {
      existingItem.ref_number = value;
      existingItem.contrato = contract;
    }
    setDataToBeSent(updatedData);
    localStorage.setItem('dataToBeSent', JSON.stringify(updatedData));
  };

  useEffect(() => {
    setStoredData(fullData);
    let array = [];
    let dContract = JSON.parse(
      localStorage.getItem('deletedContracts') || '[]',
    );

    dContract !== []
      ? setDeletedContracts(dContract)
      : localStorage.setItem('deletedContracts', JSON.stringify(array));
    let dSent = JSON.parse(localStorage.getItem('dataToBeSent') || '[]');

    dSent !== []
      ? setDataToBeSent(dSent)
      : localStorage.setItem('dataToBeSent', JSON.stringify(array));
  }, []);

  return (
    <ThemeProvider theme={theme}>
      {data
        ? data.map(item =>
            item.map(value => (
              <>
                <div className="d-flex align-items-center mt-5 mb-4">
                  <h1 style={{ color: theme.palette.primary.main }}>
                    {value[0].origin}
                  </h1>
                </div>
                <TableContainer>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                      <StyledTableRow
                        sx={{
                          '& th': {
                            color: theme.palette.secondary.contrastText,
                            backgroundColor: theme.palette.secondary.main,
                          },
                        }}
                      >
                        <StyledHeadCell>
                          {t(translations.table.contract)}
                        </StyledHeadCell>
                        <StyledHeadCell align="center">
                          {t(translations.table.ico)}
                        </StyledHeadCell>
                        <StyledHeadCell align="center">
                          {t(translations.table.marks)}
                        </StyledHeadCell>
                        <StyledHeadCell align="center">
                          {t(translations.table.av_date)}
                        </StyledHeadCell>
                        <StyledHeadCell align="center">
                          {t(translations.table.boxes_bags)}
                        </StyledHeadCell>
                        {/* <StyledHeadCell align="center">
                          {t(translations.table.net_weight)}
                        </StyledHeadCell> */}
                        <StyledHeadCell align="center">
                          {' '}
                          {t(translations.table.quantity)}
                        </StyledHeadCell>
                        <StyledHeadCell align="center">
                          <div style={{ display: 'flex' }}>
                            <div> {t(translations.table.quantity_o)}</div>
                            {''} <div>*</div>
                          </div>
                        </StyledHeadCell>
                        <StyledHeadCell align="center">
                          {t(translations.table.reference)}
                        </StyledHeadCell>
                        {/* <StyledHeadCell
                          color={theme.palette.primary.main}
                          style={{ color: theme.palette.primary.contrastText }}
                          align="center"
                        >
                          {t(translations.table.p_progress)}
                        </StyledHeadCell> */}
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      {data.map(item =>
                        item.map(tableData =>
                          tableData.map(
                            info =>
                              info.origin === value[0].origin &&
                              !deletedContracts
                                .toString()
                                .includes(info.contract) && (
                                <StyledTableRow key={info.contract}>
                                  <StyledTableCell align="center">
                                    {info.contract}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {info.ico}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {info.mark}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {info.available_date}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    <div
                                      style={{
                                        fontWeight: '400',
                                      }}
                                    >
                                      {info.units === 'Bags'
                                        ? `0/${
                                            info.available_quantity +
                                            info.released_quantity
                                          }`
                                        : `${
                                            info.available_quantity +
                                            info.released_quantity
                                          }/0`}
                                    </div>
                                  </StyledTableCell>
                                  {/* <StyledTableCell align="center">
                                    {info.net_weight} lbs
                                  </StyledTableCell> */}
                                  <StyledTableCell align="center">
                                    <div
                                      style={{
                                        fontWeight: '400',
                                      }}
                                    >{`(${info.released_quantity}) Released`}</div>
                                    <div
                                      style={{
                                        color: theme.palette.success.main,
                                        fontWeight: '400',
                                      }}
                                    >{`(${info.available_quantity}) Available`}</div>
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {/* <FileDownloadIcon style={{ color: '#1989B2' }} /> */}
                                    <CustomTextField
                                      value={dataToBeSent
                                        .map(value =>
                                          value.ico === info.ico
                                            ? value.quantity
                                            : '',
                                        )
                                        .find(item => item !== '')}
                                      type="text"
                                      style={{ appearance: 'textfield' }}
                                      onChange={e => {
                                        const inputValue = e.target.value;
                                        const numericValue = inputValue.replace(
                                          /[^0-9]/g,
                                          '',
                                        );
                                        handleNumericInputChange(
                                          info.ico,
                                          numericValue,
                                          info.units,
                                          info.available_quantity,
                                        );
                                      }}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    <Input
                                      type="text"
                                      inputMode="numeric"
                                      pattern="[0-9]*"
                                      min="1"
                                      onBlur={e =>
                                        setRefNumber(
                                          info.ico,
                                          info.contract,
                                          e.target.value,
                                          info.type,
                                        )
                                      }
                                      defaultValue={dataToBeSent
                                        .map(value =>
                                          value.contrato === info.contract
                                            ? value.ref_number
                                            : '',
                                        )
                                        .find(item => item !== '')}
                                    />
                                  </StyledTableCell>
                                  {/* <StyledTableCell align="center">
                                    <Add
                                      backgroundColor={theme.palette.error.main}
                                      onClick={() =>
                                        removeContract(info.contract)
                                      }
                                    >
                                      {t(translations.table.remove)}
                                    </Add>
                                  </StyledTableCell> */}
                                </StyledTableRow>
                              ),
                          ),
                        ),
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )),
          )
        : null}
    </ThemeProvider>
  );
}
