import { styledTheme } from '../../../styles/theme';
import { DownloadIcon } from 'app/assets/SVG/color_icons';
import ControlledDropdown from 'app/components/ControlledDropdown';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { MainContainer, Title, Clean, Heading } from './styles';
import { SalesOfferingTable } from 'app/components/SalesOfferingTable';
import { Alert, IconButton, Snackbar, ThemeProvider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';

import { useState, useEffect } from 'react';
import {
  editCoffe,
  exportCoffes,
  getCoffees,
  importCoffes,
} from 'services/salesServices';
import { LoadingPage } from '../LoadingPage';
import Tooltip from 'app/components/Tooltip';
import { ErrorsModal } from 'app/components/ErrorsModal';
import { useTheme } from 'styled-components';
import MaterialTooltip from '@mui/material/Tooltip';

const emptyFilters = {
  origin: '',
  mark: '',
  packaging: '',
  warehouse: '',
  category: '',
  process: '',
};

const CleanFilter = () => (
  <svg
    width="25px"
    height="25px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 15L21 21M21 15L15 21M10 21V14.6627C10 14.4182 10 14.2959 9.97237 14.1808C9.94787 14.0787 9.90747 13.9812 9.85264 13.8917C9.7908 13.7908 9.70432 13.7043 9.53137 13.5314L3.46863 7.46863C3.29568 7.29568 3.2092 7.2092 3.14736 7.10828C3.09253 7.01881 3.05213 6.92127 3.02763 6.81923C3 6.70414 3 6.58185 3 6.33726V4.6C3 4.03995 3 3.75992 3.10899 3.54601C3.20487 3.35785 3.35785 3.20487 3.54601 3.10899C3.75992 3 4.03995 3 4.6 3H19.4C19.9601 3 20.2401 3 20.454 3.10899C20.6422 3.20487 20.7951 3.35785 20.891 3.54601C21 3.75992 21 4.03995 21 4.6V6.33726C21 6.58185 21 6.70414 20.9724 6.81923C20.9479 6.92127 20.9075 7.01881 20.8526 7.10828C20.7908 7.2092 20.7043 7.29568 20.5314 7.46863L17 11"
      stroke="#000000"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export function SalesOfferingsPage() {
  const [loading, setLoading] = useState<boolean>(true);
  //const [coffees, setCoffees] = useState<any[]>([]);
  const [coffees, setCoffees] = useState<{
    importerId: number;
    coffees: any[];
  }>({
    importerId: 0,
    coffees: [],
  });
  console.log(coffees);
  const [filters, setFilters] = useState<any>(emptyFilters);
  const [tooltipOpen, setTooltipOpen] = useState<any>(false);
  const [error, setError] = useState<any>('');
  const [errorsExcel, setErrorsExcel] = useState<any>('');
  const [uploading, setUploading] = useState<boolean>(false);

  const SortErrrors = (a, b) => a.row - b.row;

  const { t } = useTranslation();
  const theme: any = useTheme();

  const [snackBar, setSnackBar] = useState({
    open: false,
    severity: 'success',
    message: 'Succesfully edited',
    onClose: () => {},
  });

  const [visibleClient, setVisibleClient] = useState('');

  const [filterOptions, setFiltersOptions] = useState<any>({
    origin: undefined,
    mark: undefined,
    packaging: undefined,
    warehouse: undefined,
    category: undefined,
    process: undefined,
  });
  function s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }

  const closeModal = () => {
    setErrorsExcel('');
  };

  const handleFileDownload = e => {
    exportCoffes().then(response => {
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: response.headers['content-type'],
        }),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Coffees-${new Date().toUTCString()}.xlsx`);
      document.body.appendChild(link);
      link.click();
      setSnackBar({
        open: true,
        severity: 'success',
        message: t(translations.general.file_download_success),
        onClose: () => {},
      });
    });
  };

  // const handleFileUpload = e => {
  //   setLoading(true);
  //   importCoffes(e.target.files[0])
  //     .then(response => {
  //       if (response.status === 200) {
  //         getCoffeesWithFilters(filters);
  //         setSnackBar({
  //           open: true,
  //           severity: 'success',
  //           message: t(translations.general.file_upload_success),
  //           onClose: () => {},
  //         });
  //       } else {
  //         setLoading(false);
  //         setSnackBar({
  //           open: true,
  //           severity: 'error',
  //           message: t(translations.general.file_upload_error),
  //           onClose: () => {},
  //         });
  //       }
  //     })
  //     .catch(error => {
  //       setLoading(false);
  //       setSnackBar({
  //         open: true,
  //         severity: 'error',
  //         message:
  //           // t(translations.general.unexpected_request_error) + ': ' + error,
  //           // message:
  //           t(translations.general.unexpected_request_error),
  //         onClose: () => {},
  //       });
  //     });
  // };

  const handleFileUpload = e => {
    setUploading(true);
    setLoading(true);
    const fileInput = e.target;
    const file = fileInput.files[0];
    const resetFileInput = () => {
      fileInput.value = null;
    };

    if (file) {
      importCoffes(file)
        .then(response => {
          if (response.status === 200) {
            getCoffeesWithFilters(filters);
            setSnackBar({
              open: true,
              severity: 'success',
              message: t(translations.general.file_upload_success),
              onClose: () => {},
            });
          } else {
            setSnackBar({
              open: true,
              severity: 'error',
              message: t(translations.general.file_upload_error),
              onClose: () => {},
            });
          }
        })
        .catch(error => {
          // setErrorsExcel(error.response?.data);
          console.log(error.response?.data.length);
          error.response?.data.length > 0 &&
            setErrorsExcel(error.response?.data.sort(SortErrrors));
          setSnackBar({
            open: true,
            severity: 'error',
            message: t(translations.general.unexpected_request_error),
            onClose: () => {},
          });
        })
        .finally(() => {
          setLoading(false);
          setUploading(false);
          resetFileInput();
        });
    } else {
      setLoading(false);
      setUploading(false);
    }
  };

  const getCoffeesFirstCall = () => {
    getCoffees(filters)
      .then(response => {
        if (response.status === 200) setCoffees(response.data);
        //first endpoint call, lets construct filter options
        const originsSet = new Set(undefined);
        const markSet = new Set(undefined);
        const packagingSet = new Set(undefined);
        const categorySet = new Set(undefined);
        const warehouseSet = new Set(undefined);
        const processSet = new Set(undefined);

        response.data.coffees.forEach(coffe => {
          warehouseSet.add(coffe.warehouse);
          markSet.add(coffe.mark);
          originsSet.add(coffe.origin);
          categorySet.add(coffe.category);
          packagingSet.add(coffe.packaging);
          processSet.add(coffe.process);
        });

        setFiltersOptions({
          origin: Array.from(originsSet),
          mark: Array.from(markSet),
          packaging: Array.from(packagingSet),
          warehouse: Array.from(warehouseSet),
          category: Array.from(categorySet),
          process: Array.from(processSet),
        });
        setLoading(false);
      })
      .catch(error => {
        setError(error.response?.data?.message);
        console.log(error);
        setLoading(false);
      });
  };

  const getCoffeesWithFilters = (filters = {}) => {
    getCoffees(filters)
      .then(response => {
        if (response.status === 200) setCoffees(response.data);
        setLoading(false);
      })
      .catch(error => {
        setError(error.response?.data?.message);
        console.log(error.response?.data);
      });
  };
  useEffect(() => getCoffeesFirstCall(), []);

  useEffect(() => getCoffeesWithFilters(filters), [filters]);

  return errorsExcel === '' ? (
    <>
      <ThemeProvider theme={theme}>
        <Helmet>
          <title>Offerings</title>
          <meta name="description" content="Roasterhub offerings" />
        </Helmet>
        <MainContainer>
          <Heading>
            {/* <Title>{t(translations.general.offering)}</Title> */}
            <div
              style={{
                display: 'flex',
                gap: '1em',
                height: '35px',
                margin: '0px 10px 10px',
                justifyContent: 'space-between',
              }}
            >
              <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                open={snackBar.open}
                autoHideDuration={6000}
                onClose={snackBar.onClose}
                action={
                  <Alert
                    onClose={() => setSnackBar({ ...snackBar, open: false })}
                    severity={snackBar.severity as any}
                    sx={{ width: '100%' }}
                  >
                    {snackBar.message}
                  </Alert>
                }
              />

              <ControlledDropdown
                emptyValue={t(translations.table.origin)}
                list={filterOptions.origin?.map(val => ({
                  value: val,
                  label: val,
                }))}
                value={filters.origin}
                handleChange={e => {
                  setFilters({ ...filters, origin: e.target.value });
                  setLoading(true);
                }}
                withEmptyValue
              />
              <ControlledDropdown
                emptyValue={t(translations.table.mark)}
                list={filterOptions.mark?.map(val => ({
                  value: val,
                  label: val,
                }))}
                value={filters.mark}
                handleChange={e => {
                  setFilters({ ...filters, mark: e.target.value });
                  setLoading(true);
                }}
                withEmptyValue
              />
              <ControlledDropdown
                emptyValue={t(translations.table.pack)}
                list={filterOptions.packaging?.map(val => ({
                  value: val,
                  label: val,
                }))}
                value={filters.packaging}
                handleChange={e => {
                  setFilters({ ...filters, packaging: e.target.value });
                  setLoading(true);
                }}
                withEmptyValue
              />
              {/* <ControlledDropdown
              emptyValue={t(translations.table.category)}
              list={filterOptions.category?.map(val => ({
                value: val,
                label: val,
              }))}
              value={filters.category}
              handleChange={e => {
                setFilters({ ...filters, category: e.target.value });
                setLoading(true);
              }}
              withEmptyValue
            /> */}
              <ControlledDropdown
                emptyValue={t(translations.table.location)}
                list={filterOptions.warehouse?.map(val => ({
                  value: val,
                  label: val,
                }))}
                value={filters.warehouse}
                handleChange={e => {
                  setFilters({ ...filters, warehouse: e.target.value });
                  setLoading(true);
                }}
                withEmptyValue
              />
              {/* <ControlledDropdown
              emptyValue={t(translations.table.process)}
              list={filterOptions.process?.map(val => ({
                value: val,
                label: val,
              }))}
              value={filters.process}
              handleChange={e => {
                setFilters({ ...filters, process: e.target.value });
                setLoading(true);
              }}
              withEmptyValue
            /> */}
              <MaterialTooltip
                title={t(translations.tooltips.clean)}
                placement="right"
              >
                <IconButton
                  onClick={() => {
                    setFilters(emptyFilters);
                    setLoading(true);
                  }}
                >
                  <CleanFilter />
                </IconButton>
              </MaterialTooltip>
              <div
                style={{
                  display: 'flex',
                  gap: '10px',
                  justifySelf: 'flex-end',
                }}
              >
                <Tooltip
                  visible={tooltipOpen}
                  label={t(translations.general.offerings_upload_conditions)}
                  handleClickAway={() => setTooltipOpen(false)}
                  withTail={false}
                >
                  <div style={{ cursor: 'pointer' }}>
                    <input
                      type="file"
                      onChange={handleFileUpload}
                      hidden
                      id="upload-file"
                    />
                    <label
                      htmlFor="upload-file"
                      onMouseOver={() => setTooltipOpen(true)}
                      onMouseOut={() => setTooltipOpen(false)}
                    >
                      <DownloadIcon
                        color={theme.palette.primary.main}
                        pointingUp
                      />
                    </label>
                  </div>
                </Tooltip>
                <div style={{ cursor: 'pointer' }}>
                  <input
                    type="button"
                    onClick={handleFileDownload}
                    hidden
                    id="download-file"
                  />
                  <label htmlFor="download-file">
                    <DownloadIcon
                      color={theme.palette.secondary.main}
                      arrowColor={theme.palette.secondary.contrastText}
                    />
                  </label>
                </div>
              </div>
            </div>
          </Heading>
          {loading ? (
            <div
              style={{
                // transform: 'scale(0.2)',
                // marginTop: '-200px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <LoadingPage />
              {uploading ? (
                <div> {t(translations.general.uploading)} </div>
              ) : null}
            </div>
          ) : error === '' ? (
            <SalesOfferingTable
              coffees={coffees.coffees}
              importerId={coffees.importerId}
              visibleClient={visibleClient}
              setVisibleClient={setVisibleClient}
              onEdit={newCoffeData => {
                editCoffe({
                  ico_id: newCoffeData.ico_id.value,
                  url: newCoffeData.url.value,
                  quality: newCoffeData.quality.value,
                  offering_price: newCoffeData.offering_price.value,
                  notes: newCoffeData.notes.value,
                  visible_for_clients: visibleClient,
                  //category: newCoffeData.category.value,
                  process: newCoffeData.process.value,
                })
                  .then(response => {
                    if (response.status === 200) {
                      console.log(newCoffeData);
                      setCoffees(prevState => ({
                        ...prevState,
                        coffees: prevState.coffees.map(coffe => {
                          if (
                            coffe.ico_id.trim() ===
                            newCoffeData.ico_id.value.trim()
                          ) {
                            return {
                              ...coffe,
                              ico_id: newCoffeData.ico_id.value,
                              url: newCoffeData.url.value,
                              quality: newCoffeData.quality.value,
                              offering_price: newCoffeData.offering_price.value,
                              //category: newCoffeData.category.value,
                              process: newCoffeData.process.value,
                              notes: newCoffeData.notes.value,
                              visible_for_clients:
                                newCoffeData.visible_for_clients.value,
                              //variety: newCoffeData.variety.value,
                              //certification: newCoffeData.certification.value,
                            };
                          } else {
                            return coffe;
                          }
                        }),
                      }));

                      setSnackBar({
                        open: true,
                        severity: 'success',
                        message: `${t(translations.general.coffee)} ${
                          newCoffeData.ico_id.value
                        } ${t(translations.general.edited)}`,
                        onClose: () => {},
                      });
                    } else
                      setSnackBar({
                        open: true,
                        severity: 'error',
                        message: t(
                          translations.general.unexpected_error_response,
                        ),
                        onClose: () => {},
                      });
                  })
                  .catch(e =>
                    setSnackBar({
                      open: true,
                      severity: 'error',
                      message: t(translations.general.unexpected_request_error),
                      onClose: () => {},
                    }),
                  );
              }}
            />
          ) : (
            <h3
              style={{
                color: 'gray',
                display: 'flex',
                justifyContent: 'center',
                marginTop: '70px',
                textAlign: 'center',
              }}
            >
              {t(`${translations.errors_400[error]}`)}
            </h3>
          )}
        </MainContainer>
      </ThemeProvider>
    </>
  ) : (
    <ErrorsModal errors={errorsExcel} closeModal={closeModal} />
  );
}
