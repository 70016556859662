import * as React from 'react';
import { useEffect, useState } from 'react';
import { GroupedTable } from 'app/components/GroupedTable';
import { Helmet } from 'react-helmet-async';
import Dropdown from 'app/components/Dropdown';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import CircularProgress from '@mui/material/CircularProgress';
import { IconButton, useMediaQuery } from '@mui/material';
import styled from 'styled-components/macro';
import { LoadingPage } from '../LoadingPage';
import { getReports } from 'services/reportServices';
import { GroupedMobileTable } from 'app/components/GroupedMobileTable';
import { isMemo } from 'react-is';
import FilterModal from 'app/components/FilterModal';
import { getImpersonateData } from 'services/authServices';
import Tooltip from '@mui/material/Tooltip';
import { Hero } from 'app/components/Hero';

const StyledButton = styled.button`
  background-color: #4f4e24;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  font-size: 16px;
`;

const Search = styled.div`
  // background: #f6f6f6;
  // border-radius: 30px;
  // width: 205.16px;
  // height: 40px;
  // margin: 10px 10px;
`;

const Input = styled.input`
  background: #f6f6f6;
  border-radius: 30px;
  width: 205.16px;
  height: 40px;
  margin: 10px 10px;
  outline: none;
  padding: 10px;
`;

const CleanFilter = () => (
  <svg
    width="19px"
    height="19px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 15L21 21M21 15L15 21M10 21V14.6627C10 14.4182 10 14.2959 9.97237 14.1808C9.94787 14.0787 9.90747 13.9812 9.85264 13.8917C9.7908 13.7908 9.70432 13.7043 9.53137 13.5314L3.46863 7.46863C3.29568 7.29568 3.2092 7.2092 3.14736 7.10828C3.09253 7.01881 3.05213 6.92127 3.02763 6.81923C3 6.70414 3 6.58185 3 6.33726V4.6C3 4.03995 3 3.75992 3.10899 3.54601C3.20487 3.35785 3.35785 3.20487 3.54601 3.10899C3.75992 3 4.03995 3 4.6 3H19.4C19.9601 3 20.2401 3 20.454 3.10899C20.6422 3.20487 20.7951 3.35785 20.891 3.54601C21 3.75992 21 4.03995 21 4.6V6.33726C21 6.58185 21 6.70414 20.9724 6.81923C20.9479 6.92127 20.9075 7.01881 20.8526 7.10828C20.7908 7.2092 20.7043 7.29568 20.5314 7.46863L17 11"
      stroke="#000000"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export function ReportsPage() {
  const [coffes, setCoffes] = useState<any[]>();
  const [warehouse, setWarehouse] = useState<any[]>();
  const [contract, setContract] = useState('');
  const [origin, setOrigin] = useState<any[]>();
  const [loading, setLoading] = useState<Boolean>(false);
  const [originSelected, setOriginSelected] = useState<any>('');
  const [warehouseSelected, setWarehouseSelected] = useState<any>('');
  const [error, setError] = useState<any>('');
  const [loadingData, setLoadingData] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDownload, setIsDownload] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [originalRole, setOriginalRole] = useState('');

  const isMobile = useMediaQuery('(max-width:767px)');

  const contractInputRef = React.useRef<HTMLInputElement | null>(null);

  let hash = {};

  const clean = () => {
    setLoadingData(true);
    setError('');
    setOriginSelected('');
    setWarehouseSelected('');
    setContract('');
    if (contractInputRef.current) {
      contractInputRef.current.value = '';
    }
    getReports()
      .then(response => {
        if (response.status === 200) {
          response.data.length === 0 &&
            setError(t(translations.general.no_search));
          formatCoffe(response.data);
          setOrigin(
            response.data.map(item => ({
              value: item.origin,
              label: item.origin,
            })),
          );
          setWarehouse(
            response.data.map(item => ({
              value: item.warehouse,
              label: item.warehouse,
            })),
          );
          setLoadingData(false);
        }
      })
      .catch(error => {
        // error.response?.data?.message === 'No data' &&
        setError(error.response?.data?.message);
        setLoadingData(false);
        console.log(error);
      });
  };

  const formatCoffe = (coffee: any) => {
    // let groupingByWarehouse = Object.values(
    //   coffee.reduce(
    //     (acc, current) => {
    //       if (current.warehouse == 'Afloat') acc.afloats.push(current);
    //       else if (current.warehouse === 'Contracted')
    //         acc.contracted.push(current);
    //       else acc.warehouses.push(current);
    //       return acc;
    //     },
    //     { warehouses: [], afloats: [], contracted: [] },
    //   ),
    // );
    // let finalArray;
    // let coffeesFinal: any = [];
    // for (let i = 0; i < groupingByWarehouse.length; i++) {
    //   let arr: any = groupingByWarehouse[i];
    //   finalArray = Object.values(
    //     arr.reduce((acc, current) => {
    //       acc[current.origin + current.warehouse] =
    //         acc[current.origin + current.warehouse] ?? [];
    //       acc[current.origin + current.warehouse].push(current);
    //       return acc;
    //     }, {}),
    //   );
    //   finalArray = finalArray.sort((arrCountryA, arrCountryB) => {
    //     return arrCountryA[0].origin.localeCompare(arrCountryB[0].origin);
    //   }); // order by countries
    //   coffeesFinal.push(finalArray);
    // }
    // setCoffes(coffeesFinal);

    let groupingViaCommonProperty = Object.values(
      coffee.reduce((acc, current) => {
        acc[current.warehouse] = acc[current.warehouse] ?? [];
        acc[current.warehouse].push(current);
        return acc;
      }, {}),
    );

    setCoffes(groupingViaCommonProperty);
    // setLoading(false);
  };

  const searchOrigin = (selected: any) => {
    setOriginSelected(selected.value);
  };

  const searchWarehouse = (selected: any) => {
    setWarehouseSelected(selected.value);
  };

  const search = (first: boolean) => {
    first === false && setLoadingData(true);
    first === true && setLoading(true);
    // setLoading(!loading);
    setError('');
    getReports(contract, originSelected, warehouseSelected)
      .then(response => {
        if (response.status === 200) {
          response.data.length === 0 &&
            setError(t(translations.general.no_search));
          formatCoffe(response.data);
          setOrigin(
            response.data.map(item => ({
              value: item.origin,
              label: item.origin,
            })),
          );
          setWarehouse(
            response.data.map(item => ({
              value: item.warehouse,
              label: item.warehouse,
            })),
          );
          // setActualPage(response.data.meta.current_page);
          // setPages(response.data.meta.last_page);
          first === false && setLoadingData(false);
          first === true && setLoading(false);
        }
      })
      .catch(error => {
        // error.response?.data?.message === 'No data' &&
        setError(error.response?.data?.message);
        first === false && setLoadingData(false);
        first === true && setLoading(false);
        // setLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    getImpersonateData()
      .then(response => {
        if (response.status === 200) {
          // response.data.Impersonate === 0 ? false : true;
          setOriginalRole(response.data.original_role);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    search(true);
  }, []);

  useEffect(() => {
    search(false);
  }, [isSearching]);

  const { t } = useTranslation();

  return (
    <>
      <div style={{ padding: !isMobile ? '35px' : '0px' }}>
        <Helmet>
          <title>Reports</title>
          <meta name="description" content="My Roasterhub order" />
        </Helmet>
        {loading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '200px',
            }}
          >
            <LoadingPage />
          </div>
        ) : (
          <div>
            <div
              className="d-flex "
              style={{
                padding: !isMobile ? '0 80px' : '0 15px',
                flexDirection: 'column',
              }}
            >
              <div
                className="d-flex"
                style={{
                  justifyContent: 'center',
                  flexDirection: !isMobile ? 'row' : 'column',
                  alignItems: 'center',
                }}
              >
                <div className="d-flex justify-content-start w-100">
                  <Hero
                    title={t(translations.pages.reports.title)}
                    subtitle={t(translations.pages.reports.description)}
                  />
                </div>
              </div>
              {!isMobile ? (
                <>
                  <div className="d-flex align-items-center">
                    <div style={{ marginRight: '10px' }}>
                      <Dropdown
                        handleChange={searchOrigin}
                        defaultValue="Origin"
                        value={originSelected}
                        list={
                          origin &&
                          origin.filter(o =>
                            hash[o.value] ? false : (hash[o.value] = true),
                          )
                        }
                      />
                    </div>
                    <Dropdown
                      handleChange={searchWarehouse}
                      defaultValue="Warehouse"
                      value={warehouseSelected}
                      list={
                        warehouse &&
                        warehouse.filter(o =>
                          hash[o.value] ? false : (hash[o.value] = true),
                        )
                      }
                    />
                    <Search>
                      <div className="d-flex align-items-center">
                        <Input
                          onChange={e => setContract(e.target.value)}
                          ref={contractInputRef}
                          type="text"
                          placeholder={t(translations.table.contract)}
                        />
                        <div style={{ marginLeft: 'auto', cursor: 'pointer' }}>
                          {loadingData ? (
                            <CircularProgress color="primary" />
                          ) : (
                            <IconButton
                              onClick={() => search(false)}
                              aria-label="search"
                            >
                              <SearchIcon />
                            </IconButton>
                          )}
                        </div>
                      </div>
                    </Search>
                    <Tooltip
                      title={t(translations.tooltips.clean)}
                      placement="right"
                    >
                      <IconButton onClick={clean}>
                        <CleanFilter />
                      </IconButton>
                    </Tooltip>
                  </div>
                </>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginRight: '0px',
                  }}
                >
                  <StyledButton onClick={() => setIsModalOpen(true)}>
                    {t(translations.general.filter)}
                  </StyledButton>
                </div>
              )}
              {error === '' ? (
                loadingData ? (
                  <div
                    style={{
                      // transform: 'scale(0.2)',
                      minWidth: !isMobile ? 700 : 0,
                      // marginTop: '-200px',
                    }}
                  >
                    <LoadingPage />
                    {isDownload ? (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          textAlign: 'center',
                        }}
                      >
                        <h5> {t(translations.general.downloading)}</h5>
                      </div>
                    ) : null}
                  </div>
                ) : !isMobile ? (
                  <GroupedTable
                    coffes={coffes}
                    page={'report'}
                    loading={loading}
                    setLoading={setLoading}
                    setLoadingData={setLoadingData}
                    setIsDownload={setIsDownload}
                    originalRole={originalRole}
                    setError={setError}
                  />
                ) : (
                  <GroupedMobileTable
                    coffes={coffes}
                    page={'report'}
                    loading={loading}
                    setLoading={setLoading}
                    setLoadingData={setLoadingData}
                    setIsDownload={setIsDownload}
                    setError={setError}
                  />
                )
              ) : (
                <h3
                  style={{
                    color: 'gray',
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '70px',
                    textAlign: 'center',
                  }}
                >
                  {error === t(translations.general.no_search)
                    ? error
                    : t(`${translations.errors_400[error]}`)}
                </h3>
              )}
              <FilterModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                originOptions={
                  origin &&
                  origin.filter(o =>
                    hash[o.value] ? false : (hash[o.value] = true),
                  )
                }
                warehouseOptions={
                  warehouse &&
                  warehouse.filter(o =>
                    hash[o.value] ? false : (hash[o.value] = true),
                  )
                }
                onSearch={({ originSelected, warehouseSelected, contract }) => {
                  // Handle search logic here
                  setOriginSelected(originSelected);
                  setWarehouseSelected(warehouseSelected);
                  setContract(contract);
                  setIsSearching(!isSearching);
                }}
                clean={clean}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}
