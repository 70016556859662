import Dropdown from 'app/components/Dropdown';
import { MyPositionTables } from 'app/components/MyPositionTable';
import { MyPositionTablesPreShipment } from 'app/components/MyPositionTablePreShipment';
import { translations } from 'locales/translations';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { resetPass } from 'services/authServices';
import { getSample } from 'services/sampleServices';
import styled, { ThemeConsumer, useTheme } from 'styled-components/macro';
import { LoadingPage } from '../LoadingPage';
import SearchIcon from '@mui/icons-material/Search';
import { useMediaQuery, IconButton } from '@mui/material';
import { MyPositionTableMobile } from 'app/components/MyPositionTableMobile';
import FilterModalPosition from 'app/components/FilterModalPosition';
import Tooltip from '@mui/material/Tooltip';

const StyledButton = styled.button`
  margin-right: 20px;
  background-color: #1989b2;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  font-size: 16px;
`;

const CleanFilter = () => (
  <svg
    width="19px"
    height="19px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 15L21 21M21 15L15 21M10 21V14.6627C10 14.4182 10 14.2959 9.97237 14.1808C9.94787 14.0787 9.90747 13.9812 9.85264 13.8917C9.7908 13.7908 9.70432 13.7043 9.53137 13.5314L3.46863 7.46863C3.29568 7.29568 3.2092 7.2092 3.14736 7.10828C3.09253 7.01881 3.05213 6.92127 3.02763 6.81923C3 6.70414 3 6.58185 3 6.33726V4.6C3 4.03995 3 3.75992 3.10899 3.54601C3.20487 3.35785 3.35785 3.20487 3.54601 3.10899C3.75992 3 4.03995 3 4.6 3H19.4C19.9601 3 20.2401 3 20.454 3.10899C20.6422 3.20487 20.7951 3.35785 20.891 3.54601C21 3.75992 21 4.03995 21 4.6V6.33726C21 6.58185 21 6.70414 20.9724 6.81923C20.9479 6.92127 20.9075 7.01881 20.8526 7.10828C20.7908 7.2092 20.7043 7.29568 20.5314 7.46863L17 11"
      stroke="#000000"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const Tab = styled.div<{ state: string }>`
  background-color: ${props =>
    props.state === 'active' ? '#ffffff' : '#33BA79'};
  color: ${props => (props.state === 'active' ? '#000000' : '#F8F8F8')};
  border-radius: 30px;
  border: 4px solid #33ba79;
  width: 245px;
  height: 38px;
  display: flex;
  justify-content: center;
  margin: 10px;
  cursor: ${props => (props.state === 'active' ? 'default' : 'pointer')};
  transition: all 0.5s ease-in-out;
  margin-left: -40px;
`;

const SubTitle = styled.div`
  font-style: normal;
  font-weight: 350;
  font-size: 30px;
  line-height: 36px;
`;

interface MyPositionPageProps {
  errorInfo: any;
  setErrorInfo: (value: any) => void;
}

export function MyPositionPage({
  errorInfo,
  setErrorInfo,
}: MyPositionPageProps) {
  const { t } = useTranslation();
  const theme: any = useTheme();

  const ReceivedOptions = [
    { value: '1', label: t(translations.general.received) },
    { value: '0', label: t(translations.general.not_received) },
    { value: '', label: 'In Process' },
  ];

  const ScoreOptions = [
    { value: '1', label: t(translations.general.accepted) },
    { value: '0', label: t(translations.general.declined) },
    { value: '', label: 'In Process' },
  ];

  const [coffees, setCoffees] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [originFilter, setOriginFilter] = useState<any>(0);
  const [warehouseFilter, setWarehouseFilter] = useState<any>(0);
  const [requestList, setRequestList] = useState<any[]>([]);

  const [origins, setOrigins] = useState<any[]>([]);
  const [sample, setSample] = useState<any[]>([]);
  const [warehouses, setWarehouses] = useState<any[]>([]);
  const [originSelected, setOriginSelected] = useState('');
  const [sampleSelected, setSampleSelected] = useState('');
  const [warehouseSelected, setWarehouseSelected] = useState('');
  const [receivedSelected, setReceivedSelected] = useState('');
  const [resultSelected, setResultSelected] = useState('');
  const [coffeRaw, setCoffeRaw] = useState<any[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  const isMobile = useMediaQuery('(max-width:767px)');

  let hash = {};

  useEffect(() => {
    search();
  }, [isSearching]);

  const getCoffeesWithFiltersFirstCall = () => {
    setErrorInfo('');
    setOriginSelected('');
    setWarehouseSelected('');
    setReceivedSelected('');
    setResultSelected('');
    setSampleSelected('');
    getSample()
      .then(response => {
        if (response.status === 200) {
          const originsSet = new Set(undefined);
          const warehouseSet = new Set(undefined);
          const typleSample = new Set(undefined);
          setCoffeRaw(response.data);
          formatCoffe(response.data);
          response.data.forEach(coffe => {
            warehouseSet.add(coffe.warehouse);
            originsSet.add(coffe.origin);
            typleSample.add(coffe.sample_type);
          });
          setWarehouses(Array.from(warehouseSet));
          setOrigins(Array.from(originsSet));
          setSample(Array.from(typleSample));
          setLoading(false);
        }
      })
      .catch(error => {
        setErrorInfo(error.response?.data?.message);
        setLoading(false);
        console.log(error);
      });
  };

  const search = () => {
    setErrorInfo('');
    const filteredCoffees = coffeRaw.filter(value => {
      // Filtrar por value.origin si originSelected no está vacío ("")
      if (originSelected !== '' && value.origin !== originSelected) {
        return false;
      }

      // Filtrar por value.sample_type si sampleSelected no está vacío ("")
      if (sampleSelected !== '' && value.sample_type !== sampleSelected) {
        return false;
      }

      // Filtrar por value.warehouse si warehouseSelected no está vacío ("")
      if (warehouseSelected !== '' && value.warehouse !== warehouseSelected) {
        return false;
      }

      // Filtrar por value.received si receivedSelected no está vacío ("")
      /* if (receivedSelected !== '' && value.received !== receivedSelected) {
        return false;
      } */

      // Filtrar por value.result si resultSelected no está vacío ("")
      if (resultSelected != '' && value.result != resultSelected) {
        return false;
      }

      /* if (
        resultSelected !== '' &&
        (value.received === '0' || value.received === null)
      ) {
        return false;
      } */

      return true; // Mantener el elemento si todos los filtros pasan
    });

    filteredCoffees.length > 0
      ? formatCoffe(filteredCoffees)
      : setErrorInfo(t(translations.general.no_search));
  };

  const searchOrigin = e => {
    setOriginSelected(e.value);
  };

  const searchSampleType = e => {
    setSampleSelected(e.value);
  };

  const searchWarehouse = e => {
    setWarehouseSelected(e.value);
  };

  const searchRecieved = e => {
    setReceivedSelected(e.value === '' ? null : e.value);
  };

  const searchScore = e => {
    setResultSelected(e.value === '' ? null : e.value);
  };

  useEffect(() => getCoffeesWithFiltersFirstCall(), []);

  const orderCoffes = coffesFinal => {
    return coffesFinal.map(originArr => {
      return originArr.map(warehouseArr => {
        return warehouseArr.sort((a, b) => {
          if (a.result === null) {
            return -1;
          }
          if (b.result === null) {
            return 1;
          }
          if (b.result === '0') {
            if (a.result === '0') {
              return 0;
            }
            return 1;
          }
          if (a.result === '1' && a.result === null) {
            if (b.result === null) {
              return 1;
            }
            if (b.received === '1' && b.result === null) {
              return 0;
            }
            return -1;
          }
          if (a.received === '1' && a.result === '1') {
            if (
              b.received === null ||
              (b.received === '1' && b.result === null)
            ) {
              return -1;
            }
            if (b.received === '1' && b.result === '1') {
              return 0;
            }
            return 1;
          }
          if (a.received === '0') {
            return -1;
          }
          return 0;
        });
      });
    });
  };

  const formatCoffe = coffe => {
    // let groupingByWharehouse = Object.values(
    //   coffe.reduce(
    //     (acc, current) => {
    //       if (current.warehouse == 'Afloat') acc.afloats.push(current);
    //       else if (current.warehouse === 'Contracted')
    //         acc.contracted.push(current);
    //       else acc.warehouses.push(current);
    //       return acc;
    //     },
    //     { warehouses: [], afloats: [], contracted: [] },
    //   ),
    // );
    // let finalArray;
    // let coffesFinal: any = [];
    // for (let i = 0; i < groupingByWharehouse.length; i++) {
    //   let arr: any = groupingByWharehouse[i];
    //   finalArray = Object.values(
    //     arr.reduce((acc, current) => {
    //       acc[current.origin + current.warehouse] =
    //         acc[current.origin + current.warehouse] ?? [];
    //       acc[current.origin + current.warehouse].push(current);
    //       return acc;
    //     }, {}),
    //   );
    //   finalArray = finalArray.sort((arrCountryA, arrCountryB) => {
    //     return arrCountryA[0].origin.localeCompare(arrCountryB[0].origin);
    //   }); // order by countries
    //   coffesFinal.push(finalArray);
    // }

    // const orderedCoffes = orderCoffes(coffesFinal);
    // setCoffees(orderedCoffes);
    // // setCoffees(coffesFinal);
    // setLoading(false);

    let groupingViaCommonProperty = Object.values(
      coffe.reduce((acc, current) => {
        acc[current.warehouse] = acc[current.warehouse] ?? [];
        acc[current.warehouse].push(current);
        return acc;
      }, {}),
    );

    setCoffees(groupingViaCommonProperty);
    setLoading(false);
  };

  return (
    <div>
      <Helmet>
        <title>Sample Request</title>
        <meta name="description" content="Roasterhub samples" />
      </Helmet>
      {loading ? (
        <LoadingPage />
      ) : (
        <div className="d-flex flex-column mt-5">
          {!isMobile ? (
            <>
              <div className="d-flex align-items-center gap-3 mb-5 mt-2">
                <Dropdown
                  handleChange={searchOrigin}
                  defaultValue={t(translations.table.origin)}
                  value={originSelected}
                  list={
                    origins &&
                    origins
                      .map(item => ({
                        value: item,
                        label: item,
                      }))
                      .filter(o =>
                        hash[o.value] ? false : (hash[o.value] = true),
                      )
                  }
                />
                {/* <Dropdown
                  handleChange={searchWarehouse}
                  defaultValue={t(translations.table.warehouse)}
                  value={warehouseSelected}
                  list={
                    warehouses &&
                    warehouses
                      .map(item => ({
                        value: item,
                        label: item,
                      }))
                      .filter(o =>
                        hash[o.value] ? false : (hash[o.value] = true),
                      )
                  }
                /> */}
                <Dropdown
                  defaultValue={t(translations.general.status)}
                  value={
                    resultSelected === '1'
                      ? t(translations.general.accepted)
                      : resultSelected === '0'
                      ? t(translations.general.declined)
                      : resultSelected === null
                      ? 'In Process'
                      : t(translations.general.select)
                  }
                  handleChange={searchScore}
                  list={ScoreOptions}
                />
                <Dropdown
                  handleChange={searchSampleType}
                  defaultValue={t(translations.table.type_sample)}
                  value={sampleSelected}
                  list={
                    sample &&
                    sample
                      .map(item => ({
                        value: item,
                        label: item,
                      }))
                      .filter(o =>
                        hash[o.value] ? false : (hash[o.value] = true),
                      )
                  }
                />
                <div style={{ cursor: 'pointer' }}>
                  <SearchIcon onClick={search} />
                </div>
                <Tooltip
                  title={t(translations.tooltips.clean)}
                  placement="right"
                >
                  <IconButton onClick={getCoffeesWithFiltersFirstCall}>
                    <CleanFilter />
                  </IconButton>
                </Tooltip>
              </div>
              <div className="d-flex align-items-center gap-3">
                {/* <Dropdown
                  defaultValue={t(translations.general.received)}
                  value={
                    receivedSelected === '1'
                      ? t(translations.general.received)
                      : receivedSelected === '0'
                      ? t(translations.general.not_received)
                      : receivedSelected === null
                      ? 'In Process'
                      : t(translations.general.received)
                  }
                  handleChange={searchRecieved}
                  list={ReceivedOptions}
                /> */}
              </div>
            </>
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginRight: '0px',
              }}
            >
              <StyledButton
                onClick={() => setIsModalOpen(true)}
                style={{
                  marginRight: '0px',
                  background: theme.palette.primary.main,
                }}
              >
                {t(translations.general.filter)}
              </StyledButton>
            </div>
          )}

          {loading ? (
            <LoadingPage />
          ) : errorInfo === '' ? (
            !isMobile ? (
              <MyPositionTablesPreShipment
                coffees={coffees}
                setRequestList={setRequestList}
                requestList={requestList}
                getCoffeesWithFiltersFirstCall={getCoffeesWithFiltersFirstCall}
              />
            ) : (
              <MyPositionTableMobile
                coffees={coffees}
                warehousesListProp={
                  warehouseFilter ? [warehouseFilter] : warehouses
                }
                setRequestList={setRequestList}
                requestList={requestList}
                getCoffeesWithFiltersFirstCall={getCoffeesWithFiltersFirstCall}
              />
            )
          ) : (
            <h3
              style={{
                color: 'gray',
                display: 'flex',
                justifyContent: 'center',
                marginTop: '50px',
                textAlign: 'center',
              }}
            >
              {errorInfo === t(translations.general.no_search)
                ? errorInfo
                : t(`${translations.errors_400[errorInfo]}`)}
            </h3>
          )}
          <FilterModalPosition
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            originOptions={
              origins &&
              origins
                .map(item => ({
                  value: item,
                  label: item,
                }))
                .filter(o => (hash[o.value] ? false : (hash[o.value] = true)))
            }
            warehouseOptions={
              warehouses &&
              warehouses
                .map(item => ({
                  value: item,
                  label: item,
                }))
                .filter(o => (hash[o.value] ? false : (hash[o.value] = true)))
            }
            receivedOptions={ReceivedOptions}
            resultOptions={ScoreOptions}
            onSearch={({
              originSelected,
              sampleSelected,
              warehouseSelected,
              receivedSelected,
              resultSelected,
            }) => {
              // Handle search logic here
              setOriginSelected(originSelected);
              setSampleSelected(sampleSelected);
              setWarehouseSelected(warehouseSelected);
              setReceivedSelected(receivedSelected);
              setResultSelected(resultSelected);
              setIsSearching(!isSearching);
              // search();
            }}
            clean={getCoffeesWithFiltersFirstCall}
          />
        </div>
      )}
    </div>
  );
}
