export const MEXICO = '#D98458';
export const GUATEMALA = '#F5ABB8';
export const EL_SALVADOR = '#B2D9D1';
export const NICARAGUA = '#A8A2CC';
export const COLOMBIA = '#B2BA86';
export const ECUADOR = '#D7BE5A';
export const PERU = '#D5594B';
export const GENERIC = '#4F4E24';

export const originToColor = {
  MEXICO: MEXICO,
  GUATEMALA: GUATEMALA,
  'EL SALVADOR': EL_SALVADOR,
  NICARAGUA: NICARAGUA,
  COLOMBIA: COLOMBIA,
  ECUADOR: ECUADOR,
  PERU: PERU,
};
