import React from 'react';

export const HomeIcon = ({ color }) => (
  <svg
    width="35"
    height="35"
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32.3511 17.2606L19.144 4.06039L18.2588 3.17513C18.0571 2.97476 17.7843 2.8623 17.5 2.8623C17.2157 2.8623 16.9429 2.97476 16.7412 3.17513L2.64892 17.2606C2.44224 17.4665 2.2789 17.7117 2.16853 17.9817C2.05817 18.2517 2.00302 18.5411 2.00634 18.8328C2.02002 20.036 3.02148 20.9964 4.22461 20.9964H5.67724V32.1287H29.3228V20.9964H30.8061C31.3906 20.9964 31.9409 20.7674 32.3545 20.3538C32.5581 20.1509 32.7195 19.9095 32.8292 19.6437C32.9389 19.3779 32.9948 19.093 32.9936 18.8055C32.9936 18.2245 32.7646 17.6742 32.3511 17.2606ZM19.4141 29.6678H15.5859V22.6952H19.4141V29.6678ZM26.8618 18.5355V29.6678H21.6016V21.8748C21.6016 21.1195 20.9897 20.5077 20.2344 20.5077H14.7656C14.0103 20.5077 13.3984 21.1195 13.3984 21.8748V29.6678H8.13818V18.5355H4.85693L17.5034 5.89926L18.293 6.68881L30.1465 18.5355H26.8618Z"
      fill={color}
    />
  </svg>
);

export const ArrowDownload = ({ color }) => (
  <svg
    width="35px"
    height="35px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 12L12 16M12 16L16 12M12 16V4M4 20H20"
      stroke="#FFFFFF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill={color}
    />
  </svg>
);

export const DocReportIcon = ({ color }) => (
  <svg
    width="31"
    height="37"
    viewBox="0 0 31 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.875 27.875V24.125M15.5 27.875V20.375M21.125 27.875V16.625M24.875 35.375H6.125C5.13044 35.375 4.17661 34.9799 3.47335 34.2766C2.77009 33.5734 2.375 32.6196 2.375 31.625V5.375C2.375 4.38044 2.77009 3.42661 3.47335 2.72335C4.17661 2.02009 5.13044 1.625 6.125 1.625H16.5988C17.096 1.62511 17.5728 1.82272 17.9244 2.17437L28.0756 12.3256C28.4273 12.6772 28.6249 13.154 28.625 13.6512V31.625C28.625 32.6196 28.2299 33.5734 27.5266 34.2766C26.8234 34.9799 25.8696 35.375 24.875 35.375Z"
      stroke={color}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const CheckBoxIcon = ({ color }) => (
  <svg
    width="35"
    height="35"
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.1667 2.9165H11.6667C10.0581 2.9165 8.75 4.22463 8.75 5.83317V23.3332C8.75 24.9417 10.0581 26.2498 11.6667 26.2498H29.1667C30.7752 26.2498 32.0833 24.9417 32.0833 23.3332V5.83317C32.0833 4.22463 30.7752 2.9165 29.1667 2.9165ZM11.6667 23.3332V5.83317H29.1667L29.1696 23.3332H11.6667Z"
      fill={color}
    />
    <path
      d="M5.83333 11.6666H2.91666V29.1666C2.91666 30.7751 4.22479 32.0832 5.83333 32.0832H23.3333V29.1666H5.83333V11.6666ZM18.8606 16.7984L16.3435 14.2814L14.2815 16.3434L19.056 21.118L27.3715 11.143L25.1285 9.27344L18.8606 16.7984Z"
      fill={color}
    />
  </svg>
);

export const PreShipmentIcon = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
    <path
      fill={color}
      d="m97.86,79.42c.22-1.96,1.07-3.74,2.4-5.24-2.2-2.4-5.63-4.3-9.69-5.07-1.29-.25-2.56-.37-3.79-.37-4.47,0-8.37,1.56-10.49,4.13l21.57,8.55c-.08-.66-.08-1.33,0-2Z"
    />
    <path
      fill={color}
      d="m100.06,82.29l2.12.84.92.36.94.37,12.23,4.85c1.58-4.15,6.11-6.86,11.91-7.07-.28-4.97-5.82-9.41-13.07-10.23-.78-.09-1.54-.13-2.3-.13-3.93,0-7.46,1.16-9.85,3.08-.28.21-.53.44-.77.69-.25.24-.49.49-.69.76-.92,1.13-1.49,2.42-1.65,3.83-.1.9-.03,1.78.21,2.65Z"
    />
    <path
      fill={color}
      d="m118.14,89.45l2.41.96,1.44.57,1.33.53,16.94,6.71c-.18-1.26-.09-2.51.25-3.73.49-1.74,1.47-3.25,2.84-4.46-1.98-2.48-5.15-4.57-9.01-5.66-1.45-.4-2.89-.65-4.28-.72-.34-.03-.68-.04-1.01-.04h-.07c-.33,0-.65.01-.96.03-4.72.25-8.6,2.39-9.88,5.81Z"
    />
    <path
      fill={color}
      d="m142.49,99.1l1.89.75.96.38.93.37,12.91,5.11c.07-.28.16-.55.27-.83,1.45-3.72,5.29-6.17,10.22-6.59-1.3-3.82-5.39-7.35-10.83-8.89-1.83-.52-3.65-.76-5.37-.76-2.82,0-5.4.66-7.37,1.85-.29.17-.57.36-.84.58-.27.19-.53.41-.77.64-.98.92-1.69,2.04-2.05,3.32-.38,1.33-.34,2.71.05,4.07Z"
    />
    <path
      fill={color}
      d="m161.32,105.61c-.11.28-.2.56-.26.85l3.85,1.53,1.27.5,1.2.47,20.02,7.94c.18-.31.34-.63.47-.97,1.98-5.08-2.36-11.52-9.69-14.37-2.05-.8-4.12-1.23-6.07-1.33-.29-.01-.58-.02-.87-.02h-.15c-.34,0-.67.02-1,.04-4.15.29-7.54,2.19-8.77,5.36Z"
    />
    <path
      fill={color}
      d="m192.83,121.2l-4.72-1.87-1-.4-1.01-.39-21.18-8.4-1.45-.57-1.64-.65-.98-.39-1.01-.4-.99-.4-13.86-5.49-1.01-.4-.27-.1-.78-.31-.7-.28h-.01l-1.33-.53-20.44-8.1-1.65-.66h-.01l-1.09-.43-.82-.32-.15-.06-.96-.38-12.77-5.06-.97-.39-.71-.28c-.06-.07-.11-.14-.15-.22-.02.04-.06.08-.1.12l-1.27-.5-1.36-.54-23.23-9.2-.93-.37-.92-.37-4.93-1.95c-.32-.13-.66-.19-.99-.19-1.01,0-1.97.58-2.43,1.55-.81,1.74-1.47,3.52-1.98,5.32L15.1,55.41c-.95-.46-1.94-.68-2.92-.68-2.78,0-5.42,1.75-6.36,4.6-1.13,3.39.6,7.06,3.93,8.36l51.93,20.23c-.1,14.8,9.23,28.82,24.35,33.72l68.08,22.04c3.31,1.07,6.68,1.59,10,1.59,6.16,0,12.15-1.76,17.28-4.98,5.14-3.22,9.42-7.9,12.15-13.74.29-.63.56-1.26.81-1.9.54-1.37-.16-2.91-1.52-3.45Z"
    />
  </svg>
);

export const TruckIcon = ({ color }) => (
  <svg
    width="40"
    height="32"
    viewBox="0 0 40 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.3333 25.1668H27.3333V5.00016C27.3333 4.0277 26.947 3.09507 26.2594 2.40744C25.5718 1.7198 24.6391 1.3335 23.6667 1.3335H5.33333C4.36087 1.3335 3.42824 1.7198 2.74061 2.40744C2.05298 3.09507 1.66667 4.0277 1.66667 5.00016V23.3335C1.66667 23.8197 1.85982 24.286 2.20364 24.6299C2.54746 24.9737 3.01377 25.1668 3.5 25.1668H5.33333M38.3333 19.6668H34.6667M38.3333 23.3335V16.0002C38.3333 14.0552 37.5607 12.19 36.1855 10.8147C34.8102 9.43945 32.9449 8.66683 31 8.66683H27.3333V25.1668H36.5C36.9862 25.1668 37.4525 24.9737 37.7964 24.6299C38.1402 24.286 38.3333 23.8197 38.3333 23.3335ZM25.5 25.1668C25.5 26.6255 26.0795 28.0245 27.1109 29.0559C28.1424 30.0874 29.5413 30.6668 31 30.6668C32.4587 30.6668 33.8576 30.0874 34.8891 29.0559C35.9205 28.0245 36.5 26.6255 36.5 25.1668H25.5Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.8333 30.667C13.8709 30.667 16.3333 28.2046 16.3333 25.167C16.3333 22.1294 13.8709 19.667 10.8333 19.667C7.79577 19.667 5.33334 22.1294 5.33334 25.167C5.33334 28.2046 7.79577 30.667 10.8333 30.667Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const CheckListIcon = ({ color }) => (
  <svg
    width="36"
    height="32"
    viewBox="0 0 36 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5063 2.38746C12.125 2.94371 12.175 3.88746 11.6188 4.50621L7.11875 9.50621C6.84375 9.81246 6.45625 9.99371 6.04375 9.99996C5.63125 10.0062 5.2375 9.84996 4.94375 9.56246L2.4375 7.06246C1.85 6.47496 1.85 5.52496 2.4375 4.94371C3.025 4.36246 3.975 4.35621 4.55625 4.94371L5.9375 6.32496L9.38125 2.49996C9.9375 1.88121 10.8813 1.83121 11.5 2.38746H11.5063ZM11.5063 12.3875C12.125 12.9437 12.175 13.8875 11.6188 14.5062L7.11875 19.5062C6.84375 19.8125 6.45625 19.9937 6.04375 20C5.63125 20.0062 5.2375 19.85 4.94375 19.5625L2.4375 17.0625C1.85 16.475 1.85 15.525 2.4375 14.9437C3.025 14.3625 3.975 14.3562 4.55625 14.9437L5.9375 16.325L9.38125 12.5C9.9375 11.8812 10.8813 11.8312 11.5 12.3875H11.5063ZM16 5.99996C16 4.89371 16.8938 3.99996 18 3.99996H32C33.1063 3.99996 34 4.89371 34 5.99996C34 7.10621 33.1063 7.99996 32 7.99996H18C16.8938 7.99996 16 7.10621 16 5.99996ZM16 16C16 14.8937 16.8938 14 18 14H32C33.1063 14 34 14.8937 34 16C34 17.1062 33.1063 18 32 18H18C16.8938 18 16 17.1062 16 16ZM12 26C12 24.8937 12.8938 24 14 24H32C33.1063 24 34 24.8937 34 26C34 27.1062 33.1063 28 32 28H14C12.8938 28 12 27.1062 12 26ZM5 29C3.34375 29 2 27.6562 2 26C2 24.3437 3.34375 23 5 23C6.65625 23 8 24.3437 8 26C8 27.6562 6.65625 29 5 29Z"
      fill={color}
    />
  </svg>
);

export const ShoppingCartIcon = ({ color }) => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.875 35.625C13.1867 35.625 14.25 34.5617 14.25 33.25C14.25 31.9383 13.1867 30.875 11.875 30.875C10.5633 30.875 9.5 31.9383 9.5 33.25C9.5 34.5617 10.5633 35.625 11.875 35.625Z"
      fill={color}
    />
    <path
      d="M28.5 35.625C29.8117 35.625 30.875 34.5617 30.875 33.25C30.875 31.9383 29.8117 30.875 28.5 30.875C27.1883 30.875 26.125 31.9383 26.125 33.25C26.125 34.5617 27.1883 35.625 28.5 35.625Z"
      fill={color}
    />
    <path
      d="M5.91375 3.32975C5.85996 3.06065 5.71464 2.81849 5.50248 2.64443C5.29032 2.47037 5.02442 2.37517 4.75 2.375H0V4.75H3.77625L8.33625 27.5452C8.39004 27.8143 8.53536 28.0565 8.74752 28.2306C8.95968 28.4046 9.22558 28.4998 9.5 28.5H30.875V26.125H10.4737L9.52375 21.375H30.875C31.1451 21.375 31.4072 21.2828 31.6179 21.1138C31.8286 20.9447 31.9754 20.7089 32.034 20.4452L34.7272 8.3125H32.2964L29.9226 19H9.04875L5.91375 3.32975Z"
      fill={color}
    />
    <path
      d="M21.375 7.125V2.375H19V7.125H14.25V9.5H19V14.25H21.375V9.5H26.125V7.125H21.375Z"
      fill={color}
    />
  </svg>
);
export const AnalyticsIcon = ({ color }) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.875 21.875L33.75 15M18.125 16.875L23.125 21.875M6.25 25L14.375 16.875"
      stroke={color}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M35.625 15C36.6605 15 37.5 14.1605 37.5 13.125C37.5 12.0895 36.6605 11.25 35.625 11.25C34.5895 11.25 33.75 12.0895 33.75 13.125C33.75 14.1605 34.5895 15 35.625 15Z"
      stroke={color}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25 25.625C26.0355 25.625 26.875 24.7855 26.875 23.75C26.875 22.7145 26.0355 21.875 25 21.875C23.9645 21.875 23.125 22.7145 23.125 23.75C23.125 24.7855 23.9645 25.625 25 25.625Z"
      stroke={color}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.25 16.875C17.2855 16.875 18.125 16.0355 18.125 15C18.125 13.9645 17.2855 13.125 16.25 13.125C15.2145 13.125 14.375 13.9645 14.375 15C14.375 16.0355 15.2145 16.875 16.25 16.875Z"
      stroke={color}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.375 28.75C5.41053 28.75 6.25 27.9105 6.25 26.875C6.25 25.8395 5.41053 25 4.375 25C3.33947 25 2.5 25.8395 2.5 26.875C2.5 27.9105 3.33947 28.75 4.375 28.75Z"
      stroke={color}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const HistoryIcon = ({ color }) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M35 20V7.5C35 6.83696 34.7366 6.20107 34.2678 5.73223C33.7989 5.26339 33.163 5 32.5 5H7.5C6.83696 5 6.20107 5.26339 5.73223 5.73223C5.26339 6.20107 5 6.83696 5 7.5V32.5C5 33.163 5.26339 33.7989 5.73223 34.2678C6.20107 34.7366 6.83696 35 7.5 35H20"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.6667 31.6665C29.4281 31.6665 31.6667 29.4279 31.6667 26.6665C31.6667 23.9051 29.4281 21.6665 26.6667 21.6665C23.9052 21.6665 21.6667 23.9051 21.6667 26.6665C21.6667 29.4279 23.9052 31.6665 26.6667 31.6665Z"
      stroke={color}
      strokeWidth="2"
    />
    <path
      d="M30.8333 30.0002L35 33.3335M11.6667 13.3335H28.3333M11.6667 20.0002H18.3333"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const DownloadIcon = ({
  color,
  arrowColor = '#F8F8F8',
  pointingUp = false,
}) =>
  pointingUp ? (
    <svg
      width="39"
      height="39"
      viewBox="0 0 39 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="39" height="39" rx="10" fill={color} />
      <path
        d="M30.4687 7.3125L8.5312 7.3125C8.22665 7.31306 7.93292 7.19957 7.70785 6.99438C7.48279 6.7892 7.34271 6.50718 7.31519 6.20387C7.28767 5.90056 7.37472 5.59794 7.55918 5.3556C7.74363 5.11326 8.01214 4.94876 8.31183 4.8945L8.5312 4.875L30.4687 4.875C30.7733 4.87444 31.067 4.98793 31.292 5.19312C31.5171 5.3983 31.6572 5.68032 31.6847 5.98363C31.7122 6.28694 31.6252 6.58956 31.4407 6.8319C31.2563 7.07424 30.9878 7.23874 30.6881 7.293L30.4687 7.3125ZM19.7193 36.543L19.4999 36.5625C19.2147 36.5626 18.9385 36.4626 18.7193 36.28C18.5002 36.0974 18.352 35.8437 18.3007 35.5631L18.2812 35.3437L18.2812 13.9132L11.7438 20.4482C11.5422 20.6501 11.2761 20.7748 10.9919 20.8006C10.7077 20.8265 10.4235 20.7518 10.1887 20.5896L10.0181 20.4482C9.81655 20.2462 9.69227 19.98 9.66689 19.6958C9.6415 19.4116 9.71661 19.1275 9.87914 18.8931L10.0205 18.7249L18.6371 10.1059C18.839 9.90435 19.1053 9.78007 19.3895 9.75469C19.6736 9.7293 19.9577 9.80441 20.1922 9.96694L20.3628 10.1059L28.9794 18.7249C29.1953 18.9398 29.3229 19.2279 29.337 19.5322C29.3511 19.8366 29.2506 20.1352 29.0555 20.3692C28.8604 20.6032 28.5846 20.7556 28.2827 20.7964C27.9808 20.8372 27.6745 20.7634 27.4243 20.5896L27.2561 20.4482L20.7187 13.9132L20.7187 35.3437C20.7188 35.629 20.6188 35.9052 20.4362 36.1244C20.2536 36.3435 19.9999 36.4917 19.7193 36.543Z"
        fill={arrowColor}
      />
    </svg>
  ) : (
    <svg
      width="39"
      height="39"
      viewBox="0 0 39 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="39" height="39" rx="10" fill={color} />
      <path
        d="M8.5313 31.6875H30.4688C30.7734 31.6869 31.0671 31.8004 31.2921 32.0056C31.5172 32.2108 31.6573 32.4928 31.6848 32.7961C31.7123 33.0994 31.6253 33.4021 31.4408 33.6444C31.2564 33.8867 30.9879 34.0512 30.6882 34.1055L30.4688 34.125H8.5313C8.22674 34.1256 7.93301 34.0121 7.70795 33.8069C7.48289 33.6017 7.34281 33.3197 7.31529 33.0164C7.28777 32.7131 7.37481 32.4104 7.55927 32.1681C7.74373 31.9258 8.01224 31.7613 8.31192 31.707L8.5313 31.6875H30.4688H8.5313ZM19.2807 2.457L19.5 2.4375C19.7853 2.4374 20.0615 2.53737 20.2807 2.71998C20.4998 2.90259 20.648 3.15628 20.6993 3.43687L20.7188 3.65625V25.0867L27.2562 18.5518C27.4578 18.3499 27.7239 18.2252 28.0081 18.1994C28.2923 18.1735 28.5765 18.2482 28.8113 18.4104L28.9819 18.5518C29.1834 18.7538 29.3077 19.02 29.3331 19.3042C29.3585 19.5884 29.2834 19.8725 29.1209 20.1069L28.9795 20.2751L20.3629 28.8941C20.161 29.0956 19.8947 29.2199 19.6105 29.2453C19.3264 29.2707 19.0423 29.1956 18.8078 29.0331L18.6372 28.8941L10.0206 20.2751C9.80469 20.0602 9.67711 19.7721 9.66302 19.4678C9.64894 19.1634 9.74936 18.8648 9.9445 18.6308C10.1396 18.3968 10.4154 18.2444 10.7173 18.2036C11.0192 18.1628 11.3255 18.2366 11.5757 18.4104L11.7439 18.5518L18.2813 25.0867V3.65625C18.2812 3.371 18.3812 3.09475 18.5638 2.87562C18.7464 2.65648 19.0001 2.50835 19.2807 2.457L19.5 2.4375L19.2807 2.457Z"
        fill={arrowColor}
      />
    </svg>
  );

export const EditReportIcon = ({ color }) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 4C10 2.89543 10.8954 2 12 2H26L32 8V36C32 37.1046 31.1046 38 30 38H12C10.8954 38 10 37.1046 10 36V4Z"
      stroke={color}
      stroke-width="2"
    />
    <path d="M26 2V8H32" stroke={color} stroke-width="2" />
    <path
      d="M25.8284 15.1716L22.8284 12.1716C22.4379 11.781 21.8047 11.781 21.4142 12.1716L13.4142 20.1716C13.0237 20.5621 13.0237 21.1953 13.4142 21.5858L16.4142 24.5858C16.8047 24.9763 17.4379 24.9763 17.8284 24.5858L25.8284 16.5858C26.219 16.1953 26.219 15.5621 25.8284 15.1716Z"
      stroke={color}
      stroke-width="2"
    />
    <path d="M16 25L13 28L12 32L16 31L19 28" stroke={color} stroke-width="2" />
  </svg>
);

export const UbicationIcon = ({ color }) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 2C12.268 2 6 8.268 6 16C6 25.613 18.234 37.343 19.258 38.316C19.442 38.496 19.716 38.6 20 38.6C20.284 38.6 20.558 38.496 20.742 38.316C21.766 37.343 34 25.613 34 16C34 8.268 27.732 2 20 2ZM20 21C17.791 21 16 19.209 16 17C16 14.791 17.791 13 20 13C22.209 13 24 14.791 24 17C24 19.209 22.209 21 20 21Z"
      stroke={color}
      stroke-width="2"
      fill="none"
    />
  </svg>
);

export const CuppingIcon = ({ color }) => (
  <svg
    width="1280"
    height="781"
    viewBox="0 0 1280 781"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="translate(0,781) scale(0.1,-0.1)" fill={color} stroke="none">
      <path
        d="M3673 7800 c-52 -11 -80 -25 -116 -59 -49 -45 -60 -76 -52 -148 5
        -49 3 -72 -9 -98 -9 -19 -16 -50 -14 -68 4 -67 -74 -184 -240 -358 -63 -66
        -120 -138 -155 -196 -124 -204 -229 -353 -541 -767 -167 -222 -237 -323 -438
        -633 -42 -65 -143 -202 -225 -303 -275 -343 -355 -457 -427 -612 -71 -152 -92
        -158 -215 -57 -130 106 -168 115 -313 75 -114 -31 -161 -48 -470 -172 -130
        -52 -263 -100 -295 -105 -113 -21 -132 -28 -148 -59 -20 -39 -19 -81 6 -175
        11 -44 18 -90 16 -104 -5 -27 10 -91 22 -91 4 0 11 -129 14 -287 10 -403 44
        -931 98 -1502 11 -117 18 -216 15 -219 -4 -3 -6 2 -6 10 0 16 -40 112 -44 106
        -1 -2 1 -82 5 -178 7 -188 26 -282 75 -389 23 -51 31 -110 91 -669 l66 -612
        -42 -49 c-36 -44 -52 -81 -35 -81 4 0 43 19 87 43 122 64 227 177 213 231 -3
        13 18 97 48 197 30 96 57 191 61 212 4 20 9 37 11 37 3 0 21 -11 42 -24 139
        -87 273 -119 502 -120 130 0 168 3 238 22 81 22 109 22 96 0 -10 -16 17 -4 95
        39 87 49 197 156 273 266 34 51 98 139 142 197 142 188 171 279 181 580 7 215
        17 300 47 389 28 87 72 126 233 206 142 70 257 111 338 120 29 3 111 17 182
        30 72 14 198 34 280 45 182 24 247 40 345 85 41 19 115 46 163 60 173 49 369
        146 558 275 601 409 669 464 1364 1107 382 353 399 370 479 488 95 138 158
        187 272 209 38 8 91 24 117 35 61 28 168 38 238 21 82 -19 203 -79 343 -170
        188 -121 221 -135 336 -135 89 0 100 2 172 36 84 41 152 97 197 166 l30 45
        -31 47 c-109 162 -413 483 -532 563 -34 23 -89 64 -124 91 -67 53 -62 51 -462
        174 l-115 36 -145 -7 c-195 -9 -348 -23 -505 -46 -71 -10 -131 -17 -133 -15
        -2 1 12 19 32 38 76 76 192 90 771 91 360 0 465 -3 578 -17 225 -28 425 -80
        547 -142 149 -76 328 -120 458 -113 75 4 91 9 178 54 53 27 98 53 101 57 2 4
        0 14 -7 22 -9 10 -7 21 5 45 43 83 25 195 -52 311 -69 106 -96 174 -91 224 l5
        42 -73 7 c-91 8 -157 30 -306 100 -129 60 -249 106 -279 106 -33 0 -107 41
        -159 87 -60 54 -120 166 -120 225 0 88 65 148 160 148 54 0 452 -97 620 -152
        340 -110 611 -255 712 -381 50 -64 103 -173 158 -332 89 -256 156 -352 313
        -449 379 -235 837 -262 1778 -105 629 104 1010 194 1337 313 151 55 215 114
        198 182 -19 74 -185 184 -372 247 -39 13 -120 53 -181 89 -437 263 -907 422
        -1413 479 -58 6 -197 12 -310 12 -200 1 -207 1 -239 -22 -34 -25 -52 -25 -186
        -3 -62 10 -190 -13 -283 -51 -34 -14 -129 -64 -210 -110 -165 -95 -228 -125
        -282 -133 -35 -6 -44 -1 -145 80 -188 152 -411 290 -608 377 -50 23 -115 51
        -144 64 -95 43 -233 199 -233 265 0 44 -25 87 -63 106 -17 9 -64 47 -103 84
        -39 37 -95 79 -125 93 -64 32 -197 67 -250 67 -30 0 -37 3 -33 15 5 12 -4 15
        -44 15 -28 0 -58 -3 -66 -6 -15 -6 -15 -8 1 -19 15 -11 11 -12 -27 -4 -83 17
        -155 37 -135 38 35 2 2 18 -80 39 -259 67 -722 128 -699 91 4 -7 -11 -9 -52
        -4 -124 13 -131 16 -171 55 l-39 38 -150 7 c-295 15 -960 -29 -1224 -81 -60
        -12 -139 -17 -265 -17 -185 -1 -291 11 -652 69 -182 29 -283 36 -340 24z"
      />
    </g>
  </svg>
);
