import styledComponent from 'styled-components/macro';
import styled from 'styled-components/macro';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { CustomButton } from '../Button';

export const SlimButton = styled(CustomButton)`
  font-style: normal;
  font-weight: 325;
  font-size: 13px;
  line-height: 16px;
  margin: 5px;
`;

export const StyledTableRow = styled(TableRow)`
  border-bottom: 3px solid white;
  border-top: 3px solid white;
  th:first-child {
    border-radius: 35px 0px 0px 35px;
  }
  th:last-child {
    border-radius: 0px 35px 35px 0px;
  }
  td:first-child {
    border-radius: 35px 0px 0px 35px;
  }
  td:last-child {
    border-radius: 0px 35px 35px 0px;
  }
`;

export const StyledHeadCell = styled(TableCell)<any>`
  font-style: normal;
  font-weight: 325;
  font-size: 15px;
  line-height: 18px;
  border-bottom: 5px solid white;
  color: 'white';
  background: ${props =>
    props.background ? props.background : props.theme.LightGray};
`;

export const StyledTableCell = styled(TableCell)`
  align-items: center;

  font-style: normal;
  font-weight: 325;
  font-size: 13px;
  line-height: 16px;
  background: #f8f8f8;
  border: 1px solid white;
  display: table-cell;
  overflow: auto;
`;

export const Total = styled.div`
  display: flex;
  aling-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
`;

export const Element = styled.div<{ color: string }>`
  color: #ffffff;
  margin-top: 30px;
  border-radius: 35px;
  font-weight: 350;
  height: 59px;
  background: ${props => props.color};
  flex-grow: 1;
  margin: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Chip = styledComponent.div<any>` 
  background:${props =>
    props.background ? props.background : props.theme.DarkYellow}; 
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  padding: 5px;
  margin-left: 14px;
  font-weight: 325;
  font-size: 15px;
`;
