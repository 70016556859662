import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { translations } from 'locales/translations';
import truck from 'app/assets/SVG/camion.gif';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import { PurchaseOrderPDF } from 'services/purchaseService';
import { useMediaQuery } from '@mui/material';
import axios from 'axios';
const Button = styled.input`
  border: none;
  background: #5c36f3;
  color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  // width: 253.02px;
  height: 49.59px;
  margin: 40px 10px;
`;
const ResponseContainer = styled.div`
  padding: 0px 10vw;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const CustomLabel = styled.div`
  font-style: normal;
  font-weight: 350;
  font-size: 30px;
  line-height: 36px;
  margin: 30px 0px 13px;
  text-align: center;
  @media (max-width: 767px) {
    font-size: 24px;
  }
`;
export function FinalStep(idStored: any) {
  const { t } = useTranslation();
  const theme: any = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:767px)');
  const getPdf = (id: string) => {
    let url = process.env.REACT_APP_BASE_URL + `/purchase-order-pdf/${id}`;
    const token = localStorage.getItem('caravela_access_token');
    axios({
      method: 'GET',
      url,
      responseType: 'blob',
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(response => {
        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Purchase.pdf'); //or any other extension
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch(error => {
        console.log(error);
      });
  };
  return (
    <>
      <Helmet>
        <title>Purchase Order</title>
        <meta name="description" content="My Roasterhub order" />
      </Helmet>
      <ResponseContainer>
        <img
          src={truck}
          style={{
            height: !isMobile ? '500px' : '250px',
            width: !isMobile ? '500px' : '250px',
            marginTop: !isMobile ? '-12vh' : '-5vh',
          }}
          alt="truck gif"
        />
        <div className="d-flex flex-column">
          <CustomLabel>
            {t(translations.pages.purchase_order.success)}
          </CustomLabel>
          <CustomLabel
            style={{ color: theme.palette.error.main }}
          >{`Order ID: ${idStored.idStored}`}</CustomLabel>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            type="button"
            style={{ background: theme.palette.primary.main, margin: '2%' }}
            value={t(translations.general.main_screen)}
            onClick={() => navigate(`/`)}
          />
          <Button
            type="button"
            style={{
              background: theme.palette.secondary.main,
              color: theme.palette.secondary.contrastText,
              margin: '2%',
            }}
            value={`${t(translations.general.download)}`}
            onClick={() => getPdf(idStored.idStored)}
          />
          <Button
            type="button"
            style={{ background: theme.palette.primary.main, margin: '2%' }}
            value={t(translations.pages.review_delivery_orders.title)}
            onClick={() => navigate(`/review-delivery`)}
          />
        </div>
      </ResponseContainer>
    </>
  );
}
