import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Switch from '@mui/material/Switch';
import styledComponent from 'styled-components/macro';
import styled from 'styled-components/macro';
import { alpha, ThemeProvider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Dropdown from 'app/components/Dropdown';
import { useNavigate } from 'react-router-dom';

const StyledHeadCell = styled(TableCell)`
  font-style: normal;
  font-weight: 325;
  font-size: 13px;
  line-height: 16px;
  border-radius: 35px;
  border: 1px solid white;
  ${props =>
    props.color &&
    `
    color: white; `}
  background: #EBEAEA;
  background: ${props => props.color};
`;

const Search = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledTableRow = styled(TableRow)`
  border-bottom: 3px solid white;
  border-top: 3px solid white;
  th:first-child {
    border-radius: 35px 0px 0px 35px;
  }
  th:last-child {
    border-radius: 0px 35px 35px 0px;
  }
  td:first-child {
    border-radius: 35px 0px 0px 35px;
  }
  td:last-child {
    border-radius: 0px 35px 35px 0px;
  }
`;

const StyledTableCell = styled(TableCell)`
  align-items: center;
  border-radius: 35px;

  font-style: normal;
  font-weight: 325;
  font-size: 13px;
  line-height: 16px;
  background: #f8f8f8;
  border: 1px solid white;
  display: table-cell;
  overflow: auto;
`;

const GreenSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: 'white',
    '&:hover': {
      backgroundColor: alpha('#2CCC7F', theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#2CCC7F',
  },
}));

interface SwitchProps {
  pages: boolean;
  data: any;
  modules?: any;
  permissions?: any;
  modified: boolean;
  setModified: (value: boolean) => void;
  setModules: (value: any) => void;
  setPermissions: (value: any) => void;
  role?: any;
}

export function SwitchTable({
  pages,
  data,
  modules,
  permissions,
  modified,
  setModified,
  setModules,
  setPermissions,
  role,
}: SwitchProps) {
  const theme = useTheme();
  const { t } = useTranslation();

  const addRemoveValue = (
    value: string,
    isSelected: boolean,
    element: string,
  ) => {
    modified === false && setModified(true);
    if (element === 'modules') {
      if (isSelected === true) {
        const filteredModules = modules.filter(item => item !== value);
        setModules(filteredModules);
      } else {
        const updatedModules = [...modules, value];
        setModules(updatedModules);
      }
    } else {
      if (isSelected === true) {
        const filteredPermissions = permissions.filter(item => item !== value);
        setPermissions(filteredPermissions);
      } else {
        if (value.includes('Caravela')) {
          const updatedPermissions = [...permissions, value];
          setPermissions(updatedPermissions);
        } else {
          const updatedPermissions = [...permissions, value];
          setPermissions(updatedPermissions);
        }
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <>
        <TableContainer>
          <Table
            sx={{ minWidth: 700, maxWidth: 700 }}
            aria-label="customized table"
          >
            <TableHead>
              <StyledTableRow>
                {pages ? (
                  <>
                    <StyledHeadCell>
                      {t(translations.general.module)}
                    </StyledHeadCell>
                    <StyledHeadCell align="center">
                      {t(translations.general.view)}
                    </StyledHeadCell>
                  </>
                ) : (
                  <>
                    <StyledHeadCell>
                      {t(translations.general.permissions)}
                    </StyledHeadCell>
                    <StyledHeadCell align="center">
                      {t(translations.general.customer)}
                    </StyledHeadCell>{' '}
                  </>
                )}
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {data.map(value => (
                <StyledTableRow key={value.value}>
                  <StyledTableCell align="center">
                    {value.value}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {pages === true && modules && (
                      <GreenSwitch
                        checked={
                          modules.filter(item => item === value.value)
                            .length === 0
                            ? false
                            : true
                        }
                        onChange={e =>
                          addRemoveValue(
                            value.value,
                            modules.filter(item => item === value.value)
                              .length > 0,
                            'modules',
                          )
                        }
                      />
                    )}
                    {pages === false && permissions && (
                      <GreenSwitch
                        checked={
                          permissions.filter(item => item === value.value)
                            .length === 0
                            ? false
                            : true
                        }
                        onChange={e =>
                          addRemoveValue(
                            value.value,
                            permissions.filter(item => item === value.value)
                              .length > 0,
                            'permissions',
                          )
                        }
                      />
                    )}
                  </StyledTableCell>
                  {/* )} */}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    </ThemeProvider>
  );
}
