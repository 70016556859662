import serviceClient from './serviceClientConfig';

export const getRoleList = () => {
  return serviceClient.get(`/roles-list`);
};

export const getAdminUsers = (
  role?,
  accountant_id?,
  userName?,
  mail?,
  page?,
) => {
  return serviceClient.get(
    `/admin-users?role=${role !== undefined ? role : ''}
    &accountant_id=${accountant_id !== undefined ? accountant_id : ''}
    &page=${page !== undefined ? page : ''}
    &user_name=${userName !== undefined ? userName : ''}
    &email=${mail !== undefined ? mail : ''}
    `,
  );
};

export const getAccountanList = () => {
  return serviceClient.get(`/accountant-list`);
};

export const updateUser = (payload: any) => {
  return serviceClient.post('/user-role-update', payload);
};

export const createRole = (name: string) => {
  return serviceClient.post(`/admin-role?role=${name}`, '');
};
