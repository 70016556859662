import { Hero } from 'app/components/Hero';
import * as React from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { ChangePassModal } from 'app/components/ChangePassModal';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { CustomButton } from '../../components/Button';
import { getUser, setUser, setUserId } from 'services/authServices';
import { ProfilePayload } from 'models/authModels';
import { useMediaQuery } from '@mui/material';

const Button = styled.input`
  border: none;
  background: #b2ba86;
  color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  margin-bottom: 25px;
  width: 213.02px;
  height: 49.59px;
  margin: 0 10px;
  @media (max-width: 767px) {
    margin-bottom: 10px;
    width: 150px;
    height: 40px;
  }
`;

const Text = styled.div`
  font-style: normal;
  font-weight: 350;
  font-size: 20px;
  line-height: 24px;
  color: #545454;
  margin: 10px 0;
  margin-right: 20px;
  flex-basis: 180px;
  @media (max-width: 767px) {
    flex-basis: auto;
    font-size: 16px;
  }
`;

const Line = styled.div`
  display: flex;
  margin: 18px 0;
  align-items: center;
  width: 100%;
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: baseline;
    margin-left: 20px;
  }
`;

const Input = styled.input<{
  edit?: boolean;
}>`
  width: 100%;
  height: 48.71px;
  background: ${props => (props.edit ? '#f5f5f5' : '#a3a3a3')};
  border-radius: 30px;
  border: 0;
  padding: 0 20px;
  @media (max-width: 767px) {
    width: 100%;
  }
  @media (max-width: 430px) {
    width: 100%;
  }
`;

const Container = styled.div`
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Column = styled.div`
  flex: 1;
  min-width: 300px;
  max-width: 48%;
  @media (max-width: 767px) {
    max-width: 100%;
  }
`;

const FormControl = styled.div`
  display: flex;
  width: 50%;
  @media (max-width: 767px) {
    flex-direction: column;
  }
  &:has(Input[required]) {
    ${Text} {
      &:after {
        content: ' *';
      }
    }
  }
`;

enum ErrorMessages {
  Validation = 'validation.message',
}

export function ProfilePage() {
  const { t, i18n } = useTranslation();
  const theme: any = useTheme();

  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [edited, setEdited] = useState(false);
  const [error, setError] = useState({});
  const [success, setSuccess] = useState(false);
  const [contact_name, setUsername] = useState('');
  const [fiscal_id, setFiscalID] = useState('');
  const [id, setId] = useState('');
  const [country, setCountry] = useState('');
  const [contact_number, setPhone] = useState('');
  const [email, setMail] = useState('');
  const [name_customer, setCompanyName] = useState('');
  const [invoice_address, setInvoiceAddress] = useState('');
  const [company_address, setCompanyAddress] = useState('');
  const [account_owner, SetAccountOwner] = useState('');
  const [customer, setCustomer] = useState('');
  const [zip_code, setZip] = useState('');
  const [isConfirm, setIsConfirm] = useState(false);
  const [isUploadedLogo, setIsUploadedLogo] = useState(false);

  const isMobile = useMediaQuery('(max-width:767px)');

  const openCloseModal = () => {
    setOpen(!open);
  };

  const openCloseEdit = () => {
    setEdit(!edit);
  };

  const handleSubmit = event => {
    setSuccess(false);
    setIsConfirm(true);
    setError({});
    event.preventDefault();
    const payload: ProfilePayload = {
      name_customer,
      fiscal_id,
      country,
      email,
      contact_number,
      contact_name,
      invoice_address,
      company_address,
      account_owner,
      customer,
      zip_code,
    };
    setUser(payload)
      .then(response => {
        if (response.status === 200) {
          setSuccess(true);
        } else if (response.status === 404) {
          setError({});
        }
      })
      .catch(error => {
        setError({
          message: error.response?.data?.message,
          errors: error.response?.data?.errors,
        });
      });
  };

  const getMessageError = (error, t) => {
    let message = '';
    if (error?.message === ErrorMessages.Validation) {
      message = t(translations.errors.fields_validation);
    } else {
      message = t(translations.general.error_something);
    }
    return message;
  };

  useEffect(() => {
    getUser()
      .then(response => {
        if (response.status === 200) {
          setId(response.data.id);
          setUsername(response.data.contact_name);
          setFiscalID(response.data.fiscal_id);
          setCountry(response.data.country);
          setPhone(response.data.contact_number);
          setMail(response.data.email);
          setCompanyName(response.data.name_customer);
          setInvoiceAddress(response.data.invoice_address);
          setCompanyAddress(response.data.company_address);
          SetAccountOwner(response.data.account_owner);
          setCustomer(response.data.customer);
          setZip(response.data.zip_code);
        }
      })
      .catch(error => {
        console.log(error.response?.data);
      });
  }, []);

  useEffect(() => {
    edit === true && setEdited(true);
  }, [
    company_address,
    invoice_address,
    zip_code,
    name_customer,
    contact_name,
    contact_number,
    isUploadedLogo,
  ]);

  return open === true ? (
    <ChangePassModal openCloseModal={openCloseModal} />
  ) : (
    <div>
      <Helmet>
        <title>{t(translations.pages.profile.title)}</title>
        <meta name="description" content="My Roasterhub client profile" />
      </Helmet>
      <div
        className="d-flex flex-column"
        style={{
          padding: !isMobile ? '30px 96px' : '0px',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            textAlign: 'left',
            color: 'red',
            marginRight: '20px',
            marginTop: '20px',
          }}
        >
          {t(translations.general.mandatory)}
        </div>
        <form onSubmit={handleSubmit}>
          <div
            className="d-flex w-100 mb-3"
            style={{
              justifyContent: !isMobile ? 'flex-end' : 'center',
              flexDirection: !isMobile ? 'row' : 'column',
              alignItems: 'center',
            }}
          >
            {success ? (
              <h1
                style={{
                  color: 'green',
                  marginRight: !isMobile ? 'auto' : 0,
                  textAlign: isMobile ? 'center' : 'initial',
                }}
              >
                {t(translations.pages.profile.success)}
              </h1>
            ) : null}
            {Object.keys(error).length > 0 ? (
              <h1
                style={{
                  color: 'red',
                  marginRight: !isMobile ? 'auto' : 0,
                  textAlign: isMobile ? 'center' : 'initial',
                }}
              >
                {getMessageError(error, t)}
              </h1>
            ) : null}
          </div>
          <Container>
            <h6
              style={{
                color: 'red',
                paddingRight: !isMobile ? '600px' : '0px',
                marginTop: !isMobile ? '20px' : '0px',
                textAlign: !isMobile ? 'left' : 'center',
              }}
            >
              {t(translations.general.infotext)}
            </h6>

            {/* <Line>
              <Text>{t(translations.general.logo) + ':'}</Text>
              <Hero
                id={id}
                isConfirm={isConfirm}
                isEdit={edit}
                setIsUploadedLogo={setIsUploadedLogo}
              />
            </Line> */}
            <Column>
              <Line>
                <Text>{t(translations.pages.profile.username)} *</Text>
                <Input
                  edit={edit}
                  onChange={e => setUsername(e.target.value)}
                  value={contact_name}
                  required={true}
                  disabled={edit === true ? false : true}
                />
              </Line>
              <Line>
                <Text>{t(translations.pages.profile.fiscalId)}</Text>
                <Input
                  onChange={e => setFiscalID(e.target.value)}
                  value={fiscal_id}
                  disabled
                />
              </Line>
              <Line>
                <Text>{t(translations.pages.profile.country)} *</Text>
                <Input
                  onChange={e => setCountry(e.target.value)}
                  value={country}
                  required={true}
                  disabled
                />
              </Line>
              <Line>
                <Text>{t(translations.pages.profile.companyName)}</Text>
                <Input
                  onChange={e => setCompanyName(e.target.value)}
                  value={name_customer}
                  disabled
                ></Input>
              </Line>
              <Line>
                <Text>{t(translations.pages.profile.invoice)}</Text>
                <Input
                  edit={edit}
                  onChange={e => setInvoiceAddress(e.target.value)}
                  value={invoice_address}
                  disabled={edit === true ? false : true}
                ></Input>
              </Line>
              <Line>
                <Text>{t(translations.pages.profile.zip)}</Text>
                <Input
                  edit={edit}
                  onChange={e => setZip(e.target.value)}
                  value={zip_code}
                  disabled={edit === true ? false : true}
                ></Input>
              </Line>
            </Column>
            <Column>
              <Line>
                <Text>{t(translations.pages.profile.contact)}</Text>
                <Input
                  edit={edit}
                  onChange={e => setPhone(e.target.value)}
                  value={contact_number}
                  disabled={edit === true ? false : true}
                />
              </Line>
              <Line>
                <Text>{t(translations.pages.profile.email)} *</Text>
                <Input
                  onChange={e => setMail(e.target.value)}
                  value={email}
                  required={true}
                  disabled
                />
              </Line>
              <Line>
                <Text>{t(translations.pages.profile.companyAddress)}</Text>
                <Input
                  edit={edit}
                  onChange={e => setCompanyAddress(e.target.value)}
                  value={company_address}
                  disabled={edit === true ? false : true}
                ></Input>
              </Line>
              <Line>
                <Text>{t(translations.pages.profile.accountOwner)}</Text>
                <Input
                  onChange={e => SetAccountOwner(e.target.value)}
                  value={account_owner}
                  disabled
                ></Input>
              </Line>
              <Line>
                <Text>{t(translations.pages.profile.deliveryAddress)}</Text>
                <Input
                  onChange={e => SetAccountOwner(e.target.value)}
                  value={account_owner}
                  disabled
                ></Input>
              </Line>
              {/* 
              <Line>
                <Text>{t(translations.pages.profile.costumer)}</Text>
                <Input
                  onChange={e => setCustomer(e.target.value)}
                  value={customer}
                  disabled
                ></Input>
              </Line>
              */}
            </Column>
          </Container>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'right',
              justifyContent: 'right',
              flexWrap: 'wrap',
            }}
          >
            <Button
              style={{
                marginLeft: '60px',
                background: '#D98458',
              }}
              type="button"
              value={t(translations.pages.profile.changePass)}
              onClick={openCloseModal}
            />
            <Button
              onClick={openCloseEdit}
              style={{
                background: theme.palette.primary.main,
              }}
              type="button"
              value={t(translations.general.edit)}
            />
            <Button
              style={{
                background: edited
                  ? theme.palette.success.main
                  : theme.palette.grey.A200,
                marginBottom: '10px',
              }}
              type="submit"
              value={t(translations.general.confirm)}
              disabled={edited === true ? false : true}
            />
          </div>
        </form>
      </div>
    </div>
  );
}
