import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';
import Image from '../../../app/assets/SVG/frame1.jpg';
import Video from '../../../app/assets/SVG/video.mp4';
import caravela from '../../../app/assets/SVG/Logo-rebrand.svg';
import { LoginPayload } from 'models/authModels';
import { useState } from 'react';
import { getUser, login } from 'services/authServices';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { LoadingPage } from '../LoadingPage';
import axios from 'axios';
import { useMediaQuery } from '@mui/material';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { styledTheme } from 'styles/theme';

const DefaultFontSize = '20px';

const Bg = styled.video`
  position: absolute; /* Cambiado a absolute para que se ajuste dentro de un contenedor relativo */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  filter: brightness(50%);
`;

const WrapContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding-bottom: 1%;
  box-sizing: border-box;
`;

const Login = styled.div`
  width: 518px;
  height: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: #ffffff;
  border-radius: 30px;
  z-index: 1000;
  padding-top: 20px;
`;

const InputText = styled.div`
  font-style: normal;
  font-weight: 350;
  font-size: 20px;
  line-height: 24px;
  color: #545454;
  margin: 10px 0;
`;

const Input = styled.input`
  width: 426.84px;
  height: 48.71px;
  left: 519.78px;
  top: 391.99px;
  background: #f9f9f9;
  border-radius: 30px;
  border: 0;
  padding: 0 20px;
`;

const ForgotPass = styled.div`
  font-style: normal;
  font-weight: 350;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  cursor: pointer;
  color: #f33636;
`;

const Remember = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 325;
  font-size: 20px;
  line-height: 24px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: ${props => props.theme.palette.primary.main};
`;

const Button = styled.input`
  border: none;
  background: ${props => props.theme.palette.primary.main};
  color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  margin-bottom: 25px;
  width: 213.02px;
  height: 49.59px;
`;

const RegisterText = styled.div`
  font-style: normal;
  font-weight: 325;
  font-size: 20px;
  line-height: 24px;
  text-align: center;

  color: #545454;
`;

export function LoginPage() {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<any>('');
  const navigate = useNavigate();
  let hCaptcha;
  const [loading, setLoading] = useState<Boolean>(false);
  const captchaRef = React.useRef<HCaptcha>(null);

  const isMobile = useMediaQuery('(max-width:767px)');

  const onExpire = () => {
    console.log('hCaptcha Token Expired');
  };

  const onError = err => {
    console.log(`hCaptcha Error: ${err}`);
  };

  const setToken = (token: string, ekey: string) => {
    hCaptcha = token;
  };

  const handleSubmit = event => {
    event.preventDefault();
    const payload = { email, password, hCaptcha };
    login(payload)
      .then(response => {
        setLoading(!loading);
        if (response.status === 200) {
          setLoading(!loading);
          response?.data?.token !== undefined &&
            localStorage.setItem(
              'caravela_access_token',
              response?.data?.token,
            );
          response?.data?.firstLogin !== undefined &&
            localStorage.setItem('firstLogin', response?.data?.firstLogin);
          navigate(`/`);
        } else {
          setError(response.data);
        }
      })
      .catch(error => {
        setError(error);
        console.log(error);
        payload.hCaptcha = null;
        if (captchaRef.current) {
          captchaRef.current.resetCaptcha();
        }
      });
  };

  return (
    <>
      {loading ? (
        <LoadingPage />
      ) : (
        <>
          <Helmet>
            <title>Login</title>
            <meta name="description" content="Roasterhub login" />
          </Helmet>
          <WrapContainer>
            <Login
              style={{
                width: isMobile ? '90%' : '518px',
                height: isMobile ? '80%' : '708px',
              }}
            >
              <img
                src={caravela}
                alt="caravela logo"
                style={{
                  marginTop: isMobile ? '30px' : '40px',
                  marginBottom: isMobile ? '20px' : '25px',
                  width: '60%',
                }}
              />
              <form className="d-flex flex-column" onSubmit={handleSubmit}>
                <InputText
                  style={{
                    fontSize: isMobile ? 'medium' : DefaultFontSize,
                    marginLeft: isMobile ? '10%' : '0',
                  }}
                >
                  {t(translations.general.email)}
                </InputText>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    type="email"
                    style={{
                      marginBottom: '10px',
                      width: isMobile ? '90%' : '426.84px',
                    }}
                    onChange={e => setEmail(e.target.value)}
                  />
                </div>
                <InputText
                  style={{
                    fontSize: isMobile ? 'medium' : DefaultFontSize,
                    marginLeft: isMobile ? '10%' : '0',
                  }}
                >
                  {t(translations.general.password)}
                </InputText>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    type="password"
                    style={{
                      marginBottom: '10px',
                      width: isMobile ? '90%' : '426.84px',
                    }}
                    onChange={e => setPassword(e.target.value)}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Link
                    to="/reset-pass"
                    style={{
                      textDecoration: 'none',
                      marginRight: isMobile ? '5%' : 0,
                    }}
                  >
                    <ForgotPass
                      style={{
                        fontSize: isMobile ? 'small' : DefaultFontSize,
                      }}
                    >
                      {t(translations.pages.login.forgot_password)}
                    </ForgotPass>
                  </Link>
                </div>
                <Remember>
                  <input
                    type="checkbox"
                    style={{
                      marginRight: '10px',
                      width: '18px',
                      height: '18px',
                    }}
                  />
                  <label
                    style={{ fontSize: isMobile ? 'small' : DefaultFontSize }}
                  >
                    {t(translations.pages.login.remember_me)}
                  </label>
                </Remember>
                {error !== '' ? (
                  <div
                    style={{
                      color: 'red',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    {t(
                      `${
                        translations.errors_400[error.response?.data?.message]
                      }`,
                    )}
                  </div>
                ) : null}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    margin: '4%',
                  }}
                >
                  <HCaptcha
                    sitekey="78140159-aacd-4c54-a146-27ee75e94e95"
                    onVerify={setToken}
                    onError={onError}
                    onExpire={onExpire}
                    ref={captchaRef}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Button type="submit" value={t(translations.general.login)} />
                  <RegisterText
                    style={{
                      fontSize: isMobile ? 'medium' : DefaultFontSize,
                    }}
                  >
                    {t(translations.pages.login.i_dont_have_account)},
                  </RegisterText>
                  <Link
                    to="/register"
                    style={{
                      textDecoration: 'none',
                      margin: isMobile ? '3%' : '0',
                    }}
                  >
                    <RegisterText
                      style={{
                        fontWeight: '625',
                        fontSize: isMobile ? 'medium' : DefaultFontSize,
                      }}
                    >
                      {t(translations.pages.login.i_want_to_register)}
                    </RegisterText>
                  </Link>
                </div>
              </form>
            </Login>
          </WrapContainer>
          {/* <Bg src={Image} className="bg" alt=""></Bg> */}
          <Bg id="background-video" autoPlay loop muted poster={Image}>
            <source src={Video} type="video/mp4" />
          </Bg>
        </>
      )}
    </>
  );
}
