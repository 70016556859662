import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { getImpersonateData } from 'services/authServices';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
  position: relative; /* Para poder posicionar el botón de cerrar */
`;

const ModalCloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
`;

const ModalTitle = styled.h2`
  margin-bottom: 10px;
  margin-top: 30px;
`;

const ModalBody = styled.div``;

interface InfoProps {
  closeModal: () => void;
  data: any;
  page?: any;
  getAvailabilityCall?: (contract: any, ico: any) => void;
  getTraceabilityCall?: (contract: any, ico: any) => void;
  getTransparencyCall?: (contract: any, ico: any) => void;
  openModal?: (ico: any, report: any) => void;
  reportDetail?: any;
  setReportDetail?: (value: any) => void;
  history?: any;
}

const ModalComponent = ({
  closeModal,
  data,
  page,
  getAvailabilityCall,
  getTraceabilityCall,
  getTransparencyCall,
  openModal,
  reportDetail,
  setReportDetail,
  history,
}: InfoProps) => {
  const { t } = useTranslation();
  const [role, setRole] = useState<any>('');
  const [originalRole, setOriginalRole] = useState('');

  useEffect(() => {
    const role = localStorage.getItem('role');
    setRole(role);
  }, []);

  const close = () => {
    closeModal();
    setReportDetail && setReportDetail(false);
  };
  console.log(data);

  useEffect(() => {
    getImpersonateData()
      .then(response => {
        if (response.status === 200) {
          // response.data.Impersonate === 0 ? false : true;
          setOriginalRole(response.data.original_role);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  return (
    <ModalOverlay>
      <ModalContent>
        <ModalBody>
          <ModalCloseButton onClick={close}>×</ModalCloseButton>
          {page === 'historyC' && (
            <>
              <ModalTitle style={{ marginBottom: '50px' }}>
                {t(translations.general.detailed)}
              </ModalTitle>
              <p>
                {t(translations.table.ico)}: {data.ico_number}
              </p>
              <p>
                {t(translations.table.marks)}: {data.mark}
              </p>
              <p>
                {t(translations.table.quality)}: {data.quality}
              </p>
              <p>
                {t(translations.table.pack)}: {data.packing}
              </p>
              <p>
                {t(translations.table.price_t)}: {data.price_type}
              </p>
            </>
          )}
          {page === 'historyI' && (
            <>
              <ModalTitle style={{ marginBottom: '50px' }}>
                {t(translations.general.detailed)}
              </ModalTitle>
              <p>
                {t(translations.table.number)}: {data.number}
              </p>
              <p>
                {t(translations.table.doc_type)}: {data.document_type}
              </p>
            </>
          )}
          {(page === 'home' || page === 'purchase') && (
            <>
              <ModalTitle style={{ marginBottom: '50px' }}>
                {t(translations.general.detailed)}
              </ModalTitle>
              <p>
                {t(translations.table.quality_grade)}: {data.quality}
              </p>
              <p>
                {t(translations.table.av_date)}: {data.available_date}
              </p>
              <p>
                {t(translations.table.q_released)}: {data.released_quantity}
              </p>
              <p>
                {t(translations.table.q_available)}: {data.available_quantity}
              </p>
              <p>
                {t(translations.table.warehouse_reference)}:{' '}
                {data.warehouse_reference}
              </p>
              <p>
                {t(translations.table.size)}: {data.size}
              </p>
              <p>
                {t(translations.table.pack)}: {data.packaging_type}
              </p>
              <p>
                {t(translations.table.packP)}: {data.package_type}
              </p>
            </>
          )}
          {page === 'request-coffee' && (
            <>
              <ModalTitle style={{ marginBottom: '50px' }}>
                {t(translations.general.detailed)}
              </ModalTitle>
              <p>
                {t(translations.table.origin)}: {data.contract}
              </p>
              <p>
                {t(translations.table.ico)}: {data.ico}
              </p>
              <p>
                {t(translations.table.origin)}: {data.origin}
              </p>
              <p>
                {t(translations.table.mark)}: {data.mark}
              </p>
              <p>
                {t(translations.table.av_date)}: {data.available_date}
              </p>
              <p>
                {t(translations.table.boxes_bags)}: {data.package_type}
              </p>
              <p>
                {t(translations.table.q_available)}: {data.available_quantity}
              </p>
            </>
          )}

          {page === 'report' && reportDetail === false && (
            <>
              <ModalTitle style={{ marginBottom: '50px' }}>
                {t(translations.general.choose_report)}
              </ModalTitle>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  alignItems: 'center',
                  color: '#1989B2',
                  textDecoration: 'underline',
                }}
              >
                <p
                  onClick={() =>
                    data.visible === '1' || originalRole !== undefined
                      ? getAvailabilityCall &&
                        getAvailabilityCall(data.contract, data.ico)
                      : openModal && openModal(data.ico, 'Availability')
                  }
                >
                  {t(translations.table.availability)}
                </p>
                <p
                  onClick={() =>
                    data.visible === '1' || originalRole !== undefined
                      ? getTraceabilityCall &&
                        getTraceabilityCall(data.contract, data.ico)
                      : openModal && openModal(data.ico, 'Traceability')
                  }
                >
                  {t(translations.table.traceability)}
                </p>
                <p
                  onClick={() =>
                    data.visible === '1' || originalRole !== undefined
                      ? getTransparencyCall &&
                        getTransparencyCall(data.contract, data.ico)
                      : openModal && openModal(data.ico, 'Transparency')
                  }
                >
                  {t(translations.table.transparency)}
                </p>
              </div>
            </>
          )}
          {page === 'report' && reportDetail === true && (
            <>
              <ModalTitle style={{ marginBottom: '50px' }}>
                {t(translations.general.detailed)}
              </ModalTitle>
              <p>
                {t(translations.table.marks)}: {data.mark}
              </p>
              <p>
                {t(translations.table.quality)}: {data.quality}
              </p>
            </>
          )}
          {page === 'offering' && (
            <>
              <ModalTitle style={{ marginBottom: '50px' }}>
                {t(translations.general.detailed)}
              </ModalTitle>
              <p>
                {t(translations.table.ico_solo)}: {data.ico_id}
              </p>
              <p>
                {t(translations.table.cup)}: {data.cup_score}
              </p>
              <p>
                {t(translations.table.location)}: {data.warehouse}
              </p>
              <p>
                {t(translations.table.q_available)}: {data.available_qty}
              </p>
              <p>
                {t(translations.table.pack)}: {data.packaging}
              </p>
              <p>
                {t(translations.table.units_solo)}: {data.units_available}
              </p>
            </>
          )}

          {page === 'Delivery' && (
            <>
              <ModalTitle style={{ marginBottom: '50px' }}>
                {t(translations.general.detailed)}
              </ModalTitle>
              <p>
                {t(translations.table.marks)}: {data.mark}
              </p>
              <p>
                {t(translations.table.av_date)}: {data.date_needs}
              </p>
              <p>
                {t(translations.table.do_number)}: {data.purchase_order_id}
              </p>
              <p>
                {t(translations.table.do_date)}: {data.created_at.split('T')[0]}
              </p>
            </>
          )}
          {page === 'DeliveryH' && (
            <>
              <ModalTitle style={{ marginBottom: '50px' }}>
                {t(translations.general.detailed)}
              </ModalTitle>
              <p>
                {t(translations.table.pack)}: {data.units}
              </p>
              <p>
                {t(translations.table.q_ordered)}: {data.quantity_ordered}
              </p>
            </>
          )}
        </ModalBody>
      </ModalContent>
    </ModalOverlay>
  );
};

export default ModalComponent;
