/* 
 Definition what props.theme will look like. 
 These are the color pallete used throughout the application
*/
import { createTheme } from '@mui/material/styles';

export const styledTheme = {
  White: '#FFFFFF',
  WhitePowder: '#F3F1F1',
  Black: '#000000',
  DarkGray: '#545454',
  Gray: '#CFCFCF',
  LightGray: '#B5B5B5',
  BlueBell: '#5C36F3',
  SkyBlue: '#1989B2',
  Red: '#F33636',
  LightRed: '#D98458',
  Green: '#33BA79',
  Yellow: '#FFC727',
  DarkYellow: '#4F4E24',
  SwampGreen: '#B2BA86',
  LightBrown: '#DFDCCA',
};

export const muiTheme = createTheme({
  palette: {
    primary: {
      main: styledTheme.DarkYellow,
      contrastText: styledTheme.White,
    },
    secondary: {
      main: styledTheme.LightBrown,
      contrastText: styledTheme.Black,
    },
    error: {
      main: styledTheme.LightRed,
    },
    success: {
      main: styledTheme.SwampGreen,
    },
    info: {
      main: styledTheme.Yellow,
    },
    grey: {
      A200: styledTheme.LightGray,
    },
  },
  typography: {
    fontFamily: 'Roboto Condensed',
    fontSize: 16,
    fontWeightLight: 325,
    fontWeightRegular: 350,
    fontWeightBold: 400,
  },
});
