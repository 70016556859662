import styled from 'styled-components/macro';
import caravela from '../../../app/assets/SVG/caravela.svg';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';

const Button = styled.input`
  border: none;
  background: #5c36f3;
  color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  margin-bottom: 25px;
  width: 213.02px;
  height: 49.59px;
  margin: 0 10px;
`;

const Bg = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(15px);
`;

const ScrollableContainer = styled.div`
  height: 508px;
  overflow-y: auto;
`;

const Login = styled.div`
  width: 818px;
  height: 768px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border-radius: 30px;
  z-index: 1000;
`;

const InputText = styled.div`
  font-size: 15px;
  line-height: 14px;
  color: #545454;
  margin: 5px 0;
  padding: 0 50px;
  // text-align: center;
`;

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  // align-items: center;
  // justify-content: center;
  margin-bottom: 20px;
`;

const CloseButton = styled(Button)`
  margin-top: 35px;
  background: blue;
`;

interface InfoProps {
  closeModal: () => void;
  errors: any;
}

export function ErrorsModal({ closeModal, errors }: InfoProps) {
  const { t } = useTranslation();

  const closeModalF = () => {
    closeModal();
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          closeModal();
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <Bg>
      <Login ref={wrapperRef}>
        <h3 className="mb-3">Reporte de errores</h3>
        <ScrollableContainer>
          {errors &&
            errors.map(item => (
              <ErrorContainer key={item.row}>
                <InputText>
                  {t(translations.errors_422.error_in_row)} {item.row}
                  {t(translations.errors_422.in_column)}
                  {t(translations.errors_422[item.attribute])}
                  {t(translations.errors_422.points)}
                  {t(translations.errors_422[item.errors[0]])}
                </InputText>
              </ErrorContainer>
            ))}
        </ScrollableContainer>
        <div>
          <CloseButton
            type="button"
            onClick={closeModalF}
            value={t(translations.general.close)}
          />
        </div>
      </Login>
    </Bg>
  );
}
