import React from 'react';
import styled from 'styled-components/macro';

import { muiTheme, styledTheme } from 'styles/theme';

export const customTypography = {
  typography: {
    fontFamily: muiTheme.typography.fontFamily,
    fontWeight: muiTheme.typography.fontWeightLight,
    fontSize: '16px',
    color: styledTheme.DarkGray,
  },
};
export const SyledForm = styled.form`
  overflow-y: scroll;
  height: 70vh;
  padding: 0 11vw 5vh 15vw;
`;

export const MainContainer = styled.div`
  flex-flow: column wrap;
  text-align: center;
  height: 100%;
`;

export const FlexRowDiv = styled.div`
  display: flex;
  text-align: start;
  flex-wrap: wrap;
  > div {
    flex-grow: 1;
    flex-basis: 0;
    display: flex;
    flex-direction: column;
  }
  gap: 1em 2em;
  margin-bottom: 2em;
  width: 100%;
`;

export const Title = styled.p`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  font-size: 84px;
  line-height: 103px;
  margin: 64px 0px;
`;
export const Label = styled.label`
  font-weight: 325;
  font-size: 16px;
  padding-left: 0.5em;
  line-height: 19px;
  text-align: left;
  color: ${props => props.theme.DarkGray};
`;
export const SubTitle = styled.div`
  font-weight: 350;
  font-size: 20px;
  line-height: 24px;
  float: left;
  color: ${props => props.theme.BlueBell};
  margin-bottom: 20px;
`;

export const CustomHeader = styled.div`
  background: ${props => props.theme.White};
  height: 20vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 2px inset ${props => props.theme.LightGray};
`;

export const CustomFooter = styled.div`
  background: ${props => props.theme.White};
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0px;
  height: 10vh;
  width: 100%;
  border-top: 2px inset ${props => props.theme.LightGray};
`;
