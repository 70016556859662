import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import Dropdown from 'app/components/Dropdown';
import { GroupedTable } from 'app/components/GroupedTable';
import { IconButton, useMediaQuery } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import {
  getCoffees,
  getCoffessContract,
  getCoffessOrigin,
  getCoffessPage,
  getCoffessSearch,
  getCoffessWarehouse,
  getPurchaseOrderPage,
  getStatus,
} from 'services/purchaseService';
import SearchIcon from '@mui/icons-material/Search';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import styled, { useTheme } from 'styled-components';
import { LoadingPage } from 'app/pages/LoadingPage';
import { CircularProgress } from '@mui/material';
import { GroupedMobileTable } from 'app/components/GroupedMobileTable';
import FilterModal from 'app/components/FilterModal';
import { GroupedTableByWarehouse } from 'app/components/GrupedTableByWarehause';
import Tooltip from '@mui/material/Tooltip';
// import { LoadingBlurredPage } from 'app/pages/LoadingBlurredPage';

const Search = styled.div`
  // background: #f6f6f6;
  // border-radius: 30px;
  // width: 205.16px;
  // height: 40px;
  // margin: 10px 10px;
  padding: -30px;
`;

const StyledButton = styled.button`
  margin-right: 20px;
  background-color: #1989b2;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  font-size: 16px;
`;

const Input = styled.input`
  background: #f6f6f6;
  border-radius: 30px;
  width: 205.16px;
  height: 40px;
  margin: 10px 10px;
  outline: none;
  padding: 10px;
`;

const Clean = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  margin-left: 20px;
  margin-bottom: 5px;
  color: #1989b2;
  width: 100%;
`;
const CleanFilter = () => (
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 15L21 21M21 15L15 21M10 21V14.6627C10 14.4182 10 14.2959 9.97237 14.1808C9.94787 14.0787 9.90747 13.9812 9.85264 13.8917C9.7908 13.7908 9.70432 13.7043 9.53137 13.5314L3.46863 7.46863C3.29568 7.29568 3.2092 7.2092 3.14736 7.10828C3.09253 7.01881 3.05213 6.92127 3.02763 6.81923C3 6.70414 3 6.58185 3 6.33726V4.6C3 4.03995 3 3.75992 3.10899 3.54601C3.20487 3.35785 3.35785 3.20487 3.54601 3.10899C3.75992 3 4.03995 3 4.6 3H19.4C19.9601 3 20.2401 3 20.454 3.10899C20.6422 3.20487 20.7951 3.35785 20.891 3.54601C21 3.75992 21 4.03995 21 4.6V6.33726C21 6.58185 21 6.70414 20.9724 6.81923C20.9479 6.92127 20.9075 7.01881 20.8526 7.10828C20.7908 7.2092 20.7043 7.29568 20.5314 7.46863L17 11"
      stroke="#000000"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

interface loadingProps {
  setLoading: (value: any) => void;
  loading: any;
  setLoadingData: (value: boolean) => void;
  loadingData: boolean;
}

export function FirstStep({
  setLoading,
  loading,
  setLoadingData,
  loadingData,
}: loadingProps) {
  const theme: any = useTheme();
  const [coffes, setCoffes] = useState<any[]>();
  const [origin, setOrigin] = useState<any[]>();
  const [warehouse, setWarehouse] = useState<any[]>();
  const [contract, setContract] = useState('');
  const [error, setError] = useState<any>('');
  const [originSelected, setOriginSelected] = useState<any>('');
  const [warehouseSelected, setWarehouseSelected] = useState<any>('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  const contractInputRef = useRef<HTMLInputElement | null>(null);

  const isMobile = useMediaQuery('(max-width:767px)');

  const searchOrigin = (selected: any) => {
    setOriginSelected(selected.value);
  };

  const searchWarehouse = (selected: any) => {
    setWarehouseSelected(selected.value);
  };

  useEffect(() => {
    search();
  }, [isSearching]);

  const search = () => {
    setLoadingData(true);
    // setLoading(true);
    setError('');
    getCoffessSearch(contract, originSelected, warehouseSelected)
      .then(response => {
        if (response.status === 200) {
          setLoadingData(false);
          formatCoffe(response.data);
        }
        // setLoading(false);
      })
      .catch(error => {
        setError(error.response?.data?.message);
        setLoadingData(false);
        // setLoading(false);
        console.log(error.response?.data);
      });
  };

  const clean = () => {
    setLoadingData(true);
    if (contractInputRef.current) {
      contractInputRef.current.value = '';
    }
    setOriginSelected('');
    setWarehouseSelected('');
    setContract('');
    setError('');
    getCoffees()
      .then(response => {
        if (response.status === 200) {
          setLoadingData(false);
          formatCoffe(response.data);
          setOrigin(
            response.data.map(item => ({
              value: item.origin,
              label: item.origin,
            })),
          );
          setWarehouse(
            response.data.map(item => ({
              value: item.warehouse,
              label: item.warehouse,
            })),
          );
        }
      })
      .catch(error => {
        setLoadingData(false);
        setError(error.response?.data?.message);
        console.log(error.response?.data);
      });
  };

  useEffect(() => {
    // setLoadingData(true);
    setError('');
    getCoffees()
      .then(response => {
        if (response.status === 200) {
          // setLoadingData(false);
          formatCoffe(response.data);
          setOrigin(
            response.data.map(item => ({
              value: item.origin,
              label: item.origin,
            })),
          );
          setWarehouse(
            response.data.map(item => ({
              value: item.warehouse,
              label: item.warehouse,
            })),
          );
        }
      })
      .catch(error => {
        // setLoadingData(false);
        setError(error.response?.data?.message);
        console.log(error.response?.data);
      });
  }, []);

  const formatCoffe = coffe => {
    let groupingViaCommonProperty = Object.values(
      coffe.reduce((acc, current) => {
        acc[current.warehouse] = acc[current.warehouse] ?? [];
        acc[current.warehouse].push(current);
        return acc;
      }, {}),
    );

    setCoffes(groupingViaCommonProperty);
  };

  const { t } = useTranslation();

  let hash = {};

  return (
    <>
      <Helmet>
        <title>Purchase Order</title>
        <meta name="description" content="My Roasterhub order" />
      </Helmet>
      {isMobile && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginRight: '0',
          }}
        >
          <StyledButton
            onClick={() => setIsModalOpen(true)}
            style={{
              marginRight: '0',
              background: theme.palette.secondary.main,
              color: theme.palette.secondary.contrastText,
            }}
          >
            {t(translations.general.filter)}
          </StyledButton>
        </div>
      )}
      {!isMobile && (
        <div
          style={{
            position: 'sticky',
            top: '70px',
            background: 'white',
          }}
        >
          <div className="d-flex align-items-center">
            <div style={{ margin: '0 10px' }}>
              <Dropdown
                handleChange={searchOrigin}
                defaultValue="Origin"
                value={originSelected}
                list={
                  origin &&
                  origin.filter(o =>
                    hash[o.value] ? false : (hash[o.value] = true),
                  )
                }
              />
            </div>
            <div>
              <Dropdown
                handleChange={searchWarehouse}
                value={warehouseSelected}
                defaultValue="Warehouse"
                list={
                  warehouse &&
                  warehouse.filter(o =>
                    hash[o.value] ? false : (hash[o.value] = true),
                  )
                }
              />
            </div>
            <Search>
              <div className="d-flex align-items-center">
                <Input
                  ref={contractInputRef}
                  onChange={e => setContract(e.target.value)}
                  type="text"
                  placeholder={t(translations.table.contract)}
                />
                <div style={{ marginLeft: 'auto', cursor: 'pointer' }}>
                  {loadingData ? (
                    <CircularProgress color="primary" />
                  ) : (
                    <IconButton onClick={search} aria-label="search">
                      <SearchIcon />
                    </IconButton>
                  )}
                  {/* <SearchIcon onClick={search} /> */}
                </div>
              </div>
            </Search>
            <Tooltip title={t(translations.tooltips.clean)} placement="right">
              <IconButton onClick={clean}>
                <CleanFilter />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      )}

      <div className="d-flex justify-content-center mt-5"></div>
      <>
        {error === '' ? (
          loadingData ? (
            <LoadingPage />
          ) : !isMobile ? (
            <>
              <GroupedTableByWarehouse
                coffes={coffes}
                page={'purchase'}
                loading={loading}
                setLoading={setLoading}
              />
            </>
          ) : (
            <GroupedMobileTable
              coffes={coffes}
              page={'purchase'}
              loading={loading}
              setLoading={setLoading}
              setError={setError}
            />
          )
        ) : (
          <h3
            style={{
              color: 'gray',
              display: 'flex',
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            {t(`${translations.errors_400[error]}`)}
          </h3>
        )}
        <FilterModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          originOptions={
            origin &&
            origin.filter(o => (hash[o.value] ? false : (hash[o.value] = true)))
          }
          warehouseOptions={
            warehouse &&
            warehouse.filter(o =>
              hash[o.value] ? false : (hash[o.value] = true),
            )
          }
          onSearch={({ originSelected, warehouseSelected, contract }) => {
            // Handle search logic here
            setOriginSelected(originSelected);
            setWarehouseSelected(warehouseSelected);
            setContract(contract);
            setIsSearching(!isSearching);
            // search();
          }}
          clean={clean}
        />
      </>
    </>
  );
}
