import React, { useEffect, useRef } from 'react';
import HappyFace from 'app/assets/SVG/happy_face';
import SadFace from 'app/assets/SVG/sad_face';
import AlertUnfilled from 'app/assets/SVG/alert_unfilled';

import {
  feedbackBoxStyle,
  FeedBackLabel,
  customTypography,
  CustomRadioGroup,
} from './components';
import { translations } from 'locales/translations';
import Modal from '@mui/material/Modal';

import { styledTheme } from 'styles/theme';
import { useTranslation } from 'react-i18next';
import { CustomButton } from '../Button';

import { Box } from '@mui/system';
import Slide from '@mui/material/Slide';
import TextArea from '../TextArea';
import Radio from '@mui/material/Radio';
import { FormControlLabel, Typography, useMediaQuery } from '@mui/material';
import styled, { useTheme } from 'styled-components/macro';

const Bg = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(15px);
`;

const Login = styled.div`
  width: 518px;
  height: 468px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: #ffffff;
  border-radius: 30px;
  z-index: 1000;
`;

const FeedbackModal = ({
  open,
  onClose,
  onSubmit,
  responseMessage,
}: {
  open: boolean;
  onClose: React.FormEventHandler<HTMLFormElement>;
  onSubmit: React.FormEventHandler<HTMLFormElement>;
  responseMessage: { text: string; error: boolean };
}) => {
  const { t } = useTranslation();
  const theme: any = useTheme();
  const isMobile = useMediaQuery('(max-width:767px)');

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <Modal open={open} onClose={onClose} closeAfterTransition>
      <Slide
        in={open}
        direction="left"
        timeout={400}
        mountOnEnter
        unmountOnExit
      >
        <form onSubmit={onSubmit}>
          <Box sx={feedbackBoxStyle}>
            <FeedBackLabel>
              {t(translations.navbar.feedback.title)}
            </FeedBackLabel>

            <CustomRadioGroup name="feedback_option_id" defaultValue={3}>
              <FormControlLabel
                value={1}
                slotProps={customTypography}
                control={
                  <Radio
                    icon={<HappyFace color={styledTheme.DarkGray} />}
                    checkedIcon={<HappyFace color={styledTheme.LightRed} />}
                  />
                }
                label={t(translations.navbar.feedback.i_like)}
              />
              <FormControlLabel
                value={2}
                slotProps={customTypography}
                control={
                  <Radio
                    icon={<SadFace color={styledTheme.DarkGray} />}
                    checkedIcon={<SadFace color={styledTheme.LightRed} />}
                  />
                }
                label={t(translations.navbar.feedback.i_dont_like)}
              />
              <FormControlLabel
                value={3}
                slotProps={customTypography}
                control={
                  <Radio
                    icon={<AlertUnfilled color={styledTheme.DarkGray} />}
                    checkedIcon={<AlertUnfilled color={styledTheme.LightRed} />}
                  />
                }
                label={t(translations.navbar.feedback.i_have_a_suggestion)}
              />
            </CustomRadioGroup>
            <TextArea
              required
              name="comments"
              minRows={3}
              background={styledTheme.Gray}
              placeholder={t(translations.navbar.feedback.leave_comments)}
            />
            {responseMessage.text !== '' &&
              (responseMessage.error ? (
                <Typography color="error" variant="h6">
                  ❌ {responseMessage.text}
                </Typography>
              ) : (
                <Typography color="success.main" variant="h6">
                  ✅ {responseMessage.text}
                </Typography>
              ))}
            <CustomButton
              style={{
                alignSelf: !isMobile ? 'flex-end' : 'center',
                marginTop: '15px',
                background: theme.palette.primary.main,
              }}
              withShadow
            >
              {t(translations.general.confirm)}
            </CustomButton>
          </Box>
        </form>
      </Slide>
    </Modal>
  );
};
export default FeedbackModal;
