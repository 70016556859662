import React from 'react';
import styled from 'styled-components/macro';
import { CustomButton } from '../Button';
import { styledTheme, muiTheme } from '../../../styles/theme';
import facebook from '../../assets/SVG/facebook.svg';
import x from '../../assets/SVG/x.svg';
import instagram from '../../assets/SVG/instagram.svg';
import youtube from '../../assets/SVG/youtube.svg';
import linkedin from '../../assets/SVG/linkedin.svg';
import { translations } from 'locales/translations';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  align-self: flex-end;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 55px;
  color: ${props => props.theme.palette.primary.main};
  background: ${props => props.theme.WhitePowder};
  font-style: normal;
  font-weight: 350;
  font-size: 13px;
  line-height: 24px;
  text-align: center;
`;
const SocialMediaContainer = styled.div`
  display: flex;
  gap: 5%;
`;

export function Footer() {
  const { t } = useTranslation();
  return (
    <Container>
      <a href="https://caravela.coffee/" target="_blank" rel="noreferrer">
        <CustomButton
          background={muiTheme.palette.primary.main}
          style={{ padding: '0.3em 1.8em', fontSize: '15px' }}
        >
          caravela.coffee
        </CustomButton>
      </a>
      © Caravela Limited
      <div style={{ display: 'flex' }}>
        <a
          href="https://caravela.coffee/key-policies/"
          target="_blank"
          style={{ color: 'black', marginRight: '20px', marginTop: '5px' }}
          rel="noreferrer"
        >
          <div>{t(translations.general.key)}</div>
        </a>
        <SocialMediaContainer>
          <a
            href="https://www.facebook.com/caravelacoffee/?locale=es_LA"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={facebook}
              style={{ width: '30px', height: '30px' }}
              alt="facebook logo"
            />
          </a>
          <a
            href="https://www.twitter.com/caravelacoffee"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={x}
              style={{ width: '30px', height: '30px' }}
              alt="x logo"
            />
          </a>
          <a
            href="https://www.instagram.com/caravelacoffee/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={instagram}
              style={{ width: '30px', height: '30px' }}
              alt="instagram logo"
            />
          </a>
          <a
            href="https://www.youtube.com/@caravelacoffee4783"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={youtube}
              style={{ width: '30px', height: '30px' }}
              alt="youtube logo"
            />
          </a>
          <a
            href="https://www.linkedin.com/company/caravela-coffee/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={linkedin}
              style={{ width: '30px', height: '30px' }}
              alt="linkedin logo"
            />
          </a>
        </SocialMediaContainer>
      </div>
    </Container>
  );
}
