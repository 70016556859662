import React from 'react';
import { Navigate, RouteProps } from 'react-router-dom';
const PrivateRoute = ({ children }: PrivateRouteProps) => {
  const isSignedIn = localStorage.getItem('caravela_access_token');
  return isSignedIn ? children : <Navigate to="/login" replace />;
};

interface PrivateRouteProps extends RouteProps {
  children?: any;
}

export default PrivateRoute;
