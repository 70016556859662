import serviceClient from './serviceClientConfig';
import {
  LoginPayload,
  RegisterPayload,
  EmailPayload,
  ChangePassPayload,
  ProfilePayload,
} from '../models/authModels';

export const login = (payload: LoginPayload) => {
  return serviceClient.post('/login', payload);
};

export const getUser = () => {
  return serviceClient.get('/user');
};

export const setUser = (payload: ProfilePayload) => {
  return serviceClient.put('/user', payload);
};

export const setUserId = (payload: ProfilePayload, id: string) => {
  return serviceClient.put(`/updateUser/${id}`, payload);
};

export const LogoutAction = () => {
  localStorage.clear();
  window.location.href = '/login';
};

export const register = (payload: RegisterPayload) => {
  return serviceClient.post('/register', payload);
};

export const resetPass = (payload: EmailPayload) => {
  return serviceClient.post('/reset-password', payload);
};

export const changePass = (payload: ChangePassPayload) => {
  return serviceClient.put('/change-password', payload);
};

export const getLogo = async () => {
  const response = await serviceClient.get('/user/logo');
  if (response.status === 200) {
    // Emit an event to notify the NavBar that the logo changed
    const event = new CustomEvent('logo-changed', {
      detail: { logo: response.data.image },
    });
    document.dispatchEvent(event);
  }
  return response;
};

export const getLogoById = (id: string) => {
  return serviceClient.get(`/user/logo/${id}`);
};

export const checkImpersonate = () => {
  return serviceClient.get('/check-impersonate');
};

export const uploadLogo = (formData: any, id: string) => {
  return serviceClient.post(`/user/logo/${id}`, formData);
};

export const impersonateCustomer = (customerData: any) => {
  return serviceClient.post('/impersonation/customers', customerData);
};

export const impersonateRole = (roleData: any) => {
  return serviceClient.post('/impersonation', roleData);
};

export const getAccListImpersonate = () => {
  return serviceClient.get('/impersonation/list-accountants');
};

export const getImporterListImpersonate = () => {
  return serviceClient.get('/impersonation/list-importers');
};

export const getRoleListImpersonate = () => {
  return serviceClient.get('/impersonation/roles');
};

export const getImpersonateData = () => {
  return serviceClient.get('/impersonate-data');
};
