import styled from 'styled-components/macro';

export const MainContainer = styled.div`
  padding: 10% 5% 5%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const Clean = styled.div`
  cursor: pointer;
  color: #4f4e24;
`;

export const Heading = styled.div`
  top: 0px;
  position: sticky;
  margin: 0 0 25px 10px 0px;
  background: white;
`;

export const Title = styled.div`
  font-weight: 400;
  font-size: 60px;
  line-height: 72px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
  margin-bottom: 40px;
`;
