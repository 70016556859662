import React from 'react';

const HappyFace = ({ color }: { color: string }) => (
  <svg
    width="58"
    height="59"
    viewBox="0 0 58 59"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.875 7.71875C40.8355 7.71875 50.5312 17.4146 50.5312 29.375C50.5312 41.3355 40.8355 51.0312 28.875 51.0312C16.9146 51.0312 7.21875 41.3355 7.21875 29.375C7.21875 17.4146 16.9146 7.71875 28.875 7.71875Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M19.25 34.1875C20.4531 37.7969 23.5593 41.4062 28.875 41.4062C34.1906 41.4062 37.2969 37.7969 38.5 34.1875"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M21.6562 26.9688C22.9852 26.9688 24.0625 25.3528 24.0625 23.3594C24.0625 21.366 22.9852 19.75 21.6562 19.75C20.3273 19.75 19.25 21.366 19.25 23.3594C19.25 25.3528 20.3273 26.9688 21.6562 26.9688Z"
      fill={color}
    />
    <path
      d="M36.0938 26.9688C37.4227 26.9688 38.5 25.3528 38.5 23.3594C38.5 21.366 37.4227 19.75 36.0938 19.75C34.7648 19.75 33.6875 21.366 33.6875 23.3594C33.6875 25.3528 34.7648 26.9688 36.0938 26.9688Z"
      fill={color}
    />
  </svg>
);

export default HappyFace;
