import * as React from 'react';

import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import { originToColor, GENERIC } from '../../constants';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import {
  StyledHeadCell,
  StyledTableCell,
  SlimButton,
  Chip,
  StyledTableRow,
} from './styles';
import SalesModal from '../SalesModal';
import { Table } from '@mui/material';
import { useTheme } from 'styled-components';

interface SalesOfferingTableProps {
  coffees: any[];
  importerId: number;
  onEdit: any;
  setVisibleClient: (value: any) => void;
  visibleClient: any;
}

const getEmptyFields = t => ({
  url: { label: 'URL', value: undefined, editable: true },
  quality: {
    label: 'quality',
    value: undefined,
    editable: true,
  },
  offering_price: {
    label: 'USD/Kg',
    value: undefined,
    editable: true,
  },
  process: { label: 'process', value: undefined, editable: true },
  notes: {
    label: 'notes',
    value: undefined,
    editable: true,
  },
  visible_for_clients: {
    label: 'v_client',
    value: undefined,
    editable: true,
  },

  origin: { label: 'country', value: undefined },
  mark: { label: 'mark', value: undefined },
  ico_id: { label: 'ico', value: undefined },
  warehouse: { label: 'location', value: undefined },
  available_qty: { label: 'available', value: undefined },
  packaging: { label: 'pack', value: undefined },
  variety: { label: 'variety', value: undefined },
  certification: { label: 'certification', value: undefined },
  milling_type: { label: 'milling_type', value: undefined },
});

export function SalesOfferingTable({
  coffees,
  importerId,
  onEdit,
  setVisibleClient,
  visibleClient,
}: SalesOfferingTableProps) {
  const theme: any = useTheme();
  const [modalOpen, setModalOpen] = useState(false);
  const { t } = useTranslation();
  const emptyFields = getEmptyFields(t);
  const [modalFields, setFields] = useState(emptyFields);
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortedData, setSortedData] = useState<any>([]);
  const [activeSortColumn, setActiveSortColumn] = useState<any>(null);
  const [isColumnClicked, setIsColumnClicked] = useState('');

  let dataToMap;

  if (sortedData.length > 0) {
    dataToMap = sortedData;
  } else {
    dataToMap = coffees;
  }
  console.log(dataToMap);

  const sortData = columnName => {
    const sortOrderModifier = sortOrder === 'asc' ? 1 : -1;

    const sorted = [...(coffees || [])].sort((a, b) => {
      const valueA = a[columnName] || '';
      const valueB = b[columnName] || '';

      if (valueA < valueB) return -1 * sortOrderModifier;
      if (valueA > valueB) return 1 * sortOrderModifier;
      return 0;
    });

    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    setSortedData(sorted);
    setActiveSortColumn(columnName); // Set the active sorting column
    setIsColumnClicked(columnName);
  };

  useEffect(() => {}, []);

  const clearFields = () => {
    Object.keys(modalFields).forEach(field => {
      modalFields[field].value = '';
    });
    setFields(modalFields);
  };

  const edit = t(translations.general.edit);

  //Dependiendo el IdImporter se asigna el tipo de peso
  function defineTypeWeight() {
    if (dataToMap[0].idImporter == 3) {
      return `${t(translations.table.q_available)} lbs`;
    } else {
      return `${t(translations.table.q_available)} kg`;
    }
  }

  return (
    <div>
      <SalesModal
        open={modalOpen}
        setFields={setFields}
        onClose={() => {
          setModalOpen(false);
        }}
        onSubmit={coffe => {
          onEdit(coffe);
        }}
        fields={modalFields}
        visibleClient={visibleClient}
        setVisibleClient={setVisibleClient}
        importId={importerId}
      />
      <TableContainer sx={{ width: '100%' }}>
        <Table>
          <TableHead sx={{ width: '100%' }}>
            <StyledTableRow sx={{ width: '100%' }}>
              {/* <StyledHeadCell align="center">
                {t(translations.table.country)}
              </StyledHeadCell> */}
              <StyledHeadCell
                style={{
                  cursor: 'pointer',
                  background: theme.palette.secondary.main,
                }}
                align="center"
                onClick={() => sortData('origin')}
                onMouseEnter={() => setActiveSortColumn('origin')}
                onMouseLeave={() => {
                  if (!isColumnClicked) {
                    setActiveSortColumn(null);
                  }
                }}
              >
                {t(translations.table.country)}{' '}
                {(activeSortColumn === 'origin' ||
                  isColumnClicked === 'origin') && (
                  <span style={{ fontSize: '20px' }}>
                    {sortOrder === 'asc' ? '↑' : '↓'}
                  </span>
                )}
              </StyledHeadCell>
              {/* <StyledHeadCell align="center">
                {t(translations.table.mark)}
              </StyledHeadCell> */}
              <StyledHeadCell
                style={{
                  cursor: 'pointer',
                  background: theme.palette.secondary.main,
                }}
                align="center"
                onClick={() => sortData('mark')}
                onMouseEnter={() => setActiveSortColumn('mark')}
                onMouseLeave={() => {
                  if (!isColumnClicked) {
                    setActiveSortColumn(null);
                  }
                }}
              >
                {t(translations.table.mark)}{' '}
                {(activeSortColumn === 'mark' ||
                  isColumnClicked === 'mark') && (
                  <span style={{ fontSize: '20px' }}>
                    {sortOrder === 'asc' ? '↑' : '↓'}
                  </span>
                )}
              </StyledHeadCell>
              <StyledHeadCell
                style={{ background: theme.palette.secondary.main }}
                align="center"
              >
                {t(translations.table.ico_solo)}
              </StyledHeadCell>
              <StyledHeadCell
                style={{ background: theme.palette.secondary.main }}
                align="center"
              >
                {t(translations.table.quality)}{' '}
                {/* antes -> cup_score === ahora -> quality / 82 === RTB */}
              </StyledHeadCell>
              {/* <StyledHeadCell align="center">
                {t(translations.table.location)}
              </StyledHeadCell> */}
              <StyledHeadCell
                style={{
                  cursor: 'pointer',
                  background: theme.palette.secondary.main,
                }}
                align="center"
                onClick={() => sortData('warehouse')}
                onMouseEnter={() => setActiveSortColumn('warehouse')}
                onMouseLeave={() => {
                  if (!isColumnClicked) {
                    setActiveSortColumn(null);
                  }
                }}
              >
                {t(translations.table.location)}{' '}
                {(activeSortColumn === 'warehouse' ||
                  isColumnClicked === 'warehouse') && (
                  <span style={{ fontSize: '20px' }}>
                    {sortOrder === 'asc' ? '↑' : '↓'}
                  </span>
                )}
              </StyledHeadCell>
              {/* <StyledHeadCell align="center">
                {t(translations.table.status)}
              </StyledHeadCell> */}
              <StyledHeadCell
                style={{
                  cursor: 'pointer',
                  background: theme.palette.secondary.main,
                }}
                align="center"
                onClick={() => sortData('status')}
                onMouseEnter={() => setActiveSortColumn('status')}
                onMouseLeave={() => {
                  if (!isColumnClicked) {
                    setActiveSortColumn(null);
                  }
                }}
              >
                {t(translations.table.status)}{' '}
                {(activeSortColumn === 'status' ||
                  isColumnClicked === 'status') && (
                  <span style={{ fontSize: '20px' }}>
                    {sortOrder === 'asc' ? '↑' : '↓'}
                  </span>
                )}
              </StyledHeadCell>
              <StyledHeadCell
                style={{ background: theme.palette.secondary.main }}
                align="center"
              >
                {t(translations.table.units_solo)}
              </StyledHeadCell>
              {/* <StyledHeadCell
                style={{ cursor: 'pointer' }}
                align="center"
                onClick={() => sortData('units_available')}
                onMouseEnter={() => setActiveSortColumn('units_available')}
                onMouseLeave={() => {
                  if (!isColumnClicked) {
                    setActiveSortColumn(null);
                  }
                }}
              >
                {t(translations.table.units_solo)}{' '}
                {(activeSortColumn === 'units_available' ||
                  isColumnClicked === 'units_available') && (
                  <span style={{ fontSize: '20px' }}>
                    {sortOrder === 'asc' ? '↑' : '↓'}
                  </span>
                )}
              </StyledHeadCell> */}
              {/* <StyledHeadCell align="center">
                {t(translations.table.packaging)}
              </StyledHeadCell> */}
              <StyledHeadCell
                style={{
                  cursor: 'pointer',
                  background: theme.palette.secondary.main,
                }}
                align="center"
                onClick={() => sortData('packaging')}
                onMouseEnter={() => setActiveSortColumn('packaging')}
                onMouseLeave={() => {
                  if (!isColumnClicked) {
                    setActiveSortColumn(null);
                  }
                }}
              >
                {t(translations.table.packaging)}{' '}
                {(activeSortColumn === 'packaging' ||
                  isColumnClicked === 'packaging') && (
                  <span style={{ fontSize: '20px' }}>
                    {sortOrder === 'asc' ? '↑' : '↓'}
                  </span>
                )}
              </StyledHeadCell>
              <StyledHeadCell
                style={{ background: theme.palette.secondary.main }}
                align="center"
              >
                {dataToMap.length > 0
                  ? defineTypeWeight()
                  : t(translations.table.q_available)}
                {/* {t(translations.table.q_available)} lbs */}
              </StyledHeadCell>
              {/* <StyledHeadCell
                style={{ cursor: 'pointer' }}
                align="center"
                onClick={() => sortData('available_qty')}
                onMouseEnter={() => setActiveSortColumn('available_qty')}
                onMouseLeave={() => {
                  if (!isColumnClicked) {
                    setActiveSortColumn(null);
                  }
                }}
              >
                {t(translations.table.q_available)} lbs{' '}
                {(activeSortColumn === 'available_qty' ||
                  isColumnClicked === 'available_qty') && (
                  <span style={{ fontSize: '20px' }}>
                    {sortOrder === 'asc' ? '↑' : '↓'}
                  </span>
                )}
              </StyledHeadCell> */}

              {/*Se condiciona para solo mostrar el precio para UK = importerId-1 */}
              {importerId == 1 && (
                <StyledHeadCell
                  style={{ background: theme.palette.secondary.main }}
                  align="center"
                >
                  USD/Kg
                </StyledHeadCell>
              )}

              {/* <StyledHeadCell
                style={{ cursor: 'pointer' }}
                align="center"
                ng_price')}
                onMouseEnter={() => setActiveSortColumn('offering_price')}
                onMouseLeave={() => {
                  if (!isColumnClicked) {
                    setActiveSortColumn(null);
                  }
                }}
              >
                {t(translations.table.starting)}{' '}
                {(activeSortColumn === 'offering_price' ||
                  isColumnClicked === 'offering_price') && (
                  <span style={{ fontSize: '20px' }}>
                    {sortOrder === 'asc' ? '↑' : '↓'}
                  </span>
                )}
              </StyledHeadCell> */}
              <StyledHeadCell
                align="center"
                background={theme.palette.primary.main}
                sx={{ color: theme.palette.primary.contrastText }}
              >
                {t(translations.table.info)}
              </StyledHeadCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {dataToMap?.length > 0
              ? dataToMap.map(item => {
                  // todo add key  key={item.ico_id}
                  return (
                    <StyledTableRow>
                      <StyledTableCell align="center">
                        <div
                          style={{
                            borderRadius: '30px',
                            background:
                              originToColor[item.origin.toUpperCase()] ||
                              GENERIC,
                            color: 'white',
                            padding: '15px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '40px',
                            width: '160px',
                          }}
                        >
                          {item.origin}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Chip>{item.mark}</Chip>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {item.ico_id}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {item.quality}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {item.warehouse}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {item.status}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {item.units_available}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {item.packaging}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {item.available_qty}
                      </StyledTableCell>
                      {/*Se condiciona para solo mostrar el precio para UK = importerId-1 */}
                      {importerId == 1 ? (
                        <StyledTableCell align="center">
                          {item.offering_price}
                        </StyledTableCell>
                      ) : null}
                      <StyledTableCell align="center">
                        <SlimButton
                          onClick={() => {
                            const newFields = modalFields;
                            Object.keys(newFields).forEach(field => {
                              newFields[field].value = item[field] || '';
                            });
                            setVisibleClient(item.is_visible);
                            setFields(newFields);
                            setModalOpen(true);
                          }}
                          withShadow
                          color={theme.WhitePowder}
                          background={theme.palette.primary.main}
                        >
                          {edit}
                        </SlimButton>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })
              : null}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
