import SearchIcon from '@mui/icons-material/Search';
import { AdminTable } from 'app/components/AdminTable';
import Dropdown from 'app/components/Dropdown';
import { SwitchTable } from 'app/components/SwitchTable';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components/macro';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { AddDeleteModal } from 'app/components/AddDeleteModal';
import { getRoleList } from 'services/adminServices';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import axios from 'axios';

const RoundedButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: ${props => props.color};
  color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  margin-bottom: 25px;
  width: 47px;
  height: 44px;
  margin: 0 10px;
  margin-left: auto;
  cursor: pointer;
  &:focus {
    background: #9224a5;
  }
  &:active {
    color: #5c36f3;
  }
`;

const Button = styled.input`
  border: none;
  background: #5c36f3;
  color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  margin-bottom: 25px;
  width: 213.02px;
  height: 49.59px;
  margin: 0 10px;
  margin-left: auto;
`;

const Search = styled.div`
  display: flex;
  flex-direction: column;
`;

const dataPages = [
  { value: 'Home' },
  { value: 'Purchase Order' },
  { value: 'Review Delivery Order' },
  { value: 'Sample Request' },
  { value: 'Offerings' },
  { value: 'Reports' },
  { value: 'Edit Reports' },
  { value: 'History' },
  { value: 'Where is my Coffee' },
  { value: 'Cupping' },
  { value: 'Admin' },
  { value: 'Admin Role' },
];

const dataPermission = [
  { value: 'Purchase Order' },
  { value: 'Downloads' },
  { value: 'Sample Request' },
  { value: 'Caravela Limited' },
  { value: 'Caravela Australia' },
  { value: 'Caravela USA' },
  { value: 'Caravela Asia' },
];

const dataPermissionAdmin = [
  { value: 'Purchase Order' },
  { value: 'Downloads' },
  { value: 'Sample Request' },
];

export function AdminRolePage(role: any) {
  const [id, setId] = useState('');
  const [roles, setRoles] = useState('');
  const [selectedRol, setSelectedRol] = useState('');
  const [addDeleteOption, setAddDeleteOption] = useState('');
  const [modified, setModified] = useState(false);
  const [modules, setModules] = useState<any[]>([]);
  const [permissions, setPermissions] = useState<any[]>([]);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const { t } = useTranslation();

  const addDelete = (value: string) => {
    setAddDeleteOption(value);
  };

  const searchId = () => {};

  const searchRoles = (selected: any) => {
    setSelectedRol(selected.value);
  };

  useEffect(() => {
    getRoleList()
      .then(response => {
        if (response.status === 200) {
          setRoles(
            response.data.map(item => ({
              value: item,
              label: item,
            })),
          );
        }
      })
      .catch(error => {
        console.log(error.response?.data);
      });
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('caravela_access_token');
    const url = `${process.env.REACT_APP_BASE_URL}/admin-role`;
    selectedRol !== '' &&
      axios
        .get(url, {
          params: {
            role: selectedRol,
          },
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(response => {
          if (response.status === 200) {
            const filteredModules = response.data.filter(
              value => value.split(';')[1] === 'View',
            );
            const filteredPermissions = response.data.filter(
              value =>
                value.split(';')[1] === 'Customer' ||
                value.includes('Caravela'),
            );

            setModules(filteredModules.map(value => value.split(';')[0]));
            setPermissions(
              filteredPermissions.map(value => value.split(';')[0]),
            );
          }
        })
        .catch(error => {
          console.log(error.response?.data);
        });
  }, [selectedRol]);

  interface Objeto {
    name: string;
    value: number;
  }

  const handleSubmit = () => {
    setSuccess(false);
    setError(false);
    const token = localStorage.getItem('caravela_access_token');
    const url = `${process.env.REACT_APP_BASE_URL}/admin-role`;
    const moduleModified: Objeto[] = [];
    const permissionModified: Objeto[] = [];

    for (let i = 0; i < dataPages.length; i++) {
      const newObj = {
        name: `${dataPages[i].value};View`,
        value: modules.includes(dataPages[i].value) ? 1 : 0,
      };
      moduleModified.push(newObj);
    }

    for (let i = 0; i < dataPermission.length; i++) {
      if (dataPermission[i].value.includes('Caravela')) {
        const newObj = {
          name: dataPermission[i].value,
          value: permissions.includes(dataPermission[i].value) ? 1 : 0,
        };
        permissionModified.push(newObj);
      } else {
        const newObj = {
          name: `${dataPermission[i].value};Customer`,
          value: permissions.includes(dataPermission[i].value) ? 1 : 0,
        };
        permissionModified.push(newObj);
      }
    }

    axios
      .put(
        url,
        {
          role: selectedRol,
          permissions: moduleModified.concat(permissionModified),
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )
      .then(response => {
        if (response.status === 200) {
          setSuccess(true);
          window.location.reload();
        }
      })
      .catch(error => {
        setError(true);
      });
  };

  const clean = () => {
    setId('');
  };

  let hash = {};

  return addDeleteOption !== '' ? (
    <AddDeleteModal
      setAddDeleteOption={setAddDeleteOption}
      type={addDeleteOption}
      role={'role'}
      selectedRol={selectedRol}
    />
  ) : (
    <>
      <Helmet>
        <title>{t(translations.general.admin_role)}</title>
        <meta name="description" content="Roasterhub delivery review" />
      </Helmet>

      <div>
        <div
          className="d-flex"
          style={{
            padding: '0 96px',

            flexDirection: 'column',
          }}
        >
          <>
            <div className="d-flex mt-5 justify-content-start align-items-center">
              <div>
                <Search>
                  <div
                    style={{
                      fontSize: '13px',
                      fontWeight: '625',
                      margin: '0 20px',
                    }}
                  >
                    {t(translations.general.roles)}
                  </div>
                  {/* <Dropdown
                    handleChange={searchRoles}
                    value={roles}
                    list={roles}
                  /> */}
                  {roles.length > 0 && (
                    <Dropdown
                      handleChange={searchRoles}
                      value={selectedRol}
                      list={roles}
                    />
                  )}
                </Search>
              </div>
              <div onClick={() => addDelete('add')}>
                <RoundedButton color={'#1989B2'} style={{ margin: '0 40px' }}>
                  <AddIcon />
                </RoundedButton>
              </div>
              <div onClick={() => addDelete('delete')}>
                <RoundedButton color={'#F33636'}>
                  <DeleteIcon />
                </RoundedButton>
              </div>
              <Button
                type="button"
                onClick={handleSubmit}
                disabled={modified === false ? true : false}
                style={modified === false ? { background: 'grey' } : {}}
                value={'Save'}
              />
            </div>
          </>
          {success && (
            <h3
              style={{
                color: 'green',
                marginTop: '40px',
              }}
            >
              {t(translations.general.changes_saved)}
            </h3>
          )}
          {error && (
            <h3 style={{ color: 'red', marginTop: '40px' }}>
              {t(translations.general.error_something)}
            </h3>
          )}
          {selectedRol !== '' && (
            <div
              style={{
                marginTop: '60px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div style={{ marginRight: '20px' }}>
                <SwitchTable
                  pages={true}
                  data={dataPages}
                  modules={modules}
                  permissions={permissions}
                  setModified={setModified}
                  modified={modified}
                  setModules={setModules}
                  setPermissions={setPermissions}
                />
              </div>
              <div>
                <SwitchTable
                  pages={false}
                  data={
                    selectedRol === 'Admin' || selectedRol === 'IT'
                      ? dataPermissionAdmin
                      : dataPermission
                  }
                  modules={modules}
                  permissions={permissions}
                  setModified={setModified}
                  modified={modified}
                  setModules={setModules}
                  setPermissions={setPermissions}
                  role={role}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
