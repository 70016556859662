import serviceClient from 'services/serviceClientConfig';

export const getTransparency = (contract, ico) => {
  return serviceClient.get(
    `/reports/transparency?contract=${contract}&ico=${ico}`,
  );
};

export const getPosition = () => {
  return serviceClient.get(`/reports/position`);
};

// export const getReports = () => {
//   return serviceClient.get(`/reports`);
// };

export const getReports = (contract?, origin?, warehouse?) => {
  return serviceClient.get(
    `/reports?contract=${contract === undefined ? '' : contract}&origin=${
      origin === undefined ? '' : origin
    }&warehouse=${warehouse === undefined ? '' : warehouse}`,
  ); /* with filter  /coffees?origin=COLOMBIA&warehouse=test */
};

export const getAvailability = () => {
  return serviceClient.get('/reports/availability');
};

export const getAvailabilityNotice = () => {
  return serviceClient.get('/reports/availabilityNoticeReport');
};

export const getTraceability = (contract, ico) => {
  return serviceClient.get(
    `/reports/traceability?contract=${contract}&ico=${ico}`,
  );
};
