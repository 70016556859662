import * as React from 'react';
import { useEffect, useState } from 'react';
import Dropdown from 'app/components/Dropdown';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components/macro';
import { LoadingPage } from '../LoadingPage';
import SearchIcon from '@mui/icons-material/Search';
import 'leaflet/dist/leaflet.css';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import L, { Icon, Popup } from 'leaflet';
import { LatLng } from 'leaflet'; // Import LatLng class from leaflet
import Summary from 'app/assets/SVG/resumen-general.png';
import MundoIcon from 'app/assets/SVG/ordered.png';
import SecondIcon from 'app/assets/SVG/pending.png';
import ThirdIcon from 'app/assets/SVG/milling.png';
import FourthIcon from 'app/assets/SVG/port.png';
import FifthIcon from 'app/assets/SVG/barco.png';
import SixthIcon from 'app/assets/SVG/available.png';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Ping from 'app/assets/SVG/location-sign-svgrepo-com.svg';
import { getLocation } from 'services/locationServices';
import { useMediaQuery, IconButton } from '@mui/material';
import { CircularProgress } from '@mui/material';
import 'leaflet/dist/images/marker-shadow.png';
import { Hero } from 'app/components/Hero';
import Tooltip from '@mui/material/Tooltip';

const ICOItem = styled.div<{ state: string }>`
  background-color: ${props =>
    props.state === 'active' ? '#ffffff' : '#DFDCCA'};
  color: ${props => (props.state === 'active' ? '#000000' : '#000000')};
  border-radius: 30px;
  border: 4px solid #dfdcca;
  width: 245px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3px;
  cursor: pointer;
  // margin-left: -40px;
`;

const SummaryItem = styled.div`
  background-color: #dfdcca;
  color: #000000;
  border-radius: 100%;
  width: 50px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const SummaryItemText = styled.p`
  margin: 5px 0;
  color: #333;
  font-size: 20px;
`;
const Tab = styled.div<{ state: string }>`
  background-color: ${props =>
    props.state === 'active' ? '#ffffff' : '#DFDCCA'};
  color: ${props => (props.state === 'active' ? '#000000' : '#000000')};
  border-radius: 30px;
  border: 4px solid #dfdcca;
  width: 185px;
  height: 38px;
  display: flex;
  justify-content: center;
  margin: 10px;
  cursor: pointer;
  // margin-left: -40px;
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #dfdcca;
  color: '#DFDCCA';
  border-radius: 30px;
  border: 4px solid #dfdcca;
  // width: 245px;
  height: 50px;
  display: flex;
  justify-content: center;
  margin: 30px 10px;
  cursor: pointer;
  // margin-left: -40px;
`;

const Text = styled.div`
  font-weight: 325;
  font-size: 20px;
`;

/* const TitleTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 10px;
  padding: 0 10px;
  width: 25%;
`; */
const TitleTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 20px; /* márgenes alrededor del contenedor */
  width: 200px;
`;

const TitleInfo = styled.div`
  font-style: normal;
  font-weight: 350;
  font-size: 22px;
  line-height: 30px;
  color: #000000;
  margin-bottom: 15px;
`;

/* const TextInfo = styled.div`
  font-style: normal;
  font-weight: 325;
  font-size: 19px;
  //line-height: 15px;
  color: #545454;
  margin-bottom: 15px;
`;

const TextInfoLink = styled.div`
  font-style: normal;
  font-weight: 350;
  font-size: 13px;
  line-height: 16px;
  text-decoration-line: underline;
  margin-bottom: 15px;
  color: #1989b2;
`; */

const TextInfo = styled.p`
  margin: 5px 0;
  color: #333;
  font-size: 14px;
`;

const TextInfoLink = styled.a`
  margin: 5px 0;
  color: #5c36f3;
  font-size: 14px;
  text-decoration: underline;
  &:hover {
    color: #3b21c9;
  }
`;

const DataContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  // flex-wrap: wrap;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  // flex-basis: 55%;
  // flex-grow: 5;
  align-items: center;
  justify-content: flex-start;
`;

const ICOContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
  // flex-grow: 1;
  // flex-basis: 35%;
`;

/* const InfoContainer = styled.div`
  width: 100%;
  height: 100%;
  background: #f8f8f8;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  justify-content: space-around;
  align-content: space-around;
`; */
const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px;
  margin-top: 20px;
  background-color: #f9f9f9; /* color de fondo suave */
  border-radius: 10px; /* bordes redondeados */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* sombra ligera */
`;

const Search = styled.div`
  // background: #f6f6f6;
  // border-radius: 30px;
  // width: 205.16px;
  // height: 40px;
  // margin: 10px 10px;
`;

const Input = styled.input`
  background: #f6f6f6;
  border-radius: 30px;
  width: 205.16px;
  height: 40px;
  margin: 10px 10px;
  outline: none;
  padding: 10px;
`;

const Clean = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  margin-left: 20px;
  color: #1989b2;
`;

const Port = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: black;
  padding: 0 30px;
`;

const CleanFilter = () => (
  <svg
    width="19px"
    height="19px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 15L21 21M21 15L15 21M10 21V14.6627C10 14.4182 10 14.2959 9.97237 14.1808C9.94787 14.0787 9.90747 13.9812 9.85264 13.8917C9.7908 13.7908 9.70432 13.7043 9.53137 13.5314L3.46863 7.46863C3.29568 7.29568 3.2092 7.2092 3.14736 7.10828C3.09253 7.01881 3.05213 6.92127 3.02763 6.81923C3 6.70414 3 6.58185 3 6.33726V4.6C3 4.03995 3 3.75992 3.10899 3.54601C3.20487 3.35785 3.35785 3.20487 3.54601 3.10899C3.75992 3 4.03995 3 4.6 3H19.4C19.9601 3 20.2401 3 20.454 3.10899C20.6422 3.20487 20.7951 3.35785 20.891 3.54601C21 3.75992 21 4.03995 21 4.6V6.33726C21 6.58185 21 6.70414 20.9724 6.81923C20.9479 6.92127 20.9075 7.01881 20.8526 7.10828C20.7908 7.2092 20.7043 7.29568 20.5314 7.46863L17 11"
      stroke="#000000"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export function LocationPage() {
  // const icon = new Icon({
  //   iconUrl: '/handcuffs.svg',
  //   iconSize: [25, 25],
  // });
  const [loading, setLoading] = useState<Boolean>(false);
  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState<any>('');
  const [page, setPage] = useState(1);
  const [selected, setSelected] = useState(1);
  const [ordered, setOrdered] = useState<any[]>([]);
  const [PSSPending, setPSSPending] = useState<any[]>([]);
  const [milling, setMIlling] = useState<any[]>([]);
  const [port, setPort] = useState<any[]>([]);
  const [afloat, setAfloat] = useState<any[]>([]);
  const [available, setAvailable] = useState<any[]>([]);
  const [orderedFiltered, setOrderedFiltered] = useState<any[]>([]);
  const [PSSPendingFiltered, setPSSPendingFiltered] = useState<any[]>([]);
  const [millingFiltered, setMIllingFiltered] = useState<any[]>([]);
  const [portFiltered, setPortFiltered] = useState<any[]>([]);
  const [afloatFiltered, setAfloatFiltered] = useState<any[]>([]);
  const [availableFiltered, setAvailableFiltered] = useState<any[]>([]);
  const [arrSelected, setArrSelected] = useState<any>([]);
  const [icoContractSelected, setIcoContractSelected] = useState<any>('');
  const [contractSelected, setContractSelected] = useState<any>('');
  const [originSelected, setOriginSelected] = useState<any>('');
  const [icoAvailableSelected, setIcoAvailableSelected] = useState<any>('');
  const [origins, setOrigins] = useState<any[]>();
  const [position, setPosition] = useState<any>();
  const isMobile = useMediaQuery('(max-width:767px)');

  const contractICOInputRef = React.useRef<HTMLInputElement | null>(null);
  const contractInputRef = React.useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (arrSelected.length > 0) {
      const {
        milling_latitude,
        milling_longitude,
        port_latitude,
        port_longitude,
        warehouse_latitude,
        warehouse_longitude,
      } = arrSelected[0];

      page === 3 &&
        setPosition({ lat: milling_latitude, lng: milling_longitude });
      // setPosition({ lat: '100', lng: '200' });
      page === 4 && setPosition({ lat: port_latitude, lng: port_longitude });
      page === 6 &&
        setPosition({
          lat: warehouse_latitude,
          lng: warehouse_longitude,
        });
    }
  }, [arrSelected]);

  useEffect(() => {}, [ordered, PSSPending]);

  useEffect(() => {}, [position]);

  useEffect(() => {}, [arrSelected]);

  useEffect(() => {
    if (ordered.length > 0) {
      setPageAndReset(0);
    } else if (ordered.length > 0) {
      setPageAndReset(1);
    } else if (PSSPending.length > 0) {
      setPageAndReset(2);
    } else if (milling.length > 0) {
      setPageAndReset(3);
    } else if (port.length > 0) {
      setPageAndReset(4);
    } else if (afloat.length > 0) {
      setPageAndReset(5);
    } else if (available.length > 0) {
      setPageAndReset(6);
    } else {
      setPageAndReset(0);
    }
  }, [ordered, PSSPending, milling, port, afloat, available]);

  useEffect(() => {
    setLoading(true);
    getLocation()
      .then(response => {
        if (response.status === 200) {
          setOrigins(
            response.data.map(item => ({
              value: item.origin,
              label: item.origin,
            })),
          );
          setOrdered(response.data.filter(item => item.status === 'Ordered'));
          setPSSPending(
            response.data.filter(item => item.status === 'PSSPending'),
          );
          setMIlling(response.data.filter(item => item.status === 'Milling'));
          setPort(response.data.filter(item => item.status === 'Port'));
          setAfloat(response.data.filter(item => item.status === 'Afloat'));
          setAvailable(
            response.data.filter(item => item.status === 'Available'),
          );
          setOrderedFiltered(
            response.data.filter(item => item.status === 'Ordered'),
          );
          setPSSPendingFiltered(
            response.data.filter(item => item.status === 'PSSPending'),
          );
          setMIllingFiltered(
            response.data.filter(item => item.status === 'Milling'),
          );
          setPortFiltered(response.data.filter(item => item.status === 'Port'));
          setAfloatFiltered(
            response.data.filter(item => item.status === 'Afloat'),
          );
          setAvailableFiltered(
            response.data.filter(item => item.status === 'Available'),
          );
          setLoading(false);
        }
      })
      .catch(error => {
        setLoading(false);
        setError(error.response?.data?.message);
        console.log(error);
      });
  }, []);

  const clean = () => {
    if (contractICOInputRef.current) {
      contractICOInputRef.current.value = '';
    }
    if (contractInputRef.current) {
      contractInputRef.current.value = '';
    }
    setLoadingData(true);
    setIcoContractSelected('');
    setContractSelected('');
    setOriginSelected('');
    setIcoAvailableSelected('');
    getLocation()
      .then(response => {
        if (response.status === 200) {
          setOrigins(
            response.data.map(item => ({
              value: item.origin,
              label: item.origin,
            })),
          );
          setOrdered(response.data.filter(item => item.status === 'Ordered'));
          setPSSPending(
            response.data.filter(item => item.status === 'PSSPending'),
          );
          setMIlling(response.data.filter(item => item.status === 'Milling'));
          setPort(response.data.filter(item => item.status === 'Port'));
          setAfloat(response.data.filter(item => item.status === 'Afloat'));
          setAvailable(
            response.data.filter(item => item.status === 'Available'),
          );
          setOrderedFiltered(
            response.data.filter(item => item.status === 'Ordered'),
          );
          setPSSPendingFiltered(
            response.data.filter(item => item.status === 'PSSPending'),
          );
          setMIllingFiltered(
            response.data.filter(item => item.status === 'Milling'),
          );
          setPortFiltered(response.data.filter(item => item.status === 'Port'));
          setAfloatFiltered(
            response.data.filter(item => item.status === 'Afloat'),
          );
          setAvailableFiltered(
            response.data.filter(item => item.status === 'Available'),
          );
          setLoading(false);
          setLoadingData(false);
        }
      })
      .catch(error => {
        setError(error.response?.data?.message);
        setLoadingData(false);
        setLoading(false);
        console.log(error);
      });
  };

  const setPageAndReset = page => {
    setPage(page);
    setIcoAvailableSelected('');
    setArrSelected([]);
  };

  const setSelectedData = ico => {
    setSelected(ico);
    page === 1 && setArrSelected(ordered.filter(item => item.ico === ico));
    page === 2 && setArrSelected(PSSPending.filter(item => item.ico === ico));
    page === 3 && setArrSelected(milling.filter(item => item.ico === ico));
    page === 4 && setArrSelected(port.filter(item => item.ico === ico));
    page === 5 && setArrSelected(afloat.filter(item => item.ico === ico));
    page === 6 && setArrSelected(available.filter(item => item.ico === ico));
  };

  const setOrigin = (selected: any) => {
    setOriginSelected(selected.value);
  };

  const setIcoAvailable = (selected: any) => {
    setIcoAvailableSelected(selected.value);
    setSelectedData(selected.value);
  };

  const search = () => {
    setLoadingData(true);
    setArrSelected([]);
    const filteredOrdered =
      orderedFiltered.length > 0
        ? orderedFiltered.filter(
            item =>
              (originSelected === '' || item.origin === originSelected) &&
              (icoContractSelected === '' ||
                (item.ico !== null && item.ico.includes(icoContractSelected))),
          )
        : [];
    setOrdered(filteredOrdered);

    const filteredPSSPending =
      PSSPendingFiltered.length > 0
        ? PSSPendingFiltered.filter(
            item =>
              (originSelected === '' || item.origin === originSelected) &&
              (icoContractSelected === '' ||
                (item.ico !== undefined &&
                  item.ico.includes(icoContractSelected))) &&
              (contractSelected === '' ||
                (item.contract !== undefined &&
                  item.contract.includes(contractSelected))),
          )
        : [];
    setPSSPending(filteredPSSPending);

    const filteredMilling =
      millingFiltered.length > 0
        ? millingFiltered.filter(
            item =>
              (originSelected === '' || item.origin === originSelected) &&
              (icoContractSelected === '' ||
                (item.ico !== undefined &&
                  item.ico.includes(icoContractSelected))) &&
              (contractSelected === '' ||
                (item.contract !== undefined &&
                  item.contract.includes(contractSelected))),
          )
        : [];
    setMIlling(filteredMilling);

    const filteredPort =
      portFiltered.length > 0
        ? portFiltered.filter(
            item =>
              (originSelected === '' || item.origin === originSelected) &&
              (icoContractSelected === '' ||
                (item.ico !== undefined &&
                  item.ico.includes(icoContractSelected))) &&
              (contractSelected === '' ||
                (item.contract !== undefined &&
                  item.contract.includes(contractSelected))),
          )
        : [];
    setPort(filteredPort);

    const filteredAfloat =
      afloatFiltered.length > 0
        ? afloatFiltered.filter(
            item =>
              (originSelected === '' || item.origin === originSelected) &&
              (icoContractSelected === '' ||
                (item.ico !== undefined &&
                  item.ico.includes(icoContractSelected))) &&
              (contractSelected === '' ||
                (item.contract !== undefined &&
                  item.contract.includes(contractSelected))),
          )
        : [];
    setAfloat(filteredAfloat);

    const filteredAvailable =
      availableFiltered.length > 0
        ? availableFiltered.filter(
            item =>
              (originSelected === '' || item.origin === originSelected) &&
              (icoContractSelected === '' ||
                (item.ico !== undefined &&
                  item.ico.includes(icoContractSelected))) &&
              (contractSelected === '' ||
                (item.contract !== undefined &&
                  item.contract.includes(contractSelected))),
          )
        : [];
    setAvailable(filteredAvailable);

    setTimeout(() => {
      setLoadingData(false);
    }, 1000);
  };
  const { t } = useTranslation();

  let hash = {};

  const customIcon = L.icon({
    iconUrl: Ping,
    iconSize: [32, 32], // Tamaño personalizado del ícono [ancho, alto]
  });

  return (
    <>
      <Helmet>
        <title>{t(translations.general.where)}</title>
        <meta name="description" content="Roasterhub delivery review" />
      </Helmet>
      {loading ? (
        <LoadingPage />
      ) : (
        <div>
          <div
            className="d-flex"
            style={{
              padding: '0 96px',
              flexDirection: 'column',
            }}
          >
            <div
              className="d-flex"
              style={{
                justifyContent: 'center',
                marginLeft: !isMobile ? '10px' : '0px',
                flexDirection: !isMobile ? 'row' : 'column',
                alignItems: 'center',
              }}
            >
              <div className="d-flex justify-content-start w-100">
                <Hero
                  title={t(translations.general.where)}
                  subtitle={t(translations.where_coffe.description)}
                />
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div style={{ marginRight: '10px' }}>
                <Dropdown
                  handleChange={setOrigin}
                  defaultValue="Origin"
                  value={originSelected}
                  list={
                    origins &&
                    origins.filter(o =>
                      hash[o.value] ? false : (hash[o.value] = true),
                    )
                  }
                />
              </div>
              <Search>
                <div className="d-flex align-items-center">
                  <Input
                    ref={contractICOInputRef}
                    onChange={e => setIcoContractSelected(e.target.value)}
                    type="text"
                    placeholder={t(translations.table.search_ico)}
                  />
                  <Input
                    ref={contractInputRef}
                    onChange={e => setContractSelected(e.target.value)}
                    type="text"
                    placeholder={t(translations.table.search_contract)}
                  />
                  <div style={{ marginLeft: 'auto', cursor: 'pointer' }}>
                    <SearchIcon onClick={search} />
                  </div>
                </div>
              </Search>
              <Tooltip title={t(translations.tooltips.clean)} placement="right">
                <IconButton onClick={clean}>
                  <CleanFilter />
                </IconButton>
              </Tooltip>
            </div>
            <div
              className="d-flex"
              style={{
                justifyContent: 'center',
                marginLeft: '10px',
              }}
            >
              <TabContainer>
                <div onClick={() => setPageAndReset(0)}>
                  <Tab state={page === 0 ? 'active' : 'inactive'}>
                    <Text>{t(translations.general.summary)}</Text>
                  </Tab>
                </div>
                <div onClick={() => setPageAndReset(1)}>
                  <Tab state={page === 1 ? 'active' : 'inactive'}>
                    <Text>{t(translations.general.contracted)}</Text>
                  </Tab>
                </div>
                <div onClick={() => setPageAndReset(2)}>
                  <Tab state={page === 2 ? 'active' : 'inactive'}>
                    <Text>{t(translations.general.pss_pending)}</Text>
                  </Tab>
                </div>
                <div onClick={() => setPageAndReset(3)}>
                  <Tab state={page === 3 ? 'active' : 'inactive'}>
                    <Text>{t(translations.general.milling)}</Text>
                  </Tab>
                </div>
                <div onClick={() => setPageAndReset(4)}>
                  <Tab state={page === 4 ? 'active' : 'inactive'}>
                    <Text>{t(translations.general.port)}</Text>
                  </Tab>
                </div>
                <div onClick={() => setPageAndReset(5)}>
                  <Tab state={page === 5 ? 'active' : 'inactive'}>
                    <Text>{t(translations.general.afloat)}</Text>
                  </Tab>
                </div>
                <div onClick={() => setPageAndReset(6)}>
                  <Tab state={page === 6 ? 'active' : 'inactive'}>
                    <Text>{t(translations.general.available)}</Text>
                  </Tab>
                </div>
              </TabContainer>
            </div>
            {loadingData ? (
              <div
                style={{
                  // transform: 'scale(0.2)',
                  minWidth: 700,
                  // marginTop: '-200px',
                }}
              >
                <LoadingPage />
              </div>
            ) : (
              <DataContainer>
                <ICOContainer>
                  {page === 0 && (
                    <img
                      src={Summary}
                      style={{
                        height: '150px',
                        width: '150px',
                        padding: '10px',
                      }}
                      alt="resumen"
                    />
                  )}
                  {page === 1 && (
                    <img
                      src={MundoIcon}
                      style={{
                        height: '150px',
                        width: '150px',
                        padding: '15px',
                      }}
                      alt="ordered"
                    />
                  )}
                  {page === 2 && (
                    <img
                      src={SecondIcon}
                      style={{
                        height: '150px',
                        width: '150px',
                        padding: '15px',
                      }}
                      alt="pending"
                    />
                  )}
                  {page === 3 && (
                    <img
                      src={ThirdIcon}
                      style={{
                        height: '150px',
                        width: '150px',
                        padding: '15px',
                      }}
                      alt="milling"
                    />
                  )}
                  {page === 4 && (
                    <img
                      src={FourthIcon}
                      style={{
                        height: '150px',
                        width: '150px',
                        padding: '15px',
                      }}
                      alt="port"
                    />
                  )}
                  {page === 5 && (
                    <img
                      src={FifthIcon}
                      style={{
                        height: '150px',
                        width: '150px',
                        padding: '15px',
                      }}
                      alt="afloat"
                    />
                  )}
                  {page === 6 && (
                    <img
                      src={SixthIcon}
                      style={{
                        height: '150px',
                        width: '120px',
                        padding: '15px',
                      }}
                      alt="available"
                    />
                  )}
                  {page === 1 &&
                    ordered &&
                    ordered.map(item => (
                      <ICOItem
                        state={selected === item.ico ? 'active' : 'inactive'}
                        onClick={() => setSelectedData(item.ico)}
                      >
                        {item.ico}
                      </ICOItem>
                    ))}
                  {page === 2 &&
                    PSSPending &&
                    PSSPending.map(item => (
                      <ICOItem
                        state={selected === item.ico ? 'active' : 'inactive'}
                        onClick={() => setSelectedData(item.ico)}
                      >
                        {item.ico}
                      </ICOItem>
                    ))}
                  {page === 3 &&
                    milling &&
                    milling.map(item => (
                      <ICOItem
                        state={selected === item.ico ? 'active' : 'inactive'}
                        onClick={() => setSelectedData(item.ico)}
                      >
                        {item.ico}
                      </ICOItem>
                    ))}
                  {page === 4 &&
                    port &&
                    port.map(item => (
                      <ICOItem
                        state={selected === item.ico ? 'active' : 'inactive'}
                        onClick={() => setSelectedData(item.ico)}
                      >
                        {item.ico}
                      </ICOItem>
                    ))}
                  {page === 5 &&
                    afloat &&
                    afloat.map(item => (
                      <ICOItem
                        state={selected === item.ico ? 'active' : 'inactive'}
                        onClick={() => setSelectedData(item.ico)}
                      >
                        {item.ico}
                      </ICOItem>
                    ))}
                  {page === 6 &&
                    available &&
                    available.map(item => (
                      <ICOItem
                        state={selected === item.ico ? 'active' : 'inactive'}
                        onClick={() => setSelectedData(item.ico)}
                      >
                        {item.ico}
                      </ICOItem>
                    ))}
                  {/* {page === 6 && available && (
                    <div style={{ marginRight: '10px' }}>
                      <Dropdown
                        handleChange={setIcoAvailable}
                        placeholder="Select ..."
                        defaultValue="IcoAvailable"
                        value={icoAvailableSelected}
                        list={available.map(item => ({
                          value: item.ico,
                          label: item.ico,
                        }))}
                      />
                    </div>
                  )}
 */}
                </ICOContainer>
                <ContentContainer>
                  {page === 0 && (
                    <InfoContainer>
                      <div className="d-flex">
                        <TitleTextContainer onClick={() => setPageAndReset(1)}>
                          <TitleInfo>
                            {t(translations.general.contracted)}
                          </TitleInfo>
                          <SummaryItemText>
                            <SummaryItem>{ordered.length}</SummaryItem>
                          </SummaryItemText>
                        </TitleTextContainer>
                        <TitleTextContainer onClick={() => setPageAndReset(2)}>
                          <TitleInfo>
                            {t(translations.general.pss_pending)}
                          </TitleInfo>
                          <SummaryItemText>
                            <SummaryItem>{PSSPending.length}</SummaryItem>
                          </SummaryItemText>
                        </TitleTextContainer>
                        <TitleTextContainer onClick={() => setPageAndReset(3)}>
                          <TitleInfo>
                            {t(translations.general.milling)}
                          </TitleInfo>
                          <SummaryItemText>
                            <SummaryItem>{milling.length}</SummaryItem>
                          </SummaryItemText>
                        </TitleTextContainer>
                      </div>
                      <div className="d-flex">
                        <TitleTextContainer onClick={() => setPageAndReset(4)}>
                          <TitleInfo>{t(translations.general.port)}</TitleInfo>
                          <SummaryItemText>
                            <SummaryItem>{port.length}</SummaryItem>
                          </SummaryItemText>
                        </TitleTextContainer>
                        <TitleTextContainer onClick={() => setPageAndReset(5)}>
                          <TitleInfo>
                            {t(translations.general.afloat)}
                          </TitleInfo>
                          <SummaryItemText>
                            <SummaryItem>{afloat.length}</SummaryItem>
                          </SummaryItemText>
                        </TitleTextContainer>
                        <TitleTextContainer onClick={() => setPageAndReset(6)}>
                          <TitleInfo>
                            {t(translations.general.available)}
                          </TitleInfo>
                          <SummaryItemText>
                            <SummaryItem>{available.length}</SummaryItem>
                          </SummaryItemText>
                        </TitleTextContainer>
                      </div>
                    </InfoContainer>
                  )}
                  {page === 1 && arrSelected.length > 0 && (
                    <InfoContainer>
                      <div className="d-flex">
                        <TitleTextContainer>
                          <TitleInfo>Origin</TitleInfo>
                          <TextInfo>{arrSelected[0].origin}</TextInfo>
                        </TitleTextContainer>
                        <TitleTextContainer>
                          <TitleInfo>Contract</TitleInfo>
                          <TextInfo>{arrSelected[0].contract}</TextInfo>
                        </TitleTextContainer>
                        <TitleTextContainer>
                          <TitleInfo>ICO</TitleInfo>
                          <TextInfo>{arrSelected[0].ico}</TextInfo>
                        </TitleTextContainer>
                        <TitleTextContainer>
                          <TitleInfo>Mark</TitleInfo>
                          {arrSelected[0].url_mark !== null ? (
                            <a
                              href={arrSelected[0].url_mark}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <TextInfoLink>{arrSelected[0].mark}</TextInfoLink>
                            </a>
                          ) : (
                            <TextInfo>{arrSelected[0].mark}</TextInfo>
                          )}
                        </TitleTextContainer>
                      </div>
                      <div className="d-flex">
                        <TitleTextContainer>
                          <TitleInfo>Quantity</TitleInfo>
                          <TextInfo>{arrSelected[0].quantity}</TextInfo>
                        </TitleTextContainer>
                        <TitleTextContainer>
                          <TitleInfo>Weight</TitleInfo>
                          <TextInfo>{arrSelected[0].weight}</TextInfo>
                        </TitleTextContainer>
                        <TitleTextContainer>
                          <TitleInfo>Shipment Month/Year</TitleInfo>
                          <TextInfo>{arrSelected[0].shipment_date}</TextInfo>
                        </TitleTextContainer>
                        <TitleTextContainer>
                          <TitleInfo>Estimated Time of Arrival</TitleInfo>
                          <TextInfo>{arrSelected[0].estimate_arrival}</TextInfo>
                        </TitleTextContainer>
                      </div>
                    </InfoContainer>
                  )}
                  {page === 2 && arrSelected.length > 0 && (
                    <InfoContainer>
                      <div className="d-flex">
                        <TitleTextContainer>
                          <TitleInfo>Mark</TitleInfo>
                          {arrSelected[0].url_mark !== null ? (
                            <a
                              href={arrSelected[0].url_mark}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <TextInfoLink>{arrSelected[0].mark}</TextInfoLink>
                            </a>
                          ) : (
                            <TextInfo>{arrSelected[0].mark}</TextInfo>
                          )}
                        </TitleTextContainer>
                        <TitleTextContainer>
                          <TitleInfo>Contract</TitleInfo>
                          <TextInfo>{arrSelected[0].contract}</TextInfo>
                        </TitleTextContainer>
                        <TitleTextContainer>
                          <TitleInfo>ICO</TitleInfo>
                          <TextInfo>{arrSelected[0].ico}</TextInfo>
                        </TitleTextContainer>
                        <TitleTextContainer>
                          <TitleInfo>Sample</TitleInfo>
                          <TextInfo>{arrSelected[0].sample}</TextInfo>
                        </TitleTextContainer>
                        <TitleTextContainer>
                          <TitleInfo>Status</TitleInfo>
                          <TextInfo style={{ color: '#4f4e24' }}>
                            <TextInfo>{arrSelected[0].pss_status}</TextInfo>
                          </TextInfo>
                        </TitleTextContainer>
                      </div>
                      <div className="d-flex">
                        <TitleTextContainer>
                          <TitleInfo>Shipment Date</TitleInfo>
                          <TextInfo>{arrSelected[0].shipment_date}</TextInfo>
                        </TitleTextContainer>
                        <TitleTextContainer>
                          <TitleInfo>Track & Trace</TitleInfo>
                          <TextInfo>{arrSelected[0].track_trace}</TextInfo>
                        </TitleTextContainer>
                      </div>
                    </InfoContainer>
                  )}
                  {page === 3 && arrSelected.length > 0 && (
                    <InfoContainer style={{ height: '201px' }}>
                      <div className="d-flex">
                        <TitleTextContainer>
                          <TitleInfo>Milling Date</TitleInfo>
                          <TextInfo>{arrSelected[0].milling_date}</TextInfo>
                        </TitleTextContainer>
                        <TitleTextContainer>
                          <TitleInfo>Quality</TitleInfo>
                          <TextInfo>{arrSelected[0].quality}</TextInfo>
                        </TitleTextContainer>
                        <TitleTextContainer>
                          <TitleInfo>Moisture</TitleInfo>
                          <TextInfo>{arrSelected[0].moisture}</TextInfo>
                        </TitleTextContainer>
                        <TitleTextContainer>
                          <TitleInfo>Water Activity</TitleInfo>
                          <TextInfo>{arrSelected[0].water_activity}</TextInfo>
                        </TitleTextContainer>
                      </div>
                    </InfoContainer>
                  )}
                  {page === 4 && arrSelected.length > 0 && (
                    <InfoContainer style={{ height: '201px' }}>
                      <Port>At Origin Port</Port>
                      <div className="d-flex">
                        <TitleTextContainer>
                          <TitleInfo>Port Name </TitleInfo>
                          <TextInfo> {arrSelected[0].port_name}</TextInfo>
                        </TitleTextContainer>
                        <TitleTextContainer>
                          <TitleInfo>Estimated Date of Departure</TitleInfo>
                          <TextInfo>
                            {arrSelected[0].estimated_deperture}
                          </TextInfo>
                        </TitleTextContainer>
                        <TitleTextContainer>
                          <TitleInfo>Destination Port</TitleInfo>
                          <TextInfo>{arrSelected[0].destination_port}</TextInfo>
                        </TitleTextContainer>
                        <TitleTextContainer>
                          <TitleInfo>Average Stripping</TitleInfo>
                          <TextInfo>
                            {arrSelected[0].average_stripping}
                          </TextInfo>
                        </TitleTextContainer>
                      </div>
                    </InfoContainer>
                  )}
                  {page === 5 && arrSelected.length > 0 && (
                    <InfoContainer>
                      <div className="d-flex">
                        <TitleTextContainer>
                          <TitleInfo>Current Estimated Transit Time</TitleInfo>
                          <TextInfo>
                            {arrSelected[0].current_estimated_transit}
                          </TextInfo>
                        </TitleTextContainer>
                        <TitleTextContainer>
                          <TitleInfo>Vessel Name</TitleInfo>
                          <TextInfo>{arrSelected[0].vessel}</TextInfo>
                        </TitleTextContainer>
                        <TitleTextContainer>
                          <TitleInfo>Container Number</TitleInfo>
                          <TextInfo>{arrSelected[0].container}</TextInfo>
                        </TitleTextContainer>
                        <TitleTextContainer>
                          <TitleInfo>BL</TitleInfo>
                          <TextInfo>{arrSelected[0].bl}</TextInfo>
                        </TitleTextContainer>
                      </div>
                    </InfoContainer>
                  )}
                  {page === 6 && arrSelected.length > 0 && (
                    <InfoContainer>
                      <div className="d-flex">
                        <TitleTextContainer>
                          <TitleInfo>Origin</TitleInfo>
                          <TextInfo>{arrSelected[0].origin}</TextInfo>
                        </TitleTextContainer>
                        <TitleTextContainer>
                          <TitleInfo>Contract</TitleInfo>
                          <TextInfo>{arrSelected[0].contract}</TextInfo>
                        </TitleTextContainer>
                        <TitleTextContainer>
                          <TitleInfo>ICO</TitleInfo>
                          <TextInfo>{arrSelected[0].ico}</TextInfo>
                        </TitleTextContainer>
                        <TitleTextContainer>
                          <TitleInfo>Mark</TitleInfo>
                          {arrSelected[0].url_mark !== null ? (
                            <a
                              href={arrSelected[0].url_mark}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <TextInfoLink>{arrSelected[0].mark}</TextInfoLink>
                            </a>
                          ) : (
                            <TextInfo>{arrSelected[0].mark}</TextInfo>
                          )}
                        </TitleTextContainer>
                      </div>
                      <div className="d-flex">
                        <TitleTextContainer>
                          <TitleInfo>Warehouse</TitleInfo>
                          <TextInfo>{arrSelected[0].warehouse}</TextInfo>
                        </TitleTextContainer>
                        <TitleTextContainer>
                          <TitleInfo>Quality</TitleInfo>
                          <TextInfo>{arrSelected[0].quality}</TextInfo>
                        </TitleTextContainer>
                        <TitleTextContainer>
                          <TitleInfo>Quantity</TitleInfo>
                          <TextInfo>{arrSelected[0].quantity}</TextInfo>
                        </TitleTextContainer>
                        <TitleTextContainer>
                          <TitleInfo>BIN Number</TitleInfo>
                          <TextInfo>{arrSelected[0].bin}</TextInfo>
                        </TitleTextContainer>
                      </div>
                    </InfoContainer>
                  )}
                  {((page === 1 && ordered.length > 0) ||
                    (page === 2 && PSSPending.length > 0) ||
                    (page === 3 && milling.length > 0) ||
                    (page === 4 && port.length > 0) ||
                    (page === 5 && afloat.length > 0) ||
                    (page === 6 && available.length > 0)) &&
                    arrSelected.length === 0 && (
                      <InfoContainer
                        style={{
                          width: '700px',
                          height: '229px',
                          marginLeft: '7%',
                        }}
                      >
                        <div className="d-flex justify-content-center">
                          <h4>{t(translations.general.select_ico)}</h4>
                        </div>
                      </InfoContainer>
                    )}
                  {page === 1 && ordered.length === 0 && (
                    <h3
                      style={{
                        color: 'gray',
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '70px',
                        textAlign: 'center',
                      }}
                    >
                      {error === ''
                        ? t(translations.general.no_search)
                        : t(`${translations.errors_400[error]}`)}
                    </h3>
                  )}
                  {page === 2 && PSSPending.length === 0 && (
                    <h3
                      style={{
                        color: 'gray',
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '70px',
                        textAlign: 'center',
                      }}
                    >
                      {error === ''
                        ? t(translations.general.no_search)
                        : t(`${translations.errors_400[error]}`)}
                    </h3>
                  )}
                  {page === 3 && milling.length === 0 && (
                    <h3
                      style={{
                        color: 'gray',
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '70px',
                        textAlign: 'center',
                      }}
                    >
                      {error === ''
                        ? t(translations.general.no_search)
                        : t(`${translations.errors_400[error]}`)}
                    </h3>
                  )}
                  {page === 4 && port.length === 0 && (
                    <h3
                      style={{
                        color: 'gray',
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '70px',
                        textAlign: 'center',
                      }}
                    >
                      {error === ''
                        ? t(translations.general.no_search)
                        : t(`${translations.errors_400[error]}`)}
                    </h3>
                  )}
                  {page === 5 && afloat.length === 0 && (
                    <h3
                      style={{
                        color: 'gray',
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '70px',
                        textAlign: 'center',
                      }}
                    >
                      {error === ''
                        ? t(translations.general.no_search)
                        : t(`${translations.errors_400[error]}`)}
                    </h3>
                  )}
                  {page === 6 && available.length === 0 && (
                    <h3
                      style={{
                        color: 'gray',
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '70px',
                        textAlign: 'center',
                      }}
                    >
                      {error === ''
                        ? t(translations.general.no_search)
                        : t(`${translations.errors_400[error]}`)}
                    </h3>
                  )}
                  {(page === 4 || page === 6 || page === 3) &&
                    arrSelected.length > 0 &&
                    position && (
                      <MapContainer
                        key={`${position.lat}-${position.lng}`}
                        center={position}
                        zoom={13}
                        scrollWheelZoom={false}
                      >
                        <TileLayer
                          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <Marker position={position} icon={customIcon}></Marker>
                      </MapContainer>
                    )}
                </ContentContainer>
              </DataContainer>
            )}
          </div>
        </div>
      )}
    </>
  );
}
