import styled, { useTheme } from 'styled-components/macro';
import caravela from '../../../app/assets/SVG/caravela.svg';
import { Link } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { ChangePassPayload } from 'models/authModels';
import { changePass } from 'services/authServices';
import { Wrapper } from 'app/pages/NotFoundPage/styles';

interface OfferinDataProps {
  openCloseModal: () => void;
  country: string;
  mark: string;
  ico: string;
  quality: string;
  location: string;
  qAvailable: string;
  starting: string;
  packaging: string;
  category: string;
  process: string;
  notes: string;
  variety: string;
  certification: string;
  milling_type: string;
}

const Bg = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(15px);
`;

const Login = styled.div`
  width: 518px;
  // height: 708px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: #ffffff;
  border-radius: 30px;
  z-index: 1000;
`;

const WrapperX = styled.div`
  position: relative;
  display: inline-block;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 7px;
  flex-grow: 1;
  flex-wrap: wrap;
`;

const Info = styled.div`
  color: #545454;
  font-size: 15px;
  margin-right: 10px;
  flex-basis: 20%;
`;

const Data = styled.div`
  background: #d9d9d9;
  border-radius: 20px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  color: #545454;
  width: 250px;
  flex-basis: 60%;
`;

const Close = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  font-size: 40px;
  font-weight: 900;
  margin-right: 20px;
  :before {
    content: '✕';
  }
`;

export function OfferingData({
  openCloseModal,
  country,
  mark,
  ico,
  quality,
  location,
  qAvailable,
  starting,
  packaging,
  category,
  process,
  notes,
  variety,
  certification,
  milling_type,
}: OfferinDataProps) {
  const { t } = useTranslation();
  const theme: any = useTheme();

  const closeModal = () => {
    openCloseModal();
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          closeModal();
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  // const resetModal = () => {
  //   openCloseModal();
  // };

  return (
    <Bg>
      <WrapperX>
        <Login ref={wrapperRef}>
          <div style={{ padding: '96px' }}>
            {country && (
              <Row>
                <Info>{t(translations.table.country)}</Info>
                <Data
                  style={{
                    background: theme.palette.secondary.main,
                    color: theme.palette.secondary.contrastText,
                  }}
                >
                  {country}
                </Data>
              </Row>
            )}
            {mark && (
              <Row>
                <Info>{t(translations.table.mark)}</Info>

                <Data
                  style={{
                    background: theme.palette.primary.main,
                    color: 'white',
                  }}
                >
                  {mark}
                </Data>
              </Row>
            )}
            {ico && (
              <Row>
                <Info>{t(translations.table.ico_solo)}</Info>
                <Data
                  style={{
                    background: theme.palette.secondary.main,
                    color: theme.palette.secondary.contrastText,
                  }}
                >
                  {ico}
                </Data>
              </Row>
            )}
            {quality && (
              <Row>
                <Info>{t(translations.table.quality)}</Info>
                <Data
                  style={{
                    background: theme.palette.secondary.main,
                    color: theme.palette.secondary.contrastText,
                  }}
                >
                  {quality}
                </Data>
              </Row>
            )}
            {location && (
              <Row>
                <Info>{t(translations.table.location)}</Info>
                <Data
                  style={{
                    background: theme.palette.secondary.main,
                    color: theme.palette.secondary.contrastText,
                  }}
                >
                  {location}
                </Data>
              </Row>
            )}
            {qAvailable && (
              <Row>
                <Info>{t(translations.table.q_available)}</Info>
                <Data
                  style={{
                    background: theme.palette.secondary.main,
                    color: theme.palette.secondary.contrastText,
                  }}
                >
                  {qAvailable}
                </Data>
              </Row>
            )}
            {starting && (
              <Row>
                <Info>{t(translations.table.starting)}</Info>
                <Data
                  style={{
                    background: theme.palette.secondary.main,
                    color: theme.palette.secondary.contrastText,
                  }}
                >
                  {starting}
                </Data>
              </Row>
            )}
            {packaging && (
              <Row>
                <Info>{t(translations.table.pack)}</Info>
                <Data
                  style={{
                    background: theme.palette.secondary.main,
                    color: theme.palette.secondary.contrastText,
                  }}
                >
                  {packaging}
                </Data>
              </Row>
            )}
            {category && (
              <Row>
                <Info>{t(translations.table.category)}</Info>
                <Data
                  style={{
                    background: theme.palette.secondary.main,
                    color: theme.palette.secondary.contrastText,
                  }}
                >
                  {category}
                </Data>
              </Row>
            )}
            {process && (
              <Row>
                <Info>{t(translations.table.process)}</Info>
                <Data
                  style={{
                    background: theme.palette.secondary.main,
                    color: theme.palette.secondary.contrastText,
                  }}
                >
                  {process}
                </Data>
              </Row>
            )}
            {notes && (
              <Row>
                <Info>{t(translations.table.notes)}</Info>
                <Data
                  style={{
                    background: theme.palette.secondary.main,
                    color: theme.palette.secondary.contrastText,
                  }}
                >
                  {notes}
                </Data>
              </Row>
            )}
            {variety && (
              <Row>
                <Info>{t(translations.table.variety)}</Info>
                <Data
                  style={{
                    background: theme.palette.secondary.main,
                    color: theme.palette.secondary.contrastText,
                  }}
                >
                  {variety}
                </Data>
              </Row>
            )}
            {certification && (
              <Row>
                <Info>{t(translations.table.certification)}</Info>
                <Data
                  style={{
                    background: theme.palette.secondary.main,
                    color: theme.palette.secondary.contrastText,
                  }}
                >
                  {certification}
                </Data>
              </Row>
            )}
            {milling_type && (
              <Row>
                <Info>{t(translations.table.milling_type)}</Info>
                <Data
                  style={{
                    background: theme.palette.secondary.main,
                    color: theme.palette.secondary.contrastText,
                  }}
                >
                  {milling_type}
                </Data>
              </Row>
            )}
          </div>
        </Login>
        <Close></Close>
      </WrapperX>
    </Bg>
  );
}
