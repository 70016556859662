import * as React from 'react';
import { DeliveryTable } from 'app/components/DeliveryTable';
import { DeliveryTableHistorical } from 'app/components/DeliveryTableHistorical';
import Dropdown from 'app/components/Dropdown';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { getCountries, getMarks } from 'services/generalServices';
import { getReviewHistorical, getReviewPending } from 'services/reviewServices';
import styled, { useTheme } from 'styled-components/macro';
import { LoadingPage } from '../LoadingPage';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, useMediaQuery } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { Button } from '@mui/material';
import FilterModalDelivery from 'app/components/FilterModalDelivery';
import FilterModalDeliveryH from 'app/components/FilterModalDeliveryH';
import { Hero } from 'app/components/Hero';
import Tooltip from '@mui/material/Tooltip';

const StyledButton = styled.button`
  background-color: #4f4e24;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  font-size: 16px;
  margin-top: '30px';
`;

const Tab = styled.div<{ state: string }>`
  background-color: ${props =>
    props.state === 'active' ? '#DFDCCA' : '#4F4E24'};
  color: ${props => (props.state === 'active' ? '#000000' : '#F8F8F8')};
  cursor: ${props => (props.state === 'active' ? 'default' : 'pointer')};
  transition: all 0.5s ease-in-out;
  border-radius: 30px;
  border: 4px solid #4f4e24;
  width: 245px;
  height: 38px;
  display: flex;
  justify-content: center;
  margin: 10px;
  margin-left: -40px;
  @media (max-width: 768px) {
    border-radius: 0; /* Remove border-radius for mobile */
    margin: 5px; /* Reduce the margin for mobile */
    margin-left: 0; /* Remove negative margin for mobile */
  }
`;

const Text = styled.div`
  font-weight: 325;
  font-size: 20px;
`;

const ButtonStyled = styled.input`
  border: none;
  background: #5c36f3;
  color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  margin-bottom: 25px;
  width: 213.02px;
  height: 49.59px;
  margin: 0 10px;
`;

const Input = styled.input`
  background: #f6f6f6;
  border-radius: 30px;
  width: 205.16px;
  height: 40px;
  margin: 10px 0;
  margin-right: 10px;
  outline: none;
  padding: 10px;
`;

const Search = styled.div`
  display: flex;
  flex-direction: column;
`;

const Clean = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  margin-left: 20px;
  color: #1989b2;
`;

const CleanFilter = () => (
  <svg
    width="25px"
    height="25px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 15L21 21M21 15L15 21M10 21V14.6627C10 14.4182 10 14.2959 9.97237 14.1808C9.94787 14.0787 9.90747 13.9812 9.85264 13.8917C9.7908 13.7908 9.70432 13.7043 9.53137 13.5314L3.46863 7.46863C3.29568 7.29568 3.2092 7.2092 3.14736 7.10828C3.09253 7.01881 3.05213 6.92127 3.02763 6.81923C3 6.70414 3 6.58185 3 6.33726V4.6C3 4.03995 3 3.75992 3.10899 3.54601C3.20487 3.35785 3.35785 3.20487 3.54601 3.10899C3.75992 3 4.03995 3 4.6 3H19.4C19.9601 3 20.2401 3 20.454 3.10899C20.6422 3.20487 20.7951 3.35785 20.891 3.54601C21 3.75992 21 4.03995 21 4.6V6.33726C21 6.58185 21 6.70414 20.9724 6.81923C20.9479 6.92127 20.9075 7.01881 20.8526 7.10828C20.7908 7.2092 20.7043 7.29568 20.5314 7.46863L17 11"
      stroke="#000000"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export function ReviewDeliveryPage() {
  const theme: any = useTheme();

  const [page, setPage] = useState(false);
  const [actualPage, setActualPage] = useState(1);
  const [pages, setPages] = useState();
  const [delivery, setDelivery] = useState<any[]>();
  const [deliveryHistorical, setDeliveryHistorical] = useState<any[]>();
  const [deliveryHistoricalRaw, setDeliveryHistoricalRaw] = useState<any[]>();
  const [origin, setOrigin] = useState<any[]>();
  const [mark, setMark] = useState<any[]>();
  const [originSelected, setOriginSelected] = useState('');
  const [markSelected, setMarkSelected] = useState('');
  const [dateInit, setDateInit] = useState('');
  const [dateEnd, setDateEnd] = useState('');
  const [errorInfoD, setErrorInfoD] = useState('');
  const [errorInfoH, setErrorInfoH] = useState('');
  const [loading, setLoading] = useState<Boolean>(true);
  const [loadingData, setLoadingData] = useState(false);
  const [test, setTest] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isMobile = useMediaQuery('(max-width:767px)');

  const initInputRef = useRef<HTMLInputElement | null>(null);
  const endInputRef = useRef<HTMLInputElement | null>(null);

  const searchOrigin = (selected: any) => {
    setOriginSelected(selected.value);
  };

  const searchMark = (selected: any) => {
    setMarkSelected(selected.value);
  };

  useEffect(() => {
    setOriginSelected('');
    setMarkSelected('');
    setDateInit('');
    setDateEnd('');
    if (initInputRef.current) {
      initInputRef.current.value = '';
    }
    if (endInputRef.current) {
      endInputRef.current.value = '';
    }
  }, [page]);

  const populate = () => {
    setErrorInfoD('');
    setErrorInfoH('');
    setLoadingData(true);
    getReviewPending()
      .then(response => {
        if (response.status === 200) {
          setLoadingData(false);
          setLoading(false);
          response.data.data.length > 0
            ? setDelivery(response.data)
            : setErrorInfoD(t(translations.general.no_search));
        }
      })
      .catch(error => {
        setLoadingData(false);
        setLoading(false);
        setErrorInfoD(error.response.data.message);
        console.log(error);
      });
    getCountries()
      .then(response => {
        if (response.status === 200) {
          // setLoadingData(false);
          let arrO = response.data.map(item => ({
            value: item.name,
            label: item.name,
          }));
          setOrigin(arrO);
        }
      })
      .catch(error => {
        // setLoadingData(false);
        console.log(error);
      });
    getMarks()
      .then(response => {
        if (response.status === 200) {
          // setLoadingData(false);
          let arrM = response.data.data.map(item => ({
            value: item.mark,
            label: item.mark,
          }));
          setMark(arrM);
        }
      })
      .catch(error => {
        // setLoadingData(false);
        console.log(error.response?.data);
      });

    getReviewHistorical(actualPage)
      .then(response => {
        if (response.status === 200) {
          setLoadingData(false);
          setActualPage(response.data.meta.current_page);
          setPages(response.data.meta.last_page);
          formatCoffe(response.data.data);
          getCountries().then(response => {
            if (response.status === 200) {
              let arrO = response.data.map(item => ({
                value: item.name,
                label: item.name,
              }));
              setOrigin(arrO);
            }
          });
          getMarks().then(response => {
            if (response.status === 200) {
              let arrM = response.data.map(item => ({
                value: item.name,
                label: item.name,
              }));
              setMark(arrM);
            }
          });
        }
      })
      .catch(error => {
        setLoadingData(false);
        setLoading(false);
        setErrorInfoH(error.response.data.message);
        // console.log(error);
      });
  };

  const formatCoffe = coffe => {
    setDeliveryHistoricalRaw(coffe);
    let groupingViaCommonProperty = Object.values(
      coffe.reduce((acc, current) => {
        acc[current.origin] = acc[current.origin] ?? [];
        acc[current.origin].push(current);
        return acc;
      }, {}),
    );
    let finalArray;
    let coffesFinal: any = [];
    for (let i = 0; i < groupingViaCommonProperty.length; i++) {
      let arr: any = groupingViaCommonProperty[i];
      finalArray = Object.values(
        arr.reduce((acc, current) => {
          acc[current.warehouse] = acc[current.warehouse] ?? [];
          acc[current.warehouse].push(current);
          return acc;
        }, {}),
      );
      coffesFinal.push(finalArray);
    }
    setDeliveryHistorical(coffesFinal);
    setLoading(false);
  };

  const search = () => {
    setErrorInfoD('');
    setErrorInfoH('');
    setLoadingData(true);
    page === false
      ? getReviewPending(originSelected, dateInit, dateEnd, markSelected)
          .then(response => {
            if (response.status === 200) {
              setLoadingData(false);
              response.data.data.length > 0
                ? setDelivery(response.data)
                : setErrorInfoD(t(translations.general.no_search));
            }
          })
          .catch(error => {
            setErrorInfoD(error.response.data.message);
            setLoadingData(false);
            setLoading(false);
            console.log(error);
          })
      : getReviewHistorical(
          actualPage,
          originSelected,
          dateInit,
          dateEnd,
          markSelected,
        )
          .then(response => {
            if (response.status === 200) {
              setLoadingData(false);
              formatCoffe(response.data.data);
              // setDeliveryHistorical(response.data.data);
            }
          })
          .catch(error => {
            setErrorInfoH(error.response.data.message);
            setLoadingData(false);
            setDeliveryHistorical([]);
            setLoading(false);
            console.log(error);
          });
  };

  // useEffect(() => {
  //   page === false
  //     ? getReviewPending(originSelected, dateInit, dateEnd, markSelected)
  //         .then(response => {
  //           if (response.status === 200) {
  //             setDelivery(response.data);
  //           }
  //         })
  //         .catch(error => {
  //           setLoading(false);
  //           console.log(error);
  //         })
  //     : getReviewHistorical(
  //         actualPage,
  //         originSelected,
  //         dateInit,
  //         dateEnd,
  //         markSelected,
  //       )
  //         .then(response => {
  //           if (response.status === 200) {
  //             formatCoffe(response.data.data);
  //             // setDeliveryHistorical(response.data.data);
  //           }
  //         })
  //         .catch(error => {
  //           setLoading(false);
  //           console.log(error);
  //         });
  // }, [dateInit, dateEnd, originSelected, markSelected]);

  useEffect(() => {
    populate();
  }, []);

  const clean = () => {
    setActualPage(1);
    setOriginSelected('');
    setMarkSelected('');
    setDateInit('');
    setDateEnd('');
    if (initInputRef.current) {
      initInputRef.current.value = '';
    }
    if (endInputRef.current) {
      endInputRef.current.value = '';
    }
    populate();
  };

  let hash = {};

  const { t } = useTranslation();

  return test === true ? (
    <div
      style={{
        // transform: 'scale(0.2)',
        minWidth: !isMobile ? 700 : 0,
        // marginTop: '-200px',
      }}
    >
      <LoadingPage />
    </div>
  ) : (
    <>
      <Helmet>
        <title>Review Delivery</title>
        <meta name="description" content="Roasterhub delivery review" />
      </Helmet>
      {loading ? (
        <LoadingPage />
      ) : (
        <div>
          <div
            className="d-flex"
            style={{
              padding: !isMobile ? '96px' : '0px',

              flexDirection: 'column',
            }}
          >
            <Hero title={t(translations.pages.review_delivery_orders.title)} />
            {page ? (
              <div style={{ marginLeft: '45px' }}>
                {/* <h5>
                  {t(
                    translations.pages.review_delivery_orders
                      .historical_description,
                  )}
                </h5> */}
                {/* <h5>
                  {t(translations.pages.review_delivery_orders.historical_info)}
                </h5> */}
              </div>
            ) : null}
            <div
              className="d-flex"
              style={{
                justifyContent: 'center',
                marginLeft: !isMobile ? '10px' : '0px',
                flexDirection: !isMobile ? 'row' : 'column',
                alignItems: 'center',
                marginTop: !isMobile ? '0px' : '80px',
              }}
            >
              <div onClick={() => setPage(!page)}>
                <Tab state={!page ? 'active' : 'inactive'}>
                  <Text>{t(translations.general.pending)}</Text>
                </Tab>
              </div>
              <div onClick={() => setPage(!page)}>
                <Tab state={page ? 'active' : 'inactive'}>
                  <Text>{t(translations.general.historical)}</Text>
                </Tab>
              </div>
            </div>
            <div>
              {page ? (
                <div className="mt-3" style={{ marginLeft: '45px' }}>
                  <h5>
                    {t(
                      translations.pages.review_delivery_orders
                        .historical_description,
                    )}
                  </h5>
                  {/* <h5>
                  {t(translations.pages.review_delivery_orders.historical_info)}
                </h5> */}
                </div>
              ) : null}
            </div>
            {!isMobile ? (
              <>
                <div className="d-flex mt-3 mb-3 align-items-center">
                  <Search>
                    <div
                      style={{
                        fontSize: '13px',
                        fontWeight: '625',
                        margin: '0 20px',
                      }}
                    >
                      {t(translations.general.initial_d)}
                    </div>
                    <div className="d-flex align-items-center">
                      <div
                        style={{ marginLeft: 'auto', cursor: 'pointer' }}
                      ></div>
                      <Input
                        ref={initInputRef}
                        type="date"
                        onChange={e => setDateInit(e.target.value)}
                        placeholder="Initial Date"
                      />
                    </div>
                  </Search>
                  <Search>
                    <div
                      style={{
                        fontSize: '13px',
                        fontWeight: '625',
                        margin: '0 20px',
                      }}
                    >
                      {t(translations.general.end_d)}
                    </div>
                    <div className="d-flex align-items-center">
                      <Input
                        ref={endInputRef}
                        onChange={e => setDateEnd(e.target.value)}
                        type="date"
                        placeholder="End Date"
                      />
                      <div
                        style={{ marginLeft: 'auto', cursor: 'pointer' }}
                      ></div>
                    </div>
                  </Search>
                  <div
                    style={{
                      marginLeft: '5px',
                      cursor: 'pointer',
                      marginTop: '20px',
                    }}
                  >
                    {loadingData ? (
                      <CircularProgress color="primary" />
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <IconButton onClick={search} aria-label="search">
                          <SearchIcon />
                        </IconButton>
                        <Tooltip
                          title={t(translations.tooltips.clean)}
                          placement="right"
                        >
                          <IconButton onClick={clean}>
                            <CleanFilter />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginRight: '0px',
                  marginTop: '30px',
                }}
              >
                <StyledButton
                  onClick={() => setIsModalOpen(true)}
                  style={{
                    background: theme.palette.primary.main,
                    marginRight: '0px',
                    marginBottom: '20px',
                  }}
                >
                  {t(translations.general.filter)}
                </StyledButton>
              </div>
            )}
            {page === false ? (
              errorInfoD !== '' ? (
                <h3
                  style={{
                    color: 'gray',
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '50px',
                    textAlign: 'center',
                  }}
                >
                  {errorInfoD === t(translations.general.no_search)
                    ? errorInfoD
                    : t(`${translations.errors_400[errorInfoD]}`)}
                </h3>
              ) : loadingData ? (
                <div
                  style={{
                    // transform: 'scale(0.2)',
                    minWidth: !isMobile ? 700 : 0,
                    // marginTop: '-200px',
                  }}
                >
                  <LoadingPage />
                </div>
              ) : delivery ? (
                <DeliveryTable delivery={delivery} page={page}></DeliveryTable>
              ) : null
            ) : errorInfoH !== '' ? (
              <h3
                style={{
                  color: 'gray',
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '50px',
                  textAlign: 'center',
                }}
              >
                {errorInfoH === t(translations.general.no_search)
                  ? errorInfoH
                  : t(`${translations.errors_400[errorInfoH]}`)}
              </h3>
            ) : loadingData ? (
              <div
                style={{
                  // transform: 'scale(0.2)',
                  minWidth: !isMobile ? 700 : 0,
                  // marginTop: '-200px',
                }}
              >
                <LoadingPage />
              </div>
            ) : (
              <DeliveryTableHistorical
                delivery={deliveryHistorical}
                page={page}
                setDeliveryHistorical={setDeliveryHistorical}
                deliveryHistoricalRaw={deliveryHistoricalRaw}
                setActualPage={setActualPage}
                setPages={setPages}
                actualPage={actualPage}
                pages={pages}
                formatCoffe={formatCoffe}
                setOrigin={setOrigin}
                setMark={setMark}
              ></DeliveryTableHistorical>
            )}
            {page === false ? (
              <FilterModalDelivery
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                originOptions={
                  origin &&
                  origin.filter(o =>
                    hash[o.value] ? false : (hash[o.value] = true),
                  )
                }
                markOptions={
                  mark &&
                  mark.filter(o =>
                    hash[o.value] ? false : (hash[o.value] = true),
                  )
                }
                onSearch={search}
                clean={clean}
                loadingData={loadingData}
                t={t}
                setDateInit={setDateInit} // Pasa las funciones necesarias al componente
                setDateEnd={setDateEnd}
                setOriginSelected={setOriginSelected}
                originSelected={originSelected}
                markSelected={markSelected}
                setMarkSelected={setMarkSelected}
              />
            ) : (
              <FilterModalDeliveryH
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                originOptions={
                  origin &&
                  origin.filter(o =>
                    hash[o.value] ? false : (hash[o.value] = true),
                  )
                }
                markOptions={
                  mark &&
                  mark.filter(o =>
                    hash[o.value] ? false : (hash[o.value] = true),
                  )
                }
                onSearch={search}
                clean={clean}
                loadingData={loadingData}
                t={t}
                setDateInit={setDateInit} // Pasa las funciones necesarias al componente
                setDateEnd={setDateEnd}
                setOriginSelected={setOriginSelected}
                originSelected={originSelected}
                markSelected={markSelected}
                setMarkSelected={setMarkSelected}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
}
