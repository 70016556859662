import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import styledComponent, { useTheme } from 'styled-components/macro';
import styled from 'styled-components/macro';
import { ThemeProvider, useMediaQuery } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { getReviewHistorical } from 'services/reviewServices';
import { useState } from 'react';
import drop from '../../assets/SVG/dropdown.svg';
import { originToColor, GENERIC } from '../../constants';
import ModalComponent from '../ModalComponent';

const StyledTableCellMobile = styled(TableCell)`
  align-items: center;

  font-style: normal;
  font-weight: 325;
  font-size: 13px;
  line-height: 16px;
  background: #f8f8f8;
  // border: 1px solid white;
  width: calc(20% - 1px);
  box-sizing: border-box;
`;

const StyledHeadCellMobile = styled(TableCell)`
  font-style: normal;
  font-weight: 325;
  font-size: 13px;
  line-height: 16px;
  // border: 1px solid white;
  background: #ebeaea;
  background: #1989b2;
  color: white;
  width: calc(20% - 1px);
  box-sizing: border-box;
`;

const ContainerHeader = styled.div`
  display: flex;
  width: 100%;
  background: #1989b2;
  justify-content: space-between;
  margin-bottom: 5px;
  align-items: center;
`;

const ContainerBody = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  background: #f8f8f8;
  align-items: center;
`;

const StyledHeadCell = styled(TableCell)`
  font-style: normal;
  font-weight: 325;
  font-size: 13px;
  line-height: 16px;
  border: 1px solid white;
  ${props =>
    props.color &&
    `
    color: white; `}
  background: #EBEAEA;
  background: ${props => props.color};
`;

const StyledTableRow = styled(TableRow)`
  border-bottom: 3px solid white;
  border-top: 3px solid white;
  th:first-child {
    border-radius: 35px 0px 0px 35px;
  }
  th:last-child {
    border-radius: 0px 35px 35px 0px;
  }
  td:first-child {
    border-radius: 35px 0px 0px 35px;
  }
  td:last-child {
    border-radius: 0px 35px 35px 0px;
  }
`;

const StyledTableCell = styled(TableCell)`
  align-items: center;

  font-style: normal;
  font-weight: 325;
  font-size: 13px;
  line-height: 16px;
  background: #f8f8f8;
  border: 1px solid white;
  display: table-cell;
  overflow: auto;
`;

const Warehouse = styledComponent.div` 
background: #1989b2;
color: white;
display: flex;
justify-content: center;
align-items: center;
border-radius: 30px;
width: 201px;
height: 49px;
margin-left: 14px;
font-weight: 325;
font-size: 15px;
overflow: hidden;
text-align: center;
`;

interface TableProps {
  delivery?: any[];
  page?: boolean;
  setDeliveryHistorical?: (value: any) => void;
  deliveryHistoricalRaw?: any[];
  setActualPage: (value: any) => void;
  setPages: (value: any) => void;
  pages?: any;
  actualPage?: any;
  formatCoffe: (value: any) => void;
  setOrigin: (value: any) => void;
  setMark: (value: any) => void;
}

export function DeliveryTableHistorical({
  delivery,
  page,
  setDeliveryHistorical,
  deliveryHistoricalRaw,
  setActualPage,
  setPages,
  pages,
  actualPage,
  formatCoffe,
  setOrigin,
  setMark,
}: TableProps) {
  const theme: any = useTheme();

  const [infoData, setInfo] = useState<any[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortedData, setSortedData] = useState<any>(deliveryHistoricalRaw);
  const [activeSortColumn, setActiveSortColumn] = useState<any>(null);
  const [isColumnClicked, setIsColumnClicked] = useState('');

  const sortData = columnName => {
    const sortOrderModifier = sortOrder === 'asc' ? 1 : -1;

    const sorted = [...(deliveryHistoricalRaw || [])].sort((a, b) => {
      const valueA = a[columnName] || ''; // Manejo de valores nulos
      const valueB = b[columnName] || ''; // Manejo de valores nulos
      return sortOrderModifier * valueA.localeCompare(valueB);
    });

    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    setSortedData(sorted);
    setActiveSortColumn(columnName);
    setIsColumnClicked(columnName);
  };

  const { t } = useTranslation();

  const openModalWithInfo = info => {
    setModalOpen(true);
    setInfo(info);
  };

  const isMobile = useMediaQuery('(max-width:767px)');

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    getReviewHistorical(value)
      .then(response => {
        if (response.status === 200) {
          setActualPage(response.data.meta.current_page);
          setPages(response.data.meta.last_page);
          formatCoffe(response.data.data);
          setOrigin(
            response.data.data.map(item => ({
              value: item.origin,
              label: item.origin,
            })),
          );
          setMark(
            response.data.data.map(item => ({
              value: item.mark,
              label: item.mark,
            })),
          );
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  return delivery ? (
    <ThemeProvider theme={theme}>
      <div className="d-flex justify-content-center mt-5 mb-5">
        {page === true && (
          <Stack spacing={2}>
            <Pagination
              count={pages}
              page={actualPage}
              onChange={handleChange}
            />
          </Stack>
        )}
      </div>
      {!isMobile ? (
        <>
          {/* <div className="d-flex align-items-center mt-5 mb-4">
            <h1 style={{ color: '#1989B2' }}>{value[0].origin}</h1>
            <div></div>
            <Warehouse>{value[0].warehouse}</Warehouse>
          </div> */}
          <TableContainer>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <StyledTableRow>
                  <StyledHeadCell
                    style={{ background: theme.palette.secondary.main }}
                    align="center"
                  >
                    {t(translations.table.contract)}
                  </StyledHeadCell>
                  <StyledHeadCell
                    style={{ background: theme.palette.secondary.main }}
                    align="center"
                  >
                    {t(translations.table.ico)}
                  </StyledHeadCell>
                  <StyledHeadCell
                    style={{
                      background: theme.palette.secondary.main,
                      cursor: 'pointer',
                    }}
                    align="center"
                    onClick={() => sortData('origin')}
                    onMouseEnter={() => setActiveSortColumn('origin')}
                    onMouseLeave={() => {
                      if (!isColumnClicked) {
                        setActiveSortColumn(null);
                      }
                    }}
                  >
                    {t(translations.table.origin)}{' '}
                    {(activeSortColumn === 'origin' ||
                      isColumnClicked === 'origin') && (
                      <span style={{ fontSize: '20px' }}>
                        {sortOrder === 'asc' ? '↑' : '↓'}
                      </span>
                    )}
                  </StyledHeadCell>
                  <StyledHeadCell
                    style={{ background: theme.palette.secondary.main }}
                    align="center"
                  >
                    {t(translations.table.marks)}
                  </StyledHeadCell>
                  <StyledHeadCell
                    style={{
                      background: theme.palette.secondary.main,
                      cursor: 'pointer',
                    }}
                    align="center"
                    onClick={() => sortData('warehouse')}
                    onMouseEnter={() => setActiveSortColumn('warehouse')}
                    onMouseLeave={() => {
                      if (!isColumnClicked) {
                        setActiveSortColumn(null);
                      }
                    }}
                  >
                    {t(translations.table.warehouse)}{' '}
                    {(activeSortColumn === 'warehouse' ||
                      isColumnClicked === 'warehouse') && (
                      <span style={{ fontSize: '20px' }}>
                        {sortOrder === 'asc' ? '↑' : '↓'}
                      </span>
                    )}
                  </StyledHeadCell>
                  <StyledHeadCell
                    style={{
                      background: theme.palette.secondary.main,
                      cursor: 'pointer',
                    }}
                    align="center"
                    onClick={() => sortData('quantity_ordered')}
                    onMouseEnter={() => setActiveSortColumn('quantity_ordered')}
                    onMouseLeave={() => {
                      if (!isColumnClicked) {
                        setActiveSortColumn(null);
                      }
                    }}
                  >
                    {t(translations.table.q_ordered)}{' '}
                    {(activeSortColumn === 'quantity_ordered' ||
                      isColumnClicked === 'quantity_ordered') && (
                      <span style={{ fontSize: '20px' }}>
                        {sortOrder === 'asc' ? '↑' : '↓'}
                      </span>
                    )}
                  </StyledHeadCell>
                  <StyledHeadCell
                    style={{ background: theme.palette.secondary.main }}
                    align="center"
                  >
                    {t(translations.table.pack)}
                  </StyledHeadCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {sortedData &&
                  sortedData.map(groupBy => (
                    <StyledTableRow key={groupBy.created_at}>
                      <StyledTableCell align="center">
                        {groupBy.ico}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {groupBy.contract}
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <div
                          style={{
                            borderRadius: '30px',
                            background:
                              originToColor[groupBy.origin.toUpperCase()] ||
                              GENERIC,
                            color: 'white',
                            padding: '15px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '40px',
                            width: '160px',
                          }}
                        >
                          {groupBy.origin}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{
                          textOverflow: 'ellipsis',
                          display: groupBy.broshure ? 'flex' : 'table-cell',
                          justifyContent: 'center',
                        }}
                      >
                        {groupBy.broshure ? (
                          <a
                            href={groupBy.broshure}
                            style={{
                              textDecoration: 'none',
                              borderRadius: '30px',
                              background: '#1989B2',
                              color: 'white',
                              padding: '15px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              height: '40px',
                              width: '160px',
                            }}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {groupBy.mark}
                          </a>
                        ) : (
                          groupBy.mark
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {groupBy.warehouse}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {groupBy.quantity_ordered}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {groupBy.units}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <>
          <ContainerHeader style={{ background: theme.palette.primary.main }}>
            <StyledHeadCellMobile
              style={{
                borderBottom: '0px',
                width: '25%',
                background: theme.palette.primary.main,
              }}
              align="center"
            ></StyledHeadCellMobile>
            <StyledHeadCellMobile
              style={{
                borderBottom: '0px',
                width: '25%',
                background: theme.palette.primary.main,
              }}
              align="center"
            >
              {t(translations.table.contract)}
            </StyledHeadCellMobile>
            <StyledHeadCellMobile
              style={{
                borderBottom: '0px',
                width: '25%',
                background: theme.palette.primary.main,
              }}
              align="center"
            >
              {t(translations.table.ico)}
            </StyledHeadCellMobile>
            <StyledHeadCellMobile
              style={{
                borderBottom: '0px',
                width: '25%',
                background: theme.palette.primary.main,
              }}
              align="center"
            >
              {t(translations.table.marks)}
            </StyledHeadCellMobile>
          </ContainerHeader>

          {sortedData &&
            sortedData.map(groupBy => (
              <ContainerBody key={groupBy.created_at}>
                <StyledTableCellMobile
                  style={{
                    borderBottom: '0px',
                    width: '25%',
                  }}
                  align="center"
                >
                  <img
                    src={drop}
                    alt="drop"
                    style={{ width: '22px', height: '22px' }}
                    onClick={() => openModalWithInfo(groupBy)}
                  />
                </StyledTableCellMobile>
                <StyledTableCellMobile
                  style={{
                    borderBottom: '0px',
                    width: '25%',
                  }}
                  align="center"
                >
                  {groupBy.contract}
                </StyledTableCellMobile>
                <StyledTableCellMobile
                  style={{
                    borderBottom: '0px',
                    width: '25%',
                  }}
                  align="center"
                >
                  {groupBy.ico}
                </StyledTableCellMobile>
                <StyledTableCellMobile
                  align="center"
                  style={{
                    borderBottom: '0px',
                    width: '25%',
                    textOverflow: 'ellipsis',
                    display: groupBy.broshure ? 'flex' : 'table-cell',
                    justifyContent: 'center',
                  }}
                >
                  {groupBy.broshure ? (
                    <a
                      href={groupBy.broshure}
                      style={{
                        textDecoration: 'none',
                        borderRadius: '30px',
                        background: '#1989B2',
                        color: 'white',
                        padding: '15px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '40px',
                        width: '160px',
                      }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {groupBy.mark}
                    </a>
                  ) : (
                    groupBy.mark
                  )}
                </StyledTableCellMobile>
                {/* <StyledTableCellMobile
                  style={{
                    borderBottom: '0px',
                  }}
                  align="center"
                >
                  $ {groupBy.price}
                </StyledTableCellMobile> */}
                {/* <StyledTableCellMobile align="center">
                            {groupBy.units}
                          </StyledTableCellMobile>
                          <StyledTableCellMobile align="center">
                            {groupBy.quantity_ordered}
                          </StyledTableCellMobile> */}
              </ContainerBody>
            ))}
        </>
      )}

      {modalOpen && infoData && (
        <ModalComponent
          page="DeliveryH"
          closeModal={() => setModalOpen(false)} // Función para cerrar el modal
          data={infoData} // Información de groupBy para mostrar en el modal
        />
      )}
    </ThemeProvider>
  ) : null;
}
