import serviceClient from './serviceClientConfig';

export const getHistoryContracts = (page?, origin?, dInit?, dEnd?) => {
  return serviceClient.get(
    `/history-contracts?type=contracts&page=${page !== undefined ? page : ''}
    &initial_date=${dInit !== undefined ? dInit : ''}&end_date=${
      dEnd !== undefined ? dEnd : ''
    }&origin=${origin !== undefined ? origin : ''}`,
  );
};

export const getHistoryInvoices = (page?, origin?, dInit?, dEnd?, status?) => {
  return serviceClient.get(
    `/history-contracts?type=invoices&page=${
      page !== undefined ? page : ''
    }&initial_date=${dInit !== undefined ? dInit : ''}&end_date=${
      dEnd !== undefined ? dEnd : ''
    }&origin=${origin !== undefined ? origin : ''}&status=${
      status !== undefined ? status : ''
    }`,
  ); /* with paginate /purchase-orders?page=2 with status /purchase-orders?status=draft */
};
