import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import upload from '../../assets/SVG/foto-3.gif';
import { UploadLogoModal } from 'app/components/UploadLogoModal';
import {
  checkImpersonate,
  getLogo,
  uploadLogo,
  getLogoById,
} from 'services/authServices';
import { useMediaQuery } from '@mui/material';
import { useLocalStorage } from 'utils/useLocalStorage';
import { padding } from '@mui/system';

const Title = styled.div`
  font-family: 'MarshaBold';
  font-weight: 400;
  line-height: 72px;
  letter-spacing: 0em;
  height: 40px;
  width: 100%;
  max-width: 662px; /* Cambio: Agregar máximo ancho permitido */
  @media (max-width: 767px) {
    font-size: 40px;
    height: 94px;
  }
`;

const Logo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 767px) {
    margin-top: 20px;
    justify-content: center;
  }
`;

const Upload = styled.img`
  height: 200px;
  width: 200px;

  @media (max-width: 767px) {
    height: 150px;
    width: 150px;
  }
`;

interface HeroProps {
  id?: string;
  logo?: any;
  setIsUploadedLogo?: (value: boolean) => void;
  isConfirm?: Boolean;
  isEdit?: Boolean;
  isAdmin?: Boolean;
  title?: String;
  subtitle?: String;
  size?: boolean;
}

export function Hero({
  id,
  isConfirm,
  isEdit,
  setIsUploadedLogo,
  isAdmin,
  title,
  subtitle,
  size,
}: HeroProps) {
  const openCloseModal = () => {
    if (isEdit) setOpen(!open);
  };
  const [open, setOpen] = useState(false);
  const [logo, setLogo] = useState('');
  const [storedLogo, setLogoStored] = useLocalStorage('logo', '');
  const [image, setImage] = useState('');
  const [isImpersonated, setIsImpersonated] = React.useState<
    boolean | undefined
  >(undefined);

  const isMobile = useMediaQuery('(max-width:767px)');

  useEffect(() => {
    checkImpersonate()
      .then(response => {
        if (response.status === 200) {
          response.data.result === '1'
            ? setIsImpersonated(true)
            : setIsImpersonated(false);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  /* useEffect(() => {
    if (image) {
      setIsUploadedLogo(true);
    }
  }, [image]); */

  const { t } = useTranslation();

  /* useEffect(() => {
    const savedLogo = localStorage.getItem('logo');
    if (isImpersonated === false) {
      if (isAdmin) {
        getLogoById(id)
          .then(response => {
            if (response.status === 200 && response.data.image !== null) {
              setLogo(response.data.image);
            } else if (
              response.status === 200 &&
              response.data.image === null
            ) {
              setLogo('');
            }
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        if (savedLogo !== null && savedLogo !== '') {
          setLogo(savedLogo);
        } else {
          getLogo()
            .then(response => {
              if (response.status === 200 && response.data.image !== null) {
                setLogoStored('logo', response.data.image);
                setLogo(response.data.image);
              } else if (
                response.status === 200 &&
                response.data.image === null
              ) {
                setLogo('');
              }
            })
            .catch(error => {
              console.log(error);
            });
        }
      }
    }
  }, [isImpersonated, isAdmin]); */

  /* const uploadImage = () => {
    const formData = new FormData();
    formData.append('image', image);
    setLogoStored('logo', '');

    uploadLogo(formData, id)
      .then(response => {
        if (response.status === 201) {
          getLogo()
            .then(response => {
              if (response.status === 200) {
                setLogo(response.data.image);
              }
            })
            .catch(error => {
              console.log(error.response?.data);
            });
        }
      })
      .catch(error => {
        console.log(error.response?.data?.message);
      });
  }; */

  /* useEffect(() => {
    if (isConfirm) {
      uploadImage();
    }
  }, [isConfirm]); */

  return (
    <>
      <div
        className={`d-flex justify-content-between ${
          isMobile ? 'text-center' : ''
        }`}
        style={{
          padding: !isMobile ? '10px' : '0px',
          margin: !isMobile ? '30px 0 0 0' : '15px 0 0',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        <div>
          <Title style={{ fontSize: size !== undefined ? '20px' : '40px' }}>
            {title?.toUpperCase()}
          </Title>
          <h5 style={{ paddingTop: '20px' }}>{subtitle}</h5>
        </div>
      </div>
    </>
  );
}
