import React from 'react';

const AlertUnfilled = ({ color }: { color: string }) => (
  <svg
    width="60"
    height="59"
    viewBox="0 0 46 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="22.25"
      cy="21.5"
      r="20.5"
      fill="none"
      stroke={color}
      strokeWidth="2"
    />
    <path
      d="M21.0973 22.1C21.1723 22.775 21.6223 23.2 22.2473 23.2C22.8723 23.2 23.3223 22.775 23.3973 22.1L24.4723 12.7C24.5473 11.975 24.1473 11.425 23.4723 11.425H21.0223C20.3473 11.425 19.9473 11.975 20.0223 12.7L21.0973 22.1ZM20.0723 26.975V27.025C20.0723 28.225 20.9973 29.15 22.2473 29.15C23.4973 29.15 24.4223 28.225 24.4223 27.025V26.975C24.4223 25.775 23.4973 24.85 22.2473 24.85C20.9973 24.85 20.0723 25.775 20.0723 26.975Z"
      fill={color}
    />
  </svg>
);

export default AlertUnfilled;
