import serviceClient from './serviceClientConfig';

export const getReviewPending = (origin?, dInit?, dEnd?, mark?) => {
  const marks = mark !== undefined ? encodeURIComponent(mark.trim()) : '';
  return serviceClient.get(
    `/review-delivery-orders?filter=pending&initial_date=${
      dInit !== undefined ? dInit : ''
    }&end_date=${dEnd !== undefined ? dEnd : ''}&origin=${
      origin !== undefined ? origin : ''
    }&marks=${marks !== undefined ? marks : ''}`,
  );
};

export const getReviewHistorical = (page, origin?, dInit?, dEnd?, mark?) => {
  const marks = mark !== undefined ? encodeURIComponent(mark.trim()) : '';
  return serviceClient.get(
    `/review-delivery-orders?filter=historical&page=${page}&initial_date=${
      dInit !== undefined ? dInit : ''
    }&end_date=${dEnd !== undefined ? dEnd : ''}&origin=${
      origin !== undefined ? origin : ''
    }&marks=${marks !== undefined ? marks : ''}`,
  );
};
