import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import styledComponent, { useTheme } from 'styled-components/macro';
import styled from 'styled-components/macro';
import { ThemeProvider, useMediaQuery } from '@mui/material';
import drop from '../../assets/SVG/dropdown.svg';
import { useState } from 'react';
import { originToColor, GENERIC } from '../../constants';
import ModalComponent from '../ModalComponent';
import Tooltip from '@mui/material/Tooltip';

const StyledTableCellMobile = styled(TableCell)`
  align-items: center;
  font-style: normal;
  font-weight: 325;
  font-size: 13px;
  line-height: 16px;
  background: #f8f8f8;
  // border: 1px solid white;
  width: calc(20% - 1px);
  box-sizing: border-box;
`;

const StyledHeadCellMobile = styled(TableCell)`
  font-style: normal;
  font-weight: 325;
  font-size: 13px;
  line-height: 16px;
  // border: 1px solid white;
  background: #ebeaea;
  background: #1989b2;
  color: white;
  width: calc(20% - 1px);
  box-sizing: border-box;
`;

const ContainerHeader = styled.div`
  display: flex;
  width: 100%;
  background: #1989b2;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const ContainerBody = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  background: #f8f8f8;
`;

const StyledHeadCell = styled(TableCell)`
  font-style: normal;
  font-weight: 325;
  font-size: 13px;
  line-height: 16px;
  border-bottom: 1px solid white;
  background: #ebeaea;
  background: ${props => props.color};
`;

const StyledTableRow = styled(TableRow)`
  border-bottom: 3px solid white;
  border-top: 3px solid white;
  th:first-child {
    border-radius: 35px 0px 0px 35px;
  }
  th:last-child {
    border-radius: 0px 35px 35px 0px;
  }
  td:first-child {
    border-radius: 35px 0px 0px 35px;
  }
  td:last-child {
    border-radius: 0px 35px 35px 0px;
  }
`;

const StyledTableCell = styled(TableCell)`
  align-items: center;
  font-style: normal;
  font-weight: 325;
  font-size: 13px;
  line-height: 16px;
  background: #f8f8f8;
  border: 1px solid white;
  display: table-cell;
  overflow: auto;
`;

const Group = styledComponent.div`
  background: #D9D9D9; 
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  height: 33px;
  cursor: pointer;
`;

const Warehouse = styledComponent.div` 
background: #1989b2;
color: white;
display: flex;
justify-content: center;
align-items: center;
border-radius: 30px;
width: 201px;
height: 49px;
margin-left: 14px;
font-weight: 325;
font-size: 15px;
overflow: hidden;
text-align: center;
`;

interface TableProps {
  delivery?: any;
  page?: boolean;
  setDeliveryHistorical?: (value: any) => void;
  setActualPage?: (value: any) => void;
  setPages?: (value: any) => void;
  pages?: any;
  actualPage?: any;
}

export function DeliveryTable({
  delivery,
  page,
  setDeliveryHistorical,
  setActualPage,
  setPages,
  pages,
  actualPage,
}: TableProps) {
  const theme: any = useTheme();

  const [infoData, setInfo] = useState<any[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortedData, setSortedData] = useState<any>(delivery.data);
  const [activeSortColumn, setActiveSortColumn] = useState<any>(null);
  const [isColumnClicked, setIsColumnClicked] = useState('');

  const sortData = columnName => {
    const sortOrderModifier = sortOrder === 'asc' ? 1 : -1;

    const sorted = [...(delivery.data || [])].sort((a, b) => {
      const valueA = a[columnName] || ''; // Manejo de valores nulos
      const valueB = b[columnName] || ''; // Manejo de valores nulos
      return sortOrderModifier * valueA.localeCompare(valueB);
    });

    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    setSortedData(sorted);
    setActiveSortColumn(columnName);
    setIsColumnClicked(columnName);
  };

  const { t } = useTranslation();

  const openModalWithInfo = info => {
    setModalOpen(true);
    setInfo(info);
  };

  const isMobile = useMediaQuery('(max-width:767px)');

  if (!delivery || !delivery.data) {
    return null; // Return null or a loading indicator while data is being fetched.
  }

  const formatCoffe = coffe => {
    let groupingViaCommonProperty = Object.values(
      coffe.reduce((acc, current) => {
        acc[current.origin] = acc[current.origin] ?? [];
        acc[current.origin].push(current);
        return acc;
      }, {}),
    );
    let finalArray;
    let coffesFinal: any = [];
    for (let i = 0; i < groupingViaCommonProperty.length; i++) {
      let arr: any = groupingViaCommonProperty[i];
      finalArray = Object.values(
        arr.reduce((acc, current) => {
          acc[current.warehouse] = acc[current.warehouse] ?? [];
          acc[current.warehouse].push(current);
          return acc;
        }, {}),
      );
      coffesFinal.push(finalArray);
    }
    return coffesFinal;
  };

  // const info = formatCoffe(delivery?.data?.length > 0 ? delivery.data : []);

  return sortedData ? (
    !isMobile ? (
      <ThemeProvider theme={theme}>
        <>
          <TableContainer>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <StyledTableRow>
                  <StyledHeadCell
                    style={{ background: theme.palette.secondary.main }}
                    align="center"
                  >
                    {t(translations.table.contract)}
                  </StyledHeadCell>
                  <StyledHeadCell
                    style={{ background: theme.palette.secondary.main }}
                    align="center"
                  >
                    {t(translations.table.ico)}
                  </StyledHeadCell>
                  <StyledHeadCell
                    style={{
                      background: theme.palette.secondary.main,
                      cursor: 'pointer',
                    }}
                    align="center"
                    onClick={() => sortData('origin')}
                    onMouseEnter={() => setActiveSortColumn('origin')}
                    onMouseLeave={() => {
                      if (!isColumnClicked) {
                        setActiveSortColumn(null);
                      }
                    }}
                  >
                    {t(translations.table.origin)}{' '}
                    {(activeSortColumn === 'origin' ||
                      isColumnClicked === 'origin') && (
                      <span style={{ fontSize: '20px' }}>
                        {sortOrder === 'asc' ? '↑' : '↓'}
                      </span>
                    )}
                  </StyledHeadCell>
                  <StyledHeadCell
                    style={{ background: theme.palette.secondary.main }}
                    align="center"
                  >
                    {t(translations.table.marks)}
                  </StyledHeadCell>
                  <StyledHeadCell
                    style={{
                      background: theme.palette.primary.main,
                      color: theme.palette.primary.contrastText,
                      cursor: 'pointer',
                    }}
                    align="center"
                    onClick={() => sortData('warehouse')}
                    onMouseEnter={() => setActiveSortColumn('warehouse')}
                    onMouseLeave={() => {
                      if (!isColumnClicked) {
                        setActiveSortColumn(null);
                      }
                    }}
                  >
                    {t(translations.table.warehouse)}{' '}
                    {(activeSortColumn === 'warehouse' ||
                      isColumnClicked === 'warehouse') && (
                      <span style={{ fontSize: '20px' }}>
                        {sortOrder === 'asc' ? '↑' : '↓'}
                      </span>
                    )}
                  </StyledHeadCell>
                  <StyledHeadCell
                    style={{
                      background: theme.palette.primary.main,
                      color: theme.palette.primary.contrastText,
                      cursor: 'pointer',
                    }}
                    align="center"
                    onClick={() => sortData('purchase_order_id')}
                    onMouseEnter={() =>
                      setActiveSortColumn('purchase_order_id')
                    }
                    onMouseLeave={() => {
                      if (!isColumnClicked) {
                        setActiveSortColumn(null);
                      }
                    }}
                  >
                    {t(translations.table.do_number)}{' '}
                    {(activeSortColumn === 'purchase_order_id' ||
                      isColumnClicked === 'purchase_order_id') && (
                      <span style={{ fontSize: '20px' }}>
                        {sortOrder === 'asc' ? '↑' : '↓'}
                      </span>
                    )}
                  </StyledHeadCell>

                  <StyledHeadCell
                    style={{
                      background: theme.palette.primary.main,
                      color: theme.palette.primary.contrastText,
                    }}
                    align="center"
                  >
                    {t(translations.table.request_dd)}
                  </StyledHeadCell>
                  <StyledHeadCell
                    align="center"
                    color={'#5C36F3'}
                    style={{
                      background: theme.palette.primary.main,
                      color: theme.palette.primary.contrastText,
                    }}
                  >
                    {t(translations.table.status)}
                  </StyledHeadCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {sortedData.map(groupBy => (
                  <StyledTableRow key={groupBy.created_at}>
                    <StyledTableCell align="center">
                      {groupBy.contract}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {groupBy.ico}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <div
                        style={{
                          borderRadius: '30px',
                          background: groupBy.origin
                            ? originToColor[groupBy.origin.toUpperCase()] ||
                              GENERIC
                            : GENERIC,
                          color: 'white',
                          padding: '15px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '40px',
                          width: '160px',
                        }}
                      >
                        {groupBy.origin}
                      </div>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {groupBy.mark}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {groupBy.warehouse}
                    </StyledTableCell>

                    <StyledTableCell align="center">
                      {groupBy.purchase_order_id}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {groupBy.date_needs}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {groupBy.purchase_order_status === 'Pending' && (
                        <Tooltip
                          title={t(translations.tooltips.process)}
                          placement="top"
                        >
                          <Group
                            style={{
                              background: theme.palette.success.main,
                              padding: '20px',
                            }}
                          >
                            {t(translations.table.in_process)}
                          </Group>
                        </Tooltip>
                      )}
                      {groupBy.purchase_order_status === 'Draft' && (
                        <Tooltip
                          title={t(translations.tooltips.draft)}
                          placement="top"
                        >
                          <Group
                            style={{ background: theme.palette.error.main }}
                          >
                            {t(translations.table.draft)}
                          </Group>
                        </Tooltip>
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      </ThemeProvider>
    ) : (
      <ThemeProvider theme={theme}>
        <>
          <ContainerHeader style={{ background: theme.palette.primary.main }}>
            <StyledHeadCellMobile
              style={{ background: theme.palette.primary.main }}
              align="center"
            ></StyledHeadCellMobile>
            <StyledHeadCellMobile
              style={{ background: theme.palette.primary.main }}
              align="center"
            >
              {t(translations.table.contract)}
            </StyledHeadCellMobile>
            <StyledHeadCellMobile
              style={{ background: theme.palette.primary.main }}
              align="center"
            >
              {t(translations.table.ico)}
            </StyledHeadCellMobile>
            <StyledHeadCellMobile
              align="center"
              style={{ background: theme.palette.primary.main }}
            >
              {t(translations.table.status)}
            </StyledHeadCellMobile>
          </ContainerHeader>
          {sortedData.map(groupBy => (
            <>
              <ContainerBody key={groupBy.created_at}>
                <StyledTableCellMobile align="center">
                  <img
                    src={drop}
                    alt="drop"
                    style={{ width: '22px', height: '22px' }}
                    onClick={() => openModalWithInfo(groupBy)}
                  />
                </StyledTableCellMobile>
                <StyledTableCellMobile align="center">
                  {groupBy.contract}
                </StyledTableCellMobile>
                <StyledTableCellMobile align="center">
                  {groupBy.ico}
                </StyledTableCellMobile>
                <StyledTableCellMobile align="center">
                  {groupBy.purchase_order_status === 'Pending' && (
                    <Tooltip
                      title={t(translations.tooltips.process)}
                      placement="top"
                    >
                      <Group
                        style={{
                          color: theme.palette.success.main,
                          background: '#f8f8f8',
                          padding: '20px',
                        }}
                      >
                        {t(translations.table.in_process)}
                      </Group>
                    </Tooltip>
                  )}
                  {groupBy.purchase_order_status === 'Draft' && (
                    <Tooltip
                      title={t(translations.tooltips.draft)}
                      placement="top"
                    >
                      <Group
                        style={{
                          color: theme.palette.error.main,
                          background: '#f8f8f8',
                        }}
                      >
                        {t(translations.table.draft)}
                      </Group>
                    </Tooltip>
                  )}
                </StyledTableCellMobile>
              </ContainerBody>
            </>
          ))}
        </>

        {modalOpen && infoData && (
          <ModalComponent
            page="Delivery"
            closeModal={() => setModalOpen(false)} // Función para cerrar el modal
            data={infoData} // Información de groupBy para mostrar en el modal
          />
        )}
      </ThemeProvider>
    )
  ) : null;
}
