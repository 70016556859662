import { IssuePayload, FeedbackPayload } from 'models/authModels';
import serviceClient from './serviceClientConfig';

export const getModulesOptions = () => {
  return serviceClient.get('/module-options');
};

export const getProblemsOptions = () => {
  return serviceClient.get('/problem-options');
};

export const reportIssue = (payload: IssuePayload) => {
  return serviceClient.post('/issue-feedback', payload);
};

export const sendFeedback = (payload: FeedbackPayload) => {
  return serviceClient.post('/feedback', payload);
};
