import React, { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import profile from '../../assets/SVG/profile.svg';
import bell from '../../assets/SVG/bell.svg';
import ringing_bell from '../../assets/SVG/ringing_bell.svg';
import alert from '../../assets/SVG/alert.png';
import search from '../../assets/SVG/search.svg';
import writing_pen from '../../assets/SVG/writing_pen.svg';
import sign_out from '../../assets/SVG/sign_out.svg';
import sign_out_alt from '../../assets/SVG/la_sign-out-alt.png';
import NewChatIcon from '../../assets/SVG/new_chat';
import { translations } from 'locales/translations';
import spain from '../../../app/assets/SVG/spain.png';
import china from '../../../app/assets/SVG/china.png';
import uk from '../../../app/assets/SVG/uk.png';
import Dropdown from 'app/components/Dropdown';
// import NotificationsIcon from '@mui/icons-material/Notifications';
import { HomeIcon } from 'app/assets/SVG/color_icons';
// import HomeIcon from '@mui/icons-material/Home';
import {
  CheckBoxIcon,
  ShoppingCartIcon,
  TruckIcon,
} from 'app/assets/SVG/color_icons';
import {
  Container,
  ActionsContainer,
  InputAdornment,
  LangBtn,
  UnstyledLink,
} from './components';
import Button from '@mui/material/Button';
import SimplePopper from '../SimplePopper';
import { muiTheme, styledTheme } from 'styles/theme';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  checkImpersonate,
  getAccListImpersonate,
  getImpersonateData,
  getRoleListImpersonate,
  impersonateCustomer,
  impersonateRole,
  LogoutAction,
  getLogo,
} from 'services/authServices';
import FeedbackModal from './FeedbackModal';
import { FeedbackPayload } from 'models/authModels';
import { sendFeedback } from 'services/supportServices';
import { getRoleList } from 'services/adminServices';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Toolbar,
  useMediaQuery,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import axios from 'axios';
import styled from 'styled-components';
import { display } from '@mui/system';
import { useLocalStorage } from 'utils/useLocalStorage';

interface NavBarProp {
  pendingNotifications: boolean;
  purchaseNotification: any;
  newImpersonation: any;
  setPendingNotifications: (value) => void;
  setNewImpersonation: (value: any) => void;
  setLoadingData: (value: boolean) => void;
  setLoading: (value: boolean) => void;
  // isImpersonated: any;
  // roles: any;
  // acc: any;
}

const ButtonStyled = styled.input`
  border: none;
  background: ${props => props.theme.palette.primary.main};
  color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  margin-bottom: 25px;
  width: 213.02px;
  height: 49.59px;
  margin: 0 10px;
`;

const DrawerContainer = styled.div`
  width: 250px;

  .MuiDrawer-paper {
    width: 250px;
  }
`;

export function NavBar({
  pendingNotifications,
  setPendingNotifications,
  purchaseNotification,
  setNewImpersonation,
  newImpersonation,
  setLoadingData,
  setLoading,
}: // isImpersonated,
// roles,
// acc,
NavBarProp) {
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [openPopup, setOpenPopup] = React.useState(false);
  const [isImpersonated, setIsImpersonated] = React.useState(false);
  const [selectedRole, setSelectedRole] = React.useState('');
  const [responseMessage, setResponseMessage] = useState({
    text: '',
    error: false,
  });
  const [permissions, setPermissions] = useState<string[]>([]);
  const [role, setRole] = useState('');
  const [roles, setRoles] = useState('');
  const [info, setInfo] = useState('');
  const [acc, setAcc] = useState<any>('');
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [logo, setLogo] = useLocalStorage('logo', '');
  // const [logo, setLogo] = useState('');

  useEffect(() => {
    document.addEventListener('logo-changed', event => {
      if (event instanceof CustomEvent) {
        setLogo(event.detail.logo);
      }
    });
  }, []);

  useEffect(() => {
    if (isImpersonated === false) {
      getLogo()
        .then(response => {
          if (response.status === 200 && response.data.image !== null) {
            setLogo(response.data.image);
          } else if (response.status === 200 && response.data.image === null) {
            setLogo('');
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  }, []);

  const isMobile = useMediaQuery('(max-width:767px)');

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  useEffect(() => {
    // setLoadingData(true);
    checkImpersonate()
      .then(response => {
        if (response.status === 200) {
          // setLoadingData(false);
          response.data.result === '1'
            ? setIsImpersonated(true)
            : setIsImpersonated(false);
          response.data.result === '1' &&
            response.data.role !== 'Customer' &&
            setInfo(response.data.role);
          response.data.result === '1' &&
            response.data.role === 'Customer' &&
            setInfo(response.data.accountant_name);
          localStorage.setItem(
            'impersonated',
            isImpersonated === true ? '1' : '0',
          );
        }
      })
      .catch(error => {
        // setLoadingData(false);
        console.log(error);
      });
  }, [newImpersonation]);

  useEffect(() => {
    getImpersonateData()
      .then(response => {
        if (response.status === 200) {
          setAcc(
            response.data.accountants.map(item => {
              if (item.Customer === null && item.NameCustomer === null) {
                return {
                  value: null,
                  label: '',
                };
              }
              return {
                value: item.Customer,
                label: item.NameCustomer
                  ? item.NameCustomer.trim().replace(/\s+$/, '')
                  : '',
              };
            }),
          );

          setRoles(
            response.data.roles
              .filter(value => value.name !== 'Customer')
              .map(item => ({
                value: item.name,
                label: item.name,
              })),
          );
          setRole(response.data.user_role);
          const permissionsRaw =
            response.data.Impersonate === 0
              ? response.data.permissions
              : response.data.original_permissions;
          const filteredPermissions = permissionsRaw.filter(
            value =>
              value.name.split(';')[1] === 'Customer' ||
              value.name.includes('Caravela'),
          );

          const permissionNames = filteredPermissions.map(
            module => module.name.split(';')[0],
          );
          setPermissions(permissionNames);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  const changeRole = (selected: any) => {
    setLoading(true);
    setSelectedRole(selected.value);
    if (selected.value !== 'Customer') {
      const payload = { role: selected.value };
      impersonateRole(payload)
        .then(response => {
          if (response.status === 200) {
            setLoading(false);
            setNewImpersonation(true);
            navigate(`/`);
          } else if (response.status === 400) {
          }
        })
        .catch(error => {
          setLoading(false);
          console.log(error.response?.data?.message);
        });
    }
  };

  const changeUser = (selected: any) => {
    setLoading(true);
    const payload = {
      role: 'Customer',
      customer: selected.value,
      name_customer: selected.label,
    };
    impersonateCustomer(payload)
      .then(response => {
        if (response.status === 200) {
          setNewImpersonation(true);
          setLoading(false);
          navigate(`/`);
          // console.log(response);
        } else if (response.status === 400) {
          console.log('error');
        }
      })
      .catch(error => {
        setLoading(false);
        console.log(error.response?.data?.message);
      });
  };

  const setLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };
  const onSubmitSearch = (e: any) => {
    console.log(searchQuery);
  };

  const signout = (e: any) => {
    LogoutAction();
    navigate(`login`);
  };

  const profileRedirect = (e: any) => {
    toggleMobileMenu();
    navigate(`/profile`);
  };

  const feedbackRedirect = (e: any) => {
    toggleMobileMenu();
    navigate(`/feedback`);
  };

  const support = (e: any) => {
    toggleMobileMenu();
    navigate(`/support-center`);
  };

  const goBack = () => {
    setLoadingData(true);
    const token = localStorage.getItem('caravela_access_token');
    const url = `${process.env.REACT_APP_BASE_URL}/impersonate/logout`;
    axios
      .put(
        url,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )
      .then(response => {
        if (response.status === 200) {
          navigate('/');
          setLoadingData(false);
          window.location.reload();
        } else if (response.status === 400) {
          setLoadingData(false);
          console.log('error');
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const onSubmitFeedback = e => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData.entries()) as any;
    sendFeedback(data as FeedbackPayload)
      .then(response => {
        if (response.status === 201) {
          setResponseMessage({
            text: t(translations.general.successfully_sent),
            error: false,
          });
          setTimeout(() => setOpenPopup(false), 2000); // 2 seconds to read response
        }
      })
      .catch(error => {
        setResponseMessage({
          text: error.response?.data?.message,
          error: true,
        });
      });
  };

  return (
    <>
      {!isMobile ? (
        <Container>
          <ActionsContainer>
            {isImpersonated === false && (
              <>
                {(role === 'Admin' || role === 'IT') && (
                  <Dropdown
                    width={true}
                    defaultValue={'Role'}
                    handleChange={changeRole}
                    list={roles}
                  />
                )}
                {(selectedRole === 'Customer' ||
                  permissions.some(permission =>
                    permission.includes('Caravela'),
                  )) &&
                  acc && (
                    <Dropdown
                      width={true}
                      handleChange={changeUser}
                      defaultValue={'Customer'}
                      list={acc}
                    />
                  )}
              </>
            )}
            {isImpersonated === true && !isMobile && (
              <>
                <ButtonStyled
                  style={{
                    background: muiTheme.palette.primary.main,
                  }}
                  type="button"
                  onClick={goBack}
                  value={t(translations.general.back)}
                />
                <div
                  style={{
                    color: 'grey',
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '8px',
                  }}
                >
                  <h3>{info}</h3>
                </div>
              </>
            )}
            {/* <Avatar src={`data:image/png;base64, ${logo}`} /> */}

            <SimplePopper
              onClick={() => setPendingNotifications(false)}
              clickable={
                pendingNotifications ? (
                  <img src={ringing_bell} alt="notifications" />
                ) : (
                  <img src={bell} alt="notifications" />
                )
              }
              popupContent={
                <>
                  {purchaseNotification
                    ? purchaseNotification.map(value => (
                        <div style={{ paddingLeft: '5%', display: 'flex' }}>
                          <ShoppingCartIcon color={styledTheme.Black} />
                          <div style={{ marginLeft: '10px' }}>
                            {t(translations.general.order_generated)} {value}
                          </div>
                        </div>
                      ))
                    : null}
                </>
              }
            />
            <SimplePopper
              clickable={
                logo ? (
                  <Avatar src={`data:image/png;base64, ${logo}`} />
                ) : (
                  <img src={profile} alt="profile" />
                )
              }
              popupContent={
                <>
                  <div onClick={profileRedirect} style={{ cursor: 'pointer' }}>
                    <img src={writing_pen} alt="new chat" />
                    {t(translations.navbar?.profile?.edit)}
                  </div>
                  <div onClick={support} style={{ cursor: 'pointer' }}>
                    <NewChatIcon scale={0.7} color={styledTheme.Black} />
                    {t(translations.pages.support_center.title)}
                  </div>
                  <div onClick={signout} style={{ cursor: 'pointer' }}>
                    <img src={sign_out} alt="sign out" />
                    {t(translations.navbar?.profile?.sign_out)}
                  </div>
                </>
              }
            />
            <Button onClick={() => setOpenPopup(true)}>
              <img
                style={{ width: '60px', height: '45px' }}
                src={alert}
                alt="alert icon"
              />
            </Button>
            <FeedbackModal
              open={openPopup}
              onClose={() => {
                setOpenPopup(false);
                setResponseMessage({ text: '', error: false });
              }}
              onSubmit={onSubmitFeedback}
              responseMessage={responseMessage}
            />
            <SimplePopper
              clickable={
                <LangBtn id="langBtn">
                  {t(translations.navbar?.language) === 'Idioma' ? (
                    <img
                      src={spain}
                      alt="spain logo"
                      style={{ width: '33px', height: '33px' }}
                    />
                  ) : t(translations.navbar?.language) === 'language' ? (
                    <img
                      src={uk}
                      alt="uk logo"
                      style={{ width: '33px', height: '33px' }}
                    />
                  ) : (
                    <img
                      src={china}
                      alt="china logo"
                      style={{ width: '33px', height: '33px' }}
                    />
                  )}
                </LangBtn>
              }
              popupContent={
                <>
                  <div
                    style={{ cursor: 'pointer' }}
                    id="enLang"
                    onClick={() => setLanguage('en')}
                  >
                    en
                  </div>
                  <div
                    style={{ cursor: 'pointer' }}
                    id="esLang"
                    onClick={() => setLanguage('es')}
                  >
                    es
                  </div>
                  <div
                    style={{ cursor: 'pointer' }}
                    id="zhLang"
                    onClick={() => setLanguage('zh')}
                  >
                    zh
                  </div>
                </>
              }
            />
          </ActionsContainer>
        </Container>
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              background: '#F6F6F6',
              height: '10%',
              justifyContent: 'flex-end',
              alignItems: 'center',
              padding: '0 20px',
            }}
          >
            <div style={{ marginRight: 'auto' }} onClick={() => navigate(`/`)}>
              <IconButton edge="start" color="inherit" aria-label="home">
                <HomeIcon color={'black'} />
              </IconButton>
            </div>
            <div>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="notifications"
              >
                <img
                  src={bell}
                  style={{ height: '28px', width: '28px', marginRight: '7px' }}
                  alt="notifications"
                />
              </IconButton>
            </div>

            <div>
              <IconButton
                edge="end"
                color="inherit"
                aria-label="menu"
                onClick={toggleMobileMenu}
              >
                <MenuIcon />
              </IconButton>
            </div>
          </div>

          {/* <Container> */}
          {/* {isImpersonated === true && (
            <>
              <ButtonStyled
                style={{
                  background: 'blue',
                }}
                type="button"
                onClick={goBack}
                value={t(translations.general.back)}
              />
              <div
                style={{
                  color: 'grey',
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '8px',
                }}
              >
                <h3>{info}</h3>
              </div>
            </>
          )} */}
          {/* </Container> */}

          <Drawer
            anchor="left"
            open={isMobileMenuOpen}
            onClose={toggleMobileMenu}
          >
            <DrawerContainer>
              <Toolbar />{' '}
              <List>
                <div
                  style={{
                    color: 'grey',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  {(role === 'Admin' || role === 'IT') &&
                    isImpersonated === false && (
                      <Dropdown
                        defaultValue={'Role'}
                        handleChange={changeRole}
                        list={roles}
                        width={false}
                        noMargin={false}
                      />
                    )}
                  {(selectedRole === 'Customer' ||
                    permissions.some(permission =>
                      permission.includes('Caravela'),
                    )) &&
                    isImpersonated === false &&
                    acc && (
                      <Dropdown
                        handleChange={changeUser}
                        defaultValue={'Customer'}
                        list={acc}
                        width={false}
                        noMargin={false}
                      />
                    )}
                </div>
                {isImpersonated === true && isMobile && (
                  <>
                    <div
                      style={{
                        color: 'grey',
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '8px',
                      }}
                    >
                      <h3>{info}</h3>
                    </div>
                    <ButtonStyled
                      style={{
                        background: 'blue',
                        marginBottom: '50px',
                      }}
                      type="button"
                      onClick={goBack}
                      value={t(translations.general.back)}
                    />
                  </>
                )}
                <ListItem button onClick={profileRedirect}>
                  <ListItemIcon>
                    <img src={profile} alt="profile" />
                  </ListItemIcon>
                  <ListItemText
                    primary={t(translations.navbar?.profile?.edit)}
                  />
                </ListItem>
                <ListItem button onClick={support} style={{ color: '#25A74C' }}>
                  <ListItemIcon>
                    <NewChatIcon color={'#25A74C'} />
                  </ListItemIcon>
                  <ListItemText
                    primary={t(translations.pages.support_center.title)}
                  />
                </ListItem>
                <ListItem
                  button
                  onClick={feedbackRedirect}
                  style={{ color: '#5C36F3' }}
                >
                  <ListItemIcon onClick={feedbackRedirect}>
                    <img src={alert} alt="alert icon" />
                  </ListItemIcon>
                  <ListItemText primary={t(translations.general.experience)} />
                </ListItem>
                <ListItem button onClick={signout} style={{ color: '#F33636' }}>
                  <ListItemIcon>
                    <img src={sign_out_alt} alt="sign out" />
                  </ListItemIcon>
                  <ListItemText
                    primary={t(translations.navbar?.profile?.sign_out)}
                  />
                </ListItem>
              </List>
            </DrawerContainer>
          </Drawer>
        </>
      )}
    </>
  );
}
