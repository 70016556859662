import * as React from 'react';
// import { styled } from '@mui/material/styles';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import styled, { useTheme } from 'styled-components';
import { useEffect, useState } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import { CustomButton } from '../Button';
import { t } from 'i18next';
import { translations } from 'locales/translations';
import {
  StyledHeadCell,
  StyledTableCell,
  SlimButton,
  Chip,
  StyledTableRow,
} from './styles';
import { Table, ThemeProvider, useMediaQuery } from '@mui/material';
import { AcceptRejectValueModal } from '../AcceptRejectValueModal';
import { cp } from 'shelljs';
import { TheaterComedy } from '@mui/icons-material';

interface MyPositionTableProps {
  coffees: any[];
  warehousesListProp: any[];
  requestList: any[];
  setRequestList: Function;
  getCoffeesWithFiltersFirstCall: () => void;
}

const Warehouse = styled.div`
  background: #1989b2;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  width: 201px;
  height: 49px;
  margin-left: 14px;
  font-weight: 325;
  font-size: 15px;
  overflow: hidden;
  text-align: center;
`;

const ContainerHeader = styled.div`
  display: flex;
  width: 100%;
  background: #ebeaea;
  justify-content: space-around;
  margin-bottom: 5px;
  align-items: center;
`;

const Tab = styled.div<{ state: string }>`
  background-color: ${props =>
    props.state === 'active' ? '#F6F6F6' : '#33BA79'};
  color: ${props => (props.state === 'active' ? '#000000' : '#FFFFFF')};
  display: flex;
  cursor: ${props => (props.state === 'active' ? 'default' : 'pointer')};
  transition: all 0.5s ease-in-out;
  height: 50px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Text = styled.div`
  // font-weight: 325;
  font-size: 15px;
`;

const ContainerBody = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  background: #f8f8f8;
  align-items: center;
`;

export function MyPositionTableMobile({
  coffees,
  warehousesListProp,
  requestList,
  setRequestList,
  getCoffeesWithFiltersFirstCall,
}: MyPositionTableProps) {
  const theme: any = useTheme();
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<number>(0);
  const [id, setId] = useState('');
  const [type, setType] = useState('Confirm');
  const [coffesGrouped, setCoffes] = useState(false);
  const [received, setReceived] = useState(false);

  const isMobile = useMediaQuery('(max-width:767px)');

  // useEffect(() => {
  //   coffees && formatCoffe(coffees);
  // }, []);

  const yes = t(translations.general.yes);
  const no = t(translations.general.no);
  const receivedText = t(translations.general.received);
  const resultText = t(translations.general.result);

  const formatCoffe = coffe => {
    let groupingViaCommonProperty = Object.values(
      coffe.reduce((acc, current) => {
        acc[current.origin] = acc[current.origin] ?? [];
        acc[current.origin].push(current);
        return acc;
      }, {}),
    );
    let auxArray;
    let coffesFinal: any = [];
    for (let i = 0; i < groupingViaCommonProperty.length; i++) {
      let arr: any = groupingViaCommonProperty[i];
      auxArray = Object.values(
        arr.reduce((acc, current) => {
          acc[current.warehouse] = acc[current.warehouse] ?? [];
          acc[current.warehouse].push(current);
          return acc;
        }, {}),
      );
      const finalArray = auxArray[0][0];
      for (let j = 0; j < finalArray.length; j++) {
        let internalArray: any = finalArray[j];
        finalArray[j] = internalArray.sort((a, b) => {
          if (a.received == 0 && b.received != 0) return 1; // not received should go last
          if (b.received == 0 && a.received != 0) return -1;

          if (a.received == null && b.received != null) return -1; // reception not confirmed(yes/no) should go first
          if (b.received == null && a.received != null) return 1;

          if (a.received == 1 && b.received == 1) {
            // both are accepted
            if (a.result == null && b.result != null) return -1; // unscored should before accepted/declined
            if (b.result == null && a.result != null) return 1;

            if (a.result == 1 && b.result != 1) return -1; //accepted should before declined
            if (b.result == 1 && a.result != 1) return 1;
          }

          return 0;
        });
      }
      coffesFinal.push(finalArray);
    }
    setCoffes(coffesFinal);
  };

  const openModalNumeric = id => {
    setId(id);
    setType('Numeric');
    setOpen(true);
  };

  const confirmDenie = (value, id) => {
    setId(id);
    setValue(value);
    setType(value);
    setOpen(true);
  };

  return coffees.length > 0 ? (
    <>
      <ThemeProvider theme={theme}>
        {open === true ? (
          <AcceptRejectValueModal
            setOpen={setOpen}
            type={type}
            id={id}
            value={value}
            getCoffeesWithFiltersFirstCall={getCoffeesWithFiltersFirstCall}
          />
        ) : (
          coffees.map(
            group => (
              // item.map(group => (
              <>
                <div
                  className="d-flex align-items-center mt-5 mb-4"
                  key={group[0].warehouse}
                >
                  <h1 style={{ color: theme.palette.primary.main }}>
                    {group[0].warehouse}
                  </h1>
                </div>
                <ContainerHeader
                  style={{ background: theme.palette.secondary.main }}
                >
                  <StyledHeadCell
                    style={{
                      borderBottom: '0px',
                    }}
                    background={theme.palette.secondary.main}
                    align="center"
                  >
                    {t(translations.table.ico)}
                  </StyledHeadCell>
                  <StyledHeadCell
                    style={{
                      borderBottom: '0px',
                    }}
                    background={theme.palette.secondary.main}
                    align="center"
                  >
                    {t(translations.table.marks)}
                  </StyledHeadCell>

                  <StyledHeadCell
                    align="center"
                    background={theme.palette.secondary.main}
                  >
                    {t(translations.general.result)}
                  </StyledHeadCell>
                </ContainerHeader>

                {coffees.map(
                  subitem =>
                    // item.map(subitem =>
                    subitem.map(
                      groupBy =>
                        // groupBy.origin === group[0].origin &&
                        groupBy.warehouse === group[0].warehouse && (
                          <ContainerBody>
                            <StyledTableCell
                              style={{
                                borderBottom: '0px',
                              }}
                              align="center"
                            >
                              {groupBy.ico}
                            </StyledTableCell>
                            <StyledTableCell
                              style={{
                                borderBottom: '0px',
                              }}
                              align="center"
                            >
                              {groupBy.mark}
                            </StyledTableCell>
                            {/* {!received ? ( */}
                            <StyledTableCell
                              style={{
                                borderBottom: '0px',
                                padding: '0 15px 0 0',
                              }}
                              align="center"
                            >
                              {groupBy.result === null && (
                                <>
                                  <SlimButton
                                    onClick={() =>
                                      confirmDenie('Confirm', groupBy.id)
                                    }
                                    background={theme.palette.success.main}
                                    color={theme.WhitePowder}
                                    style={{ padding: '5px 11px' }}
                                  >
                                    ✓
                                  </SlimButton>
                                  <SlimButton
                                    onClick={() =>
                                      confirmDenie('Reject', groupBy.id)
                                    }
                                    background={theme.palette.error.main}
                                    color={theme.WhitePowder}
                                    style={{ padding: '5px 11px' }}
                                  >
                                    X
                                  </SlimButton>
                                </>
                              )}
                              {groupBy.result !== null &&
                                groupBy.result !== '' && (
                                  <SlimButton
                                    // onClick={() => openModalNumeric(item.id)}
                                    background={
                                      groupBy.result === 0
                                        ? theme.palette.error.main
                                        : theme.palette.success.main
                                    }
                                    color={theme.WhitePowder}
                                    style={{
                                      cursor: 'default',
                                      fontSize: '11px',
                                    }}
                                  >
                                    {groupBy.result === 0
                                      ? t<string>(translations.general.declined)
                                      : t<string>(
                                          translations.general.accepted,
                                        )}
                                  </SlimButton>
                                )}
                            </StyledTableCell>
                          </ContainerBody>
                        ),
                    ),
                  // ),
                )}
              </>
            ),
            // )),
          )
        )}
      </ThemeProvider>
    </>
  ) : null;
}
