// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/VTCMarsha-Bold.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: 'MarshaBold';\n  src: local('MarshaBold'), url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('woff');\n}", "",{"version":3,"sources":["webpack://./src/app/index.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,gFAAyE;AAC3E","sourcesContent":["@font-face {\n  font-family: 'MarshaBold';\n  src: local('MarshaBold'), url(./fonts/VTCMarsha-Bold.woff) format('woff');\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
