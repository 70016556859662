import { Link } from 'react-router-dom';
import { RadioGroup } from '@mui/material';
import { Theme } from '@mui/system';
import styled from 'styled-components/macro';
import { muiTheme, styledTheme } from 'styles/theme';

export const customTypography = {
  typography: {
    fontWeight: muiTheme.typography.fontWeightLight,
    fontSize: '20px',
    color: styledTheme.DarkGray,
  },
};

export const feedbackBoxStyle = (theme: Theme) => ({
  position: 'absolute',
  margin: '33vh 25vw',
  minHeight: '35vh',
  minWidth: '60vw',
  backgroundColor: styledTheme.WhitePowder,
  border: 'none',
  boxShadow: '0px 24px 24px rgba(0, 0, 0, 0.25);',
  padding: '20px 31px 19px 26px',
  borderRadius: '20px',

  fontStyle: 'normal',
  fontWeight: 325,
  fontSize: '20px',
  lineHeight: '24px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
});

export const CustomRadioGroup = styled(RadioGroup)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  // padding: 30px 0px 20px;
  width: 100%;
`;

export const FeedBackLabel = styled.label`
  font-weight: 350;
  font-size: 25px;
  line-height: 30px;
  margin-top: 10px;
  color: ${props => props.theme.DarkGray};
`;

export const UnstyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

export const LangBtn = styled.div`
  display: flex;
  text-align: center;
  min-width: 40px;

  font-style: normal;
  font-weight: 300;
  font-size: 18px;
`;
export const InputAdornment = styled('div')`
  margin: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 10vh;
  top: 0;
  color: ${props => props.theme.Black};
  &.div > img {
    object-fit: contain;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  position: relative;
  top: 0;
  right: 0;
  margin: 1%;
  gap: 30px;
  justify-content: flex-end;
  height: 50%;
  width: 70%;
  align-items: center;
`;
