import styled from 'styled-components/macro';
import caravela from '../../../app/assets/SVG/caravela.svg';
import { Link } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { ChangePassPayload, EmailPayload } from 'models/authModels';
import { changePass, resetPass } from 'services/authServices';

interface ChangePassProps {
  openCloseModal: () => void;
  email: string;
}

const Button = styled.input`
  border: none;
  background: #5c36f3;
  color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  margin-bottom: 25px;
  width: 213.02px;
  height: 49.59px;
  margin: 0 10px;
`;
const Bg = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(15px);
`;

const Login = styled.div`
  width: 518px;
  height: 468px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: #ffffff;
  border-radius: 30px;
  z-index: 1000;
`;

const InputText = styled.div`
  font-style: normal;
  font-weight: 350;
  font-size: 20px;
  line-height: 24px;
  color: #545454;
  margin: 10px 0;
  padding: 0 50px;
`;

export function NewPassModal({ openCloseModal, email }: ChangePassProps) {
  const { t } = useTranslation();

  const closeModal = () => {
    openCloseModal();
  };

  const getNewPass = (email: string) => {
    const payload: EmailPayload = { email };
    resetPass(payload)
      .then(response => {
        if (response.status === 200) {
          response.data.message === 'Mail sended' && setChanged(!changed);
        } else if (response.status === 404) {
          console.log(error);
        }
      })
      .catch(error => {
        setError(error.response?.data?.message);
      });
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          closeModal();
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const [error, setError] = useState('');
  const [changed, setChanged] = useState(false);
  const [current_password, setCurrent] = useState('');
  const [new_password, setNewP] = useState('');
  const [repeat, setRepeat] = useState('');

  const resetModal = () => {
    setChanged(!changed);
    openCloseModal();
  };

  return (
    <Bg>
      <Login ref={wrapperRef}>
        <img
          src={caravela}
          alt="caravela logo"
          style={{ marginTop: '51px', marginBottom: '31px' }}
        />
        {changed ? (
          <>
            <div
              style={{
                color: 'green',
                display: 'flex',
                justifyContent: 'center',
                marginTop: '60px',
              }}
            >
              {t(translations.general.pass_sent)}
            </div>
            <Button
              type="button"
              style={{ marginTop: '50px' }}
              onClick={resetModal}
              value={t(translations.general.close)}
            />
          </>
        ) : (
          <div className="d-flex flex-column">
            <InputText>{t(translations.general.pass_confirm)}</InputText>
            {error !== '' ? (
              <div
                style={{
                  color: 'red',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {t(`${translations.errors_400}.${error}`)}
              </div>
            ) : null}
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Button
                style={{ marginTop: '35px' }}
                type="button"
                onClick={() => getNewPass(email)}
                value={t(translations.general.confirm)}
              />
              <Button
                style={{ marginTop: '35px', background: 'red' }}
                type="button"
                onClick={closeModal}
                value={t(translations.general.cancel)}
              />
            </div>
          </div>
        )}
      </Login>
    </Bg>
  );
}
