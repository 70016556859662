import React, { useState, useEffect, useRef } from 'react';

import caravela from 'app/assets/SVG/Logo-rebrand.svg';
import caravelaShortOld from 'app/assets/SVG/LOGO-SIMBOLO-GREEN 1.png';
import Arrow from 'app/assets/SVG/Arrow.png';
import { Link, useLocation } from 'react-router-dom';
import { LargeButton } from 'app/components/LargeButton';
import {
  HomeIcon,
  DocReportIcon,
  CheckBoxIcon,
  TruckIcon,
  AnalyticsIcon,
  HistoryIcon,
  ShoppingCartIcon,
  CheckListIcon,
  ArrowDownload,
  PreShipmentIcon,
  EditReportIcon,
  UbicationIcon,
} from 'app/assets/SVG/color_icons';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import {
  FlexColumnDiv,
  Container,
  CustomLink,
  CustomDiv,
  CollapsibleText,
  SidebarIcon,
} from './styles';
import Tooltip from '../Tooltip';
import { translations } from 'locales/translations';
import { getUser } from 'services/authServices';
import axios from 'axios';
import { useMediaQuery } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from 'react-router-dom';
import { width } from '@mui/system';
import Tooltipp from '@mui/material/Tooltip';

const CollapsibleImage = styled.div<{ isHovered: boolean }>`
  /* Estilos de la imagen */
  margin: 30px 10px 50px;
  // visibility: ${({ isHovered }) => (isHovered ? 'visible' : 'hidden')};
  height: 54px;
  transition: visibility 0.2s ease-in-out;
`;

export function SideBar(newImpersonation: any) {
  const theme: any = useTheme();
  const location = useLocation();
  const path = location.pathname;
  const [visibleTooltip, setVisibleTooltip] = useState(-1);
  const [role, setRole] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [firstTime, setFirstTime] = useState<Boolean>(
    localStorage.getItem('firstLogin')?.toLowerCase() === 'true',
  );
  const [userData, setUserData] = useState<any>();
  const { t, i18n } = useTranslation();
  // const [modules, setModules] = useState(localStorage.getItem('modules'));
  const [modules, setModules] = useState<any>();
  const [modulesThree, setModulesThree] = useState<any>();
  const [isHovered, setIsHovered] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  useOutsideAlerter(menuRef);

  const [loading, setLoading] = useState<Boolean>(false);
  const [error, setError] = useState<any>('');
  const navigate = useNavigate();

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const chageIcons = (value: string) => {
    // const updatedModulesThree = [
    //   value,
    //   ...modulesThree.slice(0, modulesThree.length - 1),
    // ];
    // setModulesThree(updatedModulesThree);
  };
  // Download pdf´s funtion
  const getPositionCall = () => {
    setError('');
    setLoading(true);
    let url = process.env.REACT_APP_BASE_URL + '/reports/position';
    const token = localStorage.getItem('caravela_access_token');
    axios({
      method: 'GET',
      url,
      responseType: 'blob',
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(response => {
        if (response.status === 200) {
          setLoading(false);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Position Report.pdf'); //or any other extension
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch(error => {
        console.log(error.response?.data);
        setError('report_not_found');
        setLoading(false);
      });
  };

  const redirect = module => {
    setIsMenuOpen(false);
    module === 'Purchase Order' && navigate(`/purchase-order`);
    module === 'Review Delivery Order' && navigate(`/review-delivery`);
    module === 'Sample Request' && navigate(`/sample-request`);
    module === 'Sample Request Sales' && navigate(`/sales/offerings`);
    module === 'Offerings' && navigate(`/offerings`);
    module === 'Reports' && navigate(`/reports`);
    module === 'Edit Reports' && navigate(`/edit-reports`);
    module === 'History' && navigate(`/history`);
    module === 'Admin' && navigate(`/admin`);
    module === 'Admin Role' && navigate(`/admin-roles`);
    module === 'Downloadpdf' && navigate('/downloadpdf');
    module === 'Where is my Coffee' && navigate(`/where-is-coffee`);
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsMenuOpen(false);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  const isMobile = useMediaQuery('(max-width:767px)');

  useEffect(() => {
    firstTime && setVisibleTooltip(1);
  }, []);

  useEffect(() => {
    getUser()
      .then(response => {
        if (response.status === 200) {
          setUserData(response.data);
          localStorage.setItem('role', response.data.role);
          setRole(response.data.role);
          const token = localStorage.getItem('caravela_access_token');
          const url = `${process.env.REACT_APP_BASE_URL}/admin-role`;
          response.data.role &&
            axios
              .get(url, {
                params: { role: response.data.role },
                headers: { Authorization: `Bearer ${token}` },
              })
              .then(response => {
                if (response.status === 200) {
                  const filteredModules = response.data.filter(
                    value => value.split(';')[1] === 'View',
                  );
                  const moduleNames = filteredModules.map(
                    module => module.split(';')[0],
                  );

                  const filteredPermissions = response.data.filter(
                    value => value.split(';')[1] === 'Customer',
                  );
                  const permissionNames = filteredPermissions.map(
                    module => module.split(';')[0],
                  );
                  localStorage.setItem('permissions', permissionNames);
                  setModules(moduleNames);
                  let page = '';
                  if (path === '/') page = 'Home';
                  if (path === '/purchase-order') page = 'Purchase Order';
                  if (path === '/review-delivery')
                    page = 'Review Delivery Order';
                  if (path === '/sample-request') page = 'Sample Request';
                  if (path === '/sales/offerings')
                    page = 'Sample Request Sales';
                  if (path === '/offerings') page = 'Offerings';
                  if (path === '/reports') page = 'Reports';
                  if (path === '/edit-reports') page = 'Report Edit';
                  if (path === '/history') page = 'History';
                  if (path === '/admin') page = 'Admin';
                  if (path === '/admin-role') page = 'Admin Role';
                  if (path === '/downloadpdf') page = 'Downloadpdf';
                  // const updatedModulesThree = [page, ...moduleNames.slice(-2)];
                  // setModulesThree(updatedModulesThree);
                  if (!modulesThree || !modulesThree.includes(page)) {
                    const updatedModulesThree = [
                      page,
                      ...(moduleNames.slice(-2) || []),
                    ];
                    setModulesThree(updatedModulesThree);
                  }
                }
              })
              .catch(error => {
                console.log(error.response?.data);
              });
        }
      })
      .catch(error => {
        console.log(error.response?.data);
      });
  }, [newImpersonation]);

  useEffect(() => {
    getUser()
      .then(response => {
        if (response.status === 200) {
          setUserData(response.data);
          localStorage.setItem('role', response.data.role);
          setRole(response.data.role);
          const token = localStorage.getItem('caravela_access_token');
          const url = `${process.env.REACT_APP_BASE_URL}/admin-role`;
          response.data.role &&
            axios
              .get(url, {
                params: { role: response.data.role },
                headers: { Authorization: `Bearer ${token}` },
              })
              .then(response => {
                if (response.status === 200) {
                  const filteredModules = response.data.filter(
                    value => value.split(';')[1] === 'View',
                  );
                  const moduleNames = filteredModules.map(
                    module => module.split(';')[0],
                  );

                  const filteredPermissions = response.data.filter(
                    value => value.split(';')[1] === 'Customer',
                  );
                  const permissionNames = filteredPermissions.map(
                    module => module.split(';')[0],
                  );
                  localStorage.setItem('permissions', permissionNames);
                  setModules(moduleNames);
                  let page = '';
                  if (path === '/') page = 'Home';
                  if (path === '/purchase-order') page = 'Purchase Order';
                  if (path === '/review-delivery')
                    page = 'Review Delivery Order';
                  if (path === '/sample-request') page = 'Sample Request';
                  if (path === '/sales/offerings')
                    page = 'Sample Request Sales';
                  if (path === '/offerings') page = 'Offerings';
                  if (path === '/reports') page = 'Reports';
                  if (path === '/edit-reports') page = 'Report Edit';
                  if (path === '/history') page = 'History';
                  if (path === '/admin') page = 'Admin';
                  if (path === '/admin-role') page = 'Admin Role';
                  const updatedModulesThree = [page, ...moduleNames.slice(-2)];
                  setModulesThree(updatedModulesThree);
                }
              })
              .catch(error => {
                console.log(error.response?.data);
              });
        }
      })
      .catch(error => {
        console.log(error.response?.data);
      });
  }, []);

  const changeTooltip = () => {
    if (visibleTooltip >= modules.length) {
      localStorage.setItem('firstLogin', 'false');
    } else setVisibleTooltip(visibleTooltip + 1);
  };

  const colorRole =
    role === 'Admin' || role === 'IT'
      ? '#545454'
      : role.includes('Sales')
      ? '#4F4E24'
      : theme.palette.primary.main;

  const imageUrl = isHovered ? caravela : caravelaShortOld;

  return (
    <FlexColumnDiv>
      {window.innerWidth >= 767 && (
        <Link to="/">
          <div style={{ alignSelf: 'flex-start' }}>
            <CollapsibleImage isHovered={isHovered}>
              <img
                src={imageUrl}
                alt="caravela logo"
                style={{ width: isHovered ? '80%' : '100%' }}
              />
            </CollapsibleImage>
          </div>
        </Link>
      )}

      <Container
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        role={colorRole}
        style={{
          // justifyContent: 'flex-start',
          // marginTop: '60px',
          padding: '30px 10px',
          ...(role === 'Admin' || role === 'IT'
            ? { background: '#545454' }
            : role.includes('Sales')
            ? //? { background: '#1989B2' }
              { background: '#4F4E24' }
            : { background: theme.palette.primary.main }),
        }}
      >
        {/*  {isMobile ? (
          <CollapsibleText
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              paddingBottom: '5px',
              color: 'white',
            }}
          >
            {t(translations.pages.home.roaster_hub)}
          </CollapsibleText>
        ) : (
          <CollapsibleText
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              paddingBottom: '10px',
              color: 'white',
            }}
          >
            {t(translations.pages.home.roaster_hub)}
          </CollapsibleText>
        )} */}
        {isMobile ? (
          <div onClick={() => getPositionCall()} style={{ cursor: 'pointer' }}>
            <LargeButton
              color={theme.palette.primary.main}
              icon={Arrow}
              text=""
            />
          </div>
        ) : (
          // <CustomLink role={colorRole} to={window.location.pathname} style={{width: '80%',
          // fontSize: '80%',
          // marginLeft: 'auto', }}>
          //         <>
          //           <SidebarIcon>
          //             <ArrowDownload
          //               color={
          //                 path === '/position-report' ? colorRole : theme.White
          //               }
          //             />
          //           </SidebarIcon>
          //           <CollapsibleText onClick={ () => getPositionCall()}>
          //             {t(translations.pages.home.donwloadpr)}
          //           </CollapsibleText>
          //         </>
          //       </CustomLink>
          isHovered && (
            <Tooltipp
              title={t(translations.pages.home.donwloadpr)}
              placement="right"
            >
              <div
                onClick={() => getPositionCall()}
                style={{ cursor: 'pointer' }}
              >
                <LargeButton
                  color={colorRole}
                  icon={Arrow}
                  text={t(translations.pages.home.position)}
                />
              </div>
            </Tooltipp>
          )
        )}

        {isMobile
          ? modules &&
            modules.includes('Home') &&
            modulesThree.includes('Home') && (
              <CustomLink role={colorRole} to="/">
                <>
                  <HomeIcon color={path === '/' ? colorRole : theme.White} />
                  {/* {t(translations.pages.home.title)} */}
                </>
              </CustomLink>
            )
          : modules &&
            modules.includes('Home') && (
              <CustomLink role={colorRole} to="/">
                <>
                  <SidebarIcon>
                    <HomeIcon color={path === '/' ? colorRole : theme.White} />
                  </SidebarIcon>
                  <CollapsibleText>
                    {t(translations.pages.home.title)}
                  </CollapsibleText>
                </>
              </CustomLink>
            )}
        {isMobile
          ? modules &&
            modules.includes('Purchase Order') &&
            modulesThree.includes('Purchase Order') && (
              <Tooltip
                visible={modules[visibleTooltip] === 'Purchase Order'}
                label={t(translations.pages.purchase_order.description)}
                handleClickAway={changeTooltip}
              >
                <CustomLink role={colorRole} to="/purchase-order">
                  <>
                    <CheckBoxIcon
                      color={
                        path === '/purchase-order' ? colorRole : theme.White
                      }
                    />
                    {/* {t(translations.pages.purchase_order.title)} */}
                  </>
                </CustomLink>
              </Tooltip>
            )
          : modules &&
            modules.includes('Purchase Order') && (
              <Tooltip
                visible={modules[visibleTooltip] === 'Purchase Order'}
                label={t(translations.pages.purchase_order.description)}
                handleClickAway={changeTooltip}
              >
                <CustomLink role={colorRole} to="/purchase-order">
                  <>
                    <SidebarIcon>
                      <CheckBoxIcon
                        color={
                          path === '/purchase-order' ? colorRole : theme.White
                        }
                      />
                    </SidebarIcon>
                    <CollapsibleText>
                      {t(translations.pages.purchase_order.title)}
                    </CollapsibleText>
                  </>
                </CustomLink>
              </Tooltip>
            )}

        {isMobile
          ? modules &&
            modules.includes('Review Delivery Order') &&
            modulesThree.includes('Review Delivery Order') && (
              <Tooltip
                visible={modules[visibleTooltip] === 'Review Delivery Order'}
                label={t(translations.pages.review_delivery_orders.description)}
                handleClickAway={changeTooltip}
              >
                <CustomLink role={colorRole} to="/review-delivery">
                  <>
                    <TruckIcon
                      color={
                        path === '/review-delivery' ? colorRole : theme.White
                      }
                    />
                    {/* {t(translations.pages.review_delivery_orders.title)} */}
                  </>
                </CustomLink>
              </Tooltip>
            )
          : modules &&
            modules.includes('Review Delivery Order') && (
              <Tooltip
                visible={modules[visibleTooltip] === 'Review Delivery Order'}
                label={t(translations.pages.review_delivery_orders.description)}
                handleClickAway={changeTooltip}
              >
                <CustomLink role={colorRole} to="/review-delivery">
                  <>
                    <SidebarIcon>
                      <TruckIcon
                        color={
                          path === '/review-delivery' ? colorRole : theme.White
                        }
                      />
                    </SidebarIcon>
                    <CollapsibleText>
                      {t(translations.pages.review_delivery_orders.title)}
                    </CollapsibleText>
                  </>
                </CustomLink>
              </Tooltip>
            )}

        {isMobile
          ? modules &&
            modules.includes('Sample Request') &&
            modulesThree.includes('Sample Request') && (
              <Tooltip
                visible={modules[visibleTooltip] === 'Sample Request'}
                label={t(translations.pages.sample_request.description)}
                handleClickAway={changeTooltip}
              >
                <CustomLink role={colorRole} to="/sample-request">
                  <>
                    <PreShipmentIcon
                      color={
                        path === '/sample-request' ? colorRole : theme.White
                      }
                    />
                    {/* {t(translations.pages.sample_request.title)} */}
                  </>
                </CustomLink>
              </Tooltip>
            )
          : modules &&
            modules.includes('Sample Request') && (
              <Tooltip
                visible={modules[visibleTooltip] === 'Sample Request'}
                label={t(translations.pages.sample_request.description)}
                handleClickAway={changeTooltip}
              >
                <CustomLink role={colorRole} to="/sample-request">
                  <>
                    <SidebarIcon>
                      <PreShipmentIcon
                        color={
                          path === '/sample-request' ? colorRole : theme.White
                        }
                      />
                    </SidebarIcon>
                    <CollapsibleText>
                      {t(translations.general.sample)}
                    </CollapsibleText>
                  </>
                </CustomLink>
              </Tooltip>
            )}

        {/*  {isMobile
          ? modules &&
            modules.includes('Sample Request Sales') &&
            modulesThree.includes('Sample Request Sales') && (
              <Tooltip
                visible={modules[visibleTooltip] === 'Sample Request Sales'}
                label={t(translations.general.spot_offerings)}
                handleClickAway={changeTooltip}
              >
                <CustomLink role={colorRole} to="/sales/offerings">
                  <>
                  <SidebarIcon>
                      <ShoppingCartIcon
                        color={
                          path === '/sales/offerings' ? colorRole : theme.White
                        }
                      />
                    </SidebarIcon>
                    <CollapsibleText>{'Offerings Sales'}</CollapsibleText>
                  </>
                </CustomLink>
              </Tooltip>
            )
          : modules &&
            modules.includes('Sample Request Sales') && (
              <Tooltip
                visible={modules[visibleTooltip] === 'Sample Request Sales'}
                label={t(translations.general.spot_offerings)}
                handleClickAway={changeTooltip}
              >
                <CustomLink role={colorRole} to="/sales/offerings">
                  <>
                    <SidebarIcon>
                      <ShoppingCartIcon
                        color={
                          path === '/sales/offerings' ? colorRole : theme.White
                        }
                      />
                    </SidebarIcon>
                    <CollapsibleText>{'Offerings Sales'}</CollapsibleText>
                  </>
                </CustomLink>
              </Tooltip>
            )} */}

        {isMobile
          ? modules &&
            modules.includes('Offerings') && (
              /* &&
            modulesThree.includes('Offerings') && ( */
              <Tooltip
                visible={modules[visibleTooltip] === 'Offerings'}
                label={t(translations.pages.offerings.description)}
                handleClickAway={changeTooltip}
              >
                {role.includes('Sales') ? (
                  <CustomLink role={colorRole} to="/sales/offerings">
                    <>
                      <ShoppingCartIcon
                        color={
                          path === '/sales/offerings' ? colorRole : theme.White
                        }
                      />
                    </>
                  </CustomLink>
                ) : (
                  <CustomLink role={colorRole} to="/offerings">
                    <>
                      <ShoppingCartIcon
                        color={path === '/offerings' ? colorRole : theme.White}
                      />
                    </>
                  </CustomLink>
                )}
              </Tooltip>
            )
          : modules &&
            modules.includes('Offerings') && (
              <Tooltip
                visible={modules[visibleTooltip] === 'Offerings'}
                label={t(translations.pages.offerings.description)}
                handleClickAway={changeTooltip}
              >
                {role.includes('Sales') ? (
                  <CustomLink role={colorRole} to="/sales/offerings">
                    <>
                      <SidebarIcon>
                        <ShoppingCartIcon
                          color={
                            path === '/sales/offerings'
                              ? colorRole
                              : theme.White
                          }
                        />
                      </SidebarIcon>
                      <CollapsibleText>
                        {t(translations.general.spot_offerings)}
                      </CollapsibleText>
                    </>
                  </CustomLink>
                ) : (
                  <CustomLink role={colorRole} to="/offerings">
                    <>
                      <SidebarIcon>
                        <ShoppingCartIcon
                          color={
                            path === '/offerings' ? colorRole : theme.White
                          }
                        />
                      </SidebarIcon>
                      <CollapsibleText>
                        {t(translations.general.spot_offerings)}
                      </CollapsibleText>
                    </>
                  </CustomLink>
                )}
              </Tooltip>
            )}

        {isMobile
          ? modules &&
            modules.includes('Reports') &&
            modulesThree.includes('Reports') && (
              <Tooltip
                visible={modules[visibleTooltip] === 'Reports'}
                label={t(translations.pages.reports.description)}
                handleClickAway={changeTooltip}
              >
                <CustomLink role={colorRole} to="/reports">
                  <>
                    <DocReportIcon
                      color={path === '/reports' ? colorRole : theme.White}
                    />
                    {/* {t(translations.pages.reports.title)} */}
                  </>
                </CustomLink>
              </Tooltip>
            )
          : modules &&
            modules.includes('Reports') && (
              <Tooltip
                visible={modules[visibleTooltip] === 'Reports'}
                label={t(translations.pages.reports.description)}
                handleClickAway={changeTooltip}
              >
                <CustomLink role={colorRole} to="/reports">
                  <>
                    <SidebarIcon>
                      <DocReportIcon
                        color={path === '/reports' ? colorRole : theme.White}
                      />
                    </SidebarIcon>
                    <CollapsibleText>
                      {t(translations.pages.reports.title)}
                    </CollapsibleText>
                  </>
                </CustomLink>
              </Tooltip>
            )}

        {/* nuevo campo */}
        {isMobile
          ? modules &&
            modules.includes('Edit Reports') &&
            modulesThree.includes('Edit Reports') && (
              <Tooltip
                visible={modules[visibleTooltip] === 'Edit Reports'}
                label="Edit Reports"
                handleClickAway={changeTooltip}
              >
                <CustomLink role={colorRole} to="/edit-reports">
                  <>
                    <EditReportIcon
                      color={path === '/edit-reports' ? colorRole : theme.White}
                    />
                    {/* {t(translations.pages.reports.title)} */}
                  </>
                </CustomLink>
              </Tooltip>
            )
          : modules &&
            modules.includes('Edit Reports') && (
              <Tooltip
                visible={modules[visibleTooltip] === 'Edit Reports'}
                label={t(translations.pages.reports.title_edit)}
                handleClickAway={changeTooltip}
              >
                <CustomLink role={colorRole} to="/edit-reports">
                  <>
                    <SidebarIcon>
                      <EditReportIcon
                        color={
                          path === '/edit-reports' ? colorRole : theme.White
                        }
                      />
                    </SidebarIcon>
                    <CollapsibleText>
                      {t(translations.pages.reports.title_edit)}
                    </CollapsibleText>
                  </>
                </CustomLink>
              </Tooltip>
            )}
        {/* nuevo campo */}

        {isMobile
          ? modules &&
            modules.includes('History') &&
            modulesThree.includes('History') && (
              <Tooltip
                visible={modules[visibleTooltip] === 'History'}
                label={t(translations.pages.history.description)}
                handleClickAway={changeTooltip}
              >
                <CustomLink role={colorRole} to="/history">
                  <>
                    <HistoryIcon
                      color={path === '/history' ? colorRole : theme.White}
                    />
                    {/* {t(translations.pages.history.title)} */}
                  </>
                </CustomLink>
              </Tooltip>
            )
          : modules &&
            modules.includes('History') && (
              <Tooltip
                visible={modules[visibleTooltip] === 'History'}
                label={t(translations.pages.history.description)}
                handleClickAway={changeTooltip}
              >
                <CustomLink role={colorRole} to="/history">
                  <>
                    <SidebarIcon>
                      <HistoryIcon
                        color={path === '/history' ? colorRole : theme.White}
                      />
                    </SidebarIcon>
                    <CollapsibleText>
                      {t(translations.pages.history.title)}
                    </CollapsibleText>
                  </>
                </CustomLink>
              </Tooltip>
            )}
        {/* nuevo campo */}
        {isMobile
          ? modules &&
            modules.includes('Where is my Coffee') &&
            modulesThree.includes('Where is my Coffee') && (
              <Tooltip
                visible={modules[visibleTooltip] === 'Where is my Coffee'}
                label="Where is my Coffee"
                handleClickAway={changeTooltip}
              >
                <CustomLink role={colorRole} to="/where-is-coffee">
                  <>
                    <UbicationIcon
                      color={
                        path === '/where-is-coffee' ? colorRole : theme.White
                      }
                    />
                  </>
                </CustomLink>
              </Tooltip>
            )
          : modules &&
            modules.includes('Where is my Coffee') && (
              <Tooltip
                visible={modules[visibleTooltip] === 'Where is my Coffee'}
                label={t(translations.general.where)}
                handleClickAway={changeTooltip}
              >
                <CustomLink role={colorRole} to="/where-is-coffee">
                  <>
                    <SidebarIcon>
                      <UbicationIcon
                        color={
                          path === '/where-is-coffee' ? colorRole : theme.White
                        }
                      />
                    </SidebarIcon>
                    <CollapsibleText>
                      {t(translations.general.where)}
                    </CollapsibleText>
                  </>
                </CustomLink>
              </Tooltip>
            )}
        {/* nuevo campo */}
        {isMobile
          ? modules &&
            modules.includes('Admin') &&
            modulesThree.includes('Admin') && (
              <CustomLink role={colorRole} to="/admin">
                <>
                  <HomeIcon
                    color={path === '/admin' ? colorRole : theme.White}
                  />
                  {/* {t(translations.general.users)} */}
                </>
              </CustomLink>
            )
          : modules &&
            modules.includes('Admin') && (
              <CustomLink role={colorRole} to="/admin">
                <>
                  <SidebarIcon>
                    <HomeIcon
                      color={path === '/admin' ? colorRole : theme.White}
                    />
                  </SidebarIcon>
                  <CollapsibleText>
                    {t(translations.general.users)}
                  </CollapsibleText>
                </>
              </CustomLink>
            )}

        {isMobile
          ? modules &&
            modules.includes('Admin Role') &&
            role.includes('Sales') === false &&
            modulesThree.includes('Admin Role') && (
              <CustomLink role={colorRole} to="/admin-role">
                <>
                  <HistoryIcon
                    color={path === '/admin-role' ? colorRole : theme.White}
                  />
                  {/* {t(translations.general.roles)} */}
                </>
              </CustomLink>
            )
          : modules &&
            role.includes('Sales') === false &&
            modules.includes('Admin Role') && (
              <CustomLink role={colorRole} to="/admin-role">
                <>
                  <SidebarIcon>
                    <HistoryIcon
                      color={path === '/admin-role' ? colorRole : theme.White}
                    />
                  </SidebarIcon>
                  <CollapsibleText>
                    {t(translations.general.roles)}
                  </CollapsibleText>
                </>
              </CustomLink>
            )}

        {modules && isMobile && modules.length > 3 && (
          <CustomDiv role={colorRole} style={{ padding: '0' }}>
            <>
              <MoreVertIcon
                color={path === '' ? colorRole : theme.White}
                onClick={() => setIsMenuOpen(true)}
              />
              {/* {t(translations.general.more)} */}
            </>
          </CustomDiv>
        )}

        {isMenuOpen && (
          <div
            ref={menuRef}
            style={{
              position: 'fixed',
              bottom: '0',
              left: '0',
              width: '100%',
              backgroundColor: theme.palette.primary.main,
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
              padding: '10px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              minHeight: '40vh',
              flexGrow: 1,
              flexShrink: 0,
              transition: 'transform 0.3s ease',
              transform: isMenuOpen ? 'translateY(0)' : 'translateY(100%)',
            }}
          >
            {modules
              .filter(module => !modulesThree.includes(module))
              .map(module => (
                <div
                  key={module}
                  onClick={() => redirect(module)}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '10px',
                    margin: '5px 0',
                    color: 'white',
                    cursor: 'pointer',
                  }}
                >
                  {module === 'Purchase Order' && (
                    <CustomLink
                      role={colorRole}
                      to="/purchase-order"
                      onClick={() => chageIcons('Purchase Order')}
                    >
                      <>
                        <CheckBoxIcon
                          color={
                            path === '/purchase-order' ? colorRole : theme.White
                          }
                        />
                        {/* {t(translations.pages.purchase_order.title)} */}
                      </>
                    </CustomLink>
                  )}
                  {module === 'Home' && (
                    <CustomLink
                      role={colorRole}
                      to="/"
                      onClick={() => chageIcons('Home')}
                    >
                      <>
                        <HomeIcon
                          color={path === '/' ? colorRole : theme.White}
                        />
                        {/* {t(translations.pages.home.title)} */}
                      </>
                    </CustomLink>
                  )}
                  {module === 'Review Delivery Order' && (
                    <CustomLink
                      role={colorRole}
                      style={{ background: colorRole }}
                      to="/review-delivery"
                      onClick={() => chageIcons('Review Delivery Order')}
                    >
                      <>
                        <TruckIcon
                          color={
                            path === '/review-delivery'
                              ? colorRole
                              : theme.White
                          }
                        />
                      </>
                    </CustomLink>
                  )}
                  {module === 'Sample Request' && (
                    <CustomLink
                      role={colorRole}
                      style={{ background: colorRole }}
                      to="/sample-request"
                      onClick={() => chageIcons('Sample Request')}
                    >
                      <>
                        <CheckListIcon
                          color={
                            path === '/sample-request' ? colorRole : theme.White
                          }
                        />
                      </>
                    </CustomLink>
                  )}
                  {module === 'Sample Request Sales' && (
                    <CustomLink
                      role={colorRole}
                      style={{ background: colorRole }}
                      to="/sales/offerings"
                      onClick={() => chageIcons('Sample Request Sales')}
                    >
                      <>
                        <ShoppingCartIcon
                          color={
                            path === '/sales/offerings'
                              ? colorRole
                              : theme.White
                          }
                        />
                      </>
                    </CustomLink>
                  )}
                  {module === 'Offerings' &&
                    (role.includes('Sales') ? (
                      <CustomLink
                        role={colorRole}
                        style={{ background: colorRole }}
                        to="/sales/offerings"
                        onClick={() => chageIcons('Offerings')}
                      >
                        <ShoppingCartIcon
                          color={
                            path === '/sales/offerings'
                              ? colorRole
                              : theme.White
                          }
                        />
                      </CustomLink>
                    ) : (
                      <CustomLink
                        role={colorRole}
                        style={{ background: colorRole }}
                        to="/offerings"
                        onClick={() => chageIcons('Offerings')}
                      >
                        <ShoppingCartIcon
                          color={
                            path === '/offerings' ? colorRole : theme.White
                          }
                        />
                      </CustomLink>
                    ))}
                  {module === 'Reports' && (
                    <CustomLink
                      role={colorRole}
                      style={{ background: colorRole }}
                      to="/reports"
                      onClick={() => chageIcons('Reports')}
                    >
                      <>
                        <DocReportIcon
                          color={path === '/reports' ? colorRole : theme.White}
                        />
                      </>
                    </CustomLink>
                  )}
                  {module === 'Admin' && (
                    <CustomLink
                      role={colorRole}
                      style={{ background: colorRole }}
                      to="/admin"
                      onClick={() => chageIcons('Admin')}
                    >
                      <>
                        <HomeIcon
                          color={path === '/admin' ? colorRole : theme.White}
                        />
                      </>
                    </CustomLink>
                  )}
                  {module === 'History' && (
                    <CustomLink
                      role={colorRole}
                      style={{ background: colorRole }}
                      to="/history"
                      onClick={() => chageIcons('History')}
                    >
                      <>
                        <HistoryIcon
                          color={path === '/history' ? colorRole : theme.White}
                        />
                      </>
                    </CustomLink>
                  )}
                  {module === 'Admin Role' && (
                    <>
                      <CustomLink
                        role={colorRole}
                        style={{ background: colorRole }}
                        to="/admin-role"
                        onClick={() => chageIcons('Admin Role')}
                      >
                        <>
                          <HistoryIcon
                            color={
                              path === '/admin-role' ? colorRole : theme.White
                            }
                          />
                        </>
                      </CustomLink>
                    </>
                  )}
                  {module === 'Purchase Order'
                    ? t(translations.pages.purchase_order.title)
                    : module === 'Home'
                    ? t(translations.pages.home.title)
                    : module === 'Review Delivery Order'
                    ? t(translations.pages.review_delivery_orders.title)
                    : module === 'Sample Request'
                    ? t(t(translations.pages.sample_request.title))
                    : module === 'Sample Request Sales'
                    ? t(t(translations.pages.offerings.title))
                    : module === 'Offerings'
                    ? t(translations.general.spot_offerings)
                    : module === 'Reports'
                    ? t(translations.pages.reports.title)
                    : module === 'Admin'
                    ? t(t(translations.general.admin))
                    : module === 'History'
                    ? t(translations.pages.history.title)
                    : module === 'Admin Role'
                    ? t(translations.general.admin_role)
                    : ''}
                </div>
              ))}
          </div>
        )}

        {/* <Tooltip
          visible={visibleTooltip == 4}
          label={t(translations.pages.review_delivery_orders.description)}
          handleClickAway={changeTooltip}
        >
          <CustomLink role={colorRole} to="/analytics">
            <>
              <AnalyticsIcon
                color={path === '/analytics' ? colorRole : theme.White}
              />
              {t(translations.pages.analytics.title)}
            </>
          </CustomLink>
        </Tooltip> */}
      </Container>
    </FlexColumnDiv>
  );
}
