import SearchIcon from '@mui/icons-material/Search';
import { AdminTable } from 'app/components/AdminTable';
import Dropdown from 'app/components/Dropdown';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { getAdminUsers, getRoleList, updateUser } from 'services/adminServices';
import styled from 'styled-components/macro';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { CircularProgress, IconButton } from '@mui/material';
import MaterialTooltip from '@mui/material/Tooltip';

import { LoadingPage } from '../LoadingPage';

const Input = styled.input`
  background: #f6f6f6;
  border-radius: 30px;
  width: 205.16px;
  height: 40px;
  margin: 10px 10px;
  outline: none;
  padding: 10px;
  margin-top: 30px;
`;

const Button = styled.input`
  border: none;
  background: #5c36f3;
  color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  margin-bottom: 25px;
  width: 213.02px;
  height: 49.59px;
  margin: 0 10px;
  margin-left: auto;
`;

const Search = styled.div`
  display: flex;
  flex-direction: column;
`;

const Clean = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  margin-left: 20px;
  color: #493021;
  margin-top: 5px;
`;

const CleanFilter = () => (
  <svg
    width="25px"
    height="25px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 15L21 21M21 15L15 21M10 21V14.6627C10 14.4182 10 14.2959 9.97237 14.1808C9.94787 14.0787 9.90747 13.9812 9.85264 13.8917C9.7908 13.7908 9.70432 13.7043 9.53137 13.5314L3.46863 7.46863C3.29568 7.29568 3.2092 7.2092 3.14736 7.10828C3.09253 7.01881 3.05213 6.92127 3.02763 6.81923C3 6.70414 3 6.58185 3 6.33726V4.6C3 4.03995 3 3.75992 3.10899 3.54601C3.20487 3.35785 3.35785 3.20487 3.54601 3.10899C3.75992 3 4.03995 3 4.6 3H19.4C19.9601 3 20.2401 3 20.454 3.10899C20.6422 3.20487 20.7951 3.35785 20.891 3.54601C21 3.75992 21 4.03995 21 4.6V6.33726C21 6.58185 21 6.70414 20.9724 6.81923C20.9479 6.92127 20.9075 7.01881 20.8526 7.10828C20.7908 7.2092 20.7043 7.29568 20.5314 7.46863L17 11"
      stroke="#000000"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const RolesValues = [
  { label: 'Admin', value: 'Admin' },
  { label: 'Sales', value: 'Sales' },
  { label: 'Customer', value: 'Customer' },
];

const Users = [
  {
    name: 'First',
    mail: 'first@mail.com',
    id: '1',
    status: 'active',
    role: 'admin',
  },
  {
    name: 'Second',
    mail: 'second@mail.com',
    id: '2',
    status: 'inactive',
    role: 'sales',
  },
  {
    name: 'Third',
    mail: 'third@mail.com',
    id: '3',
    status: 'active',
    role: 'costumer',
  },
  {
    name: 'Fourth',
    mail: 'fourth@mail.com',
    id: '4',
    status: 'active',
    role: 'costumer',
  },
];

export function AdminPage() {
  const [id, setId] = useState('');
  const [userName, setUserName] = useState('');
  const [mail, setMail] = useState('');
  const [roles, setRoles] = useState('');
  const [role, setRole] = useState<any>('');
  const [data, setData] = useState('');
  const [actualPage, setActualPage] = useState(1);
  const [pages, setPages] = useState();
  const [selectedRole, setSelectedRole] = useState('');
  const [changedUser, setChangedUser] = useState<any[]>([]);
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [error3, setError3] = useState(false);

  useEffect(() => {
    const role = localStorage.getItem('role');
    setRole(role);
  }, []);

  const { t } = useTranslation();

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setError(false);
    setError3(false);
    getAdminUsers(selectedRole, '', '', '', value)
      .then(response => {
        if (response.status === 200) {
          setData(response.data);
          setActualPage(response.data.meta.current_page);
          setPages(response.data.meta.last_page);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const searchId = (
    role: string,
    id: string,
    userName: string,
    mail: string,
  ) => {
    setError(false);
    setError3(false);
    if (
      (userName.length < 3 && userName !== '') ||
      (mail.length < 3 && mail !== '')
    ) {
      setError3(true);
    } else {
      setIsLoading(true);
      getAdminUsers(role, id, userName, mail)
        .then(response => {
          if (response.status === 200) {
            setData(response.data);
            setActualPage(response.data.meta.current_page);
            setPages(response.data.meta.last_page);
          }
        })
        .catch(error => {
          console.log(error.response?.data);
        })
        .finally(() => setIsLoading(false));
    }
  };

  const handleRoleChange = (selected: any) => {
    setSelectedRole(selected.value);
  };

  const handleSubmit = () => {
    setError(false);
    setError3(false);
    const payload = { user: changedUser };
    updateUser(payload)
      .then(response => {
        if (response.status === 200) {
          setSuccess(true);
        }
      })
      .catch(error => {
        setError(true);
      });
  };

  const clean = () => {
    setError(false);
    setError3(false);
    setId('');
    setSelectedRole('');
    setMail('');
    setUserName('');
    searchId('', '', '', '');
  };

  useEffect(() => {
    getAdminUsers()
      .then(response => {
        if (response.status === 200) {
          setData(response.data);
          setActualPage(response.data.meta.current_page);
          setPages(response.data.meta.last_page);
        }
      })
      .catch(error => {
        console.log(error.response?.data);
      });
    getRoleList()
      .then(response => {
        if (response.status === 200) {
          setRoles(
            response.data.map(item => ({
              value: item,
              label: item,
            })),
          );
        }
      })
      .catch(error => {
        console.log(error.response?.data);
      });
  }, []);

  const changeInfo = user => {};

  let hash = {};

  return (
    <>
      <Helmet>
        <title>{t(translations.general.admin)}</title>
        <meta name="description" content="Roasterhub delivery review" />
      </Helmet>

      <div>
        <div
          className="d-flex"
          style={{
            padding: '0 96px',

            flexDirection: 'column',
          }}
        >
          <>
            <div className="d-flex mt-5  align-items-center">
              {role !== '' && !role.includes('Sales') && (
                <Search>
                  <div
                    style={{
                      fontSize: '13px',
                      fontWeight: '625',
                      margin: '0 20px',
                    }}
                  >
                    {t(translations.general.roles)}
                  </div>
                  <Dropdown
                    value={selectedRole}
                    handleChange={handleRoleChange}
                    placeholder={t(translations.general.roles)}
                    list={roles}
                  />
                </Search>
              )}
              <Search>
                {/* <div
                  style={{
                    fontSize: '13px',
                    fontWeight: '625',
                    margin: '0 20px',
                  }}
                >
                  {t(translations.general.acc_id)}
                </div> */}
                <div className="d-flex align-items-center">
                  <Input
                    onChange={e => setId(e.target.value)}
                    type="text"
                    value={id}
                    placeholder={t(translations.general.acc_id)}
                  />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      marginTop: '25px',
                    }}
                  >
                    <Input
                      onChange={e => setUserName(e.target.value)}
                      type="text"
                      value={userName}
                      placeholder={t(
                        translations.pages.profile.username,
                      ).replace(/:$/, '')}
                    />
                    <h6>{t(translations.general.min_three)}</h6>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      marginTop: '25px',
                    }}
                  >
                    <Input
                      onChange={e => setMail(e.target.value)}
                      type="text"
                      value={mail}
                      placeholder={t(translations.pages.profile.email).replace(
                        /:$/,
                        '',
                      )}
                    />
                    <h6>{t(translations.general.min_three)}</h6>
                  </div>
                  <div style={{ marginLeft: 'auto', marginTop: '20px' }}>
                    {isLoading ? (
                      <CircularProgress color="primary" />
                    ) : (
                      <IconButton
                        onClick={() =>
                          searchId(selectedRole, id, userName, mail)
                        }
                        aria-label="search"
                      >
                        <SearchIcon />
                      </IconButton>
                    )}
                  </div>
                  <div style={{ marginLeft: 'auto', marginTop: '20px' }}>
                    <MaterialTooltip title={t(translations.tooltips.clean)}>
                      <IconButton onClick={clean}>
                        <CleanFilter />
                      </IconButton>
                    </MaterialTooltip>
                  </div>
                </div>
              </Search>
            </div>
            {/* <Clean onClick={clean}>
              <div>{t(translations.table.clean_filter)}</div>
            </Clean> */}
          </>
          <div style={{ marginTop: '60px' }}>
            {success && (
              <h3
                style={{
                  color: 'green',
                  marginTop: '20px',
                  marginBottom: '20px',
                }}
              >
                {t(translations.general.changes_saved)}
              </h3>
            )}
            {error && (
              <h3 style={{ color: 'red', marginTop: '20px' }}>
                {t(translations.general.error_something)}
              </h3>
            )}
            {error3 && (
              <h3 style={{ color: 'red', marginTop: '20px' }}>
                {t(translations.general.error_three)}
              </h3>
            )}
            <Stack
              spacing={2}
              style={{
                margin: '20px 0',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {isLoading ? (
                <div
                  style={{
                    // transform: 'scale(0.2)',
                    position: 'absolute',
                    // top: 0,
                    minWidth: 700,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <LoadingPage />
                </div>
              ) : (
                <>
                  <AdminTable
                    rolesValues={roles}
                    handleSubmit={handleSubmit}
                    data={data}
                    changeInfo={changeInfo}
                    setChangedUser={setChangedUser}
                    changedUser={changedUser}
                    role={role}
                  />
                  <Pagination
                    count={pages}
                    page={actualPage}
                    onChange={handleChange}
                  />
                </>
              )}
            </Stack>
            <div style={{ float: 'right' }}>
              <Button
                type="button"
                onClick={handleSubmit}
                disabled={changedUser.length === 0 ? true : false}
                style={
                  changedUser.length === 0
                    ? { background: 'grey', marginTop: '13px' }
                    : { marginTop: '13px' }
                }
                value={'Save'}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
