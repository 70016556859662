import styled from 'styled-components/macro';
import TextareaAutosize from '@mui/base/TextareaAutosize';

const TextArea = styled(TextareaAutosize)<any>`
  border-radius: 20px;
  background: ${props =>
    props.background ? props.background : props.theme.LightGray};
  color: ${props => (props.color ? props.color : props.theme.DarkGray)};
  border: none;
  outline: none;

  font-style: normal;
  font-weight: 325;
  font-size: 20px;
  line-height: 24px;
  margin: 1%;
  padding: 0.5em 1em;
  width: 100%;
`;
export default TextArea;
