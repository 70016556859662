import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import styledComponent from 'styled-components/macro';
import { ThemeProvider } from '@mui/material';
import { useTheme } from 'styled-components';
import styled from 'styled-components/macro';
import { useEffect, useState } from 'react';
import { InputUnstyled } from '@mui/base';
import drop from '../../assets/SVG/dropdown.svg';
import ModalComponent from '../ModalComponent';

const ContainerBody = styledComponent.div` 
display: flex;
width: 120%;
//justify-content: space-between;
//background: #f8f8f8;
`;

const ContainerHeader = styledComponent.div` 
display: flex;
width: 120%;
//background: #ebeaea;
//justify-content: space-between;
margin-bottom: 5px;
`;

const StyledHeadCell = styled(TableCell)`
  font-style: normal;
  font-weight: 325;
  font-size: 13px;
  line-height: 16px;
  border: 1px solid white;
  background: #ebeaea;
  background: ${props => props.color};
  width: calc(20% - 1px);
  box-sizing: border-box;
`;

const StyledTableCell = styled(TableCell)`
  align-items: center;

  font-style: normal;
  font-weight: 325;
  font-size: 13px;
  line-height: 16px;
  background: #f8f8f8;
  border: 1px solid white;
  width: calc(20% - 1px);
  box-sizing: border-box;
`;

const Add = styledComponent.div`
  background: #D98458; 
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  height: 33px;
  cursor: pointer;

`;

const CustomTextField = styled(InputUnstyled)({
  '& input': {
    background: '#f9f9f9',
    borderRadius: '40px',
    borderColor: 'black',
    border: 'solid 1px black',
    width: '52px',
    height: '35px',
    padding: '0 10px',
  },
});

const Input = styled.input`
  width: 52px;
  height: 35px;
  left: 519.78px;
  top: 391.99px;
  background: #f9f9f9;
  border-radius: 40px;
  border-color: black;
  padding: 0 20px;
`;

interface QuantityProps {
  data: any;
  fullData: any;
  setTotal: (value: any) => void;
  setTotalShow: (value: any) => void;
  setBag: (value: any) => void;
  setBoxes: (value: any) => void;
  total: any;
  storedData: any;
  setStoredData: (value: any) => void;
  deletedContracts: any;
  setDeletedContracts: (value: any) => void;
  dataToBeSent: any;
  setDataToBeSent: (value: any) => void;
}

export function QuantityTableMobile({
  data,
  fullData,
  setTotal,
  total,
  setTotalShow,
  setBoxes,
  setBag,
  storedData,
  setStoredData,
  deletedContracts,
  setDeletedContracts,
  dataToBeSent,
  setDataToBeSent,
}: QuantityProps) {
  const [info, setInfo] = useState<any[]>([]);
  const [modalOpen, setModalOpen] = useState(false);

  const theme: any = useTheme();

  const { t } = useTranslation();

  useEffect(() => {
    let total = dataToBeSent
      .map(value => parseInt(value.quantity))
      .reduce((a, b) => a + b, 0);
    setTotalShow(total || 0);
    let bags = dataToBeSent
      .map(value => (value.type === 'Bags' ? parseInt(value.quantity) : 0))
      .reduce((a, b) => a + b, 0);
    setBag(bags || 0);
    let boxes = dataToBeSent
      .map(value => (value.type === 'Boxes' ? parseInt(value.quantity) : 0))
      .reduce((a, b) => a + b, 0);
    setBoxes(boxes || 0);
  }, []);

  const removeContract = contract => {
    let deletedContractsStored = JSON.parse(
      localStorage.getItem('deletedContracts') || '[]',
    );
    deletedContractsStored.push(contract);
    localStorage.setItem(
      'deletedContracts',
      JSON.stringify(deletedContractsStored),
    );
    setDeletedContracts(deletedContractsStored);
    let arr = dataToBeSent.filter(value => value.contrato !== contract);

    setDataToBeSent(arr);
    let total = arr
      .map(value => parseInt(value.quantity))
      .reduce((a, b) => a + b, 0);

    setTotalShow(total || 0);
    let bags = arr
      .map(value => (value.type === 'Bags' ? parseInt(value.quantity) : 0))
      .reduce((a, b) => a + b, 0);

    setBag(bags || 0);
    let boxes = arr
      .map(value => (value.type === 'Boxes' ? parseInt(value.quantity) : 0))
      .reduce((a, b) => a + b, 0);

    setBoxes(boxes || 0);
    localStorage.setItem('dataToBeSent', JSON.stringify(arr));
  };

  // const handleNumericInputChange = (
  //   contract,
  //   value,
  //   units,
  //   available_quantity,
  // ) => {
  //   const number = parseInt(value);
  //   if (!isNaN(number)) {
  //     setQuantity(contract, value, units, available_quantity);
  //   } else {
  //     setQuantity(contract, '', units, available_quantity); // Dejar el campo vacío
  //   }
  // };

  const openModalWithInfo = info => {
    setModalOpen(true);
    setInfo(info);
  };

  const handleNumericInputChange = (
    contract,
    value,
    units,
    available_quantity,
  ) => {
    const number = parseInt(value);
    if (!isNaN(number) && number !== 0) {
      setQuantity(contract, value, units, available_quantity);
    } else {
      setQuantity(contract, '', units, available_quantity);
    }
  };

  const setQuantity = (contract, value, type, available_quantity) => {
    if (
      !dataToBeSent.find(item => item.contrato === contract) &&
      value !== ''
    ) {
      dataToBeSent.push({
        contrato: contract,
        quantity: value,
        ref_number: '',
        type: type,
        available_quantity: available_quantity,
      });
      let total = dataToBeSent
        .map(value => parseInt(value.quantity))
        .reduce((a, b) => a + b, 0);

      setTotalShow(total || 0);
      let bags = dataToBeSent
        .map(value => (value.type === 'Bags' ? parseInt(value.quantity) : 0))
        .reduce((a, b) => a + b, 0);

      setBag(bags || 0);
      let boxes = dataToBeSent
        .map(value => (value.type === 'Boxes' ? parseInt(value.quantity) : 0))
        .reduce((a, b) => a + b, 0);

      setBoxes(boxes || 0);
      setDataToBeSent(dataToBeSent);
    }
    if (dataToBeSent.find(item => item.contrato === contract)) {
      const copy = [...dataToBeSent];
      let index = dataToBeSent.findIndex(item => item.contrato === contract);
      dataToBeSent[index].quantity = value;
      dataToBeSent[index].available_quantity = available_quantity;
      if (
        dataToBeSent[index].ref_number === '' &&
        dataToBeSent[index].quantity === ''
      ) {
        dataToBeSent.splice(index, 1);
        copy.splice(index, 1);
      }
      let total = dataToBeSent
        .map(value => parseInt(value.quantity))
        .reduce((a, b) => a + b, 0);

      setTotalShow(total || 0);
      let bags = dataToBeSent
        .map(value => (value.type === 'Bags' ? parseInt(value.quantity) : 0))
        .reduce((a, b) => a + b, 0);

      setBag(bags || 0);
      let boxes = dataToBeSent
        .map(value => (value.type === 'Boxes' ? parseInt(value.quantity) : 0))
        .reduce((a, b) => a + b, 0);

      setBoxes(boxes || 0);
      setDataToBeSent(dataToBeSent);
    }
    localStorage.setItem('total', JSON.stringify(total));
    localStorage.setItem('dataToBeSent', JSON.stringify(dataToBeSent));
  };

  const setRefNumber = (contract, value, type) => {
    if (
      !dataToBeSent.find(item => item.contrato === contract) &&
      value !== ''
    ) {
      dataToBeSent.push({
        contrato: contract,
        quantity: '',
        ref_number: value,
        type: type,
      });
      setDataToBeSent(dataToBeSent);
    }
    if (dataToBeSent.find(item => item.contrato === contract)) {
      let index = dataToBeSent.findIndex(item => item.contrato === contract);

      dataToBeSent[index].ref_number = value;
      if (
        dataToBeSent[index].ref_number === '' &&
        dataToBeSent[index].quantity === ''
      ) {
        dataToBeSent.splice(index, 1);
      }
    }
    localStorage.setItem('dataToBeSent', JSON.stringify(dataToBeSent));
  };

  useEffect(() => {
    setStoredData(fullData);
    let array = [];
    let dContract = JSON.parse(
      localStorage.getItem('deletedContracts') || '[]',
    );

    dContract !== []
      ? setDeletedContracts(dContract)
      : localStorage.setItem('deletedContracts', JSON.stringify(array));
    let dSent = JSON.parse(localStorage.getItem('dataToBeSent') || '[]');

    dSent !== []
      ? setDataToBeSent(dSent)
      : localStorage.setItem('dataToBeSent', JSON.stringify(array));
  }, []);

  return (
    <ThemeProvider theme={theme}>
      {data
        ? data.map(item =>
            item.map(value => (
              <>
                <div className="d-flex align-items-center mt-5 mb-4">
                  <h1 style={{ color: theme.palette.primary.main }}>
                    {value[0].origin}
                  </h1>
                </div>

                <ContainerHeader>
                  <StyledHeadCell
                    color={theme.palette.secondary.main}
                  ></StyledHeadCell>
                  <StyledHeadCell
                    style={{
                      fontSize: '13px',
                    }}
                    color={theme.palette.secondary.main}
                  >
                    {t(translations.table.contract)}
                  </StyledHeadCell>
                  <StyledHeadCell
                    align="center"
                    color={theme.palette.secondary.main}
                    style={{ fontSize: '13px' }}
                  >
                    {t(translations.table.quantity_o)} *
                  </StyledHeadCell>
                  <StyledHeadCell
                    align="center"
                    color={theme.palette.secondary.main}
                    style={{ fontSize: '13px' }}
                  >
                    {t(translations.table.reference)}
                  </StyledHeadCell>
                  {/* <StyledHeadCell
                    color={theme.palette.primary.main}
                    style={{
                      color: theme.palette.primary.contrastText,
                      fontSize: '10px',
                    }}
                    align="center"
                  >
                    {t(translations.table.p_progress)}
                  </StyledHeadCell> */}
                </ContainerHeader>

                {data.map(item =>
                  item.map(tableData =>
                    tableData.map(
                      info =>
                        info.origin === value[0].origin &&
                        !deletedContracts
                          .toString()
                          .includes(info.contract) && (
                          <ContainerBody key={info.contract}>
                            <StyledTableCell align="center">
                              {' '}
                              <img
                                src={drop}
                                alt="drop"
                                style={{ width: '22px', height: '22px' }}
                                onClick={() => openModalWithInfo(info)}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {info.contract}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {/* <FileDownloadIcon style={{ color: '#1989B2' }} /> */}
                              <CustomTextField
                                value={dataToBeSent
                                  .map(value =>
                                    value.contrato === info.contract
                                      ? value.quantity
                                      : '',
                                  )
                                  .find(item => item !== '')}
                                type="text"
                                style={{ appearance: 'textfield' }}
                                onChange={e => {
                                  const inputValue = e.target.value;
                                  const numericValue = inputValue.replace(
                                    /[^0-9]/g,
                                    '',
                                  );
                                  handleNumericInputChange(
                                    info.contract,
                                    numericValue,
                                    info.units,
                                    info.available_quantity,
                                  );
                                }}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Input
                                type="text"
                                inputMode="numeric"
                                pattern="[0-9]*"
                                min="1"
                                max={info.available_quantity}
                                onBlur={e =>
                                  setRefNumber(
                                    info.contract,
                                    e.target.value,
                                    info.type,
                                  )
                                }
                                defaultValue={dataToBeSent
                                  .map(value =>
                                    value.contrato === info.contract
                                      ? value.ref_number
                                      : '',
                                  )
                                  .find(item => item !== '')}
                              />
                            </StyledTableCell>
                            {/* <StyledTableCell align="center">
                              <Add
                                onClick={() => removeContract(info.contract)}
                              >
                                -
                              </Add>
                            </StyledTableCell> */}
                          </ContainerBody>
                        ),
                    ),
                  ),
                )}
              </>
            )),
          )
        : null}
      {modalOpen && info && (
        <ModalComponent
          closeModal={() => setModalOpen(false)} // Función para cerrar el modal
          data={info} // Información de groupBy para mostrar en el modal
          page="request-coffee"
        />
      )}
    </ThemeProvider>
  );
}
