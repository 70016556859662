/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { Routes, Route, Navigate } from 'react-router-dom';

import './index.css';

import { GlobalStyle } from 'styles/global-styles';
import PrivateRoute from 'utils/private-route/privateRoute';

import { HomeLayout } from './pages/HomeLayout';
import { LoginPage } from './pages/LoginPage';
import { ResetPassPage } from './pages/ResetPassPage';
import { AdminPage } from './pages/AdminPage';
import { RegisterPage } from './pages/RegisterPage';

import { ProfilePage } from './pages/ProfilePage';
import { ReportsPage } from './pages/ReportsPage';

import { NotFoundPage } from './pages/NotFoundPage';
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from 'styled-components';
import { styledTheme, muiTheme } from '../styles/theme';
import { PurchaseOrderPage } from './pages/PurchaseOrderPage';
import { ReviewDeliveryPage } from './pages/ReviewDeliveryPage';
import { SalesOfferingsPage } from './pages/SalesOfferingsPage';
import { ReportsEdit } from './pages/ReportsEdit';
import { AnalyticsPage } from './pages/AnalyticsPage';
import { HistoryPage } from './pages/HistoryPage';
import { SampleRequestPage } from './pages/SampleRequestPage';
import { SpotOfferingPage } from './pages/SpotOfferingPage';
import { getImpersonateData, LogoutAction } from 'services/authServices';
import { SupportCenterPage } from './pages/SupportCenterPage';
import ActivityDetector from 'react-activity-detector';
import { useEffect, useState } from 'react';
import { AdminProfilePage } from './pages/AdminProfilePage';
import { AdminRolePage } from './pages/AdminRolePage';
import { UnauthorizedPage } from './pages/UnauthorizedPage';
import AuthorizedRoute from 'utils/authorized-routes/authorizedRoute';
import { LocationPage } from './pages/LocationPage';
// import { ChartPage } from './pages/ChartPage';
import { CuppingPage } from './pages/CuppingPage';
import FeedbackMobilePage from './components/NavBar/FeedbackMobilePage';

function App() {
  const { i18n } = useTranslation();

  const customActivityEvents = ['click', 'keydown'];
  const [pendingNotifications, setPendingNotifications] = useState(false);
  const [purchaseNotification, setPurchaseNotification] = useState([]);
  const [isImpersonated, setIsImpersonated] = React.useState(false);
  const [acc, setAcc] = useState<any>('');
  const [roles, setRoles] = useState('');
  const [role, setRole] = useState('');
  const [permissions, setPermissions] = useState('');

  useEffect(() => {
    const token = localStorage.getItem('caravela_access_token') || '';
    token !== '' &&
      getImpersonateData()
        .then(response => {
          if (response.status === 200) {
            setIsImpersonated(response.data.Impersonate === 0 ? false : true);
            setAcc(
              response.data.accountants.map(item => {
                if (item.Customer === null && item.NameCustomer === null) {
                  return {
                    value: null,
                    label: '',
                  };
                }
                return {
                  value: item.Customer,
                  label: item.NameCustomer
                    ? item.NameCustomer.trim().replace(/\s+$/, '')
                    : '',
                };
              }),
            );

            setRoles(
              response.data.roles.map(item => ({
                value: item.name,
                label: item.name,
              })),
            );
            setRole(response.data.user_role);
            setPermissions(response.data.permissions);
          }
        })
        .catch(error => {
          console.log(error);
        });
  }, []);

  const onIdle = () => {
    LogoutAction();
  };

  return (
    <ThemeProvider theme={muiTheme}>
      <ThemeProvider theme={styledTheme}>
        <Helmet
          titleTemplate="%s - Roaster Hub"
          defaultTitle="React Boilerplate"
          htmlAttributes={{ lang: i18n.language }}
        >
          <meta name="description" content="Coffee ordering app" />
        </Helmet>
        <ActivityDetector
          activityEvents={customActivityEvents}
          enabled={true}
          timeout={30 * 1000 * 60}
          onIdle={onIdle}
        />
        <Routes>
          <Route
            path="/" // Client route
            element={
              <PrivateRoute>
                <HomeLayout
                  pendingNotifications={pendingNotifications}
                  setPendingNotifications={setPendingNotifications}
                  purchaseNotification={purchaseNotification}
                  isImpersonated={isImpersonated}
                  roles={roles}
                  acc={acc}
                />
              </PrivateRoute>
            }
          >
            <Route
              path="profile"
              element={
                <PrivateRoute>
                  <ProfilePage />
                </PrivateRoute>
              }
            />
            <Route
              path="purchase-order"
              element={
                <AuthorizedRoute module={'Purchase Order;View'}>
                  <PrivateRoute>
                    <PurchaseOrderPage
                      pendingNotifications={pendingNotifications}
                      setPendingNotifications={setPendingNotifications}
                      setPurchaseNotification={setPurchaseNotification}
                      purchaseNotification={purchaseNotification}
                    />
                  </PrivateRoute>
                </AuthorizedRoute>
              }
            />
            <Route
              path="review-delivery"
              element={
                <AuthorizedRoute module={'Review Delivery Order;View'}>
                  <PrivateRoute>
                    <ReviewDeliveryPage />
                  </PrivateRoute>
                </AuthorizedRoute>
              }
            />
            <Route
              path="sample-request"
              element={
                <AuthorizedRoute module={'Sample Request;View'}>
                  <PrivateRoute>
                    <SampleRequestPage />
                  </PrivateRoute>
                </AuthorizedRoute>
              }
            />
            <Route
              path="offerings"
              element={
                <AuthorizedRoute module={'Offerings;View'}>
                  <PrivateRoute>
                    <SpotOfferingPage />
                  </PrivateRoute>
                </AuthorizedRoute>
              }
            />
            <Route
              path="/edit-reports"
              element={
                <AuthorizedRoute module={'Edit Reports;View'}>
                  <PrivateRoute>
                    <ReportsEdit />
                  </PrivateRoute>
                </AuthorizedRoute>
              }
            />
            <Route
              path="analytics"
              element={
                //<AuthorizedRoute module={''}>
                <PrivateRoute>
                  <AnalyticsPage />
                </PrivateRoute>
                // </AuthorizedRoute>
              }
            />
            <Route
              path="reports"
              element={
                <AuthorizedRoute module={'Reports;View'}>
                  <PrivateRoute>
                    <ReportsPage />
                  </PrivateRoute>
                </AuthorizedRoute>
              }
            />
            <Route
              path="history"
              element={
                <AuthorizedRoute module={'History;View'}>
                  <PrivateRoute>
                    <HistoryPage />
                  </PrivateRoute>
                </AuthorizedRoute>
              }
            />
            <Route
              path="where-is-coffee"
              element={
                <PrivateRoute>
                  <LocationPage />
                </PrivateRoute>
              }
            />
            {/* <Route
              path="chart"
              element={
                <PrivateRoute>
                  <ChartPage />
                </PrivateRoute>
              }
            /> */}
            {/* <Route
              path="cupping"
              element={
                <PrivateRoute>
                  <CuppingPage />
                </PrivateRoute>
              }
            /> */}
            <Route
              path="/support-center"
              element={
                <PrivateRoute>
                  <SupportCenterPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/admin-profile/:id"
              element={
                <AuthorizedRoute module={'Admin;View'}>
                  <PrivateRoute>
                    <AdminProfilePage />
                  </PrivateRoute>
                </AuthorizedRoute>
              }
            />
            <Route
              path="/admin-role"
              element={
                <AuthorizedRoute module={'Admin Role;View'}>
                  <PrivateRoute>
                    <AdminRolePage role={role} />
                  </PrivateRoute>
                </AuthorizedRoute>
              }
            />

            <Route
              path="/feedback"
              element={
                <PrivateRoute>
                  <FeedbackMobilePage />
                </PrivateRoute>
              }
            />

            <Route
              path="/admin"
              element={
                <AuthorizedRoute module={'Admin;View'}>
                  <PrivateRoute>
                    <AdminPage />
                  </PrivateRoute>
                </AuthorizedRoute>
              }
            />
          </Route>
          <Route
            path="/sales"
            element={
              <AuthorizedRoute module={'Offerings;View'}>
                <PrivateRoute>
                  <HomeLayout //TODO SALESLAYOUT
                    pendingNotifications={pendingNotifications}
                    setPendingNotifications={setPendingNotifications}
                    purchaseNotification={purchaseNotification}
                    isImpersonated={isImpersonated}
                    roles={roles}
                    acc={acc}
                  />
                </PrivateRoute>
              </AuthorizedRoute>
            }
          >
            <Route
              path="/sales/offerings"
              element={
                <AuthorizedRoute module={'Offerings;View'}>
                  <PrivateRoute>
                    <SalesOfferingsPage />
                  </PrivateRoute>
                </AuthorizedRoute>
              }
            />
          </Route>
          <Route path="/reset-pass" element={<ResetPassPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/sales/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />

          <Route path="*" element={<NotFoundPage />} />
          <Route path="403" element={<UnauthorizedPage />} />
          <Route path="401" element={<LoginPage />} />
          <Route path="/COL-P-:id*" element={<Navigate to="/" />} />
          <Route path="/ECU-P-:id*" element={<Navigate to="/" />} />
          <Route path="/GTM-P-:id*" element={<Navigate to="/" />} />
          <Route path="/MEX-P-:id*" element={<Navigate to="/" />} />
          <Route path="/NIC-P-:id*" element={<Navigate to="/" />} />
          <Route path="/PER-P-:id*" element={<Navigate to="/" />} />
          <Route path="/SLV-P-:id*" element={<Navigate to="/" />} />
        </Routes>
        <GlobalStyle />
      </ThemeProvider>
    </ThemeProvider>
  );
}
export default App;
