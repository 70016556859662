import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { useEffect, useState, useRef } from 'react';
import { FirstStep } from './steps/firstStep';
import { SecondStep } from './steps/secondStep';
import { ThirdStep } from './steps/thirdStep';
import { FinalStep } from './steps/finalStep';
import styled, { useTheme } from 'styled-components';
import { usageMetrics } from 'services/generalServices';
import {
  DeletePurchaseOrder,
  getPurchaseValidate,
} from 'services/purchaseService';
import {
  getStatus,
  PostPurchaseOrder,
  PurchaseOrderStep2,
  PurchaseOrderStep3,
  generateNewAddress,
} from 'services/purchaseService';
import { LoadingPage } from '../LoadingPage';
import { DeletePurchaseModal } from 'app/components/DeletePurchaseModal';
import { useMediaQuery } from '@mui/material';
import { Hero } from 'app/components/Hero';

const Button = styled.input<{ backgroundColor?: string }>`
  border: none;
  background: ${props =>
    props.backgroundColor ? props.backgroundColor : '#5c36f3'};
  color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  margin-bottom: 25px;
  width: 213.02px;
  height: 49.59px;
  margin: 0 10px;
`;

interface PurchaseOrderProp {
  pendingNotifications: boolean;
  purchaseNotification: any;
  setPendingNotifications: (value) => void;
  setPurchaseNotification: (value) => void;
}

export function PurchaseOrderPage({
  pendingNotifications,
  setPendingNotifications,
  setPurchaseNotification,
  purchaseNotification,
}: PurchaseOrderProp) {
  const { t } = useTranslation();
  const theme: any = useTheme();
  const [step, setStep] = useState(1);
  const [idStored, setId] = useState('0');
  const [errors, setErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState<Boolean>(true);
  const [userData, setUserData] = useState<any>();
  const [storedData, setStoredData] = useState<any[]>([]);
  const [deletedContracts, setDeletedContracts] = useState<any[]>([]);
  const [dataToBeSent, setDataToBeSent] = useState<any[]>([]);
  const [deletedModal, setDeletedModal] = useState<Boolean>(false);
  const [loadingData, setLoadingData] = useState(false);
  const [needDetail, setNeedDetail] = useState(false);

  const [newAddress, setNewAddress] = useState(false);

  const [infoUK, setInfoUK] = useState<any[]>([
    { name: 'UK-1', value: false },
    { name: 'UK-2', value: false },
    { name: 'UK-3', value: false, choices: '', lift: '' },
    { name: 'UK-4', value: false, details: '' },
    { name: 'Aditional', value: '' },
    { name: 'Date', value: '' },
    { name: 'Address', value: '' },
  ]);

  const [infoOther, setInfoOther] = useState<any[]>([
    { name: 'OTHER-1', value: false },
    { name: 'OTHER-2', value: false },
    { name: 'IMPORTERS', value: '' },
    { name: 'BAGS', value: '' },
    { name: 'OTHER-3', value: false },
    { name: 'OTHER-3BIS', value: false },
    { name: 'OTHER-3C-Pickup', value: false },
    { name: 'OTHER-3D-MyPosition', value: false },
    { name: 'MAIL', value: '' },
    { name: 'PHONE', value: '' },
    { name: 'OTHER-4', value: false },
    { name: 'OTHER-5', value: false },
    { name: 'OTHER-6', value: false },
    { name: 'CARRIERS', value: '' },
    { name: 'Aditional', value: '' },
    { name: 'Date', value: '' },
    { name: 'FREIGHT', value: '' },
  ]);

  const hasMounted = useRef(false);
  const deleteDraftPrchase = () => {
    DeletePurchaseOrder('0')
      .then(response => {
        if (response.status === 204) {
          localStorage.removeItem('dataToBeSent');
          localStorage.removeItem('deletedContracts');
          localStorage.removeItem('contracts');
          localStorage.removeItem('order-id');
        }
      })
      .catch(error => {
        console.log(error);
      });
    setStep(1);
  };

  useEffect(() => {
    if (!hasMounted.current) {
      hasMounted.current = true;
      setTimeout(() => setLoading(false), 7000);

      if (step === 2) {
        let array = [];
        let dataUK = JSON.parse(localStorage.getItem('infoUK') || '[]');
        if (!Array.isArray(dataUK) || dataUK.length > 0) {
          setInfoUK(dataUK);
        } else {
          localStorage.setItem('dataToBeSent', JSON.stringify(array));
        }
        let dataOther = JSON.parse(localStorage.getItem('infoOther') || '[]');
        if (!Array.isArray(dataOther) || dataOther.length > 0) {
          setInfoOther(dataOther);
        } else {
          localStorage.setItem('dataToBeSent', JSON.stringify(array));
        }
      }

      getStep();

      const role = localStorage.getItem('role');
      if (role === 'Customer') {
        const payload = {
          module: 'Release Coffee',
          role: 'Customer',
        };

        usageMetrics(payload)
          .then(response => {
            if (response.status === 200) {
              console.log('Metrica enviada');
            }
          })
          .catch(error => {
            console.log(error.response?.data, 'error.response.data');
          });
      }

      if (localStorage.getItem('dataToBeSent')) {
        if (step === 1) {
          localStorage.removeItem('dataToBeSent');
          localStorage.removeItem('deletedContracts');
          localStorage.removeItem('contracts');
          localStorage.removeItem('order-id');
          setStep(1);
        }
        if (step === 2) {
          getPurchaseValidate()
            .then(response => {
              if (response.status === 200) {
                if (localStorage.getItem('dataToBeSent')) {
                  const dataToBeSent = JSON.parse(
                    localStorage.getItem('dataToBeSent') || '[]',
                  );
                  const hasMatchingIco = response.data.items.some(item =>
                    dataToBeSent.some(dataItem => dataItem.ico_f === item.ico),
                  );
                  if (hasMatchingIco) {
                    console.log('');
                  } else {
                    localStorage.removeItem('dataToBeSent');
                    localStorage.removeItem('deletedContracts');
                    localStorage.removeItem('contracts');
                    localStorage.removeItem('order-id');
                    deleteDraftPrchase();
                    setStep(1);
                  }
                } else {
                  deleteDraftPrchase();
                }
              }
            })
            .catch(error => {
              console.log(error);
            });
        }
      } else {
        localStorage.removeItem('dataToBeSent');
        localStorage.removeItem('deletedContracts');
        localStorage.removeItem('contracts');
        localStorage.removeItem('order-id');
        setStep(1);
      }
    }
  }, []);

  const validateStep3 = (): string[] => {
    setErrors([]);

    const errorsArray: string[] = [];

    if (userData[0].importer_country !== 'United Kingdom') {
      const other1Checked = infoOther.find(
        item => item.name === 'OTHER-1',
      )?.value;
      const other2Checked = infoOther.find(
        item => item.name === 'OTHER-2',
      )?.value;
      const other3Checked = infoOther.find(
        item => item.name === 'OTHER-3',
      )?.value;
      const other3bChecked = infoOther.find(
        item => item.name === 'OTHER-3BIS',
      )?.value;
      const other3cChecked = infoOther.find(
        item => item.name === 'OTHER-3C-Pickup',
      )?.value;
      const other4dChecked = infoOther.find(
        item => item.name === 'OTHER-3D-MyPosition',
      )?.value;

      if (
        !other1Checked &&
        !other2Checked &&
        !other3cChecked &&
        !other4dChecked
      ) {
        errorsArray.push('checkboxes_unchecked');
      }
      if (!other3Checked && !other3bChecked) {
        if (!other1Checked && other2Checked) {
          errorsArray.push('checkboxes_unchecked2');
        }
      }

      if (other2Checked) {
        const importers = infoOther.find(
          item => item.name === 'IMPORTERS',
        )?.value;
        const bags = infoOther.find(item => item.name === 'BAGS')?.value;

        if (!importers || importers === '' || !bags || bags === '') {
          errorsArray.push('importers_bags_required');
        }

        if (other3bChecked) {
          const email = infoOther.find(item => item.name === 'MAIL')?.value;
          const freight = infoOther.find(
            item => item.name === 'FREIGHT',
          )?.value;
          if (!email || email === '' || !freight || freight === '') {
            errorsArray.push('email_freight_required');
          }
        }
      }
    } else {
      const uk1Checked = infoUK.find(item => item.name === 'UK-1')?.value;
      const uk2Checked = infoUK.find(item => item.name === 'UK-2')?.value;
      const uk3Checked = infoUK.find(item => item.name === 'UK-3')?.value;
      if (!uk1Checked && !uk2Checked && !uk3Checked) {
        errorsArray.push('checkboxes_unchecked');
      }

      if (uk3Checked) {
        const deliveryAddress = infoUK.find(
          item => item.name === 'Address',
        )?.value;
        const additionalInfo = infoUK.find(
          item => item.name === 'Aditional',
        )?.value;
        if (!deliveryAddress && !additionalInfo) {
          errorsArray.push('delivery_address_required');
        }
      }
    }

    setErrors(errorsArray);

    return errorsArray;
  };

  const handleStep3 = () => {
    const errorsArray: string[] = validateStep3();

    if (errorsArray.length <= 0) {
      let id =
        idStored !== '0' ? idStored : localStorage.getItem('order-id' || '');
      if (userData[0].importer_country !== 'United Kingdom') {
        let option_ids = infoOther.filter((data, index) => data.value === true);
        let index = option_ids.map(data =>
          data.name.substr(data.name.length - 1),
        );
        let questions = infoOther.map((data, index) =>
          data.name === 'OTHER-2'
            ? {
                question_id: 'OTHER-2',
                aditionalInfo1: infoOther[2].value,
                aditionalInfo2: infoOther[3].value,
                contactEmail: '',
              }
            : data.name === 'OTHER-3'
            ? {
                question_id: infoOther[index].name,
                aditionalInfo1: '',
                aditionalInfo2: infoOther[9].value,
                contactEmail: infoOther[8].value,
              }
            : data.name === 'OTHER-3BIS'
            ? {
                question_id: infoOther[index].name,
                aditionalInfo1: infoOther[9].value,
                aditionalInfo2: infoOther[16].value,
                contactEmail: infoOther[8].value,
              }
            : data.name === 'OTHER-3C-Pickup'
            ? {
                question_id: infoOther[index].name,
                aditionalInfo1: '',
                aditionalInfo2: infoOther[9].value,
                contactEmail: infoOther[8].value,
              }
            : data.name === 'OTHER-3D-MyPosition'
            ? {
                question_id: infoOther[index].name,
                aditionalInfo1: '',
                aditionalInfo2: infoOther[9].value,
                contactEmail: infoOther[8].value,
              }
            : {
                question_id: infoOther[index].name,
                aditionalInfo1: '',
                aditionalInfo2: '',
                contactEmail: '',
              },
        );

        let filteredQuestions = questions.filter(data =>
          index.includes(data.question_id.substr(data.question_id.length - 1)),
        );

        if (infoOther[13].value !== '') {
          filteredQuestions = [
            ...filteredQuestions,
            {
              question_id: 'OTHER-7',
              aditionalInfo1: infoOther[13].value,
              aditionalInfo2: '',
              contactEmail: '',
            },
          ];
        }

        let payload = {
          option_ids: index,
          questions: filteredQuestions,
          date_needs: infoOther[15].value,
          comments: infoOther[14].value,
          id: id,
        };

        PurchaseOrderStep3(payload, id as string)
          .then(response => {
            if (response.status === 200 || response.status === 201) {
              localStorage.removeItem('dataToBeSent');
              localStorage.removeItem('deletedContracts');
              localStorage.removeItem('contracts');
              localStorage.removeItem('order-id');
              pendingNotifications === false
                ? setPendingNotifications(!pendingNotifications)
                : setPendingNotifications(pendingNotifications);
              setPurchaseNotification([...purchaseNotification, idStored]);
              setStep(3);
            }
          })
          .catch(error => {
            setErrors(error.response?.data.errors[0].type);
          });
      } else {
        if (newAddress === true) {
          generateNewAddress({ address: infoUK[6].value });
        }

        let option_ids = infoUK.filter((data, index) => data.value === true);
        let index = option_ids.map(data =>
          data.name.substr(data.name.length - 1),
        );

        let questions = infoUK.map((data, index) =>
          data.name === 'UK-3'
            ? {
                question_id: 'UK-3',
                aditionalInfo1: `${infoUK[2].choices} ${infoUK[2].lift}`,
                aditionalInfo2: infoUK[6].value,
                contactEmail: '',
              }
            : data.name === 'UK-4'
            ? {
                question_id: 'UK-4',
                aditionalInfo1: infoUK[3].details,
                aditionalInfo2: '',
                contactEmail: '',
              }
            : {
                question_id: infoUK[index].name,
                aditionalInfo1: '',
                aditionalInfo2: '',
                contactEmail: '',
              },
        );

        let filteredQuestions = questions.filter(data =>
          index.includes(data.question_id.substr(data.question_id.length - 1)),
        );

        let payload = {
          option_ids: index,
          questions: filteredQuestions,
          date_needs: infoUK[5].value,
          comments: infoUK[4].value,
          id: id,
        };

        if (id !== null) {
          PurchaseOrderStep3(payload, id)
            .then(response => {
              if (response.status === 200 || response.status === 201) {
                localStorage.removeItem('dataToBeSent');
                localStorage.removeItem('deletedContracts');
                localStorage.removeItem('contracts');
                localStorage.removeItem('order-id');
                pendingNotifications === false
                  ? setPendingNotifications(!pendingNotifications)
                  : setPendingNotifications(pendingNotifications);
                setPurchaseNotification([...purchaseNotification, idStored]);
                setStep(3);
              }
            })
            .catch(error => {
              setErrors(error.response?.data.errors[0].type);
            });
        }
      }
    }
  };

  const handleChange = (e, name) => {
    const newArr = infoOther.map(object => {
      if (object.name === name) {
        return { ...object, value: !object.value };
      }
      return object;
    });
    setInfoOther(newArr);
    localStorage.setItem('infoOther', JSON.stringify(infoOther));
  };

  const handleTextDate = (value, id) => {
    const newArr = infoOther.map(object => {
      if (object.name === id) {
        return { ...object, value: value };
      }
      return object;
    });
    setInfoOther(newArr);
    localStorage.setItem('infoOther', JSON.stringify(infoOther));
  };

  const handleChangeUK = e => {
    if (e === 'day' || e === 'asap') {
      const newArr = infoUK.map(object => {
        if (object.name === 'UK-3' && e === 'day') {
          return { ...object, choices: 'In a specified day' };
        } else if (object.name === 'UK-3' && e === 'asap') {
          return { ...object, choices: 'As soon as possible' };
        }
        return object;
      });

      setInfoUK(newArr);
      localStorage.setItem('infoUK', JSON.stringify(infoUK));
    } else if (e === 'lift') {
      const newArr = infoUK.map(object => {
        if (object.name === 'UK-3' && object.lift === '') {
          return { ...object, lift: '* YES' };
        } else if (object.name === 'UK-3' && object.lift === 'lift') {
          return { ...object, lift: '' };
        }
        return object;
      });

      setInfoUK(newArr);
      localStorage.setItem('infoUK', JSON.stringify(infoUK));
    } else {
      const newArr = infoUK.map(object => {
        if (object.name === e) {
          return { ...object, value: !object.value };
        }
        return object;
      });

      setInfoUK(newArr);
      localStorage.setItem('infoUK', JSON.stringify(infoUK));
    }
  };

  const handleTextDateUK = (value, id) => {
    if (id === 'UK-4') {
      const newArr = infoUK.map(object => {
        if (object.name === 'UK-4') {
          return { ...object, details: value };
        }
        return object;
      });

      setInfoUK(newArr);
      infoUK[3].details = value;
      localStorage.setItem('infoUK', JSON.stringify(infoUK));
    } else if (id === 'address') {
      const newArr = infoUK.map(object => {
        if (object.name === 'Address') {
          return { ...object, value: value };
        }
        return object;
      });

      setInfoUK(newArr);
    } else {
      const newArr = infoUK.map(object => {
        if (object.name === id) {
          return { ...object, value: value };
        }
        return object;
      });
      setInfoUK(newArr);
      localStorage.setItem('infoUK', JSON.stringify(infoUK));
    }
  };

  const handleStep1 = () => {
    let contracts = JSON.parse(localStorage.getItem('contracts') || '[]');
    let formatContract = contracts.map(item => ({
      contrato: item,
    }));
    const contractSent = { items: formatContract };
    PostPurchaseOrder(contractSent)
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          // pendingNotifications === false
          //   ? setPendingNotifications(!pendingNotifications)
          //   : setPendingNotifications(pendingNotifications);
          // setPurchaseNotification([...purchaseNotification, idStored]);
          getStep();
        }
      })
      .catch(error => {
        console.log(error.response?.data);
      });
  };

  const validateData = (data, deletedDataQuantity, userData) => {
    const isNotZero = data.every(item => item.quantity > 0);
    const isValidQuantity = data.every(
      item => item.quantity <= item.available_quantity,
    );
    const hasAtLeastOne = data.length >= 1;

    const newErrors: string[] = [];

    if (!hasAtLeastOne) {
      newErrors.push('fill');
    }

    if (!isValidQuantity) {
      newErrors.push('quantity');
    }

    if (!isNotZero) {
      newErrors.push('zero');
    }
    return newErrors;
  };

  const handleStep2 = () => {
    const contractSent = { items: dataToBeSent };
    const newErrors = validateData(
      dataToBeSent,
      deletedContracts?.length || 0,
      userData,
    );

    if (newErrors.length === 0) {
      PurchaseOrderStep2(contractSent)
        .then(response => {
          if (response.status === 200 || response.status === 201) {
            // localStorage.removeItem('dataToBeSent');
            // localStorage.removeItem('deletedContracts');
            // localStorage.removeItem('contracts');
            // localStorage.removeItem('order-id');
            getStep();
          }
        })
        .catch(error => {
          console.log(error.response?.data);
        });
    }
    setErrors(newErrors);
  };

  const handleStep4 = () => {
    getStep(true);
  };

  const handleSubmit = () => {
    step === 0 && handleStep1();
    step === 1 && handleStep2();
    step === 2 && handleStep3();
    step === 3 && handleStep4();
  };

  const getStep = (shouldReload = false) => {
    getStatus()
      .then(response => {
        if (response.status === 200) {
          setUserData(response.data);
          setId(response.data[0].id);
          localStorage.setItem('order-id', response.data[0].id);
          response.data !== undefined &&
            response.data[0].items[0].quantity_order === null &&
            setStep(1);
          response.data !== undefined &&
            response.data[0].items[0].quantity_order !== null &&
            setStep(2);
          if (shouldReload) {
            window.location.reload();
          }
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const cancelPurchase = (value: string) => {};

  const deleteModal = () => {
    setDeletedModal(!deletedModal);
  };

  const goBack = () => {
    setStep(step - 1);
  };

  // const firstError = Object.keys(errors).find(errorKey => errors[errorKey]);
  const firstError = errors.length > 0 ? errors[0] : null;

  const isMobile = useMediaQuery('(max-width:767px)');

  return (
    <>
      {deletedModal && (
        <DeletePurchaseModal
          setDeletedModal={setDeletedModal}
          deletedModal={deletedModal}
          id={idStored}
        />
      )}
      {loading ? (
        <LoadingPage />
      ) : (
        <>
          <Helmet>
            <title>Purchase Order</title>
            <meta name="description" content="My Roasterhub order" />
          </Helmet>

          {!isMobile ? (
            <div
              style={{
                position: 'sticky',
                background: 'white',
                padding: '0px 110px 0px 94px',
                marginLeft: '20px',
                marginTop: '28px',
                marginBottom: '20px',
                // width: '82vw',
                zIndex: '0',
                top: '0',
              }}
            >
              {step !== 3 && (
                <div>
                  <div className="d-flex justify-content-end w-100 mb-3  mt-2">
                    {step !== 2 && (
                      <>
                        <Hero
                          title={t(translations.pages.purchase_order.title)}
                          subtitle={t(
                            translations.pages.purchase_order.subtitle,
                          )}
                        />
                        <h5
                          style={{
                            // color: Object.keys(errors).find(
                            //   errorKey =>
                            //     (errorKey === 'fill' && errors[errorKey]) ||
                            //     (errorKey === 'quantity' && errors[errorKey]) ||
                            //     (errorKey === 'zero' && errors[errorKey]),
                            // )
                            color:
                              firstError && firstError.length > 0
                                ? 'red'
                                : 'black',
                            marginRight: 'auto',
                          }}
                        >
                          {step === 1 &&
                            firstError &&
                            t(translations.pages.purchase_order[firstError])}
                        </h5>
                      </>
                    )}
                    {step === 2 && (
                      <div
                        style={{
                          marginRight: 'auto',
                        }}
                      >
                        <h1
                          style={{
                            //
                            textAlign: 'center',
                            color: theme.palette.error.main,
                          }}
                        >
                          {errors.includes('date_needs') &&
                            t(translations.errors_400.date)}
                          {errors.includes('checkboxes_unchecked') &&
                            t(translations.errors.checkboxes_unchecked)}
                          {errors.includes('checkboxes_unchecked2') &&
                            t(translations.errors.checkboxes_unchecked2)}
                          {errors.includes('importers_bags_required') &&
                            t(translations.errors.importers_bags_required)}
                          {errors.includes('email_freight_required') &&
                            t(translations.errors.email_freight_required)}
                          {errors.includes('delivery_address_required') &&
                            t(translations.errors.delivery_address_required)}
                        </h1>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {/* {(step === 1 || step === 2) && (
                <div
                  style={{
                    textAlign: 'right',
                    color: 'red',
                    marginRight: '20px',
                  }}
                >
                  {t(translations.general.mandatory)}
                </div>
              )} */}
            </div>
          ) : (
            <div
              style={{
                position: 'sticky',
                background: 'white',
                padding: '0px 10px 0px 10px',
                marginBottom: '20px',
              }}
            >
              {step !== 2 && (
                <>
                  <Hero
                    title={t(translations.pages.purchase_order.title)}
                    subtitle={t(translations.pages.purchase_order.subtitle)}
                  />
                  <h5
                    style={{
                      // color: Object.keys(errors).find(
                      //   errorKey =>
                      //     (errorKey === 'fill' && errors[errorKey]) ||
                      //     (errorKey === 'quantity' && errors[errorKey]) ||
                      //     (errorKey === 'zero' && errors[errorKey]),
                      // )
                      color:
                        firstError && firstError.length > 0 ? 'red' : 'black',
                      marginRight: 'auto',
                    }}
                  >
                    {step === 1 &&
                      firstError &&
                      t(translations.pages.purchase_order[firstError])}
                  </h5>
                </>
              )}
              {step !== 3 && (
                <div>
                  <div className="d-flex flex-column  w-100 mb-3  mt-2 justify-content-center align-items-center">
                    {step !== 2 && (
                      <div>
                        <h1
                          style={{
                            color: Object.keys(errors).find(
                              errorKey =>
                                (errorKey === 'fill' && errors[errorKey]) ||
                                (errorKey === 'quantity' && errors[errorKey]) ||
                                (errorKey === 'zero' && errors[errorKey]),
                            )
                              ? 'red'
                              : 'black',
                            marginRight: 'auto',
                            textAlign: 'center',
                          }}
                        >
                          {step === 0 &&
                            !isMobile &&
                            t(translations.pages.purchase_order.release)}
                          {step === 1 &&
                            firstError &&
                            t(translations.pages.purchase_order[firstError])}
                        </h1>
                      </div>
                    )}
                    {step === 2 && (
                      <div>
                        <h1
                          style={{
                            color: 'red',
                            marginRight: 'auto',
                            textAlign: 'center',
                          }}
                        >
                          {errors.includes('date_needs') &&
                            t(translations.errors_400.date)}
                          {errors.includes('checkboxes_unchecked') &&
                            t(translations.errors.checkboxes_unchecked)}
                          {errors.includes('importers_bags_required') &&
                            t(translations.errors.importers_bags_required)}
                          {errors.includes('email_freight_required') &&
                            t(translations.errors.email_freight_required)}
                          {errors.includes('address_required') &&
                            t(translations.errors.address_required)}
                        </h1>
                      </div>
                    )}
                    {/* {step !== 3 && (
                      <Button
                        backgroundColor={theme.palette.primary.main}
                        type="button"
                        onClick={handleSubmit}
                        value={t(translations.general.confirm)}
                      />
                    )}

                    {step === 1 || step === 2 ? (
                      <Button
                        type="button"
                        onClick={deleteModal}
                        value={t(translations.general.cancel)}
                        style={{ marginTop: '20px' }}
                        backgroundColor={theme.palette.error.main}
                      />
                    ) : null} */}
                  </div>
                </div>
              )}
              {/* {(step === 1 || step === 2) && (
                <div
                  style={{
                    textAlign: 'right',
                    color: 'red',
                    marginRight: '20px',
                  }}
                >
                  {t(translations.general.mandatory)}
                </div>
              )} */}
            </div>
          )}
          <div style={{ padding: !isMobile ? '10px 96px' : '0 15px' }}>
            {/* {loadingData ? (
              <div
                style={{
                  transform: 'scale(0.2)',
                  minWidth: 700,
                  marginTop: '-200px',
                }}
              >
                <LoadingPage />
              </div>
            ) : ( */}
            <div>
              {step === 0 && (
                <FirstStep
                  setLoading={setLoading}
                  loading={loading}
                  loadingData={loadingData}
                  setLoadingData={setLoadingData}
                />
              )}
              {step === 1 && (
                <SecondStep
                  userData={userData}
                  storedData={storedData}
                  setStoredData={setStoredData}
                  deletedContracts={deletedContracts}
                  setDeletedContracts={setDeletedContracts}
                  dataToBeSent={dataToBeSent}
                  setDataToBeSent={setDataToBeSent}
                />
              )}
              {step === 2 && (
                <ThirdStep
                  userData={userData}
                  handleChangeUK={handleChangeUK}
                  infoUK={infoUK}
                  setInfoUK={setInfoUK}
                  handleTextDateUK={handleTextDateUK}
                  handleChange={handleChange}
                  infoOther={infoOther}
                  setInfoOther={setInfoOther}
                  handleTextDate={handleTextDate}
                  setNeedDetail={setNeedDetail}
                  newAddress={newAddress}
                  setNewAddress={setNewAddress}
                  // country={country}
                />
              )}
              {step === 3 && <FinalStep idStored={idStored} />}
            </div>
            {/* )} */}
          </div>
          <div>
            <div className="d-flex justify-content-end w-100 mb-3  mt-2">
              {step !== 3 && (
                <Button
                  backgroundColor={theme.palette.primary.main}
                  type="button"
                  onClick={handleSubmit}
                  value={t(translations.general.confirm)}
                />
              )}

              {step === 2 ? (
                <>
                  <Button
                    type="button"
                    onClick={deleteModal}
                    value={t(translations.general.cancel)}
                    backgroundColor={theme.palette.error.main}
                  />
                </>
              ) : null}
            </div>
          </div>
        </>
      )}
    </>
  );
}
