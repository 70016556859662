import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import styled, { useTheme } from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { CustomButton } from 'app/components/Button';
import envelope_video from 'app/assets/SVG/envelope.gif';
import {
  getModulesOptions,
  getProblemsOptions,
  reportIssue,
} from 'services/supportServices';
import { IssuePayload } from 'models/authModels';
import Typography from '@mui/material/Typography';
import Select from 'react-select';
import i18next from 'i18next';
import axios from 'axios';
import { TextField, useMediaQuery } from '@mui/material';
import { Hero } from 'app/components/Hero';
import { width } from '@mui/system';
import TextArea from 'app/components/TextArea';

const CustomLabel = styled.label`
  font-style: normal;
  font-weight: 350;
  font-size: 30px;
  line-height: 36px;
  margin: 30px 0px 13px;
  text-align: center;
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
`;

const DescriptionDiv = styled.div`
  margin: 40px 0 0 0;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const CustomForm = styled.form`
  padding: 4vw 16vw;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const ResponseContainer = styled.div`
  padding: 0px 10vw;
  display: flex;
  align-items: center;
`;

export function SupportCenterPage() {
  const { t } = useTranslation();
  const theme: any = useTheme();
  const [moduleList, setModules] = useState([] as Array<any>);
  const [problemList, setProblems] = useState([] as Array<any>); // TODO add textarea description for other
  const [reviewSent, setReviewSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [module_option_id, setModuleID] = useState<number>(0);
  const [problem_option_id, setProblemID] = useState<number>(0);
  const [description, setDescription] = useState<string>('');

  const isMobile = useMediaQuery('(max-width:767px)');

  const handleModuleChange = (e: any) => {
    setModuleID(e.value);
  };

  const handleDescriptionChange = (e: any) => {
    setDescription(e.target.value);
  };

  const handleProblemChange = (e: any) => {
    setProblemID(e.value);
  };

  const onSubmit = e => {
    e.preventDefault();
    const payload: IssuePayload = {
      module_option_id,
      problem_option_id,
      description,
    };
    if (module_option_id === 0 || problem_option_id === 0) {
      setErrorMessage(t(translations.pages.support_center.select_both_fields));
      return;
    }
    reportIssue(payload)
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          setReviewSent(true);
        }
      })
      .catch(error => {
        setErrorMessage(error.response?.data?.message);
      });
  };

  useEffect(() => {
    const currentLanguage = i18next.language;
    const languageCode =
      currentLanguage.length > 2
        ? currentLanguage.substr(0, 2)
        : currentLanguage;

    let moduleUrl = process.env.REACT_APP_BASE_URL + '/module-options';
    let problemUrl = process.env.REACT_APP_BASE_URL + '/problem-options';

    const token = localStorage.getItem('caravela_access_token');

    // Realizar la solicitud GET a moduleUrl con el encabezado de idioma y el token de autorización
    axios
      .get(moduleUrl, {
        headers: {
          'Accept-Language': languageCode,
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => setModules(response.data))
      .catch(error => {
        setModules([error.response?.data?.message]);
      });

    axios
      .get(problemUrl, {
        headers: {
          'Accept-Language': languageCode,
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => setProblems(response.data))
      .catch(error => {
        setProblems([error.response?.data?.message]);
      });
  }, [i18next.language]); // Agrega i18next.language como dependencia

  return (
    <MainContainer>
      <Helmet>
        <title>{t(translations.pages.support_center.title)}</title>
        <meta
          name="description"
          content={'Roasterhub ' + t(translations.pages.support_center.title)}
        />
      </Helmet>
      <div
        style={{
          position: 'sticky',
          background: 'white',
          padding: '0px 110px 0px 94px',
          marginLeft: '20px',
          marginTop: '28px',
          marginBottom: '20px',
          // width: '82vw',
          zIndex: '0',
          top: '0',
        }}
      >
        {!reviewSent ? (
          <Hero
            title={t(translations.pages.support_center.title)}
            subtitle={t(translations.pages.support_center.subtitle)}
          />
        ) : (
          <Hero title={t(translations.pages.support_center.title)} />
        )}
        {!reviewSent ? (
          <CustomForm onSubmit={onSubmit}>
            <CustomLabel htmlFor="module_option_id">
              {t(translations.pages.support_center.which_module_has_issues)}
            </CustomLabel>

            <Select
              isSearchable={false}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  background: '#F6F6F6',
                  borderRadius: '30px',
                  width: !isMobile ? '100vh' : '30vh',
                  height: '50px',
                }),
              }}
              options={moduleList.map(option => ({
                label: option.name,
                value: option.id,
              }))}
              defaultValue={{ label: '', value: 0 }}
              onChange={e => handleModuleChange(e)}
            />

            <CustomLabel htmlFor="problem_option_id">
              {t(translations.pages.support_center.what_is_the_issue)}
            </CustomLabel>

            <Select
              isSearchable={false}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  background: '#F6F6F6',
                  borderRadius: '30px',
                  width: !isMobile ? '100vh' : '30vh',
                  height: '50px',
                }),
              }}
              options={problemList.map(option => ({
                label: option.name,
                value: option.id,
              }))}
              defaultValue={{ label: '', value: 0 }}
              onChange={e => handleProblemChange(e)}
            />

            {errorMessage && (
              // <Typography color="error" variant="h6">
              <div style={{ color: 'red', marginTop: '10px' }}>
                ❌ {errorMessage}
              </div>
              // </Typography>
            )}
            <DescriptionDiv>
              <TextArea
                style={{
                  width: '650px',
                  height: '150px',
                }}
                onChange={e => handleDescriptionChange(e)}
              />
            </DescriptionDiv>

            <CustomButton
              style={{
                alignSelf: !isMobile ? 'flex-end' : 'center',
                marginTop: '40px',
                background: theme.palette.primary.main,
              }}
              withShadow
            >
              {t(translations.general.submit)}
            </CustomButton>
          </CustomForm>
        ) : (
          <ResponseContainer
            style={{ flexDirection: !isMobile ? 'row' : 'column' }}
          >
            {!isMobile && (
              <CustomLabel>
                {t(
                  translations.pages.support_center.thank_you_for_contacting_us,
                )}
              </CustomLabel>
            )}
            <img
              src={envelope_video}
              style={{ maxWidth: '40vh' }}
              alt="envelope gif"
            />
            {isMobile && (
              <CustomLabel>
                {t(
                  translations.pages.support_center.thank_you_for_contacting_us,
                )}
              </CustomLabel>
            )}
          </ResponseContainer>
        )}
      </div>
    </MainContainer>
  );
}
