import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { CustomInput } from 'app/components/CustomInput';
import { CustomButton } from '../../components/Button';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { Country, State, ICountry } from 'country-state-city';
import { register } from 'services/authServices';
import { getAllCountries } from 'services/salesServices';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';

import {
  CustomHeader,
  CustomFooter,
  Title,
  MainContainer,
  SyledForm,
  Label,
  FlexRowDiv,
  SubTitle,
  customTypography,
} from './components';
import {
  RadioGroup,
  FormControlLabel,
  FormControl,
  Radio,
  Typography,
} from '@mui/material';
import caravela from 'app/assets/SVG/logo-caravela-2-SIN-LINEAS 2.png';
import { CustomSelect, StyledOption } from 'app/components/CustomSelect';
import { translations } from 'locales/translations';
import { RegisterPayload } from 'models/authModels';
import { Link } from 'react-router-dom';
import styled, { useTheme } from 'styled-components/macro';

const Checkbox = styled.input`
  width: 30px;
  height: 30px;
  margin: 12px 0;
`;

const LabelContact = styled.div`
  margin: 0 12px;

  font-weight: 350;
  font-size: 1.1428571428571428rem;
  line-height: 1.5;

  font-weight: 325;
  font-size: 16px;
  color: #545454;
`;

enum ResponseStatus {
  OK = 'OK',
}

type CountryOption = {
  label: string;
  value: number;
  name: number;
};

export function RegisterPage(props) {
  const { t, i18n } = useTranslation();
  const theme: any = useTheme();
  const [otherValue, setOtherValue] = useState<String | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<CountryOption[]>([]);

  const [submitResponse, setSubmitResponse] = useState<{
    response: string;
    error: boolean;
  } | null>({
    error: false,
    response: '',
  });
  const [selectedCountry, setSelectedCountry] = useState<any | null>(null);
  const [selectedState, setSelectedState] = useState<any | null>(null);
  const [contact, setContact] = useState<any | null>({
    sms: false,
    call: false,
    mail: false,
  });
  const navigate = useNavigate();

  useEffect(() => {
    getCountries();
  }, []);

  const onSubmit = e => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData.entries());
    data.country_code = selectedCountry?.option.name;
    data.country_id = selectedCountry?.option.value;
    data.state_province = (selectedState?.option.label as string) || '';
    let contactValues: string[] = [];
    contact.sms === true && contactValues.push('SMS');
    contact.call === true && contactValues.push('Call');
    contact.mail === true && contactValues.push('Email');
    data.preferred_contact = contactValues.toString();
    const validationError = validateData(data);

    const redirect = () => {
      setTimeout(() => {
        navigate('/login');
      }, 7000);
    };

    if (!validationError) {
      register({
        ...data,
        contact_name: data.name as string,
      } as RegisterPayload)
        .then((response: any) => {
          if (response.status === 201) {
            setSubmitResponse({
              response: t(translations.pages.register.success),
              error: false,
            });
            redirect();
          }
        })
        .catch(error => {
          if (error?.response?.status === 400) {
            console.log(error?.response?.data?.errors);
            const firstErrorKey = Object.keys(
              error?.response?.data?.errors as Object,
            )[0];

            setSubmitResponse({
              response:
                `${firstErrorKey} ${error?.response?.data?.errors[firstErrorKey]}` as string,
              error: true,
            });
          } else if (
            error.response.status === 422 &&
            error.response.data.errors[0].type === 'email' &&
            error.response.data.errors[0].message[0] === 'validation.unique'
          ) {
            setSubmitResponse({
              response: t(translations.pages.register.unique),
              error: true,
            });
          } else {
            console.log(error.response);
            setSubmitResponse({
              response: 'unexpected error',
              error: true,
            });
          }
        });
    } else setSubmitResponse({ response: validationError, error: true });
  };

  const validateData = (data: any): string | null => {
    if (data.name === '')
      return (
        t(translations.pages.register.full_name) +
        ' ' +
        t(translations.pages.register.is_required)
      );
    if (data.email === '')
      return (
        t(translations.general.email) +
        ' ' +
        t(translations.pages.register.is_required)
      );
    if (data.contact_number === '')
      return (
        t(translations.pages.register.mobile_number) +
        ' ' +
        t(translations.pages.register.is_required)
      );
    if (data.companyName === '')
      return (
        t(translations.pages.register.company_name) +
        ' ' +
        t(translations.pages.register.is_required)
      );
    if (data.password === '' || data.password !== data.password_confirmation)
      return (
        t(translations.general.password) +
        ' ' +
        t(translations.pages.register.is_required) +
        ' ' +
        t(translations.pages.register.and_should_match)
      );
    if (!data.country_id)
      return (
        t(translations.pages.register.country) +
        ' ' +
        t(translations.pages.register.is_required)
      );
    if (data.state_province === '')
      return (
        t(translations.pages.register.state_province) +
        ' ' +
        t(translations.pages.register.is_required)
      );
    if (data.zip_code === '')
      return (
        t(translations.pages.register.zip_postal_code) +
        ' ' +
        t(translations.pages.register.is_required)
      );
    if (data.company_address === '')
      return (
        t(translations.pages.register.street_address) +
        ' ' +
        t(translations.pages.register.is_required)
      );
    // if (data.address_line_2 === '')
    //   return (
    //     t(translations.pages.register.address_line_2) +
    //     ' ' +
    //     t(translations.pages.register.is_required)
    //   );
    if (data.preferred_contact === '')
      return (
        t(translations.pages.register.preferred_contact_way) +
        ' ' +
        t(translations.pages.register.is_required)
      );
    if (data['g-recaptcha-response'] === '')
      return 'Captcha ' + t(translations.pages.register.is_required);

    return null;
  };

  const onClickRadio = isOther => {
    if (isOther) setOtherValue(otherValue || '');
    else setOtherValue(null);
  };

  const AddRemoveContact = e => {
    e === 'sms' &&
      setContact(oldValue => ({ ...oldValue, sms: !oldValue.sms }));
    e === 'call' &&
      setContact(oldValue => ({ ...oldValue, call: !oldValue.call }));
    e === 'mail' &&
      setContact(oldValue => ({ ...oldValue, mail: !oldValue.mail }));
  };

  const getCountries = () => {
    setIsLoading(true);
    getAllCountries()
      .then(response => {
        if (response?.statusText === ResponseStatus.OK) {
          const parsedData = response?.data?.map(countryInfo => ({
            label: countryInfo.name,
            value: parseInt(countryInfo.id),
            name: countryInfo.iso_code,
          }));
          setOptions(parsedData);
        }
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <MainContainer>
      <Helmet>
        <title>{t(translations.pages.register.title)}</title>
        <meta name="description" content="Roasterhub registration" />
      </Helmet>
      <CustomHeader>
        <Link to="/">
          <div>
            <img
              src={caravela}
              style={{ transform: 'scale(1.4)' }}
              alt="caravela logo"
            />
          </div>
        </Link>
      </CustomHeader>
      <SyledForm onSubmit={onSubmit}>
        <Title>{t(translations.pages.register.title)}</Title>
        <FlexRowDiv>
          <div style={{ flexGrow: '1' }}>
            <Label htmlFor="name">
              {t(translations.pages.register.full_name)}: *
            </Label>
            <CustomInput
              name="name"
              onChange={e => console.log(e.target.value)}
            />
          </div>
          <div>
            <Label htmlFor="email">{t(translations.general.email)}: *</Label>
            <CustomInput name="email" type="email" />
          </div>
        </FlexRowDiv>
        <FlexRowDiv>
          <div>
            <Label htmlFor="contact_number">
              {t(translations.pages.register.mobile_number)}: *
            </Label>
            <CustomInput name="contact_number" />
          </div>
          <div>
            <Label htmlFor="companyName">
              {t(translations.pages.register.company_name)}: *
            </Label>
            <CustomInput name="companyName" />
          </div>
          <div>
            <Label htmlFor="websiteUrl">
              {t(translations.pages.register.website_url)}:
            </Label>
            <CustomInput name="websiteUrl" type="url" />
          </div>
        </FlexRowDiv>
        <FlexRowDiv>
          <div>
            <Label htmlFor="password">
              {t(translations.general.password)}:*
            </Label>
            <CustomInput name="password" type="password" />
          </div>
          <div>
            <Label htmlFor="password_confirmation">
              {t(translations.pages.register.password_confirmation)}:*
            </Label>
            <CustomInput name="password_confirmation" type="password" />
          </div>
        </FlexRowDiv>
        {/* <SubTitle>
          {t(translations.pages.register.coffe_shipment_address_number)}
        </SubTitle> */}
        <FlexRowDiv>
          <div>
            <Label htmlFor="country">
              {' '}
              {t(translations.pages.register.country)}: *
            </Label>
            {/* <CustomSelect
              name="country"
              onChange={(e, newValue) =>
                setSelectedCountry(newValue as ICountry)
              }
            >
              {Country.getAllCountries().map(countryObj => (
                <StyledOption value={countryObj}>
                  {countryObj.name}
                </StyledOption>
              ))}
            </CustomSelect> */}
            <Select
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  background: '#F6F6F6',
                  borderRadius: '30px',
                  // width: '100vh',
                  height: '50px',
                }),
              }}
              isLoading={isLoading}
              options={options}
              defaultValue={{ label: '', value: 0 }}
              onChange={option => setSelectedCountry({ option })}
            />
          </div>
          <div>
            <Label htmlFor="state_province">
              {t(translations.pages.register.state_province)}: *
            </Label>
            {/* <CustomSelect name="state_province">
              {
                selectedCountry ? (
                  State.getStatesOfCountry(selectedCountry.option.name).length >
                  0 ? (
                    State.getStatesOfCountry(selectedCountry.option.name).map(
                      countryObj => (
                        <StyledOption value={countryObj.name}>
                          {countryObj.name}
                        </StyledOption>
                      ),
                    )
                  ) : (
                    <StyledOption value={'-'}>
                      {t(
                        translations.pages.register.errors
                          .no_state_province_found,
                      )}{' '}
                      {selectedCountry.name}
                    </StyledOption>
                  )
                ) : (
                  <StyledOption disabled={true} value={'disabled'}>
                    {t(translations.pages.register.errors.select_country_first)}
                  </StyledOption>
                )
              }
            </CustomSelect> */}
            <Select
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  background: '#F6F6F6',
                  borderRadius: '30px',
                  // width: '100vh',
                  height: '50px',
                }),
              }}
              options={
                selectedCountry
                  ? State.getStatesOfCountry(selectedCountry.option.name)
                      .length > 0
                    ? State.getStatesOfCountry(selectedCountry.option.name).map(
                        option => ({
                          label: option.name,
                          value: parseInt(option.isoCode),
                        }),
                      )
                    : [{ value: 0, label: '' }]
                  : [{ value: 0, label: '' }]
              }
              defaultValue={{ label: '', value: 0 }}
              onChange={option => setSelectedState({ option })}
              isDisabled={!selectedCountry}
            />
          </div>
          <div>
            <Label htmlFor="city">
              {t(translations.pages.register.city)}: *
            </Label>
            <CustomInput name="city" />
          </div>
        </FlexRowDiv>
        <FlexRowDiv>
          <div>
            <Label htmlFor="zip_code">
              {t(translations.pages.register.zip_postal_code)}: *
            </Label>
            <CustomInput name="zip_code" />
          </div>
          <div>
            <Label htmlFor="company_address">
              {t(translations.pages.register.street_address)}: *
            </Label>
            <CustomInput name="company_address" />
          </div>
          <div>
            <Label htmlFor="address_line_2">
              {t(translations.pages.register.address_line_2)}: *
            </Label>
            <CustomInput name="address_line_2" />
          </div>
        </FlexRowDiv>

        <FlexRowDiv></FlexRowDiv>

        <FlexRowDiv>
          <div className="d-flex ">
            {/* <FormControl> */}
            <Label
              id="preferred_contact"
              htmlFor="preferred_contact"
              style={{ paddingLeft: '0' }}
            >
              {t(translations.pages.register.preferred_contact_way)}:
            </Label>
            <div className="d-flex align-items-center">
              <Checkbox
                type="checkbox"
                id="sms"
                name="sms"
                value="sms"
                onChange={e => AddRemoveContact(e.target.id)}
              />
              <LabelContact>Text SMS</LabelContact>
            </div>
            <div className="d-flex align-items-center">
              <Checkbox
                type="checkbox"
                id="call"
                name="call"
                value="call"
                onChange={e => AddRemoveContact(e.target.id)}
              />
              <LabelContact>Call</LabelContact>
            </div>
            <div className="d-flex align-items-center">
              <Checkbox
                type="checkbox"
                id="mail"
                name="mail"
                value="mail"
                onChange={e => AddRemoveContact(e.target.id)}
              />
              <LabelContact>E-mail</LabelContact>
            </div>
          </div>
          {/* <FormControlLabel
              value={t(translations.pages.register.text_sms)}
              slotProps={customTypography}
              control={<Radio color="primary" />}
              label={t(translations.pages.register.text_sms)}
            />
            <FormControlLabel
              value={t(translations.pages.register.call)}
              slotProps={customTypography}
              control={<Radio color="primary" />}
              label={t(translations.pages.register.call)}
            />
            <FormControlLabel
              value={t(translations.general.email)}
              slotProps={customTypography}
              control={<Radio color="primary" />}
              label={t(translations.general.email)}
            /> */}
          {/* </FormControl> */}

          {/* <FormControl>
            <Label
              id="identity_yourself"
              htmlFor="identity_yourself"
              style={{ paddingLeft: '0' }}
            >
              {t(translations.pages.register.identify_yourself)}
            </Label>

            <RadioGroup name="identity_yourself">
              <FormControlLabel
                value="Coffee Roaster"
                slotProps={customTypography}
                control={
                  <Radio onClick={() => onClickRadio(false)} color="primary" />
                }
                label={t(translations.pages.register.coffe_roaster)}
              />
              <FormControlLabel
                value="Coffee Producer"
                slotProps={customTypography}
                control={
                  <Radio onClick={() => onClickRadio(false)} color="primary" />
                }
                label={t(translations.pages.register.coffe_producer)}
              />
              <FormControlLabel
                value="Coffee Exporter"
                slotProps={customTypography}
                control={
                  <Radio onClick={() => onClickRadio(false)} color="primary" />
                }
                label={t(translations.pages.register.coffe_exporter)}
              />
              <FormControlLabel
                value="Coffee Importer"
                slotProps={customTypography}
                control={
                  <Radio onClick={() => onClickRadio(false)} color="primary" />
                }
                label={t(translations.pages.register.coffe_importer)}
              />
              <FormControlLabel
                value={otherValue || ''}
                slotProps={customTypography}
                control={
                  <Radio onClick={() => onClickRadio(true)} color="primary" />
                }
                label={t(translations.pages.register.other)}
              />
              <CustomInput
                name="identity_yourself"
                placeholder={` ${t(translations.pages.register.other)} ...`}
                disabled={otherValue === null}
                value={otherValue || ''}
                onChange={e => setOtherValue(e.target.value)}
              />
            </RadioGroup>
          </FormControl> */}
          <div style={{ alignSelf: 'center' }}>
            {/* <ReCAPTCHA
              sitekey={process.env.REACT_APP_CAPTCHA_PUBLIC_KEY as string}
              hl={i18n.language}
              style={{ alignSelf: 'center', justifySelf: 'flex-end' }}
            /> */}
          </div>
        </FlexRowDiv>
        {submitResponse?.response !== '' &&
          (submitResponse?.error === true ? (
            <Typography color="error" variant="h6">
              ❌ {submitResponse?.response}
            </Typography>
          ) : (
            <Typography color="success.main" variant="h6">
              ✅ {submitResponse?.response}
            </Typography>
          ))}
        <CustomButton
          withShadow
          style={{ background: theme.palette.primary.main }}
          onClick={() => setSubmitResponse({ response: '', error: false })}
        >
          {t(translations.general.confirm)}
        </CustomButton>
      </SyledForm>
      <CustomFooter>© Caravela Limited</CustomFooter>
    </MainContainer>
  );
}
