import { styledTheme } from '../../../styles/theme';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Modal,
  Box,
  TextField,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Alert,
  AlertTitle,
  Snackbar,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import {
  useMediaQuery,
  Switch,
  FormControlLabel,
  IconButton,
} from '@mui/material';
import { useState, useEffect } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import {
  getListCustomers,
  getListReports,
  getReportDetails,
  saveReportData,
} from 'services/reportsServices';
import { LoadingPage } from '../LoadingPage';
import { useTheme } from 'styled-components';
import Dropdown from 'app/components/Dropdown';
import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import axios from 'axios';

const StyledHeadCell = styled(TableCell)`
  font-style: normal;
  font-size: 13px;
  line-height: 16px;
  border-bottom: 2px solid white;
  background: #dfdcca;
  text-align: center;
  font-family: Roboto, sans-serif;
`;

const StyledTableRow = styled(TableRow)`
  border-bottom: 3px solid white;
  border-top: 3px solid white;

  th:first-child {
    border-radius: 35px 0px 0px 35px;
  }

  th:last-child {
    border-radius: 0px 35px 35px 0px;
  }

  td:first-child {
    border-radius: 35px 0px 0px 35px;
  }

  td:last-child {
    border-radius: 0px 35px 35px 0px;
  }
`;

const CustomButton = styled.button`
  background: #b2ba86;
  color: ${props => props.theme.White};
  border: none;
  border-radius: 20px;
  padding: 0.5em 2.8em;
  margin: 5px 10px;
  font-weight: 350;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  &:disabled {
    background-color: ${props => props.theme.Gray};
  }
`;
const CustomButton2 = styled.button`
  background: #f0b27a;
  color: ${props => props.theme.White};
  border: none;
  border-radius: 20px;
  padding: 0.5em 2.8em;
  margin: 5px 10px;
  font-weight: 350;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  &:disabled {
    background-color: ${props => props.theme.Gray};
  }
`;
const StyledTableCell = styled(TableCell)`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 325;
  font-size: 13px;
  line-height: 16px;
  background: #f8f8f8;
  border-bottom: 1px solid white;
  color: rgba(0, 0, 0, 0.87);
`;

const SearchInput = styled.input`
  background: #f6f6f6;
  border-radius: 30px;
  width: 50%;
  height: 40px;
  margin-right: 10px;
  //outline: none;
  padding: 10px;
  border: 1px solid #ccc;
`;

const EditButton = styled.button`
  background: #b2ba86;
  color: ${props => props.theme.White};
  border: none;
  border-radius: 20px;
  padding: 5px 15px;
  cursor: pointer;
`;

export function ReportsEdit() {
  interface Report {
    contract: string;
    origin: string;
    ico: string;
    mark: string;
    warehouse: string;
    quality: string;
    visible: string;
    infoIco: {
      ico: string;
      contract: string;
      milling_date: string;
      confidential: string;
      green_add: string | null;
      local_currency: string;
      po: string;
      mo: string;
      parchment_kg: string;
      green_kg: string;
      cost_usd: string;
      cost_local: string;
      green_cost_usd_lb: string;
      parchment_cost_usd_kg: string;
      parchment_cost_local_kg: string;
    }[];
    producers: {
      ico_spot: string;
      producer: string;
      lot_id: string;
      parchment_kg: string;
      green_kg: string;
      green_factor: string;
      cost_usd: string;
      cost_local: string;
      cost_usd_lb: string;
    }[];
  }

  const { t } = useTranslation();
  const [customers, setCustomers] = useState<any>([]);
  const [reports, setReports] = useState<Report[]>([]);
  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState<Boolean>(false);
  const theme: any = useTheme();
  const isMobile = useMediaQuery('(max-width:767px)');
  const [openModal, setOpenModal] = useState(false);
  const [selectedReport, setSelectedReport] = useState<Report | null>(null);
  const [switchChecked, setSwitchChecked] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarType, setSnackbarType] = useState<'success' | 'error'>(
    'success',
  );
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isDownload, setIsDownload] = useState(false);

  const getTraceabilityCall = (contract, ico) => {
    setLoading(true);
    setIsDownload && setIsDownload(true);
    let url =
      process.env.REACT_APP_BASE_URL +
      `/reports/traceability?contract=${contract}&ico=${ico}`;
    const token = localStorage.getItem('caravela_access_token');
    axios({
      method: 'GET',
      url,
      responseType: 'blob',
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(response => {
        if (response.status === 200) {
          setLoadingData && setLoadingData(false);
          setIsDownload && setIsDownload(false);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Traceability.pdf'); //or any other extension
          document.body.appendChild(link);
          link.click();
          setLoading(false);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const getTransparencyCall = (contract, ico) => {
    setLoading(true);
    setIsDownload && setIsDownload(true);
    let url =
      process.env.REACT_APP_BASE_URL +
      `/reports/transparency?contract=${contract}&ico=${ico}`;
    const token = localStorage.getItem('caravela_access_token');
    axios({
      method: 'GET',
      url,
      responseType: 'blob',
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(response => {
        if (response.status === 200) {
          setLoadingData && setLoadingData(false);
          setIsDownload && setIsDownload(false);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Transparency.pdf'); //or any other extension
          document.body.appendChild(link);
          link.click();
          setLoading(false);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const handleOpenModal = ico => {
    setLoading(true);
    getReportDetails(ico)
      .then(response => {
        if (response.status === 200) {
          setSelectedReport(response.data);
          const isVisible = response.data.visible === '1';
          setSwitchChecked(isVisible);
          setOpenModal(true);
          setLoading(false);
        }
      })
      .catch(error => {
        console.error('Error fetching contract details:', error);
      });
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedReport(null);
  };

  const handleInputChange = (e, index, type) => {
    const { name, value } = e.target;
    setSelectedReport(prevState => {
      if (!prevState) return null;
      const updatedInfoIco = [...prevState.infoIco];
      updatedInfoIco[index] = {
        ...updatedInfoIco[index],
        [name]: value,
      };
      return {
        ...prevState,
        infoIco: updatedInfoIco,
      };
    });
  };

  const handleProducerInputChange = (e, index) => {
    const { name, value } = e.target;
    setSelectedReport(prevState => {
      if (!prevState) return null;
      const updatedProducers = [...prevState.producers];
      updatedProducers[index] = {
        ...updatedProducers[index],
        [name]: value,
      };
      return {
        ...prevState,
        producers: updatedProducers,
      };
    });
  };

  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleSaveReport = event => {
    event.preventDefault();
    if (selectedReport) {
      const switchValue = switchChecked ? 1 : 0;
      selectedReport.infoIco = selectedReport.infoIco.map(info => ({
        ...info,
        visible: switchValue,
      }));
      saveReportData(selectedReport)
        .then(response => {
          handleCloseModal();
          setSnackbarMessage('Report saved successfully!');
          setSnackbarType('success');
          setSnackbarOpen(true);
        })
        .catch(error => {
          setSnackbarMessage(
            'There was an error with the request, please try again!',
          );
          setSnackbarType('error');
          setSnackbarOpen(true);
        });
    }
  };

  const flagListCustomers = 1;
  const contractsList = [];
  useEffect(() => {
    getListCustomers(flagListCustomers)
      .then(response => {
        if (response.status === 200) {
          setCustomers(
            response.data.map(item => {
              if (item.Customer === null && item.NameCustomer === null) {
                return {
                  value: null,
                  label: '',
                };
              }
              return {
                value: item.Customer,
                label: item.NameCustomer
                  ? item.NameCustomer.trim().replace(/\s+$/, '')
                  : '',
                importer: item.idImporter,
              };
            }),
          );
          setLoadingData(false);
        }
      })
      .catch(error => {
        if (error.response?.data?.message === 'No data') {
          console.log('No data available');
        } else {
          setError(error.message);
        }
        setLoadingData(false);
        console.error(error);
      });
  }, []);

  const [searchTerm, setSearchTerm] = useState('');

  const handleInputIcoChange = event => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const filteredReports = reports.filter(contrato =>
    contrato.ico.toLowerCase().includes(searchTerm),
  );

  const handleCustomerSelect = selectedCustomer => {
    const importer = selectedCustomer.importer;
    const customer = selectedCustomer.value;

    getListReports(importer, customer)
      .then(response => {
        if (response.status === 200) {
          console.log(response.data);
          setReports(response.data as Report[]);
          setLoadingData(false);
        }
      })
      .catch(error => {
        if (error.response?.data?.message === 'No data') {
          console.log('No data available');
        } else {
          setError(error.message);
        }
        setLoadingData(false);
        console.error(error);
      });
  };

  return (
    <div style={{ padding: !isMobile ? '96px' : '0px' }}>
      <Helmet>
        <title>Edit Reports</title>
        <meta name="description" content="My Roasterhub order" />
      </Helmet>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '200px',
          }}
        >
          <LoadingPage />
        </div>
      ) : (
        <div>
          <h1>Edit Reports</h1>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '10px',
            }}
          >
            <Dropdown
              width={true}
              handleChange={handleCustomerSelect}
              defaultValue={'Customer'}
              list={customers}
            />
            {customers && customers.length > 0 && (
              <SearchInput
                type="text"
                placeholder="Search ICO..."
                value={searchTerm}
                onChange={handleInputIcoChange}
                style={{ marginLeft: '10px' }}
              />
            )}
          </div>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert
              onClose={handleSnackbarClose}
              severity={snackbarType}
              sx={{ width: '100%' }}
            >
              <AlertTitle>
                {snackbarType === 'success' ? 'Success' : 'Error'}
              </AlertTitle>
              {snackbarMessage}
            </Alert>
          </Snackbar>
          {reports.length > 0 ? (
            <TableContainer
              component={Paper}
              style={{ border: 'none', boxShadow: 'none' }}
            >
              <Table>
                <TableHead>
                  <StyledTableRow>
                    <StyledHeadCell>Contract</StyledHeadCell>
                    <StyledHeadCell>Origin</StyledHeadCell>
                    <StyledHeadCell>ICO</StyledHeadCell>
                    <StyledHeadCell>Trazability</StyledHeadCell>
                    <StyledHeadCell>Transparency</StyledHeadCell>
                    <StyledHeadCell>Option</StyledHeadCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {filteredReports.map((contrato, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell>{contrato.contract}</StyledTableCell>
                      <StyledTableCell>{contrato.origin}</StyledTableCell>
                      <StyledTableCell>{contrato.ico}</StyledTableCell>
                      <StyledTableCell
                        onClick={() =>
                          getTraceabilityCall(contrato.contract, contrato.ico)
                        }
                      >
                        <FileDownloadIcon
                          style={{
                            color: '#4F4E24',
                            cursor: 'pointer',
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell
                        onClick={() =>
                          getTransparencyCall(contrato.contract, contrato.ico)
                        }
                      >
                        <FileDownloadIcon
                          style={{
                            color: '#4F4E24',
                            cursor: 'pointer',
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        <EditButton
                          onClick={() => handleOpenModal(contrato.ico)}
                        >
                          Edit
                        </EditButton>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <p>Select a customer to display the list of reports.</p>
          )}
        </div>
      )}
      <Modal
        open={openModal}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleCloseModal();
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxHeight: '90vh',
            overflowY: 'auto',
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          {selectedReport && (
            <form>
              {selectedReport.infoIco.map((info, index) => (
                <React.Fragment key={index}>
                  <TextField
                    label="ICO"
                    name="ico"
                    value={info.ico}
                    fullWidth
                    margin="normal"
                    disabled={true}
                    onChange={e => handleInputChange(e, index, 'infoIco')}
                  />
                  <TextField
                    label="Contract"
                    name="contract"
                    value={info.contract}
                    fullWidth
                    margin="normal"
                    disabled={true}
                    onChange={e => handleInputChange(e, index, 'infoIco')}
                  />
                  <TextField
                    label="Milling Date"
                    name="milling_date"
                    value={info.milling_date}
                    fullWidth
                    margin="normal"
                    disabled={true}
                    onChange={e => handleInputChange(e, index, 'infoIco')}
                  />
                  {/* Aca se añaden mas opciones del Ico... */}
                </React.Fragment>
              ))}
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledHeadCell>Producer</StyledHeadCell>
                      <StyledHeadCell>Lot ID</StyledHeadCell>
                      <StyledHeadCell>Parchment KG</StyledHeadCell>
                      <StyledHeadCell>Green KG</StyledHeadCell>
                      <StyledHeadCell>Green Factor</StyledHeadCell>
                      <StyledHeadCell>Cost USD</StyledHeadCell>
                      <StyledHeadCell>Cost Local</StyledHeadCell>
                      <StyledHeadCell>Cost USD LB</StyledHeadCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedReport.producers.map((producer, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell>
                          <TextField
                            name="producer"
                            value={producer.producer}
                            fullWidth
                            onChange={e => handleProducerInputChange(e, index)}
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          <TextField
                            name="lot_id"
                            value={producer.lot_id}
                            fullWidth
                            onChange={e => handleProducerInputChange(e, index)}
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          <TextField
                            name="parchment_kg"
                            value={producer.parchment_kg}
                            fullWidth
                            onChange={e => handleProducerInputChange(e, index)}
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          <TextField
                            name="green_kg"
                            value={producer.green_kg}
                            fullWidth
                            onChange={e => handleProducerInputChange(e, index)}
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          <TextField
                            name="green_factor"
                            value={producer.green_factor}
                            fullWidth
                            onChange={e => handleProducerInputChange(e, index)}
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          <TextField
                            name="cost_usd"
                            value={producer.cost_usd}
                            fullWidth
                            onChange={e => handleProducerInputChange(e, index)}
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          <TextField
                            name="cost_local"
                            value={producer.cost_local}
                            fullWidth
                            onChange={e => handleProducerInputChange(e, index)}
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          <TextField
                            name="cost_usd_lb"
                            value={producer.cost_usd_lb}
                            fullWidth
                            onChange={e => handleProducerInputChange(e, index)}
                          />
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <hr />
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                  marginTop: '20px',
                  marginLeft: 'auto',
                }}
              >
                <FormControlLabel
                  control={
                    <Switch
                      checked={switchChecked}
                      color="default"
                      onChange={e => setSwitchChecked(e.target.checked)}
                      sx={{
                        '& .MuiSwitch-thumb': {
                          backgroundColor: switchChecked
                            ? '#b2ba86'
                            : '#777575',
                        },
                        '& .MuiSwitch-track': {
                          backgroundColor: switchChecked
                            ? '#b2ba86'
                            : '#777575',
                        },
                      }}
                    />
                  }
                  label=""
                  sx={{ marginLeft: 'auto' }}
                />
                <CustomButton2 onClick={handleCloseModal}>Cancel</CustomButton2>
                <CustomButton onClick={handleSaveReport}>Save</CustomButton>
              </Box>
            </form>
          )}
        </Box>
      </Modal>
    </div>
  );
}
