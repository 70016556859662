import * as React from 'react';
import styled from 'styled-components/macro';

const Button = styled.div`
  display: flex;
  flex-direction: column;
  width: 230px;
  height: 80px;
  left: 300px;
  top: 100px;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;

  background: ${props => props.color};
  border-radius: 30px;

  @media (max-width: 767px) {
    width: 100px;
    height: 80px;
  }
`;

const ButtonText = styled.div`
  display: flex;
  flex-direction: column;
  width: 270px;
  height: 79px;
  font-style: normal;
  font-weight: 350;
  font-size: 15px;
  line-height: 66px;
  text-align: center;
  padding: 10px 26px;
  color: ${props => props.color};
`;

const Number = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 66px;
  height: 66px;
  background: #f8f8f8;
  border-radius: 50px;
  font-style: normal;
  font-weight: 350;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  color: #f5515d;
`;

interface LargeButtonProps {
  color: string;
  icon?: any;
  number?: number;
  text: string;
  secondaryText?: string;
  textColor?: string;
}

export function LargeButton({
  color,
  icon,
  number,
  text,
  secondaryText,
  textColor = '#FFFFFF',
}: LargeButtonProps) {
  return (
    <>
      <Button color={color}>
        <ButtonText color={textColor}>
          <div style={{ marginTop: !icon ? '4px' : '-4px' }}>
            {icon ? (
              <img
                style={{
                  height: '23px',
                  width: '23px',
                  marginRight: '10px',
                  color: textColor,
                }}
                src={icon}
                alt="Upload"
              />
            ) : (
              <Number>{number}</Number>
            )}
            {text}
          </div>
          <div style={{ marginTop: '-40px', fontSize: '12px' }}>
            {secondaryText}
          </div>
        </ButtonText>
      </Button>
    </>
  );
}
