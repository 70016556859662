import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { useMediaQuery } from '@mui/material';
import { translations } from 'locales/translations';
import { useTheme } from '@mui/material/styles';

import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { IconButton, Alert, Snackbar } from '@mui/material';
import drop from '../../assets/SVG/dropdown.svg';
import { CustomButton } from '../Button';
import { AcceptRejectValueModal } from '../AcceptRejectValueModal';
import { AlertColor } from '@mui/material/Alert';
import Checkbox from '@mui/material/Checkbox';
import Dropdown from 'app/components/Dropdown';
import Select from 'react-select';

import {
  getListItemsEvaluation,
  getSamplesCup,
  saveCupping,
  getResultOptionsCup,
  getInfoSample,
  rejectedReasons,
} from 'services/cuppingServices';
import { get } from 'http';
import { use } from 'i18next';

const Title = styled.div`
  font-size: 30px;
  font-weight: 325;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
`;

const Button = styled.input`
  border: none;
  background: #5c36f3;
  color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  margin-bottom: 25px;
  width: 213.02px;
  height: 49.59px;
  margin: 0 10px;
`;

const SmallButton = styled.input`
  border: none;
  background: #5c36f3;
  color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  margin-bottom: 25px;
  width: 86px;
  height: 33px;
  margin: 0 10px;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CoffeesContainer = styled.div`
  display: flex;
  border-radius: 30px;
  background: #DFDCCA;
  width 100%;
  margin-top: 3vh; 
  height: 63px;
  align-items: center;
  padding: 0 10px
`;

const DetailContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
`;

const NamesContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  justify-content: flex-start;
  width: auto;
`;

const Names = styled.div`
  margin: 3px 10px;
  padding: 10px 20px;
  cursor: pointer;
  background: #dfdcca;
  border-radius: 30px;

  font-size: 15px;
  font-weight: 325;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  width: 204px;
  height: 38px;
`;

const ContentContainer = styled.div`
  width: 100%;
  min-width: 204px; // Ancho fijo de las secciones
  max-width: 100%; // O también limitar el ancho máximo si lo deseas
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: width 0.3s ease;
`;

const Coffee = styled.div`
  display: flex;
  border-radius: 30px;
  // background: white;

  font-size: 15px;
  font-weight: 325;
  line-height: 18px;
  letter-spacing: 0em;
  // text-align: left;
  margin: 0 10px;
  padding: 10px 20px;
  cursor: pointer;
`;

const TitleFlavour = styled.div`
  font-size: 15px;
  font-weight: 350;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  margin: 15px 30px 10px 15px;
`;

const Text = styled.div`
  font-size: 20px;
  font-weight: 325;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  margin-right: 20px;
`;

const Input = styled.input`
  background: #f6f6f6;
  border-radius: 30px;
  width: 205.16px;
  height: 40px;
  margin: 10px 10px;
  outline: none;
  padding: 10px;
`;

const InputLarge = styled.textarea`
  background: #f6f6f6;
  border-radius: 30px;
  width: 771px;
  height: 135px;
  margin: 10px 0;
  outline: none;
  padding: 10px 20px;
  @media (max-width: 767px) {
    width: 30vh;
  }
`;

const Box = styled.div`
  width: 147px;
  height: 123px;
  top: 545px;
  left: 569px;
  border-radius: 15px;
  background: #f6f6f6;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-right: 20px;
  padding: 33px;
`;

interface TableProps {
  setOpen: (value: boolean) => void;
  setPage: (value: any) => void;
  setDetailOpen: (value: boolean) => void;
  tableIdCup: number;
  getSamplesFunction: () => void;
}

export function DetailCupping({
  setOpen,
  setPage,
  setDetailOpen,
  tableIdCup,
  getSamplesFunction,
}: TableProps) {
  const [items, setItems] = useState<any[]>([]);
  const [coffeeSelected, setCoffeeSelected] = useState<string>('');
  const [nameSelected, setNameSelected] = useState('');
  const [list, setList] = useState();
  const [flavours, SetFlavours] = useState<any[]>([]);
  const [overall, SetOverall] = useState<any>();
  const [body, SetBody] = useState<any[]>([]);
  const [alternatives, SetAlternative] = useState<any>();
  const [defects, SetDefects] = useState<any>();
  const [roast, SetRoast] = useState<any>();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState<AlertColor | undefined>(
    undefined,
  );
  const [selected, setSelected] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [value, setValue] = useState<number>(0);
  const [id, setId] = useState('');
  const [sampleAtlas, setSampleAtlas] = useState('');
  const [type, setType] = useState('Confirm');
  const [rejectedOptions, setRejectedOptions] = useState([] as Array<any>);
  const [reason_option_id, setReasonID] = useState<number>(0);

  const { t } = useTranslation();

  const save = () => {
    const payload = {
      resultCupping: Object.keys(selected)
        .map(sampleId => {
          const sampleData = selected[sampleId];

          if (sampleData && Object.keys(sampleData).length !== 0) {
            if (
              (Array.isArray(sampleData.flavours) &&
                sampleData.flavours.length < 3) ||
              !sampleData.flavours
            ) {
              setAlertMessage(t('general.three_flavours'));
              setAlertSeverity('error');
              setAlertOpen(true);
              return null;
            }
            if (!sampleData.overall) {
              setAlertMessage(t('cupping.error_overral'));
              setAlertSeverity('error');
              setAlertOpen(true);
              return null;
            }
            if (!sampleData.body) {
              setAlertMessage(t('cupping.error_body'));
              setAlertSeverity('error');
              setAlertOpen(true);
              return null;
            }
            if (!sampleData.score) {
              setAlertMessage(t('cupping.error_score'));
              setAlertSeverity('error');
              setAlertOpen(true);
              return null;
            }

            return {
              table_id: tableIdCup,
              sample: sampleId,
              alternatives: Array.isArray(sampleData.alternatives)
                ? sampleData.alternatives.join('; ')
                : sampleData.alternatives || '',
              body: Array.isArray(sampleData.body)
                ? sampleData.body.join('; ')
                : sampleData.body || '',
              defects: Array.isArray(sampleData.defects)
                ? sampleData.defects.join('; ')
                : sampleData.defects || '',
              flavours: Array.isArray(sampleData.flavours)
                ? sampleData.flavours.join('; ')
                : sampleData.flavours || '',
              overall: Array.isArray(sampleData.overall)
                ? sampleData.overall.join('; ')
                : sampleData.overall || '',
              roast: Array.isArray(sampleData.roast)
                ? sampleData.roast.join('; ')
                : sampleData.roast || '',
              score: sampleData.score || '',
              observations: sampleData.observations || '',
              accepted_rejected:
                sampleData.accepted_rejected !== undefined
                  ? sampleData.accepted_rejected
                  : '',
              id_rejected_reason: reason_option_id || '',
              sent_atlas: 0, //0 no se ha enviado a atlas
            };
          }
          setAlertMessage(t('cupping.error_empty'));
          setAlertSeverity('error');
          setAlertOpen(true);
          return null;
        })
        .filter(item => item !== null),
    };

    const validCuppings = payload.resultCupping.filter(
      cup => cup && cup.score != '',
    );

    if (validCuppings.length > 0) {
      saveCupping({ resultCupping: validCuppings }) // Aquí guardas solo los válidos
        .then(response => {
          if (response.status === 200) {
            setAlertMessage(t('cupping.cupping_save'));
            setAlertSeverity('success');
            setAlertOpen(true);
          }
        })
        .catch(error => {
          setAlertMessage(t('cupping.error_save'));
          setAlertSeverity('error');
          setAlertOpen(true);
        });
    }
  };

  const backPage = () => {
    setDetailOpen(false);
    setOpen(false);
    setPage(3);
  };

  const handleScoreChange = e => {
    const score = e.target.value;
    setSelected(prevSelected => ({
      ...prevSelected,
      [coffeeSelected]: {
        ...prevSelected[coffeeSelected],
        score,
      },
    }));
  };

  const handleObservationsChange = e => {
    const observations = e.target.value;
    setSelected(prevSelected => ({
      ...prevSelected,
      [coffeeSelected]: {
        ...prevSelected[coffeeSelected],
        observations,
      },
    }));
  };

  const resultOptionsCup = sampleId => {
    getResultOptionsCup(sampleId)
      .then(response => {
        if (response.status === 200) {
          if (selected[sampleId]) {
            if (response.data.flavours !== undefined) {
              selected[sampleId]['flavours'] = response.data.flavours;
            }
            if (response.data.overall !== undefined) {
              selected[sampleId]['overall'] = response.data.overall;
            }
            if (response.data.body !== undefined) {
              selected[sampleId]['body'] = response.data.body;
            }
            if (response.data.alternatives !== undefined) {
              selected[sampleId]['alternatives'] = response.data.alternatives;
            }
            if (response.data.defects !== undefined) {
              selected[sampleId]['defects'] = response.data.defects;
            }
            if (response.data.roast !== undefined) {
              selected[sampleId]['roast'] = response.data.roast;
            }
            if (response.data.score !== undefined) {
              selected[sampleId]['score'] = response.data.score;
            }
            if (response.data.observations !== undefined) {
              selected[sampleId]['observations'] = response.data.observations;
            }
            if (response.data.accepted_rejected !== undefined) {
              selected[sampleId]['accepted_rejected'] =
                response.data.accepted_rejected;
            }
            if (response.data.id_rejected_reason !== undefined) {
              setReasonID(response.data.id_rejected_reason);
            }
          }
          setCoffeeSelected(sampleId);
        }
      })
      .catch(error => {
        console.log(error);
      });

    getInfoSample(sampleId)
      .then(response => {
        if (response.status === 200) {
          setId(response.data.id);
          setSampleAtlas(response.data.sample_id_atlas);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  useEffect(() => {
    rejectedReasons()
      .then(response => {
        if (response.status === 200) {
          setRejectedOptions(response.data);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }, []);
  const defaultOption = rejectedOptions.find(
    option => option.id === reason_option_id,
  );

  const handleResult =
    (resultCup: number) =>
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setSelected(prevSelected => ({
        ...prevSelected,
        [coffeeSelected]: {
          ...prevSelected[coffeeSelected],
          accepted_rejected: resultCup,
        },
      }));
      if (resultCup === 0) {
        rejectedReasons()
          .then(response => {
            if (response.status === 200) {
              setRejectedOptions(response.data);
            }
          })
          .catch(error => {
            console.log(error);
          });
      }
    };

  const handleClick = (itemName: string, category: string) => {
    setSelected(prevSelected => {
      const currentCoffeeSelected = prevSelected[coffeeSelected] || {};

      const singleSelectionCategories = ['overall', 'roast'];

      let updatedCategoryItems;

      if (singleSelectionCategories.includes(category)) {
        updatedCategoryItems = [itemName];
      } else {
        let categoryItems = currentCoffeeSelected[category] || [];
        if (typeof categoryItems === 'string') {
          categoryItems = categoryItems.split('; ');
        }

        if (categoryItems.includes(itemName)) {
          updatedCategoryItems = categoryItems.filter(
            item => item !== itemName,
          );
        } else {
          updatedCategoryItems = [...categoryItems, itemName];
        }
      }

      return {
        ...prevSelected,
        [coffeeSelected]: {
          ...currentCoffeeSelected,
          [category]: updatedCategoryItems,
        },
      };
    });
  };

  useEffect(() => {
    getSamplesCup(tableIdCup)
      .then(response => {
        if (response.status === 200) {
          response.data.forEach(sample => {
            if (sample.sample_type == 'sample_pre_shipment') {
              sample.sample_type = 'PSS Sample';
            }
            if (sample.sample_type == 'sample_offer') {
              sample.sample_type = 'Offer Sample';
            }
            if (sample.sample_type == 'sample_arrival') {
              sample.sample_type = 'Arrival Sample';
            }
          });
          setItems(response.data);
          response.data.forEach(sample => {
            selected[sample.sample_id] = {};
          });
        }
      })
      .catch(error => {
        console.log(error);
      });

    getListItemsEvaluation()
      .then(response => {
        if (response.status === 200) {
          setList(response.data);
          SetFlavours(response.data[0].cupping_evaluation_detail);
          SetOverall(response.data[1].cupping_evaluation_detail);
          SetBody(response.data[2].cupping_evaluation_detail);
          SetAlternative(response.data[3].cupping_evaluation_detail);
          SetDefects(response.data[4].cupping_evaluation_detail);
          SetRoast(response.data[5].cupping_evaluation_detail);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  const handleReasonChange = (e: any) => {
    setReasonID(e.value);
  };

  const colorsByGroup = {
    1: '#ffeff7',
    2: '#DACFC0',
    3: '#FFFADD',
    4: '#DFE5BA',
    5: '#FFF6BF',
  };
  const colorsByGroupBody = {
    1: '#FEFEFA',
    2: '#DEE0DD',
    3: '#D6CDC5',
  };

  const isMobile = useMediaQuery('(max-width:767px)');

  const initInputRef = useRef<HTMLInputElement | null>(null);

  return (
    <>
      {openModal === true ? (
        <AcceptRejectValueModal
          setOpen={setOpenModal}
          type={type}
          id={id}
          value={value}
          sample_id_atlas={sampleAtlas}
          getCoffeesWithFiltersFirstCall={getSamplesFunction}
        />
      ) : null}
      <TitleContainer
        style={{
          flexDirection: isMobile ? 'column' : 'row',
        }}
      >
        <Title>
          {t(translations.cupping.cupping_table)} {tableIdCup}
        </Title>
        <div
          style={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button
            style={{ marginTop: '35px', background: '#B2BA86' }}
            type="button"
            onClick={save}
            value={t(translations.general.save)}
          />
          <Button
            style={{ marginTop: '35px', background: '#4F4E24' }}
            type="button"
            onClick={backPage}
            value={t(translations.general.back)}
          />
        </div>
      </TitleContainer>
      <CoffeesContainer>
        {items.map(item => (
          <Coffee
            key={item.sample_id}
            style={{
              background:
                coffeeSelected === item.sample_id ? '#4F4E24' : '#DFDCCA',
              color: coffeeSelected === item.sample_id ? 'white' : 'black',
            }}
            onClick={() => resultOptionsCup(item.sample_id)}
          >
            {item.sample_type} - {item.contract_id}
          </Coffee>
        ))}
      </CoffeesContainer>
      {coffeeSelected && (
        <DetailContainer>
          <NamesContainer>
            <Names
              style={{
                background: nameSelected === 'Flavour' ? '#4F4E24' : '#DFDCCA',
                color: nameSelected === 'Flavour' ? 'white' : 'black',
                marginTop: '20px',
                verticalAlign: 'super',
              }}
              onClick={() => setNameSelected('Flavour')}
            >
              {t(translations.cupping.flavours)} *
            </Names>
            <Names
              style={{
                background: nameSelected === 'Overall' ? '#4F4E24' : '#DFDCCA',
                color: nameSelected === 'Overall' ? 'white' : 'black',
                verticalAlign: 'super',
              }}
              onClick={() => setNameSelected('Overall')}
            >
              {t(translations.cupping.overall)} *
            </Names>
            <Names
              style={{
                background: nameSelected === 'Body' ? '#4F4E24' : '#DFDCCA',
                color: nameSelected === 'Body' ? 'white' : 'black',
                verticalAlign: 'super',
              }}
              onClick={() => setNameSelected('Body')}
            >
              {t(translations.cupping.body)} *
            </Names>
            <Names
              style={{
                background:
                  nameSelected === 'Alternatives' ? '#4F4E24' : '#DFDCCA',
                color: nameSelected === 'Alternatives' ? 'white' : 'black',
              }}
              onClick={() => setNameSelected('Alternatives')}
            >
              {t(translations.cupping.alternatives)}
            </Names>
            <Names
              style={{
                background: nameSelected === 'Defects' ? '#4F4E24' : '#DFDCCA',
                color: nameSelected === 'Defects' ? 'white' : 'black',
              }}
              onClick={() => setNameSelected('Defects')}
            >
              {t(translations.cupping.defects)}
            </Names>
            <Names
              style={{
                background: nameSelected === 'Roast' ? '#4F4E24' : '#DFDCCA',
                color: nameSelected === 'Roast' ? 'white' : 'black',
              }}
              onClick={() => setNameSelected('Roast')}
            >
              {t(translations.cupping.roast)}
            </Names>
            <Names
              style={{
                background: nameSelected === 'Score' ? '#4F4E24' : '#DFDCCA',
                color: nameSelected === 'Score' ? 'white' : 'black',
              }}
              onClick={() => setNameSelected('Score')}
            >
              {t(translations.cupping.score)}
            </Names>
          </NamesContainer>
          <ContentContainer>
            {nameSelected === 'Flavour' && flavours && (
              <div>
                {flavours && (
                  <TitleFlavour>
                    {t(translations.general.three_flavours)}
                  </TitleFlavour>
                )}

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                  }}
                >
                  {/* Creamos un bloque para cada color asociados en colorsByGroup */}
                  {[1, 2, 3, 4, 5].map(groupId => (
                    <div
                      key={groupId}
                      style={{
                        backgroundColor: colorsByGroup[groupId],
                        borderRadius: '15px',
                        margin: '10px 20px',
                        padding: '20px',
                        width: '249px',
                        display: 'flex',
                        flexDirection: 'column',
                        height: 'auto',
                      }}
                    >
                      {flavours &&
                        flavours
                          .filter(item => item.GroupId == groupId)
                          .map((item, index) => (
                            <div
                              key={index}
                              style={{
                                margin: '5px',
                                width: '100%',
                                boxSizing: 'border-box',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <input
                                  checked={
                                    selected[coffeeSelected]?.[
                                      'flavours'
                                    ]?.includes(item.name) || false
                                  }
                                  type="checkbox"
                                  onChange={() =>
                                    handleClick(item.name, 'flavours')
                                  }
                                  style={{
                                    marginRight: '5px',
                                    height: '15px',
                                    width: '15px',
                                  }}
                                />
                                {translations.cupping.flavoursOptions[
                                  item.name
                                ] && (
                                  <span>
                                    {t(`cupping.flavoursOptions.${item.name}`)}
                                  </span>
                                )}
                              </div>
                            </div>
                          ))}
                    </div>
                  ))}
                </div>
              </div>
            )}

            {nameSelected === 'Overall' && overall && overall.length > 0 && (
              <div
                style={{
                  margin: '20px 0 0 20px',
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                  cursor: 'pointer',
                }}
              >
                {overall &&
                  overall.map(item => (
                    <Box
                      onClick={() => handleClick(item.name, 'overall')}
                      key={item.id}
                      style={{
                        background: selected[coffeeSelected]?.[
                          'overall'
                        ]?.includes(item.name)
                          ? 'black'
                          : '#ebeaea',
                        color: selected[coffeeSelected]?.['overall']?.includes(
                          item.name,
                        )
                          ? 'white'
                          : 'black',
                        flex: '0 0 20%',
                        margin: '5px',
                        textAlign: 'center',
                      }}
                    >
                      {translations.cupping.overallOptions[item.name] && (
                        <span>{t(`cupping.overallOptions.${item.name}`)}</span>
                      )}
                    </Box>
                  ))}
              </div>
            )}
            {nameSelected === 'Body' && body && (
              <div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                  }}
                >
                  {[1, 2, 3].map(groupId => (
                    <div
                      key={groupId}
                      style={{
                        backgroundColor: colorsByGroupBody[groupId],
                        borderRadius: '15px',
                        margin: '10px 20px',
                        padding: '20px',
                        width: '249px',
                        display: 'flex',
                        flexDirection: 'column',
                        height: 'auto',
                      }}
                    >
                      {body &&
                        body
                          .filter(item => item.GroupId == groupId)
                          .map((item, index) => (
                            <div
                              key={index}
                              style={{
                                margin: '5px',
                                width: '100%',
                                boxSizing: 'border-box',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <input
                                  checked={
                                    selected[coffeeSelected]?.[
                                      'body'
                                    ]?.includes(item.name) || false
                                  }
                                  onChange={() =>
                                    handleClick(item.name, 'body')
                                  }
                                  type="radio"
                                  name="body"
                                  style={{
                                    marginRight: '5px',
                                    height: '15px',
                                    width: '15px',
                                  }}
                                />
                                <span>
                                  {groupId === 1
                                    ? 'Light: '
                                    : groupId === 2
                                    ? 'Medium: '
                                    : groupId === 3
                                    ? 'Heavy: '
                                    : ''}
                                  {translations.cupping.bodyOptions[
                                    item.name
                                  ] && (
                                    <span>
                                      {t(`cupping.bodyOptions.${item.name}`)}
                                    </span>
                                  )}
                                </span>
                              </div>
                            </div>
                          ))}
                    </div>
                  ))}
                </div>
              </div>
            )}

            {nameSelected === 'Alternatives' && (
              <div>
                <div
                  style={{
                    backgroundColor: '#F8F8F8',
                    borderRadius: '15px',
                    margin: '20px 0',
                    marginLeft: '20px',
                    padding: '20px 20px',
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                  }}
                >
                  {alternatives &&
                    alternatives.map((item, index) => (
                      <div
                        style={{
                          margin: '5px',
                          width: '24%',
                          boxSizing: 'border-box',
                        }}
                        key={index}
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <input
                            checked={
                              selected[coffeeSelected]?.[
                                'alternatives'
                              ]?.includes(item.name) || false
                            }
                            type="checkbox"
                            onChange={() =>
                              handleClick(item.name, 'alternatives')
                            }
                            style={{
                              marginRight: '5px',
                              height: '15px',
                              width: '15px',
                            }}
                          />
                          {translations.cupping.alternativesOptions[
                            item.name
                          ] && (
                            <span>
                              {t(`cupping.alternativesOptions.${item.name}`)}
                            </span>
                          )}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            )}

            {nameSelected === 'Defects' && (
              <div>
                <div
                  style={{
                    backgroundColor: '#F8F8F8',
                    borderRadius: '15px',
                    margin: '20px 0',
                    marginLeft: '20px',
                    padding: '20px 20px',
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                  }}
                >
                  {defects &&
                    defects.map((item, index) => (
                      <div
                        style={{
                          margin: '5px',
                          width: '20%',
                          boxSizing: 'border-box',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                        key={index}
                      >
                        <input
                          checked={
                            selected[coffeeSelected]?.['defects']?.includes(
                              item.name,
                            ) || false
                          }
                          type="checkbox"
                          onChange={() => handleClick(item.name, 'defects')}
                          style={{
                            marginRight: '5px',
                            height: '15px',
                            width: '15px',
                          }}
                        />
                        {translations.cupping.defectsOptions[item.name] && (
                          <span>
                            {t(`cupping.defectsOptions.${item.name}`)}
                          </span>
                        )}
                      </div>
                    ))}
                </div>
              </div>
            )}

            {nameSelected === 'Roast' && (
              <div
                style={{
                  margin: '20px 0 0 20px',
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'center', // Centrar los elementos horizontalmente
                  cursor: 'pointer',
                }}
              >
                {roast &&
                  roast.map(item => (
                    <Box
                      onClick={() => handleClick(item.name, 'roast')}
                      key={item.id}
                      style={{
                        marginTop: '20px',
                        background: selected[coffeeSelected]?.[
                          'roast'
                        ]?.includes(item.name)
                          ? 'black'
                          : '#ebeaea',
                        color: selected[coffeeSelected]?.['roast']?.includes(
                          item.name,
                        )
                          ? 'white'
                          : 'black',
                        flex: '0 0 20%',
                        margin: '5px',
                        textAlign: 'center',
                      }}
                    >
                      {translations.cupping.roastOptions[item.name] && (
                        <span>{t(`cupping.roastOptions.${item.name}`)}</span>
                      )}
                    </Box>
                  ))}
              </div>
            )}

            {nameSelected === 'Score' && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    margin: '40px 0 0 40px',
                    alignItems: 'center',
                    width: '90%',
                    maxWidth: '800px',
                  }}
                >
                  <Text>{t(translations.general.final_score)}</Text>
                  <Input
                    type="number"
                    value={selected[coffeeSelected]?.['score'] || ''}
                    onChange={handleScoreChange}
                    style={{ marginRight: '20px', width: '80px' }}
                  />

                  <Checkbox
                    checked={
                      selected[coffeeSelected]?.['accepted_rejected'] === 1 ||
                      false
                    }
                    onChange={handleResult(1)}
                    sx={{
                      '&.Mui-checked': {
                        color: '#B2BA86',
                      },
                      '&.Mui-checked:hover': {
                        backgroundColor: 'rgba(76, 175, 80, 0.1)',
                      },
                    }}
                  />
                  <Text style={{ marginLeft: '20px' }}>
                    {t(translations.general.accept)}
                  </Text>

                  <Checkbox
                    checked={
                      selected[coffeeSelected]?.['accepted_rejected'] === 0 ||
                      false
                    }
                    onChange={handleResult(0)}
                    sx={{
                      '&.Mui-checked': {
                        color: '#D98458',
                      },
                      '&.Mui-checked:hover': {
                        backgroundColor: 'rgba(76, 175, 80, 0.1)',
                      },
                    }}
                  />
                  <Text style={{ marginLeft: '20px' }}>
                    {t(translations.general.reject)}
                  </Text>
                </div>

                {selected[coffeeSelected]?.['accepted_rejected'] === 0 && (
                  <div
                    style={{
                      width: '90%',
                      maxWidth: '800px',
                      margin: '20px 0',
                    }}
                  >
                    <Select
                      isSearchable={false}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          background: '#F6F6F6',
                          borderRadius: '30px',
                          width: '100%',
                          height: '50px',
                        }),
                      }}
                      options={rejectedOptions.map(option => ({
                        label: option.reason,
                        value: option.id,
                      }))}
                      defaultValue={
                        defaultOption
                          ? {
                              label: defaultOption.reason,
                              value: defaultOption.id,
                            }
                          : null
                      }
                      onChange={e => handleReasonChange(e)}
                    />
                  </div>
                )}

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    margin: '40px 0 0 40px',
                    alignItems: 'center',
                    width: '90%',
                    maxWidth: '800px',
                  }}
                >
                  <Text>{t(translations.general.observations)}</Text>
                  <InputLarge
                    value={selected[coffeeSelected]?.['observations'] || ''}
                    onChange={handleObservationsChange}
                    style={{ width: '100%' }}
                  />
                </div>
              </div>
            )}
          </ContentContainer>
        </DetailContainer>
      )}
      <Snackbar
        open={alertOpen}
        autoHideDuration={4000}
        onClose={() => setAlertOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setAlertOpen(false)}
          severity={alertSeverity}
          sx={{
            width: '100%',
            fontSize: '1.5rem',
            fontWeight: 'bold',
            padding: '20px',
            maxWidth: '600px',
            margin: '0 auto',
          }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
