import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Switch from '@mui/material/Switch';
import styledComponent from 'styled-components/macro';
import styled from 'styled-components/macro';
import { alpha, ThemeProvider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Dropdown from 'app/components/Dropdown';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

const StyledHeadCell = styled(TableCell)`
  font-style: normal;
  font-weight: 325;
  font-size: 13px;
  line-height: 16px;
  border-radius: 35px;
  border: 1px solid white;
  ${props =>
    props.color &&
    `
    color: white; `}
  background: #EBEAEA;
  background: ${props => props.color};
`;

const StyledHeadCellInfo = styled(TableCell)`
  font-style: normal;
  background: #4f4e24;
  color: white !important;
  font-weight: 325;
  font-size: 13px;
  line-height: 16px;
  border-radius: 35px;
  border: 1px solid white;
  ${props =>
    props.color === 'Info' &&
    `
    background: #4F4E24; 
    color: white;
  `}
`;

const StyledTableCell = styled(TableCell)`
  align-items: center;
  border-radius: 35px;
  font-style: normal;
  font-weight: 325;
  font-size: 13px;
  line-height: 16px;
  background: #f8f8f8;
  border: 1px solid white;
  display: table-cell;
`;

const Add = styledComponent.div`
${props =>
  props.color === 'Add' &&
  `
  background: #4F4E24; 
  color: white;
`}
  ${props =>
    props.color === 'Remove' &&
    `
  background: #F33636; 
  color: white;`}
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  height: 33px;
  width: 55px;
  cursor: pointer;
`;

const GreenSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: 'white',
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#2CCC7F',
  },
}));

interface AdmintableProps {
  rolesValues: any;
  handleSubmit: () => void;
  data: any;
  changeInfo: (value: any) => void;
  setChangedUser: (value: any) => void;
  changedUser: any[];
  role: any;
}

export function AdminTable({
  rolesValues,
  handleSubmit,
  data,
  changeInfo,
  setChangedUser,
  changedUser,
  role,
}: AdmintableProps) {
  const theme = useTheme();

  const { t } = useTranslation();

  const [sortOrder, setSortOrder] = useState('asc');
  const [sortedData, setSortedData] = useState<any>([]);
  const [activeSortColumn, setActiveSortColumn] = useState<any>(null);
  const [isColumnClicked, setIsColumnClicked] = useState('');

  let dataToMap;
  if (sortedData.length > 0) {
    dataToMap = sortedData;
  } else {
    dataToMap = data.data;
  }

  const sortData = columnName => {
    const sortOrderModifier = sortOrder === 'asc' ? 1 : -1;

    const sorted = [...(data.data || [])].sort((a, b) => {
      return sortOrderModifier * a[columnName].localeCompare(b[columnName]);
    });

    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    setSortedData(sorted);
    setActiveSortColumn(columnName); // Set the active sorting column
    setIsColumnClicked(columnName);
  };

  const changeStatus = (e, id, role) => {
    const statusValue = e.target.checked === true ? '1' : '0';
    if (changedUser.length > 0) {
      const valueToDelete = changedUser.filter(value => value.id === id);
      const clear = changedUser.filter(value => value.id !== id);
      const newDArray: any[] = [
        ...clear,
        {
          id: id,
          status: statusValue,
          role: valueToDelete.length > 0 ? valueToDelete[0].role : role,
        },
      ];
      setChangedUser(newDArray);
    } else {
      const newDArray: any[] = [{ id: id, status: statusValue, role: role }];
      setChangedUser(newDArray);
    }
  };

  const changeRole = (stats, id, e) => {
    const roleValue = e.value;
    if (changedUser.length > 0) {
      const value = changedUser.map(value =>
        value.id === id ? value.status : null,
      );
      const clear = changedUser.filter(value => value.id !== id);
      const newDArray: any[] = [
        ...clear,
        {
          id: id,
          status: value[0] !== null ? value[0] : stats,

          role: roleValue,
        },
      ];
      setChangedUser(newDArray);
    } else {
      const newDArray: any[] = [{ id: id, status: stats, role: roleValue }];
      setChangedUser(newDArray);
    }
  };

  let hash = {};

  const navigate = useNavigate();

  const profile = (id: string) => {
    navigate(`/admin/admin-profile/${id}`);
  };

  return (
    <ThemeProvider theme={theme}>
      <>
        {dataToMap && (
          <TableContainer>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  {/* <StyledHeadCell>Username</StyledHeadCell> */}
                  <StyledHeadCell
                    style={{ cursor: 'pointer' }}
                    align="center"
                    onClick={() => sortData('name_customer')}
                    onMouseEnter={() => setActiveSortColumn('name_customer')}
                    onMouseLeave={() => {
                      if (!isColumnClicked) {
                        setActiveSortColumn(null);
                      }
                    }}
                  >
                    Username{' '}
                    {(activeSortColumn === 'name_customer' ||
                      isColumnClicked === 'name_customer') && (
                      <span style={{ fontSize: '20px' }}>
                        {sortOrder === 'asc' ? '↑' : '↓'}
                      </span>
                    )}
                  </StyledHeadCell>
                  <StyledHeadCell align="center">Email</StyledHeadCell>
                  {/* <StyledHeadCell align="center">Account ID</StyledHeadCell> */}
                  <StyledHeadCell
                    style={{ cursor: 'pointer' }}
                    align="center"
                    onClick={() => sortData('accountant_id')}
                    onMouseEnter={() => setActiveSortColumn('accountant_id')}
                    onMouseLeave={() => {
                      if (!isColumnClicked) {
                        setActiveSortColumn(null);
                      }
                    }}
                  >
                    Account ID{' '}
                    {(activeSortColumn === 'accountant_id' ||
                      isColumnClicked === 'accountant_id') && (
                      <span style={{ fontSize: '20px' }}>
                        {sortOrder === 'asc' ? '↑' : '↓'}
                      </span>
                    )}
                  </StyledHeadCell>
                  <StyledHeadCell align="center">Status</StyledHeadCell>
                  <StyledHeadCell align="center">Roles</StyledHeadCell>
                  {/* {role !== '' && !role.includes('Sales') && ( */}
                  <StyledHeadCellInfo color="Info" align="center">
                    Info
                  </StyledHeadCellInfo>
                  {/* )} */}
                </TableRow>
              </TableHead>
              <TableBody>
                {dataToMap.map(values => (
                  <TableRow key={values.id_user}>
                    <StyledTableCell align="center">
                      {values.name_customer}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {values.email}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {values.accountant_id}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <GreenSwitch
                        defaultChecked={
                          values.active_user === '0' ? false : true
                        }
                        onChange={e =>
                          changeStatus(e, values.id_user, values.role_name)
                        }
                      />
                    </StyledTableCell>
                    {role !== '' && !role.includes('Sales') && (
                      <StyledTableCell align="center">
                        <div
                          style={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <Dropdown
                            noMargin={true}
                            handleChange={e =>
                              changeRole(values.active_user, values.id_user, e)
                            }
                            list={rolesValues}
                            defaultValue={values.role_name}
                          />
                        </div>
                      </StyledTableCell>
                    )}
                    {role !== '' && role.includes('Sales') && (
                      <StyledTableCell align="center">
                        <div
                          style={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <Dropdown
                            noMargin={true}
                            handleChange={e =>
                              changeRole(values.active_user, values.id_user, e)
                            }
                            list={
                              rolesValues &&
                              rolesValues.filter(
                                item =>
                                  item.value === 'Customer' ||
                                  item.value === 'Spot',
                              )
                            }
                            defaultValue={values.role_name}
                          />
                        </div>
                      </StyledTableCell>
                    )}
                    <StyledTableCell align="center">
                      <div
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <Add
                          color="Add"
                          id={`add-1`}
                          onClick={() => profile(values.id_user)}
                        >
                          {t(translations.general.edit)}
                        </Add>
                      </div>
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </>
    </ThemeProvider>
  );
}
