import * as React from 'react';
import { P, Wrapper, Title } from './styles';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';

export function NotFoundPage() {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>404 Page Not Found</title>
        <meta name="description" content="Page not found" />
      </Helmet>
      <Wrapper>
        <Title>
          4
          <span role="img" aria-label="Crying Face">
            😢
          </span>
          4
        </Title>
        <P>
          {t(translations.general.page_not_found)}
          <br />
          <Link to="/">{t(translations.general.go_back)}</Link>
        </P>
      </Wrapper>
    </>
  );
}
