import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import styledComponent from 'styled-components/macro';
import styled from 'styled-components/macro';
import { ThemeProvider, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import drop from '../../assets/SVG/dropdown.svg';
import { useState } from 'react';
import ModalComponent from '../ModalComponent';

const StyledHeadCell = styled(TableCell)`
  font-style: normal;
  font-weight: 325;
  font-size: 13px;
  line-height: 16px;
  border: 1px solid white;
  color: black;
  background: #dfdccb;
`;

const StyledTableRow = styled(TableRow)`
  border-bottom: 3px solid white;
  border-top: 3px solid white;
  th:first-child {
    border-radius: 35px 0px 0px 35px;
  }
  th:last-child {
    border-radius: 0px 35px 35px 0px;
  }
  td:first-child {
    border-radius: 35px 0px 0px 35px;
  }
  td:last-child {
    border-radius: 0px 35px 35px 0px;
  }
`;

const StyledTableCell = styled(TableCell)`
  align-items: center;

  font-style: normal;
  font-weight: 325;
  font-size: 13px;
  line-height: 16px;
  border: 1px solid white;
  display: table-cell;
  overflow: auto;
  background: ${props =>
    props.color === 'Paid'
      ? '#33BA79'
      : props.color === 'Pending'
      ? '#5C36F3'
      : props.color === 'Late'
      ? '#F5515D'
      : '#f8f8f8'};
  ${props =>
    props.color &&
    `
        color: white; `}
`;

const StyledTableCellMobile = styled(TableCell)`
  align-items: center;

  font-style: normal;
  font-weight: 325;
  font-size: 13px;
  line-height: 16px;
  background: #f8f8f8;
  // border: 1px solid white;
  width: calc(20% - 1px);
  box-sizing: border-box;
`;

const StyledHeadCellMobile = styled(TableCell)`
  font-style: normal;
  font-weight: 325;
  font-size: 13px;
  line-height: 16px;
  // border: 1px solid white;
  background: #ebeaea;
  background: ${props => props.color};
  width: calc(20% - 1px);
  box-sizing: border-box;
`;

const ContainerHeader = styled.div`
  display: flex;
  width: 100%;
  background: #ebeaea;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const ContainerBody = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  background: #f8f8f8;
`;

interface TableProps {
  historyInvoices?: any[];
  page?: boolean;
  setHistoryInvoices?: (value: any) => void;
  setActualPageInvoices: (value: any) => void;
  setPagesInvoices: (value: any) => void;
  pages?: any;
  actualPageInvoices?: any;
  setOrigin: (value: any) => void;
  handleChangeInvoices: (event: any, value: any) => void;
}

export function HistoryITable({
  historyInvoices,
  page,
  setHistoryInvoices,
  setActualPageInvoices,
  setPagesInvoices,
  pages,
  actualPageInvoices,
  setOrigin,
  handleChangeInvoices,
}: TableProps) {
  const theme = useTheme();
  const { t } = useTranslation();

  const [info, setInfo] = useState<any[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortedData, setSortedData] = useState(
    (historyInvoices &&
      historyInvoices.sort((a, b) => {
        const statusOrder = {
          Late: 0,
          Pending: 1,
          Paid: 2,
        };

        const statusComparison = statusOrder[a.status] - statusOrder[b.status];
        if (statusComparison !== 0) {
          return statusComparison;
        }

        return a.date.localeCompare(b.date);
      })) ||
      [],
  );
  const [activeSortColumn, setActiveSortColumn] = useState<any>(null);
  const [isColumnClicked, setIsColumnClicked] = useState('');

  // const sortData = columnName => {
  //   const sortOrderModifier = sortOrder === 'asc' ? 1 : -1;

  //   const sorted = [...(sortedData || [])].sort((a, b) => {
  //     // Assuming your date format is sortable as a string
  //     return sortOrderModifier * a[columnName].localeCompare(b[columnName]);
  //   });

  //   setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  //   setSortedData(sorted);
  // };

  const sortData = columnName => {
    const sortOrderModifier = sortOrder === 'asc' ? 1 : -1;

    const sorted = [...(historyInvoices || [])].sort((a, b) => {
      return sortOrderModifier * a[columnName].localeCompare(b[columnName]);
    });

    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    setSortedData(sorted);
    setActiveSortColumn(columnName); // Set the active sorting column
    setIsColumnClicked(columnName);
  };

  const isMobile = useMediaQuery('(max-width:767px)');

  const openModalWithInfo = info => {
    setModalOpen(true);
    setInfo(info);
  };

  const opciones = {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  return historyInvoices ? (
    <ThemeProvider theme={theme}>
      <div className="d-flex justify-content-center mt-5 mb-5">
        <Stack spacing={2}>
          <Pagination
            count={pages}
            page={actualPageInvoices}
            onChange={handleChangeInvoices}
          />
        </Stack>
      </div>
      {!isMobile ? (
        <>
          <TableContainer>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <StyledTableRow>
                  {/* <StyledHeadCell
                    style={{ color: 'white', cursor: 'pointer' }}
                    align="center"
                    onClick={() => sortData('date')}
                    onMouseEnter={() => setActiveSortColumn('date')}
                    onMouseLeave={() => {
                      if (!isColumnClicked) {
                        setActiveSortColumn(null);
                      }
                    }}
                  >
                    {t(translations.table.date)}{' '}
                    {(activeSortColumn === 'date' || isColumnClicked) && (
                      <span style={{ fontSize: '20px' }}>
                        {sortOrder === 'asc' ? '↑' : '↓'}
                      </span>
                    )}
                  </StyledHeadCell> */}
                  <StyledHeadCell
                    style={{ color: 'white', cursor: 'pointer' }}
                    align="center"
                    onClick={() => sortData('date')}
                    onMouseEnter={() => setActiveSortColumn('date')}
                    onMouseLeave={() => {
                      if (!isColumnClicked) {
                        setActiveSortColumn(null);
                      }
                    }}
                  >
                    {t(translations.table.date)}{' '}
                    {(activeSortColumn === 'date' ||
                      isColumnClicked === 'date') && (
                      <span style={{ fontSize: '20px' }}>
                        {sortOrder === 'asc' ? '↑' : '↓'}
                      </span>
                    )}
                  </StyledHeadCell>
                  {/* <StyledHeadCell style={{ color: 'white' }} align="center">
                    {t(translations.form.ponumber)}
                  </StyledHeadCell> */}
                  <StyledHeadCell
                    style={{ color: 'white', cursor: 'pointer' }}
                    align="center"
                    onClick={() => sortData('po_number')}
                    onMouseEnter={() => setActiveSortColumn('po_number')}
                    onMouseLeave={() => {
                      if (!isColumnClicked) {
                        setActiveSortColumn(null);
                      }
                    }}
                  >
                    {t(translations.form.ponumber)}{' '}
                    {(activeSortColumn === 'po_number' ||
                      isColumnClicked === 'po_number') && (
                      <span style={{ fontSize: '20px' }}>
                        {sortOrder === 'asc' ? '↑' : '↓'}
                      </span>
                    )}
                  </StyledHeadCell>
                  <StyledHeadCell style={{ color: 'white' }} align="center">
                    {t(translations.table.number)}
                  </StyledHeadCell>
                  {/* <StyledHeadCell style={{ color: 'white' }} align="center">
                    {t(translations.table.t_invoice)}
                  </StyledHeadCell> */}
                  <StyledHeadCell
                    style={{ color: 'white', cursor: 'pointer' }}
                    align="center"
                    onClick={() => sortData('total_invoice')}
                    onMouseEnter={() => setActiveSortColumn('total_invoice')}
                    onMouseLeave={() => {
                      if (!isColumnClicked) {
                        setActiveSortColumn(null);
                      }
                    }}
                  >
                    {t(translations.table.t_invoice)}{' '}
                    {(activeSortColumn === 'total_invoice' ||
                      isColumnClicked === 'total_invoice') && (
                      <span style={{ fontSize: '20px' }}>
                        {sortOrder === 'asc' ? '↑' : '↓'}
                      </span>
                    )}
                  </StyledHeadCell>
                  <StyledHeadCell style={{ color: 'white' }} align="center">
                    {t(translations.table.due_date)}
                  </StyledHeadCell>
                  <StyledHeadCell style={{ color: 'white' }} align="center">
                    {t(translations.table.doc_type)}
                  </StyledHeadCell>
                  {/* <StyledHeadCell style={{ color: 'white' }} align="center">
                    {t(translations.table.status)}
                  </StyledHeadCell> */}
                  <StyledHeadCell
                    style={{ color: 'white', cursor: 'pointer' }}
                    align="center"
                    onClick={() => sortData('status')}
                    onMouseEnter={() => setActiveSortColumn('status')}
                    onMouseLeave={() => {
                      if (!isColumnClicked) {
                        setActiveSortColumn(null);
                      }
                    }}
                  >
                    {t(translations.table.status)}{' '}
                    {(activeSortColumn === 'status' ||
                      isColumnClicked === 'status') && (
                      <span style={{ fontSize: '20px' }}>
                        {sortOrder === 'asc' ? '↑' : '↓'}
                      </span>
                    )}
                  </StyledHeadCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {sortedData &&
                  sortedData.map(groupBy => (
                    <StyledTableRow key={Math.random()}>
                      <StyledTableCell align="center">
                        {groupBy.date.split(' ')[0]}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {groupBy.po_number}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {groupBy.number}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {new Intl.NumberFormat('en-US', opciones).format(
                          Number(groupBy.total_invoice),
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {groupBy.due_date.split(' ')[0]}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {groupBy.document_type}
                      </StyledTableCell>
                      <StyledTableCell color={groupBy.status} align="center">
                        {groupBy.status}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <>
          <ContainerHeader>
            <StyledHeadCellMobile
              style={{ color: 'black' }}
              align="center"
            ></StyledHeadCellMobile>
            <StyledHeadCellMobile style={{ color: 'black' }} align="center">
              {t(translations.table.status)}
            </StyledHeadCellMobile>
            <StyledHeadCellMobile style={{ color: 'black' }} align="center">
              {t(translations.table.date)}
            </StyledHeadCellMobile>
            <StyledHeadCellMobile style={{ color: 'black' }} align="center">
              {t(translations.form.ponumber)}
            </StyledHeadCellMobile>
            {/* <StyledHeadCellMobile style={{ color: 'white' }} align="center">
              {t(translations.table.number)}
            </StyledHeadCellMobile> */}
            <StyledHeadCellMobile style={{ color: 'black' }} align="center">
              {t(translations.table.t_invoice)}
            </StyledHeadCellMobile>
            {/* <StyledHeadCellMobile style={{ color: 'white' }} align="center">
              {t(translations.table.doc_type)}
            </StyledHeadCellMobile> */}
          </ContainerHeader>

          {historyInvoices.map(groupBy => (
            <ContainerBody key={Math.random()}>
              <StyledTableCellMobile align="center">
                <img
                  src={drop}
                  alt="drop"
                  style={{ width: '22px', height: '22px' }}
                  onClick={() => openModalWithInfo(groupBy)}
                />
              </StyledTableCellMobile>
              <StyledTableCellMobile color={groupBy.status} align="center">
                {groupBy.status}
              </StyledTableCellMobile>
              <StyledTableCellMobile align="center">
                {groupBy.date.split(' ')[0]}
              </StyledTableCellMobile>
              <StyledTableCellMobile align="center">
                {groupBy.po_number}
              </StyledTableCellMobile>
              {/* <StyledTableCellMobile align="center">
                {groupBy.number}
              </StyledTableCellMobile> */}
              <StyledTableCellMobile align="center">
                {new Intl.NumberFormat('en-US', opciones).format(
                  Number(groupBy.total_invoice),
                )}
              </StyledTableCellMobile>
              {/* <StyledTableCellMobile align="center">
                {groupBy.document_type}
              </StyledTableCellMobile> */}
            </ContainerBody>
          ))}
        </>
      )}
      {modalOpen && info && (
        <ModalComponent
          // page={page}
          closeModal={() => setModalOpen(false)} // Función para cerrar el modal
          data={info} // Información de groupBy para mostrar en el modal
          page="historyI"
        />
      )}
    </ThemeProvider>
  ) : null;
}
