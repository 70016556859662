import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import styledComponent from 'styled-components/macro';
import styled, { useTheme } from 'styled-components/macro';
import { ThemeProvider } from '@mui/material';
import { useEffect, useState } from 'react';
import { PurchaseData } from '../PurchaseData';
import axios from 'axios';
import { getImpersonateData, getUser } from 'services/authServices';
import { originToColor, GENERIC } from '../../constants';
import { InfoModal } from '../InfoModal';

const StyledHeadCell = styled(TableCell)`
  font-style: normal;
  font-weight: 325;
  font-size: 13px;
  line-height: 16px;

  border-bottom: 2px solid white;
  background-color: ${props => props.color} !important;
  text-align: center;
`;
const StyledTableRow = styled(TableRow)`
  border-bottom: 3px solid white;
  border-top: 3px solid white;
  th:first-child {
    border-radius: 35px 0px 0px 35px;
  }
  th:last-child {
    border-radius: 0px 35px 35px 0px;
  }

  td:first-child {
    border-radius: 35px 0px 0px 35px;
  }
  td:last-child {
    border-radius: 0px 35px 35px 0px;
  }
`;

const StyledTableCell = styled(TableCell)`
  align-items: center;

  font-style: normal;
  font-weight: 325;
  font-size: 13px;
  line-height: 16px;
  background: #f8f8f8;
  border-bottom: 1px solid white;
  display: table-cell;
  overflow: auto;
`;
const StyledTableCellTotal = styled(TableCell)`
  align-items: center;
  font-family: 'Gotham Rounded';
  font-style: normal;
  font-weight: 325;
  font-size: 13px;
  display: table-cell;
  overflow: auto;
  padding: 15px 0px !important;
`;

const Total = styled.div`
  display: flex;
  aling-items: center;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

const Element = styled.div<{ color: string; textColor?: string }>`
  color: ${props => (props.textColor ? props.textColor : '#ffffff')};
  margin-top: 30px;
  border-radius: 35px;
  font-weight: 350;
  height: 59px;
  background: ${props => props.color};
  flex-grow: 1;
  margin: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  padding-right: 20px;
  padding-left: 20px;
`;

const Add = styledComponent.div`
${props =>
  props.color === 'Add' &&
  `
  background: #4F4E24; 
  color: white;
`}
  ${props =>
    props.color === 'Remove' &&
    `
  background: #D98458; 
  color: white;`}
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  height: 33px;
  cursor: pointer;

`;

const Group = styledComponent.div`
  background: #545454;
  font-size: 14px; 
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  height: 40px;
  cursor: pointer;
`;

const Warehouse = styledComponent.div` 
background: #1989b2;
color: white;
display: flex;
justify-content: center;
align-items: center;
border-radius: 30px;
width: 201px;
height: 49px;
margin-left: 14px;
font-weight: 325;
font-size: 15px;
overflow: hidden;
text-align: center;
`;

const QuantityAvailable = styledComponent.div`
width: 11%
`;

interface TableProps {
  coffes?: any[];
  page: string;
  setLoading?: (value: any) => void;
  setLoadingData?: (value: any) => void;
  setIsDownload?: (value: any) => void;
  loading?: any;
}

export function GroupedTableByWarehouse({
  coffes,
  page,
  loading,
  setLoading,
  setLoadingData,
  setIsDownload,
}: TableProps) {
  const theme: any = useTheme();
  const [disabledOptions, setDisabledOptions] = useState<any[]>([]);
  const [open, setOpen] = useState(false);
  const [openModalSign, setOpenModalSign] = useState(false);
  const [info, setInfo] = useState<any[]>([]);
  const [permissions, setPermissions] = useState<string[]>([]);
  const [role, setRole] = useState<any>('');

  function reorderCoffes(coffes) {
    if (coffes && Array.isArray(coffes) && coffes.length > 0) {
      if (coffes[0] && Array.isArray(coffes[0]) && coffes[0].length > 0) {
        const firstCoffee = coffes[0][0];
        if (firstCoffee && firstCoffee.warehouse === 'Afloat') {
          // Verificar si el primer arreglo es "afloat" y moverlo al final
          coffes.push(coffes.shift());
        }
      }
      if (coffes.length > 1) {
        const secondCoffee = coffes[1][0];
        if (secondCoffee && secondCoffee.warehouse === 'Contracted') {
          // Verificar si el segundo arreglo es "contracted" y moverlo al final
          coffes.push(coffes.splice(1, 1)[0]);
        }
      }
      if (
        coffes[0] &&
        Array.isArray(coffes[0]) &&
        coffes[0][0] &&
        coffes[0][0].warehouse === 'Contracted'
      ) {
        // Verificar si el primer arreglo es "contracted" y moverlo al final
        coffes.push(coffes.shift());
      }
    }
  }

  function BoxesReleaseCounter({ group }) {
    const countReleaseBoxes = () => {
      return group.reduce((total, objeto) => {
        if (objeto.package_type === 'Boxes') {
          const updatedTotal = total + objeto.released_quantity;
          return updatedTotal < 0 ? 0 : updatedTotal;
        }
        return total;
      }, 0);
    };
    return countReleaseBoxes();
  }

  function BagsReleaseCounter({ group }) {
    const countReleaseBags = () => {
      return group.reduce((total, objeto) => {
        if (objeto.package_type === 'Bags') {
          const updatedTotal = total + objeto.released_quantity;
          return updatedTotal < 0 ? 0 : updatedTotal;
        }
        return total;
      }, 0);
    };
    return countReleaseBags();
  }

  function BoxesAvailableCounter({ group }) {
    const countAvailableBoxes = () => {
      return group.reduce((total, objeto) => {
        if (objeto.package_type === 'Boxes') {
          const updatedTotal = total + objeto.available_quantity;
          return updatedTotal < 0 ? 0 : updatedTotal;
        }
        return total;
      }, 0);
    };
    return countAvailableBoxes();
  }

  function BagsAvailableCounter({ group }) {
    const countAvailableBags = () => {
      return group.reduce((total, objeto) => {
        if (objeto.package_type === 'Bags') {
          const updatedTotal = total + objeto.available_quantity;
          return updatedTotal < 0 ? 0 : updatedTotal;
        }
        return total;
      }, 0);
    };
    return countAvailableBags();
  }

  useEffect(() => {
    const role = localStorage.getItem('role');
    setRole(role);
    reorderCoffes(coffes);
  }, []);

  const { t } = useTranslation();

  // const openModal = (ico, type) => {

  const openModal = (ico, type) => {
    setOpenModalSign(!openModalSign);
    const token = localStorage.getItem('caravela_access_token');
    const url = `${process.env.REACT_APP_BASE_URL}/report-error`;
    axios
      .request({
        method: 'POST', // Change the request method to POST
        url: url,
        data: { ico: ico, report: type }, // Use the data object to send the request body
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(response => {
        if (response.status === 200) {
          // Additional code here if needed
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const closeModal = () => {
    setOpenModalSign(!openModalSign);
  };

  useEffect(() => {
    getImpersonateData()
      .then(response => {
        if (response.status === 200) {
          const permissionsRaw =
            response.data.Impersonate === 0
              ? response.data.permissions
              : response.data.original_permissions;
          const filteredPermissions = permissionsRaw.filter(
            value => value.name.split(';')[1] === 'Customer',
          );
          const permissionNames = filteredPermissions.map(
            module => module.name.split(';')[0],
          );
          setPermissions(permissionNames);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  const openAndPopulate = (
    quantity: string,
    size: string,
    package_type: string,
    type: string,
  ) => {
    populateModal(quantity, size, package_type, type);
    openCloseModal();
  };

  const openCloseModal = () => {
    setOpen(!open);
  };

  const getAvailabilityCall = (contract, ico) => {
    setLoadingData && setLoadingData(true);
    setIsDownload && setIsDownload(true);
    let url =
      process.env.REACT_APP_BASE_URL +
      `/reports/availability?contract=${contract}&ico=${ico}`;
    const token = localStorage.getItem('caravela_access_token');
    axios({
      method: 'GET',
      url,
      responseType: 'blob',
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(response => {
        if (response.status === 200) {
          setLoadingData && setLoadingData(false);
          setIsDownload && setIsDownload(false);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Availability.pdf'); //or any other extension
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch(error => {
        setLoadingData && setLoadingData(false);
        setIsDownload && setIsDownload(false);
        console.log(error);
      });
  };

  const getTraceabilityCall = (contract, ico) => {
    setLoadingData && setLoadingData(true);
    setIsDownload && setIsDownload(true);
    let url =
      process.env.REACT_APP_BASE_URL +
      `/reports/traceability?contract=${contract}&ico=${ico}`;
    const token = localStorage.getItem('caravela_access_token');
    axios({
      method: 'GET',
      url,
      responseType: 'blob',
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(response => {
        if (response.status === 200) {
          setLoadingData && setLoadingData(false);
          setIsDownload && setIsDownload(false);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Traceability.pdf'); //or any other extension
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch(error => {
        setLoadingData && setLoadingData(false);
        setIsDownload && setIsDownload(false);
        console.log(error);
      });
  };

  const getTransparencyCall = (contract, ico) => {
    setLoadingData && setLoadingData(true);
    setIsDownload && setIsDownload(true);
    let url =
      process.env.REACT_APP_BASE_URL +
      `/reports/transparency?contract=${contract}&ico=${ico}`;
    const token = localStorage.getItem('caravela_access_token');
    axios({
      method: 'GET',
      url,
      responseType: 'blob',
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(response => {
        if (response.status === 200) {
          setLoadingData && setLoadingData(false);
          setIsDownload && setIsDownload(false);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Transparency.pdf'); //or any other extension
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch(error => {
        setLoadingData && setLoadingData(false);
        setIsDownload && setIsDownload(false);
        console.log(error);
      });
  };

  const populateModal = (
    quantity: string,
    size: string,
    package_type: string,
    type: string,
  ) => {
    const arr = [quantity, size, package_type, type];
    setInfo(arr);
  };

  useEffect(() => {
    let array = [];
    let contracts = JSON.parse(localStorage.getItem('contracts') || '[]');
    contracts
      ? setDisabledOptions(contracts)
      : localStorage.setItem('contracts', JSON.stringify(array));
  }, []);

  const setContract = (contract: string) => {
    const newDisabledArray: any[] = [...disabledOptions, contract];
    setDisabledOptions(newDisabledArray);
    localStorage.setItem('contracts', JSON.stringify(newDisabledArray));
  };

  const removeContract = (contract: string) => {
    let contractDelete = disabledOptions.filter(elem => elem !== contract);
    setDisabledOptions(contractDelete);
    localStorage.setItem('contracts', JSON.stringify(contractDelete));
  };

  const totalboxesrelease = 0;
  return openModalSign === true ? (
    <InfoModal closeModal={closeModal} openModalSign={openModalSign} />
  ) : coffes ? (
    <ThemeProvider theme={theme}>
      {open === true ? (
        <PurchaseData
          openCloseModal={openCloseModal}
          quantity={info[0]}
          size={info[1]}
          package_type={info[2]}
          type={info[3]}
        />
      ) : null}
      {coffes
        .filter(group => group.length > 0)
        .map(group => (
          // item.map(group => (
          <>
            <div
              className="d-flex align-items-center mt-3 mb-4"
              key={
                group[0].status === 'Afloat' || group[0].status === 'Contracted'
                  ? group[0].status
                  : group[0].warehouse
              }
            >
              <h1 style={{ color: theme.palette.primary.main }}>
                {group[0].status === 'Afloat' ||
                group[0].status === 'Contracted'
                  ? group[0].status
                  : group[0].warehouse}
              </h1>
              {/* <Warehouse>{group[0].warehouse}</Warehouse> */}
            </div>
            <TableContainer>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <StyledTableRow
                    sx={{
                      '& th': {
                        color: theme.palette.secondary.contrastText,
                        backgroundColor: theme.palette.secondary.main,
                      },
                    }}
                  >
                    <StyledHeadCell>
                      {t(translations.table.contract)}
                    </StyledHeadCell>
                    <StyledHeadCell align="center">
                      {t(translations.table.ico)}
                    </StyledHeadCell>
                    <StyledHeadCell align="center">
                      {t(translations.table.marks)}
                    </StyledHeadCell>
                    <StyledHeadCell align="center">
                      {t(translations.table.quality_grade)}
                    </StyledHeadCell>
                    {page !== 'report' && (
                      <>
                        <StyledHeadCell align="center">
                          {t(translations.table.warehouse_reference)}
                        </StyledHeadCell>
                        <StyledHeadCell align="center">
                          {t(translations.table.origin)}
                        </StyledHeadCell>
                        <StyledHeadCell align="center">
                          {t(translations.table.av_date)}
                        </StyledHeadCell>
                        <StyledHeadCell align="center">
                          {t(translations.table.q_released)}
                        </StyledHeadCell>
                        <StyledHeadCell align="center">
                          {t(translations.table.q_available)}
                        </StyledHeadCell>
                      </>
                    )}
                    {page === 'report' &&
                      permissions &&
                      permissions.includes('Downloads') && (
                        <>
                          <StyledHeadCell align="center">
                            {t(translations.table.availability)}
                          </StyledHeadCell>
                          <StyledHeadCell align="center">
                            {t(translations.table.traceability)}
                          </StyledHeadCell>
                          <StyledHeadCell align="center">
                            {t(translations.table.transparency)}
                          </StyledHeadCell>
                        </>
                      )}
                    {page === 'purchase' &&
                    permissions &&
                    permissions.includes('Purchase Order') ? (
                      <StyledHeadCell
                        color={theme.palette.primary.main}
                        style={{ color: theme.palette.primary.contrastText }}
                        align="center"
                      >
                        {t(translations.table.p_progress)}
                      </StyledHeadCell>
                    ) : null}
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {group.map(
                    // subitem =>
                    // item.map(subitem =>
                    // subitem.map(
                    groupBy => (
                      // groupBy.origin === group[0].origin &&
                      // groupBy.warehouse === group[0].warehouse && (
                      <StyledTableRow key={groupBy.contract}>
                        <StyledTableCell align="center">
                          {groupBy.contract}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {page !== 'report' ? groupBy.ico_number : groupBy.ico}
                        </StyledTableCell>

                        <StyledTableCell
                          align="center"
                          style={{
                            textOverflow: 'ellipsis',
                            display: groupBy.broshure ? 'flex' : 'table-cell',
                            justifyContent: 'center',
                          }}
                        >
                          {groupBy.broshure ? (
                            <a
                              href={groupBy.broshure}
                              style={{
                                textDecoration: 'none',
                                borderRadius: '30px',
                                background: theme.palette.primary.main,
                                color: 'white',
                                padding: '15px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '40px',
                                width: '160px',
                              }}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {groupBy.mark}
                            </a>
                          ) : (
                            groupBy.mark
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {groupBy.quality}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {groupBy.warehouse_reference}
                        </StyledTableCell>
                        {page !== 'report' && (
                          <>
                            <StyledTableCell align="center">
                              <div
                                style={{
                                  borderRadius: '30px',
                                  background: groupBy.origin
                                    ? originToColor[
                                        groupBy.origin.toUpperCase()
                                      ] || GENERIC
                                    : GENERIC,
                                  color: 'white',
                                  padding: '15px',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  height: '40px',
                                  width: '160px',
                                }}
                              >
                                {groupBy.origin}
                              </div>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {groupBy.available_date}
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              onClick={() =>
                                openAndPopulate(
                                  groupBy.released_quantity +
                                    groupBy.available_quantity,
                                  groupBy.size,
                                  groupBy.packaging_type,
                                  groupBy.package_type,
                                )
                              }
                            >
                              <Group>
                                {groupBy.released_quantity < 0
                                  ? 0
                                  : groupBy.released_quantity}{' '}
                                {groupBy.package_type === 'Bags'
                                  ? t(translations.table.bags).slice(0, -1)
                                  : t(translations.table.boxes).slice(0, -1)}
                              </Group>
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              onClick={() =>
                                openAndPopulate(
                                  groupBy.released_quantity +
                                    groupBy.available_quantity,
                                  groupBy.size,
                                  groupBy.packaging_type,
                                  groupBy.package_type,
                                )
                              }
                            >
                              <Group>
                                {groupBy.available_quantity < 0
                                  ? 0
                                  : groupBy.available_quantity}{' '}
                                {groupBy.package_type === 'Bags'
                                  ? t(translations.table.bags).slice(0, -1)
                                  : t(translations.table.boxes).slice(0, -1)}
                              </Group>
                            </StyledTableCell>
                            {page === 'purchase' &&
                            permissions &&
                            permissions.includes('Purchase Order') ? (
                              <StyledTableCell align="center">
                                {!disabledOptions.find(
                                  element => element === groupBy.contract,
                                ) &&
                                  (groupBy.available_quantity > 0 ? (
                                    <Add
                                      color="Add"
                                      id={`add-${groupBy.contract}`}
                                      onClick={() =>
                                        setContract(groupBy.contract)
                                      }
                                    >
                                      {t(translations.table.add)}
                                    </Add>
                                  ) : (
                                    <Add
                                      color="Add"
                                      id={`add-${groupBy.contract}`}
                                      style={{
                                        backgroundColor:
                                          theme.palette.grey.A200,
                                        cursor: 'no-drop',
                                      }}
                                    >
                                      {t(translations.table.add)}
                                    </Add>
                                  ))}
                                {disabledOptions.find(
                                  element => element === groupBy.contract,
                                ) && (
                                  <Add
                                    color="Remove"
                                    id={`remove-${groupBy.contract}`}
                                    onClick={() =>
                                      removeContract(groupBy.contract)
                                    }
                                  >
                                    {t(translations.table.remove)}
                                  </Add>
                                )}
                              </StyledTableCell>
                            ) : null}
                          </>
                        )}
                        {page === 'report' &&
                          permissions &&
                          permissions.includes('Downloads') && (
                            <>
                              <StyledTableCell
                                onClick={() =>
                                  groupBy.visible === '1' || role !== 'Customer'
                                    ? getAvailabilityCall(
                                        groupBy.contract,
                                        groupBy.ico,
                                      )
                                    : openModal(groupBy.ico, 'Availability')
                                }
                                align="center"
                              >
                                <FileDownloadIcon
                                  style={{
                                    color: '#1989B2',
                                    cursor: 'pointer',
                                  }}
                                />
                              </StyledTableCell>
                              <StyledTableCell
                                onClick={() =>
                                  groupBy.visible === '1' || role !== 'Customer'
                                    ? getTraceabilityCall(
                                        groupBy.contract,
                                        groupBy.ico,
                                      )
                                    : openModal(groupBy.ico, 'Traceability')
                                }
                                align="center"
                              >
                                <FileDownloadIcon
                                  style={{
                                    color: '#1989B2',
                                    cursor: 'pointer',
                                  }}
                                />
                              </StyledTableCell>
                              <StyledTableCell
                                onClick={() =>
                                  groupBy.visible === '1' || role !== 'Customer'
                                    ? getTransparencyCall(
                                        groupBy.contract,
                                        groupBy.ico,
                                      )
                                    : openModal(groupBy.ico, 'Transparency')
                                }
                                align="center"
                              >
                                <FileDownloadIcon
                                  style={{
                                    color: '#1989B2',
                                    cursor: 'pointer',
                                  }}
                                />
                              </StyledTableCell>
                            </>
                          )}
                      </StyledTableRow>
                    ),
                    //     ),
                    // ),
                    // ),
                  )}
                  {page !== 'report' && group[0].status !== 'Contracted' && (
                    <StyledTableRow>
                      <StyledTableCellTotal></StyledTableCellTotal>
                      <StyledTableCellTotal></StyledTableCellTotal>
                      <StyledTableCellTotal></StyledTableCellTotal>
                      <StyledTableCellTotal></StyledTableCellTotal>
                      <StyledTableCellTotal></StyledTableCellTotal>
                      <StyledTableCellTotal></StyledTableCellTotal>
                      <StyledTableCellTotal align="center">
                        <div
                          style={{
                            fontWeight: '350',
                            fontSize: '20px',
                          }}
                        >
                          <Total>{t(translations.table.total)}</Total>
                        </div>
                      </StyledTableCellTotal>
                      <StyledTableCellTotal>
                        <Element
                          color={theme.palette.secondary.main}
                          textColor={theme.palette.secondary.contrastText}
                          style={{ padding: '0px' }}
                        >
                          {''}
                          {BoxesReleaseCounter({ group }) || 0 > 0 ? (
                            <>
                              <div>
                                {group.reduce((total, objeto) => {
                                  if (objeto.package_type === 'Boxes') {
                                    const updatedTotal =
                                      total + objeto.released_quantity;
                                    return updatedTotal < 0 ? 0 : updatedTotal;
                                  }
                                  return total;
                                }, 0)}{' '}
                                {t(translations.table.boxes).slice(0, -1)}
                              </div>
                            </>
                          ) : (
                            <></>
                          )}{' '}
                          {BagsReleaseCounter({ group }) > 0 ? (
                            <>
                              <div style={{ marginLeft: '2px' }}>
                                {group.reduce((total, objeto) => {
                                  if (objeto.package_type === 'Bags') {
                                    const updatedTotal =
                                      total + objeto.released_quantity;
                                    return updatedTotal < 0 ? 0 : updatedTotal;
                                  }
                                  return total;
                                }, 0)}{' '}
                                {t(translations.table.bags).slice(0, -1)}
                              </div>
                            </>
                          ) : (
                            <>
                              {0 +
                                ' ' +
                                t(translations.table.bags).slice(0, -1)}
                            </>
                          )}
                        </Element>
                      </StyledTableCellTotal>
                      <StyledTableCellTotal>
                        <Element
                          color={theme.palette.secondary.main}
                          textColor={theme.palette.secondary.contrastText}
                          style={{ padding: '0px' }}
                        >
                          {''}
                          {BoxesAvailableCounter({ group }) || 0 > 0 ? (
                            <>
                              <div>
                                {group.reduce((total, objeto) => {
                                  if (objeto.package_type === 'Boxes') {
                                    const updatedTotal =
                                      total + objeto.available_quantity;
                                    return updatedTotal < 0 ? 0 : updatedTotal;
                                  }
                                  return total;
                                }, 0)}{' '}
                                {t(translations.table.boxes).slice(0, -1)}
                              </div>
                            </>
                          ) : (
                            <></>
                          )}{' '}
                          {BagsAvailableCounter({ group }) || 0 > 0 ? (
                            <>
                              <div style={{ marginLeft: '2px' }}>
                                {group.reduce((total, objeto) => {
                                  if (objeto.package_type === 'Bags') {
                                    const updatedTotal =
                                      total + objeto.available_quantity;
                                    return updatedTotal < 0 ? 0 : updatedTotal;
                                  }
                                  return total;
                                }, 0)}{' '}
                                {t(translations.table.bags).slice(0, -1)}
                              </div>
                            </>
                          ) : (
                            <>
                              {0 +
                                ' ' +
                                t(translations.table.bags).slice(0, -1)}
                            </>
                          )}
                        </Element>
                      </StyledTableCellTotal>
                    </StyledTableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ))}
    </ThemeProvider>
  ) : null;
}

function flat(arr: any[]): any {
  let flatArray = arr.reduce((acc, curVal) => {
    return acc.concat(curVal);
  }, []);
  return flatArray;
}

function suma(
  arg0: any,
  arg1: any,
):
  | import('react-i18next').ReactI18NextChild
  | Iterable<import('react-i18next').ReactI18NextChild> {
  return arg0[0] !== undefined && arg1[0] !== undefined
    ? parseInt(arg0) + parseInt(arg1)
    : arg0[0] === undefined && arg1[0] !== undefined
    ? parseInt(arg1)
    : arg1[0] === undefined && arg0[0] !== undefined
    ? parseInt(arg0)
    : 0;
}
