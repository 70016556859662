import * as React from 'react';
import Loading from '../../assets/SVG/saco.gif';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components/macro';

const Center = styled.div`
  display: flex;
  // align-items: center;
  justify-content: center;
  margin: auto;
`;

export function LoadingPage() {
  return (
    <>
      <Helmet>
        <title>Loading</title>
        <meta name="description" content="Roasterhub registration" />
      </Helmet>
      <Center>
        <img src={Loading} alt="" style={{ width: '300px', height: 'auto' }} />
      </Center>
    </>
  );
}
