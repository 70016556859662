import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import styled from 'styled-components/macro';
import { CircularProgress, ThemeProvider, useMediaQuery } from '@mui/material';
import styledComponent from 'styled-components/macro';
import { useTheme } from '@mui/material/styles';
import { CustomButton } from '../Button';
import Dropdown from '../Dropdown';
import { IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import TrashImg from 'app/assets/SVG/trash.png';
import SpoonB from 'app/assets/SVG/cupb.png';
import SpoonG from 'app/assets/SVG/cupg.png';
import { useRef, useState } from 'react';
import drop from '../../assets/SVG/dropdown.svg';
import { DetailCupping } from '../DetailCupping';

export const SlimButton = styled(CustomButton)`
  font-style: normal;
  font-weight: 325;
  font-size: 13px;
  line-height: 16px;
  margin: 5px;
`;

const Group = styledComponent.div`
  background: #1989B2; 
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  height: 33px;
  cursor: pointer;
`;

const StyledHeadCell = styled(TableCell)`
  font-style: normal;
  font-weight: 325;
  font-size: 13px;
  line-height: 16px;
  border: 1px solid white;
  color: black;
  background: #ebeaea;
`;

const Add = styledComponent.div`
${props =>
  props.color === 'Add' &&
  `
  background: #4f4e24; 
  color: white;
`}
  ${props =>
    props.color === 'Remove' &&
    `
  background: #F33636; 
  color: white;`}
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  height: 33px;
  padding: 0 10px;
  cursor: pointer;

`;

const StyledTableRow = styled(TableRow)`
  border-bottom: 3px solid white;
  border-top: 3px solid white;
  th:first-child {
    border-radius: 35px 0px 0px 35px;
  }
  th:last-child {
    border-radius: 0px 35px 35px 0px;
  }
  td:first-child {
    border-radius: 35px 0px 0px 35px;
  }
  td:last-child {
    border-radius: 0px 35px 35px 0px;
  }
`;

const Search = styled.div`
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  background: #f6f6f6;
  border-radius: 30px;
  width: 205.16px;
  height: 40px;
  margin: 10px 10px;
  outline: none;
  padding: 10px;
`;

const Clean = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  margin-left: 20px;
  color: #1989b2;
  margin-bottom: 10px;
`;

const StyledTableCell = styled(TableCell)`
  align-items: center;

  font-style: normal;
  font-weight: 325;
  font-size: 13px;
  line-height: 16px;
  background: #f8f8f8;
  border: 1px solid white;
  display: table-cell;
  overflow: auto;
`;

const StyledTableCellMobile = styled(TableCell)`
  align-items: center;

  font-style: normal;
  font-weight: 325;
  font-size: 13px;
  line-height: 16px;
  background: #f8f8f8;
  // border: 1px solid white;
  width: calc(20% - 1px);
  box-sizing: border-box;
`;

const StyledHeadCellMobile = styled(TableCell)`
  font-style: normal;
  font-weight: 325;
  font-size: 13px;
  line-height: 16px;
  // border: 1px solid white;
  background: #ebeaea;
  background: ${props => props.color};
  width: calc(20% - 1px);
  box-sizing: border-box;
`;

const ContainerHeader = styled.div`
  display: flex;
  width: 100%;
  background: #ebeaea;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const Title = styled.div`
  font-size: 30px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
`;

const ContainerBody = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  background: #f8f8f8;
`;

interface TableDetailProps {
  setDetailOpen: (detail: boolean) => void;
  idTable: any;
  infoTable: any;
}

let hash = {};

export function DetailTable({
  setDetailOpen,
  idTable,
  infoTable,
}: TableDetailProps) {
  const theme = useTheme();
  const { t } = useTranslation();

  const isMobile = useMediaQuery('(max-width:767px)');
  console.log(infoTable);

  return (
    <ThemeProvider theme={theme}>
      <div
        className="d-flex justify-content-start mb-5 mt-5"
        style={{ flexDirection: 'column' }}
      >
        <Title>Cupping Table {idTable}</Title>
        <div
          className="mt-2"
          style={{ color: '#4f4e24', cursor: 'pointer' }}
          onClick={() => setDetailOpen(false)}
        >
          Return
        </div>
      </div>
      <>
        <TableContainer>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <StyledTableRow>
                <StyledHeadCell align="center">
                  {t(translations.table.sample)}
                </StyledHeadCell>
                <StyledHeadCell align="center">
                  {t(translations.pages.profile.username)}
                </StyledHeadCell>
                <StyledHeadCell align="center">
                  {t(translations.cupping.flavours)}
                </StyledHeadCell>
                <StyledHeadCell align="center">
                  {t(translations.cupping.overall)}
                </StyledHeadCell>
                <StyledHeadCell align="center">
                  {t(translations.cupping.body)}
                </StyledHeadCell>
                <StyledHeadCell align="center">
                  {t(translations.cupping.alternatives)}
                </StyledHeadCell>
                <StyledHeadCell align="center">
                  {t(translations.cupping.defects)}
                </StyledHeadCell>
                <StyledHeadCell align="center">
                  {t(translations.cupping.roast)}
                </StyledHeadCell>
                <StyledHeadCell align="center">
                  {t(translations.cupping.score)}
                </StyledHeadCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {/* {historyContract.map(groupBy => */}
              {infoTable.map(item => (
                <StyledTableRow>
                  <StyledTableCell align="center">
                    {item.sample}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {item.ContactName}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {item.flavours}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {item.overall}
                  </StyledTableCell>
                  <StyledTableCell align="center">{item.body}</StyledTableCell>
                  <StyledTableCell align="center">
                    {item.alternatives}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {item.defects}
                  </StyledTableCell>
                  <StyledTableCell align="center">{item.roast}</StyledTableCell>
                  <StyledTableCell align="center" style={{ color: '#4f4e24' }}>
                    {item.score}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    </ThemeProvider>
  );
}
