import { useState, useEffect } from 'react';
import { Footer } from 'app/components/Footer';
import { NavBar } from 'app/components/NavBar';
import { SideBar } from 'app/components/SideBar';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Outlet, useOutlet } from 'react-router-dom';
import styled from 'styled-components/macro';
import { Home } from '../Home';
import { LoadingPage } from '../LoadingPage';
import CircularProgress from '@mui/material/CircularProgress';
import { getImpersonateData } from 'services/authServices';
import { useMediaQuery } from '@mui/material';

const FlexColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: 92vh;
  width: 100%;
`;
const Container = styled(FlexColumnDiv)`
  height: 100vh;
`;

const OutletContainer = styled(FlexColumnDiv)`
  height: 82vh;
  overflow-y: scroll;
`;

const FlexRowDiv = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

interface HomeLayoutProp {
  pendingNotifications: boolean;
  purchaseNotification: any;
  setPendingNotifications: (value) => void;
  isImpersonated: any;
  roles: any;
  acc: any;
}

export function HomeLayout({
  pendingNotifications,
  setPendingNotifications,
  purchaseNotification,
  isImpersonated,
  roles,
  acc,
}: HomeLayoutProp) {
  const outlet = useOutlet();
  // const [loading, setLoading] = useState<Boolean>(true);
  const [loading, setLoading] = useState<Boolean>(false);
  const [loadingData, setLoadingData] = useState<Boolean>(false);
  const [newImpersonation, setNewImpersonation] = useState<Boolean>(false);

  const isMobile = useMediaQuery('(max-width:767px)');

  return loadingData ? (
    <LoadingPage />
  ) : (
    // <div
    //   style={{
    //     position: 'fixed',
    //     top: 0,
    //     left: 0,
    //     width: '100%',
    //     height: '100%',
    //     background: 'rgba(0, 0, 0, 0.5)',
    //     display: 'flex',
    //     justifyContent: 'center',
    //     alignItems: 'center',
    //   }}
    // >
    //   <CircularProgress color="primary" />
    // </div>
    <>
      <Helmet>
        <title>HomeLayout</title>
        <meta name="description" content="Coffee ordering app HomeLayout" />
      </Helmet>
      {loading ? (
        <LoadingPage />
      ) : (
        <Container>
          <FlexRowDiv>
            <SideBar newImpersonation={newImpersonation} />
            <FlexColumnDiv>
              <NavBar
                pendingNotifications={pendingNotifications}
                setPendingNotifications={setPendingNotifications}
                purchaseNotification={purchaseNotification}
                setNewImpersonation={setNewImpersonation}
                newImpersonation={newImpersonation}
                setLoadingData={setLoadingData}
                setLoading={setLoading}
                // isImpersonated={isImpersonated}
                // roles={roles}
                // acc={acc}
              />
              <OutletContainer>
                {outlet ? <Outlet /> : <Home acc={acc} />}
              </OutletContainer>
            </FlexColumnDiv>
          </FlexRowDiv>
          {!isMobile && <Footer />}
        </Container>
      )}
    </>
  );
}
