import { MenuItem, Select } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { styledTheme } from '../../../styles/theme';
interface ControlledDropdownProps {
  list: any;
  value: any;
  handleChange: (string) => void;
  withEmptyValue: boolean;
  emptyValue?: string;
}

const CustomSelect = styled(Select)`
  background: #f7f7f7;
  border-radius: 30px;
  min-width: 12%;
`;

const ControlledDropdown = ({
  list,
  value,
  handleChange,
  withEmptyValue,
  emptyValue,
}: ControlledDropdownProps) => {
  return (
    <CustomSelect
      value={value}
      displayEmpty
      onChange={handleChange}
      renderValue={(selected: any) => {
        if (selected.length === 0)
          return (
            <em style={{ color: styledTheme.Gray }}>
              {emptyValue ? emptyValue : '--------'}
            </em>
          );
        else return selected;
      }}
    >
      {withEmptyValue && (
        <MenuItem style={{ color: styledTheme.Gray }} value="">
          <em>{emptyValue ? emptyValue : '--------'}</em>
        </MenuItem>
      )}
      {list &&
        list.map(opc => (
          <MenuItem key={opc.value} value={opc.value}>
            {opc.label}
          </MenuItem>
        ))}
    </CustomSelect>
  );
};

export default ControlledDropdown;
