import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

class ServiceClient {
  client: AxiosInstance;
  constructor(config: AxiosRequestConfig) {
    this.client = axios.create(config);
    this.client.interceptors.request.use(
      config => {
        let token = getToken();
        if (config.headers) {
          config.headers['Authorization'] = 'Bearer ' + token;
          config.headers['X-Frame-Options'] = 'SAMEORIGIN';
          config.headers['Content-Security-Policy'] = "default-src 'self'";
          config.headers['X-Content-Type-Options'] = 'nosnif';
          config.headers['Referer-Policy'] = 'no-referrer';
          config.headers['Permissions-Policy'] = 'geolocation=(none)';
          config.headers['Strict-Transport-Security'] = 'max-age=31536000';
        }
        return config;
      },
      error => {
        return Promise.reject(error);
      },
    );

    this.client.interceptors.response.use(
      response => {
        return response;
      },
      async error => {
        const originalConfig = error?.config;
        const isLogin = String(originalConfig?.url).endsWith('login');
        if (
          !isLogin &&
          (error?.response?.status === 401 ||
            error?.response?.status === 403) &&
          !originalConfig._retry
        ) {
          originalConfig._retry = true;
          await new Promise(resolve => setTimeout(resolve, 1000));
          return this.client(originalConfig);
        } else if (
          !isLogin &&
          (error?.response?.status === 401 ||
            error?.response?.status === 403) &&
          originalConfig._retry
        ) {
          // window.location.href = `${process.env.REACT_APP_WEBSITE_URL}?reason=401`;
        }

        return Promise.reject(error);
      },
    );
  }

  get(endpoint: string, config: AxiosRequestConfig = {}) {
    return this.client.get<any>(endpoint, config);
  }

  post(endpoint: string, payload: any) {
    return this.client.post<any>(endpoint, payload);
  }

  put(endpoint: string, payload: any) {
    return this.client.put<any>(endpoint, payload);
  }

  delete(endpoint: string, payload?: any) {
    return this.client.delete<any>(endpoint, { data: payload });
  }

  patch(endpoint: string, payload: any) {
    return this.client.patch<any>(endpoint, payload);
  }
}

const getToken = (): string | null => {
  return localStorage.getItem('caravela_access_token');
};

const setToken = (token: string) => {
  localStorage.setItem('caravela_access_token', token);
};

const serviceClient = new ServiceClient({
  baseURL: process.env.REACT_APP_BASE_URL,
  /* headers: {
    Authorization: `Bearer ${localStorage.getItem('caravela_access_token')}`
   }, */
});

export default serviceClient;
