import serviceClient from 'services/serviceClientConfig';

export const getListCustomers = id => {
  return serviceClient.get(`/edit-reports/customers?id=${id}`);
};

export const getListReports = (importer, customer) => {
  return serviceClient.get(
    `/edit-reports/list?importer=${importer}&customer=${customer}`,
  );
};

export const getReportDetails = ico => {
  return serviceClient.get(`/edit-reports/reportConfidential?ico=${ico}`);
};

export const saveReportData = data => {
  return serviceClient.post('/edit-reports/saveReportEdit', data);
};
