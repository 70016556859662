import serviceClient from './serviceClientConfig';

export const getSamples = () => {
  return serviceClient.get('/sample-request');
};

export const getListCoffees = () => {
  return serviceClient.get('/list-coffees');
};

export const createExternalSamples = payload => {
  return serviceClient.post('/external-samples', payload);
};

export const createTable = payload => {
  return serviceClient.post('/cupping-table', payload);
};

export const deleteTable = table => {
  return serviceClient.delete(`/cupping-table/?id=${table}`);
};

export const getListTablesCoffees = () => {
  return serviceClient.get('/list-tables-coffee');
};

export const getListItemsEvaluation = () => {
  return serviceClient.get('/list-items-evaluation');
};

export const getSamplesCup = tableIdCup => {
  return serviceClient.get(`/samples-cupping?tableIdCup=${tableIdCup}`);
};

export const getListUsersCompany = () => {
  return serviceClient.get('/list-user-company');
};

export const getCountriesSamples = () => {
  return serviceClient.get('/countries-samples');
};

export const saveCupping = payload => {
  return serviceClient.post('/save-cupping-coffee', payload);
};

export const getResultOptionsCup = idSampleCup => {
  return serviceClient.get(`/result-sample-cup?idSampleCup=${idSampleCup}`);
};

export const getInfoSample = idSampleCup => {
  return serviceClient.get(`/info-sample?idSampleCup=${idSampleCup}`);
};

export const rejectedReasons = () => {
  return serviceClient.get(`/rejected-reasons`);
};

export const finishCupping = payload => {
  return serviceClient.post('/finish-cup', payload);
};

export const confirmReceived = payload => {
  return serviceClient.post('/confirm-received', payload);
};

export const getSamplesReceived = () => {
  return serviceClient.get('/samples-received');
};
