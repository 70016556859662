import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import styled, { useTheme } from 'styled-components/macro';
import caravela from '../../../app/assets/SVG/logo-caravela-2-SIN-LINEAS 2.png';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { EmailPayload } from 'models/authModels';
import { useState } from 'react';
import { resetPass } from 'services/authServices';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';

const WrapContainer = styled.div<{ backgroundColor?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background: ${props =>
    props.backgroundColor ? props.backgroundColor : '#5c36f3'};
`;

const Login = styled.div`
  width: 518px;
  height: 708px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: #ffffff;
  border-radius: 30px;
  z-index: 1000;
  padding: 20px;
  cursor: pointer;

  @media (max-width: 375px) {
    width: 100%;
    height: 100%;
    border-radius: 0;
    padding: 10px;
  }
`;

const InputText = styled.div`
  font-style: normal;
  font-weight: 350;
  font-size: 20px;
  line-height: 24px;
  color: #545454;
  margin: 10px 0;
  max-width: 100%; /* Añadido */

  @media (max-width: 420px) {
    text-align: center; /* Añadido */
    margin: 10px auto; /* Añadido */
  }
`;

const Input = styled.input`
  width: 426.84px;
  height: 48.71px;
  left: 519.78px;
  top: 391.99px;
  background: #f9f9f9;
  border-radius: 30px;
  border: 0;
  padding: 0 20px;

  @media (max-width: 420px) {
    width: 326.84px;
    height: 48.71px;
    text-align: center; /* Añadido */
    margin: 10px auto; /* Añadido */
  }
`;

const Button = styled.input<{ backgroundColor: string }>`
  background: ${props =>
    props.backgroundColor ? props.backgroundColor : '#5c36f3'};
  color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  margin: 25px 0 70px;
  width: 213.02px;
  height: 49.59px;
`;

const RegisterText = styled.div`
  font-style: normal;
  font-weight: 325;
  font-size: 20px;
  line-height: 24px;
  text-align: center;

  color: #545454;
`;

const CloseButton = styled.div`
  font-size: 34px;
  font-weight: bold;
  cursor: pointer;
  margin-left: auto;
  padding: 0 15px;
`;

export function ResetPassPage() {
  const theme: any = useTheme();
  const isMobile = useMediaQuery('(max-width:767px)');
  const [email, setEmail] = useState('');
  const { t, i18n } = useTranslation();
  const [sent, setsent] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = event => {
    event.preventDefault();
    const payload: EmailPayload = { email };
    resetPass(payload)
      .then(response => {
        if (response.status === 200) {
          response.data.message === 'Mail sended' && setsent(!sent);
        } else if (response.status === 404) {
          setError(true);
        }
      })
      .catch(error => {
        setError(error.response?.data?.message);
      });
  };

  return (
    <>
      <Helmet>
        <title>{t(translations.pages.reset.reset)}</title>
        <meta name="description" content="Roasterhub login" />
      </Helmet>
      <WrapContainer backgroundColor={theme.palette.primary.main}>
        <Login
          style={{
            width: isMobile ? '90%' : '518px',
            height: isMobile ? '70%' : '708px',
          }}
        >
          <CloseButton onClick={() => navigate(`/`)}>X</CloseButton>
          <img
            src={caravela}
            alt="caravela logo"
            style={{ marginTop: '51px', marginBottom: '31px' }}
            onClick={() => navigate(`/`)}
          />
          <form className="d-flex flex-column" onSubmit={handleSubmit}>
            <InputText>{t(translations.general.email)}</InputText>
            <Input
              type="email"
              style={{ marginBottom: '15px' }}
              onChange={e => setEmail(e.target.value)}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {error && !sent ? (
                <div
                  style={{
                    color: 'red',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                ></div>
              ) : null}
              <Button
                backgroundColor={theme.palette.primary.main}
                type="submit"
                value={t(translations.general.confirm)}
              />
            </div>
          </form>
          {!sent && (
            <RegisterText style={{ fontWeight: '625' }}>
              {t(translations.pages.reset.we_need_confirm)}
            </RegisterText>
          )}
          {sent && (
            <RegisterText style={{ fontWeight: '625', color: 'green' }}>
              {t(translations.general.pass_sent_)}
            </RegisterText>
          )}
        </Login>
      </WrapContainer>
    </>
  );
}
