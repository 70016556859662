import React from 'react';
import styled from 'styled-components/macro';
import {
  InputUnstyled,
  inputUnstyledClasses,
  InputUnstyledProps,
} from '@mui/base';

export const StyledInputWrapper = styled.div`
  background: ${props => props.theme.LightGray};
  border-radius: 30px;
  outline: 0;
  outline: 0px solid black;
  display: flex;
  align-items: center;
  &.${inputUnstyledClasses.focused} {
    outline: 1px solid ${props => props.theme.BlueBell};
  }
`;
const StyledInputElement = styled.input`
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: flex-start;
  text-align: flex-start;
  color: ${props => props.theme.Black};
  background: inherit;
  border: none;
  border-radius: inherit;
  padding: 12px 12px;
  outline: 0;
  height: 5%;
  width: 100%;
  min-width: 10em;
`;
export const CustomInput = React.forwardRef(function CustomInput(
  props: InputUnstyledProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  const { slots, ...other } = props;
  return (
    <InputUnstyled
      slots={{
        root: StyledInputWrapper, //outside div element of custom input
        input: StyledInputElement, // inside input element
        ...slots,
      }}
      {...other}
      ref={ref}
    />
  );
});
