import styled from 'styled-components/macro';

// width: 213.02px;
// height: 49.59px;
// left: 614.53px;
// top: 692.2px;

export const CustomButton = styled.button<{
  background?: string;
  withShadow?: boolean;
  color?: string;
}>`
  background: ${props => props.background || props.theme.BlueBell};
  ${props =>
    props.withShadow && 'box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);'};
  border-radius: 30px;
  padding: 0.5em 2.8em;
  margin: 5px 10px;
  color: ${props => props.color || props.theme.White};
  border: none;
  font-style: normal;
  font-weight: 350;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  &:disabled {
    background-color: ${props => props.theme.Gray};
  }
`;
